// SpeechRecognitionContext.js
import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const SpeechRecognitionContext = createContext(null);

export const SpeechRecognitionProvider = ({ children }) => {
  const { transcript, interimTranscript, finalTranscript, resetTranscript, listening } = useSpeechRecognition();
  
  const startListening = useCallback(() => {
    if (!listening) {
      SpeechRecognition.startListening({ continuous: true, interimResults: true })
        .catch(err => console.error('Error starting listening:', err));
    }
  }, [listening]);

  const stopListening = useCallback(() => {
    if (listening) {
      SpeechRecognition.stopListening();
    }
  }, [listening]);

  return (
    <SpeechRecognitionContext.Provider value={{
      transcript,
      interimTranscript,
      finalTranscript,
      resetTranscript,
      startListening,
      stopListening
    }}>
      {children}
    </SpeechRecognitionContext.Provider>
  );
};

export const useSpeechRecognitionContext = () => useContext(SpeechRecognitionContext);
