import {
  Alert,
  AlertIcon,
  Button,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  Text,
  Stack,
  RadioGroup,
  Select,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import {
  emailValidation,
  TEXT_FIELD_VALIDATION_RULES,
} from '../../../helpers/validation';
import TextConstants from '../../../constants/TextConstants';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import {
  AddressFormFields,
  Order,
  UserAddress,
  UserDataType,
} from '../../../global-components/GlobalTypes';
import AddressForm from '../../common/AddressForm/AddressForm';
import {
  setAddProductsToBag,
  setIsHeaderModalsShown,
} from '../../../store/reducers/commerceReducer';
import { setInfoModal } from '../../../store/reducers/infoModalReducer';
import { createOrder } from '../../../global-components/RequestFactory';
import CountryDropdown from '../../common/CountryDropdown/CountryDropdown';
import { FormikContextType } from 'formik/dist/types';

interface Props {
  cartItems: any;
  cancelCallback: VoidFunction;
}

const PHONE_MIN_LENGTH = 9;
const PHONE_MAX_LENGTH = 11;
const PHONE_CODE = '+1';

const CONDITIONAL_TEXT_FIELD_VALIDATION_RULES = Yup.string().when(
  'isSavedAddressUsed',
  { is: false, then: TEXT_FIELD_VALIDATION_RULES }
);

const validationSchema = Yup.object().shape({
  email: emailValidation(),
  phone: Yup.number()
    .required(TextConstants.ERROR_TEXT.REQUIRED)
    .test(
      'length',
      TextConstants.ORDER_CONTACT_INFO.PHONE_LENGTH,
      (value) =>
        value &&
        value.toString().length >= PHONE_MIN_LENGTH &&
        value.toString().length <= PHONE_MAX_LENGTH
    ),
  country: Yup.string().required(TextConstants.ERROR_TEXT.REQUIRED),
  state: CONDITIONAL_TEXT_FIELD_VALIDATION_RULES,
  city: CONDITIONAL_TEXT_FIELD_VALIDATION_RULES,
  zipcode: CONDITIONAL_TEXT_FIELD_VALIDATION_RULES,
  addressLine1: CONDITIONAL_TEXT_FIELD_VALIDATION_RULES,
  addressLine2: CONDITIONAL_TEXT_FIELD_VALIDATION_RULES,
  isSavedAddressUsed: Yup.boolean(),
});

const getDefaultAddress = (userData?: UserDataType) => {
  return (
    userData &&
    userData.addresses &&
    (userData.addresses.find((address) => address.isDefault) ||
      userData.addresses[0])
  );
};

const getAddressById = (id: string, addresses: UserAddress[]) => {
  return addresses.find((address) => address.id === Number(id));
};

const getAddressInlineText = (address: UserAddress) => {
  return `${address.addressLine1} ${address.addressLine2}, ${address.city} ${address.state} ${address.zipcode}`;
};

const getAddressFieldsProps = (
  formik: FormikContextType<any>
): AddressFormFields => {
  return {
    state: {
      ...formik.getFieldProps('state'),
      ...formik.getFieldMeta('state'),
    },
    city: { ...formik.getFieldProps('city'), ...formik.getFieldMeta('city') },
    zipcode: {
      ...formik.getFieldProps('zipcode'),
      ...formik.getFieldMeta('zipcode'),
    },
    addressLine1: {
      ...formik.getFieldProps('addressLine1'),
      ...formik.getFieldMeta('addressLine1'),
    },
    addressLine2: {
      ...formik.getFieldProps('addressLine2'),
      ...formik.getFieldMeta('addressLine2'),
    },
  };
};

const extractPhoneNumberWithoutCode = (phone?: string): string =>
  phone?.replace(PHONE_CODE, '');

function OrderContactInfo({ cartItems, cancelCallback }: Props) {
  const dispatch = useDispatch();
  const userData = useSelector(
    (state: RootState) => state.authenticationState.userData
  );
  const formik = useFormik({
    initialValues: {
      email: userData?.email ?? '',
      phone: extractPhoneNumberWithoutCode(userData?.phoneNumber) ?? '',
      country: 'US',
      state: '',
      city: '',
      zipcode: '',
      addressLine1: '',
      addressLine2: '',
      isSavedAddressUsed: Boolean(userData && userData.addresses.length),
    },
    onSubmit: (values) => {
      const order: Order = {
        contactInformation: {
          email: values.email,
          userId: userData?.id,
          phone: `${PHONE_CODE}${values.phone}`,
          shippingAddress: {
            country: values.isSavedAddressUsed
              ? userData.country
              : values.country,
            state: values.isSavedAddressUsed
              ? selectedSavedAddress.state
              : values.state,
            city: values.isSavedAddressUsed
              ? selectedSavedAddress.city
              : values.city,
            zipcode: values.isSavedAddressUsed
              ? selectedSavedAddress.zipcode
              : values.zipcode,
            addressLine1: values.isSavedAddressUsed
              ? selectedSavedAddress.addressLine1
              : values.addressLine1,
            addressLine2: values.isSavedAddressUsed
              ? selectedSavedAddress.addressLine2
              : values.addressLine2,
          },
        },
        orderItems: cartItems.map((cartItem) => ({
          variantId: cartItem.productVariant.id,
          quantity: cartItem.quantity || 1,
        })),
      };
      setIsRequesting(true);
      createOrder(order)
        .then(() => {
          setIsRequesting(false);
          dispatch(setIsHeaderModalsShown({ myBagDrawer: false }));
          dispatch(setAddProductsToBag([]));
          dispatch(
            setInfoModal({
              isOpen: true,
              title: TextConstants.THANK_FOR_ORDER_MODAL.TITLE,
              message: TextConstants.THANK_FOR_ORDER_MODAL.MESSAGE,
            })
          );
          cancelCallback();
        })
        .catch((error) => {
          setIsRequesting(false);
          setRequestError({ isErrorShown: true, errorText: error.message });
        });
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });
  const [isRequesting, setIsRequesting] = useState(false);
  const [requestError, setRequestError] = useState({
    isErrorShown: false,
    errorText: '',
  });
  const [selectedSavedAddress, setSelectedSavedAddress] = useState(
    getDefaultAddress(userData)
  );

  return (
    <>
      <DrawerBody marginTop="15px">
        <FormControl isInvalid={!!formik.errors.email}>
          <FormLabel>
            {TextConstants.ORDER_CONTACT_INFO.EMAIL_INPUT_LABEL}
          </FormLabel>
          <Input
            name="email"
            borderRadius="none"
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder={
              TextConstants.ORDER_CONTACT_INFO.EMAIL_INPUT_PLACEHOLDER
            }
          />
          <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!formik.errors.phone} pt="20px">
          <FormLabel>
            {TextConstants.ORDER_CONTACT_INFO.PHONE_INPUT_LABEL}
          </FormLabel>
          <InputGroup>
            <InputLeftAddon borderRadius="none" children="+1" />
            <Input
              name="phone"
              borderRadius="none"
              onChange={formik.handleChange}
              value={formik.values.phone}
              placeholder={
                TextConstants.ORDER_CONTACT_INFO.PHONE_INPUT_PLACEHOLDER
              }
            />
          </InputGroup>
          <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
        </FormControl>
        <Text textStyle="title" color="gray.900" py="20px">
          {TextConstants.ORDER_CONTACT_INFO.ADDRESS_TITLE}
        </Text>
        {selectedSavedAddress && (
          <RadioGroup value={formik.values.isSavedAddressUsed ? 1 : 0}>
            <Stack>
              <Radio
                colorScheme="brand"
                value={1}
                onChange={() =>
                  formik.setFieldValue('isSavedAddressUsed', true)
                }
              >
                {TextConstants.ORDER_CONTACT_INFO.SAVED_ADDRESS}
              </Radio>
              <FormControl pt="10px">
                <Select
                  colorScheme="brand"
                  name="savedAddress"
                  borderRadius="none"
                  onChange={(event) =>
                    setSelectedSavedAddress(
                      getAddressById(event.target.value, userData.addresses)
                    )
                  }
                  value={selectedSavedAddress.id}
                  disabled={!formik.values.isSavedAddressUsed}
                >
                  {userData.addresses.map((address) => {
                    return (
                      <option key={address.id} value={address.id}>
                        {getAddressInlineText(address)}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <Radio
                py="10px"
                colorScheme="brand"
                value={0}
                onChange={() =>
                  formik.setFieldValue('isSavedAddressUsed', false)
                }
              >
                {TextConstants.ORDER_CONTACT_INFO.NEW_ADDRESS}
              </Radio>
              <CountryDropdown
                value={formik.values.country}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.country}
                disabled={formik.values.isSavedAddressUsed}
                errorMessage={formik.errors.country}
              />
              <AddressForm
                fields={getAddressFieldsProps(formik)}
                disabled={formik.values.isSavedAddressUsed}
              />
            </Stack>
          </RadioGroup>
        )}
        {!selectedSavedAddress && (
          <>
            <CountryDropdown
              value={formik.values.country}
              onChange={formik.handleChange}
              isInvalid={!!formik.errors.country}
              errorMessage={formik.errors.country}
            />
            <AddressForm fields={getAddressFieldsProps(formik)} />
          </>
        )}

        {requestError.isErrorShown && (
          <Alert status="error">
            <AlertIcon />
            {requestError.errorText}
          </Alert>
        )}
      </DrawerBody>

      <DrawerFooter flexDirection={{ base: 'column', md: 'unset' }}>
        <Button
          m={2}
          size="sm"
          width={{ base: '200px', md: 'unset' }}
          variant="secondary-gray"
          textTransform="uppercase"
          disabled={isRequesting}
          onClick={cancelCallback}
        >
          {TextConstants.ORDER_CONTACT_INFO.CANCEL_BUTTON_TEXT}
        </Button>
        <Button
          m={2}
          size="sm"
          width="200px"
          textTransform="uppercase"
          variant="squared"
          disabled={isRequesting}
          onClick={() => formik.handleSubmit()}
        >
          {TextConstants.ORDER_CONTACT_INFO.SUBMIT_BUTTON_TEXT}
        </Button>
      </DrawerFooter>
    </>
  );
}

export default OrderContactInfo;
