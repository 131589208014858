import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalCloseButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import TextConstants from '../../../constants/TextConstants';
import { setIsHeaderModalsShown } from '../../../store/reducers/commerceReducer';
import successIcon from '../../../assets/icons/success-icon.png';
import { setSignInModal } from '../../../store/reducers/signInModalReducer';
import {
  executeAuthPromptModalCallback,
  setAuthPromptOpened,
} from '../../../store/reducers/authPromptReducer';

const AuthPromptModal = ({
  isOpen,
  closeAuthPrompt,
}: {
  isOpen: boolean;
  closeAuthPrompt: () => void;
}): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(setAuthPromptOpened(true));
    }
  }, []);

  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      isCentered
      onClose={() => {
        // do nothing
      }}
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent borderRadius="0">
      <ModalCloseButton
          _focus={{ boxShadow: 'none' }}
          zIndex="22"
          size='lg'        
        />
        <ModalBody>
          <VStack spacing="none">
            <Image
              mt="20px"
              alignSelf="center"
              boxSize="48px"
              objectFit="cover"
              src={successIcon}
            />
            <Text as="h3" textStyle="text-lg" marginTop="4">
              {TextConstants.MODALS.AUTH_PROMPT_MODAL.TITLE}
            </Text>
            <VStack
              spacing="4"
              color="gray.500"
              textAlign="center"
              marginTop="2"
            >
              <Text textStyle="text-sm">
                {TextConstants.MODALS.AUTH_PROMPT_MODAL.SECTION}
              </Text>
            </VStack>
          </VStack>
        </ModalBody>

        <ModalFooter justifyContent="center">
          <VStack w="100%" gap="10px">
            <Box
              w="100%"
              display="flex"
              flexDirection={{ base: 'column', sm: 'unset' }}
              justifyContent="space-between"
              alignItems="center"
              gap="10px"
            >
              <Button
                borderRadius="0"
                w={{ base: '100%', sm: '48%' }}
                onClick={() => {
                  dispatch(setSignInModal({ isOpen: true }));
                  closeAuthPrompt();
                }}
                type="button"
                textTransform="uppercase"
              >
                {TextConstants.MODALS.AUTH_PROMPT_MODAL.BUTTON_1}
              </Button>

              <Button
                borderRadius="0"
                w={{ base: '100%', sm: '48%' }}
                onClick={() => {
                  dispatch(setIsHeaderModalsShown({ registerModal: true }));
                  closeAuthPrompt();
                }}
                type="button"
                textTransform="uppercase"
              >
                {TextConstants.MODALS.AUTH_PROMPT_MODAL.BUTTON_2}
              </Button>
            </Box>

            <Button
              w="100%"
              borderRadius="0"
              colorScheme="gray"
              variant="outline"
              color="var(--chakra-colors-brand-600)"
              type="button"
              textTransform="uppercase"
              _hover={{ backgroundColor: 'var(--chakra-colors-brand-50)' }}
              onClick={() => {
                dispatch(executeAuthPromptModalCallback(true));
                closeAuthPrompt();
              }}
            >
              {TextConstants.MODALS.AUTH_PROMPT_MODAL.BUTTON_3}
            </Button>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AuthPromptModal;
