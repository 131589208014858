import { Box, useMediaQuery } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import TextConstants from '../../../constants/TextConstants';
import { RootState } from '../../../store/store';

const StyleWrapper = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [isLargerThanIPadPro] = useMediaQuery(
    `(min-width: ${TextConstants.SIZES.IPAD_PRO_WIDTH}px)`
  );

  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );

  return (
    <Box
      display="flex"
      px="30px"
      gap="10px"
      flexDirection="column"
      justifyContent="center"
      marginBottom={isTranscriptOpen || isLargerThanIPadPro ? 'unset' : '5vh'}
      minW="100%"
      maxH="60vh"
      overflowY={isTranscriptOpen ? 'unset' : 'scroll'}
      data-sm-content="bronzerHighlighterOptions"
    >
      {children}
    </Box>
  );
};

export default StyleWrapper;
