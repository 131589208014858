export function checkIfIOSDevice() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export function checkIfSafariBrowser() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function checkIfMobile() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

export function checkIfAndroid() {
  return /Android/i.test(navigator.userAgent);
}
