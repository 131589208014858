import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomizeMakeupProductType,
  SearchedProductType,
} from '../../global-components/GlobalTypes';
import { openMakeupAccordionSliderByWatsonFeature } from '../../store/reducers/commerceReducer';
import { AppDispatch, RootState } from '../../store/store';

const useOpenCustomizeMakeup = (
  level: 'subcategory' | 'product',
  productData: CustomizeMakeupProductType | SearchedProductType
): (() => void) => {
  const dispatch = useDispatch<AppDispatch>();
  const categorizedProductsData = useSelector(
    (state: RootState) => state.commerce.categorizedProductsData
  );

  const openMakeupAccordionSlider = (
    category: string,
    subcategory: string,
    selectedProduct: string | null
  ) => {
    dispatch(
      openMakeupAccordionSliderByWatsonFeature({
        category,
        subcategory,
        selectedProduct,
      })
    );
  };

  const handleDispatch = useCallback(() => {
    if (!categorizedProductsData || !productData) {
      return;
    }

    let foundMatch = false;
    for (let i = 0; i < categorizedProductsData.length; i++) {
      const category = categorizedProductsData[i];
      for (let j = 0; j < category?.children?.length; j++) {
        const subcategory = category.children[j];
        for (let k = 0; k < subcategory?.children?.length; k++) {
          const product = subcategory.children[k];
          if (product.name === productData.name) {
            const categoryVal = category.name;
            const subcategoryVal = subcategory.name;
            const selectedProductVal =
              level === 'product' ? product.name : null;
            openMakeupAccordionSlider(
              categoryVal,
              subcategoryVal,
              selectedProductVal
            );
            foundMatch = true;
            break;
          }
        }
        if (foundMatch) {
          break;
        }
      }
      if (foundMatch) {
        break;
      }
    }
  }, [productData]);

  return handleDispatch;
};
export default useOpenCustomizeMakeup;
