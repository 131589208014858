import { useDispatch, useSelector } from 'react-redux';
import { openMakeupAccordionSliderByWatsonFeature } from '../../../../store/reducers/commerceReducer';
import { AppDispatch, RootState } from '../../../../store/store';

const OpenCustomizeMakeupByActiveProduct = (): null => {
  const dispatch = useDispatch<AppDispatch>();
  const categorizedProductsData = useSelector(
    (state: RootState) => state.commerce.categorizedProductsData
  );
  const activeProductVariant = useSelector(
    (state: RootState) => state.commerce.activeProductVariant
  );

  const openMakeupAccordionSlider = (
    category: string,
    subcategory: string,
    selectedProduct: string | null
  ): void => {
    dispatch(
      openMakeupAccordionSliderByWatsonFeature({
        category,
        subcategory,
        selectedProduct,
      })
    );
  };

  if (!categorizedProductsData || !activeProductVariant) {
    return null;
  }

  let foundMatch = false;
  for (let i = 0; i < categorizedProductsData.length; i++) {
    const category = categorizedProductsData[i];
    for (let j = 0; j < category?.children?.length; j++) {
      const subcategory = category.children[j];
      for (let k = 0; k < subcategory?.children?.length; k++) {
        const product = subcategory.children[k];
        if (product.name === activeProductVariant?.name) {
          const categoryVal = category.name;
          const subcategoryVal = subcategory.name;
          const selectedProductVal = product.name;
          openMakeupAccordionSlider(
            categoryVal,
            subcategoryVal,
            selectedProductVal
          );
          foundMatch = true;
          break;
        }
      }
      if (foundMatch) {
        break;
      }
    }
    if (foundMatch) {
      break;
    }
  }

  return null;
};

export default OpenCustomizeMakeupByActiveProduct;
