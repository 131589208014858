import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import styled from 'styled-components';
import s from '../UserNav/UserNav.module.scss';
import IntroVideoModal from './IntroVideoModal';
import TextConstants from '../../../constants/TextConstants';
import { AppDispatch, RootState } from '../../../store/store';
import { mute, stopSpeaking } from '../../../store/reducers/digitalAssistantReducer';
import { SVG } from '../../icons/icons';

const Container = styled.div`
  .chakra-menu__menu-button {
    span {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
`;

const IntroVideoButton = (): JSX.Element => {
  const cloudURL = process.env.REACT_APP_CDN_URL;
  const dispatch = useDispatch<AppDispatch>();
  const isMuted = useSelector((state: RootState) => state.digitalAssistant.isMuted);

  const [isCityModalOpen, setIsCityModalOpen] = useState(false);
  const [isDesertModalOpen, setIsDesertModalOpen] = useState(false);
  const [isOverviewModalOpen, setIsOverviewModalOpen] = useState(false);

  const isMutedBeforePlayingVideo = useRef(false);

  const handleCloseModals = () => {
    setIsCityModalOpen(false);
    setIsDesertModalOpen(false);
    setIsOverviewModalOpen(false);
    dispatch(mute(isMutedBeforePlayingVideo.current));
  };

  const mutePersona = () => {
    isMutedBeforePlayingVideo.current = isMuted;
    dispatch(stopSpeaking());
    dispatch(mute(true));
  };

  return (
    <Container>
      <Menu>
        <MenuButton
          _hover={{ color: 'var(--chakra-colors-brand-700)' }}
          color="var(--chakra-colors-brand-700)"
          className={s.textLink}
          display="flex"
        >
          <SVG id="intro-icon" />
          <Text>{TextConstants.HEADER.FIRST_SECTION.TITLE}</Text>
        </MenuButton>
        <MenuList zIndex="20">
          <MenuGroup>
            <MenuItem
              _focus={{ bg: 'var(--chakra-colors-brand-50)' }}
              onClick={() => {
                setIsCityModalOpen(true);
                mutePersona();
              }}
              color="var(--chakra-colors-brand-700)"
            >
              {TextConstants.HEADER.FIRST_SECTION.FIRST_OPTION}
            </MenuItem>
            <MenuItem
              _focus={{ bg: 'var(--chakra-colors-brand-50)' }}
              onClick={() => {
                setIsDesertModalOpen(true);
                mutePersona();
              }}
              color="var(--chakra-colors-brand-700)"
            >
              {TextConstants.HEADER.FIRST_SECTION.SECOND_OPTION}
            </MenuItem>
            <MenuItem
              _focus={{ bg: 'var(--chakra-colors-brand-50)' }}
              onClick={() => {
                setIsOverviewModalOpen(true);
                mutePersona();
              }}
              color="var(--chakra-colors-brand-700)"
            >
              {TextConstants.HEADER.FIRST_SECTION.THIRD_OPTION}
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
      <IntroVideoModal
        isModalsOpen={isCityModalOpen}
        srcVideo={`${cloudURL}/360_VIDEO_ENTER.1150.mp4`}
        handleCloseModals={handleCloseModals}
        isControlsShown={false}
        pageToRedirect="foyer-welcome"
      />
      <IntroVideoModal
        isModalsOpen={isDesertModalOpen}
        srcVideo={`${cloudURL}/desert_video.mp4`}
        handleCloseModals={handleCloseModals}
        isControlsShown={false}
        pageToRedirect="desert"
      />
      <IntroVideoModal
        isModalsOpen={isOverviewModalOpen}
        srcVideo={`${cloudURL}/overview_video.mp4`}
        handleCloseModals={handleCloseModals}
        isControlsShown
        pageToRedirect="foyer-welcome"
      />
    </Container>
  );
};

export default IntroVideoButton;
