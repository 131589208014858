export const calculateEntriesDataSummary = (dayEntriesData) => {
  const summary =
    dayEntriesData.joy +
    dayEntriesData.fear +
    dayEntriesData.anger +
    dayEntriesData.frustrated +
    dayEntriesData.sad +
    dayEntriesData.excited +
    dayEntriesData.satisfied;
  return summary;
};
