import { Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import StyleWrapper from './StyleWrapper';
import TextConstants from '../../../constants/TextConstants';
import { AppDispatch } from '../../../store/store';
import { openMakeupAccordionSliderByWatsonFeature } from '../../../store/reducers/commerceReducer';

const MistAndDiffusingPrimerOptions = ({
  isCardInTranscript,
}: {
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const handleClick = (
    category: 'Cheeks' | 'Lips' | 'Eyes' | 'Miscellaneous',
    subcategory: string,
    selectedProduct: string
  ) => {
    dispatch(
      openMakeupAccordionSliderByWatsonFeature({
        category,
        subcategory,
        selectedProduct,
      })
    );
  };

  return (
    <StyleWrapper>
      <>
        <Button
          fontSize={{ base: 'small', sm: '1rem' }}
          variant="secondary"
          p="20px"
          w="100%"
          m="0 auto"
          borderRadius="0.5rem"
          _focus={{ boxShadow: 'none' }}
          onClick={() => {
            handleClick('Cheeks', 'Mist', 'Always an Optimist 4-in-1 Mist');
          }}
          disabled={isCardInTranscript}
        >
          {TextConstants.COMMERCE.ALWAYS_AN_OPTIMIST_MIST_PRODUCT}
        </Button>

        <Button
          fontSize={{ base: 'small', sm: '1rem' }}
          variant="secondary"
          p="20px"
          w="100%"
          m="0 auto"
          borderRadius="0.5rem"
          _focus={{ boxShadow: 'none' }}
          onClick={() => {
            handleClick(
              'Cheeks',
              'Primer',
              'Always an Optimist Pore Diffusing Primer'
            );
          }}
          disabled={isCardInTranscript}
        >
          {TextConstants.COMMERCE.OPTIMIST_PORE_DIFFUSING_PRIMER_PRODUCT}
        </Button>
      </>
    </StyleWrapper>
  );
};

export default MistAndDiffusingPrimerOptions;
