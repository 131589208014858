import { useRef, useState } from 'react';
import { useFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import styled from 'styled-components';
import Slider from 'react-slick';
import SignupNamesStep from './SignupNamesStep';
import SignupBirthdayStep from './SignupBirthdayStep';
import SignupGenderStep from './SignupGenderStep';
import SignupCountryStep from './SignupCountryStep';
import SignupPhoneNumberStep from './SignupPhoneNumberStep';
import TextConstants from '../../../../constants/TextConstants';
const phoneUtil =
  require('google-libphonenumber').PhoneNumberUtil.getInstance();

const Container = styled.div`
  .slick-dots {
    li {
      cursor: default;
      button {
        color: red !important;
        pointer-events: ${({ currentStep }) => {
          return currentStep === 1 ? 'none' : '';
        }};
      }
    }
  }
`;

const stepsCount = 5;

export type FormikSignUpValues = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dateOfBirth: string;
  gender: string;
  country: string;
};

function SignupStepsModal({
  isModalOpen,
  closeModals,
  submit,
  isRequesting,
}: {
  isModalOpen: boolean;
  closeModals: () => void;
  submit: (data: FormikSignUpValues) => void;
  isRequesting: boolean;
}): JSX.Element {
  const sliderRef = useRef(null);
  const [currentSlider, setCurrentSlider] = useState(0);
  const [countryCode, setCountryCode] = useState('');

  const validationSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    phoneNumber: Yup.string(),
    dateOfBirth: Yup.date(),
    gender: Yup.string(),
    country: Yup.string(),
  });

  const formik: FormikProps<FormikSignUpValues> = useFormik<FormikSignUpValues>(
    {
      initialValues: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        dateOfBirth: '',
        gender: '',
        country: '',
      },
      onSubmit: () => {
        // do nothing
      },
      validationSchema,
      validateOnBlur: false,
      validateOnChange: false,
    }
  );

  const handleSetCountryCode = (value) => {
    setCountryCode(value);
  };

  const handlePhoneNumberValidation = () => {
    if (countryCode) {
      const number = phoneUtil.parseAndKeepRawInput(
        formik.values.phoneNumber,
        countryCode.toUpperCase()
      );
      const isValidPhone = phoneUtil.isValidNumberForRegion(
        number,
        countryCode.toUpperCase()
      );
      if (isValidPhone) {
        sliderRef.current.slickNext();
        formik.setFieldError('phoneNumber', '');
      } else {
        formik.setFieldError(
          'phoneNumber',
          TextConstants.ERROR_TEXT.PHONE_NUMBER
        );
      }
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => {
        formik.resetForm();
        setCurrentSlider(0);
        closeModals();
      }}
      isCentered
    >
      <ModalOverlay
        backdropFilter="auto"
        backdropInvert="10%"
        backdropBlur="0px"
      />
      <ModalContent borderRadius="0">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <Box mt="20px">
            <Container currentStep={currentSlider}>
              <Slider
                slidesToShow={1}
                slidesToScroll={1}
                dots
                arrows={false}
                infinite={false}
                speed={500}
                ref={sliderRef}
                draggable={false}
                touchMove={false}
                className="slick-dotted"
                beforeChange={(current, next) => {
                  if (current === stepsCount - 1 && next === stepsCount - 1) {
                    submit(formik.values);
                    formik.resetForm();
                    setCurrentSlider(0);
                  } else {
                    setCurrentSlider(next);
                  }
                }}
              >
                <SignupNamesStep formik={formik} />
                <SignupPhoneNumberStep
                  setCountryCode={handleSetCountryCode}
                  formik={formik}
                />
                <SignupBirthdayStep formik={formik} />
                <SignupGenderStep formik={formik} />
                <SignupCountryStep formik={formik} />
              </Slider>
            </Container>
          </Box>

          <Stack mt="40px" mb="16px" mx="16px">
            <Button
              disabled={isRequesting}
              type="button"
              onClick={() => {
                if (currentSlider === 1 && formik.values.phoneNumber) {
                  handlePhoneNumberValidation();
                } else {
                  formik.setFieldError('phoneNumber', '');
                  sliderRef.current.slickNext();
                }
              }}
            >
              {TextConstants.MODALS.SIGN_UP_MODAL.NEXT}
            </Button>
          </Stack>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default SignupStepsModal;
