import { useMemo } from 'react';
import { ReactEditor, Slate } from 'slate-react';
import { Descendant } from 'slate';

import NoteEditor from './editor/NoteEditor';
import { NoteValue } from '../../../interfaces/Notes';
import NoteHeader from './NoteHeader';
import Logo from '../../../assets/icons/logo.jpg';
import { RARE_REMINDER_LINK } from '../../../constants/Notes';

type NoteBodyProps = {
  onChange: (noteValue: Descendant[]) => void;
  value: NoteValue[];
  isEdit: boolean;
  editor: ReactEditor;
};

const NoteBody = ({
  editor,
  isEdit,
  value,
  onChange,
}: NoteBodyProps): JSX.Element => {
  const footer = useMemo(
    () => (
      <div className="note-footer d-flex justify-content-between">
        {isEdit ? (
          <a
            className="reminder-text"
            target="_blank"
            rel="noreferrer"
            href={RARE_REMINDER_LINK}
          >
            #rarereminder
          </a>
        ) : (
          <span className="reminder-text">#rarereminder</span>
        )}

        <img src={Logo} alt="logo" />
      </div>
    ),
    [isEdit]
  );

  return (
    <>
      <div>
        <Slate editor={editor} value={value} onChange={onChange}>
          <NoteHeader isEdit={isEdit} />
          <NoteEditor editor={editor} />
        </Slate>
        {footer}
      </div>
    </>
  );
};

export default NoteBody;
