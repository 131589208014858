import { useRef, useState } from 'react';
import { Box, Button, Heading } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  mute,
  sendTextMessage,
  setDigitalCharacterAnimationStage,
  stopSpeaking,
} from '../../../store/reducers/digitalAssistantReducer';
import { AppDispatch, RootState } from '../../../store/store';
import TextConstants from '../../../constants/TextConstants';
import VideoHOCWithLogoutTimer from '../../HOC/VideoHOCWithLogoutTimer';
import { pauseTalking, resumeTalking } from '../../../utils/textToSpeech';

const DPOverviewVideoCard = ({
  data,
  isCardInTranscript,
}: {
  data: {
    title: string;
    video_url: string;
    proceed_button_title: string;
  };
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [proceedText, setProceedText] = useState(data.proceed_button_title);
  const isMuted = useSelector((state: RootState) => state.digitalAssistant.isMuted);
  const isMutedBeforePlayingVideo = useRef(isMuted);
  const videoRef = useRef(null);
  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );
  const handleClick = () => {
    dispatch(stopSpeaking());
    dispatch(sendTextMessage({ text: 'proceed' }));
    dispatch(mute(isMutedBeforePlayingVideo.current));
    videoRef.current.pause();
    setIsButtonDisabled(true);
  };

  const onPlay = async () => {
    // mute persona
    pauseTalking()
    dispatch(setDigitalCharacterAnimationStage({digitalCharacterAnimationStage: TextConstants.DIGITAL_CHARACTER_ANIMATION_STAGE.REGULAR_NEUTRAL_IDLE}));
    dispatch(mute(true));
  };

  const onPause = () => {
    resumeTalking()
    dispatch(setDigitalCharacterAnimationStage({digitalCharacterAnimationStage: TextConstants.DIGITAL_CHARACTER_ANIMATION_STAGE.REGULAR_POSITIVE_SPEAKING}));
    dispatch(mute(isMutedBeforePlayingVideo.current));
  };

  const onEnded = () => {
    resumeTalking()
    dispatch(setDigitalCharacterAnimationStage({digitalCharacterAnimationStage: TextConstants.DIGITAL_CHARACTER_ANIMATION_STAGE.REGULAR_POSITIVE_SPEAKING}));
    setProceedText(TextConstants.BUTTON_TEXT.PROCEED);
  };

  return (
    <Box
      display="block"
      flexDirection="column"
      maxH="55vh"
      data-sm-content="DPOverviewVideoModal"
      alignItems={isTranscriptOpen ? 'start' : 'center'}
      overflowY={isTranscriptOpen ? 'unset' : 'scroll'}
      maxW={!isTranscriptOpen && { base: '80%', sm: '35vw' }}
      p={isTranscriptOpen ? '10px 0 10px 35px' : '0'}
      paddingBottom={isTranscriptOpen ? 0 : '5vh'}
      marginRight={!isTranscriptOpen && { base: '0', sm: '40px' }}
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbaWidth: 'none',
      }}
    >
      {data.title !== TextConstants.DP_OVERVIEW_VIDEO_CARD.OVERVIEW_TITLE && (
        <Heading as="h2" py="20px" className="therapy-primer-title">
          {data.title}
        </Heading>
      )}

      <VideoHOCWithLogoutTimer
        className="DP-overview-video"
        src={`${data.video_url}#t=0.1`}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnded}
        poster={`${process.env.PUBLIC_URL}/images/wellness-video-poster.jpg`}
        preload="auto"
        controls={true}
        autoPlay={false}
        ref={videoRef}
      />
      <Button
        zIndex="1"
        mt="20px"
        onClick={() => {
          handleClick();
        }}
        disabled={isButtonDisabled || isCardInTranscript}
      >
        {proceedText}
      </Button>
    </Box>
  );
};

export default DPOverviewVideoCard;
