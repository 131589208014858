import { createSlice } from '@reduxjs/toolkit';
import { VMInitialState } from '../../global-components/GlobalTypes';

const initialState: VMInitialState = {
  showVirtualMirror: false,
  isUserFavoriteProductInputActive: false,
};

export const VMReducer = createSlice({
  name: 'vm',
  initialState,
  reducers: {
    setVirtualMirrorVisibility: (state, { payload }) => ({
      ...state,
      showVirtualMirror: payload,
    }),

    getUserFavoriteProductInput: (
      state,
      { payload }: { payload: boolean }
    ) => ({
      ...state,
      isUserFavoriteProductInputActive: payload,
    }),
  },
});

export const { setVirtualMirrorVisibility, getUserFavoriteProductInput } =
  VMReducer.actions;

export default VMReducer.reducer;
