import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  postGuestEntryData,
  verifyUser,
} from '../../../global-components/RequestFactory';
import VerificationSuccessModal from '../../../components/modals/signUp/VerificationSuccessModal';
import VerificationFaildModal from '../../../components/modals/signUp/VerificationFaildModal';
import TextConstants from '../../../constants/TextConstants';
import cookies from '../../../helpers/addCookies';
import { removeGuestEntryData } from '../../../store/reducers/journalReducer';
import { setSignInModal } from '../../../store/reducers/signInModalReducer';

const Verification = (): JSX.Element => {
  const history = useHistory();
  const { token } = useParams();
  const dispatch = useDispatch();
  const [isModalsOpen, setIsModalsOpen] = useState({
    isVerificationSuccess: false,
    isVerificationFaild: false,
  });

  useEffect(() => {
    verifyUser(token)
      .then((result) => {
        const guestEntryData = cookies.get('guestEntryData');
        cookies.remove('guestEntryData', { path: '/' });
        dispatch(removeGuestEntryData());
        if (guestEntryData) {
          postGuestEntryData(guestEntryData, result.token)
            .then(() => {
              // do nothing
            })
            .catch((error) => {
              console.log(error);
            });
        }
        setIsModalsOpen((prev) => ({
          ...prev,
          isVerificationSuccess: true,
        }));
        setTimeout(() => {
          setIsModalsOpen((prev) => ({
            ...prev,
            isVerificationSuccess: false,
          }));
          history.push(TextConstants.PATHNAMES.MAIN_PAGE);
          dispatch(setSignInModal({ isOpen: true }));
        }, TextConstants.ALERT_MESSAGE_TRASHHOLD);
      })
      .catch(() => {
        setIsModalsOpen((prev) => ({
          ...prev,
          isVerificationFaild: true,
        }));
        setTimeout(() => {
          setIsModalsOpen((prev) => ({
            ...prev,
            isVerificationFaild: false,
          }));
          history.push(TextConstants.PATHNAMES.MAIN_PAGE);
        }, TextConstants.ALERT_MESSAGE_TRASHHOLD);
      });
  }, [history, token, dispatch]);

  return (
    <>
      <VerificationSuccessModal
        isModalOpen={isModalsOpen.isVerificationSuccess}
      />
      <VerificationFaildModal isModalOpen={isModalsOpen.isVerificationFaild} />
    </>
  );
};

export default Verification;
