import { Button, Image, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import leftArrowIcon from '../../../img/arrow-left-icon.svg';
import rightArrowIcon from '../../../img/arrow-right-icon.svg';
import { CustomizeMakeupVariantType } from '../../../global-components/GlobalTypes';
import removeActiveClassHelper from '../../../helpers/products/removeActiveClassHelper';
import checkSliderArrowDisabledOrNo from '../../../helpers/checkSliderArrowDisabledOrNo';

const SliderContainer = styled.div`
  padding: 0px 25px 0px 25px;
  display: flex;
  height: 22px;
  margin-top: 14px;
  margin-bottom: 20px;
  .slick-slide {
    div {
      display: flex;
      justify-content: center;
    }
  }
  .slick-list {
    padding-top: 2px;
  }
  .slick-dots {
    display: none !important;
  }

  .isActive {
    border: 1px solid #fdf7f952;
    box-shadow: 0px 0px 3px 3px rgb(127 37 73);
  }
`;

function LeftArrow(props) {
  const { onClick, className } = props;
  return (
    <Image
      onClick={onClick}
      src={leftArrowIcon}
      style={{
        display: checkSliderArrowDisabledOrNo(className),
        left: '-25px',
        position: 'absolute',
        top: '44%',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
      }}
    />
  );
}

function RightArrow(props) {
  const { onClick, className } = props;
  return (
    <Image
      onClick={onClick}
      src={rightArrowIcon}
      style={{
        display: checkSliderArrowDisabledOrNo(className),
        right: '-25px',
        position: 'absolute',
        top: '44%',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
      }}
    />
  );
}

const ProductSizeVariantsSlider = ({
  sizeProductVariants,
  handleClickSizeProductVariant,
}: {
  sizeProductVariants: CustomizeMakeupVariantType[];
  handleClickSizeProductVariant: (data: CustomizeMakeupVariantType) => void;
}): JSX.Element => {
  const maxCount = sizeProductVariants && sizeProductVariants.length;
  const [swiped, setSwiped] = useState(false);
  const handleSwiped = useCallback(() => {
    setSwiped(true);
  }, [setSwiped]);

  useEffect(() => {
    // reset isActive border if new product is selected
    const allDataSizeSliders = document.querySelectorAll(`[data-size-slider]`);
    removeActiveClassHelper(allDataSizeSliders);

    allDataSizeSliders[0].classList.add('isActive');
  }, [sizeProductVariants]);

  const handleOnItemClick = useCallback(
    (event, productVariant, index) => {
      if (swiped) {
        event.stopPropagation();
        event.preventDefault();
        setSwiped(false);
      } else {
        const removingElements =
          document.querySelectorAll(`[data-size-slider]`);
        removeActiveClassHelper(removingElements);
        const element = document.querySelector(
          `[data-size-slider=index${index}]`
        );
        element.classList.add('isActive');
        handleClickSizeProductVariant(productVariant);
      }
    },
    [swiped]
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 1500,
    slidesToShow: maxCount ? Math.min(maxCount, 5) : 0,
    slidesToScroll: maxCount ? Math.min(maxCount, 5) : 0,
    initialSlide: 0,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    onSwipe: handleSwiped,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: maxCount ? Math.min(maxCount, 4) : 0,
          slidesToScroll: maxCount ? Math.min(maxCount, 4) : 0,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: maxCount ? Math.min(maxCount, 2) : 0,
          slidesToScroll: maxCount ? Math.min(maxCount, 2) : 0,
        },
      },
    ],
  };

  useEffect(() => {
    const allDataSizeSliders = document.querySelectorAll(`[data-size-slider]`);
    allDataSizeSliders[0].classList.add('isActive');
    handleClickSizeProductVariant(sizeProductVariants[0]);
  }, []);

  return (
    sizeProductVariants.length && (
      <SliderContainer>
        <Slider {...settings}>
          {sizeProductVariants?.length &&
            sizeProductVariants.map((productVariant, index) => (
              <Button
                width="fit-content !important"
                data-size-slider={'index' + index}
                key={productVariant?.id}
                type="button"
                borderRadius="17px"
                bg="transparent"
                border="1px solid var(--chakra-colors-brand-500)"
                color="var(--chakra-colors-brand-500)"
                _hover={{
                  color: '#fff',
                  bg: 'var(--chakra-colors-brand-600)',
                }}
                height="30px"
                py="unset"
                onClickCapture={(event) =>
                  handleOnItemClick(event, productVariant, index)
                }
              >
                <Text>{productVariant?.optionValues[0]?.value}</Text>
              </Button>
            ))}
        </Slider>
      </SliderContainer>
    )
  );
};

export default ProductSizeVariantsSlider;
