import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { syllable } from 'syllable';
import { RootState } from '../store/store';

const Container = styled.div`
  margin-bottom: 0.3rem;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  font-size: 20px;
  color: #fff;
  line-height: 30.12px;
  max-width: 490px;
  text-align: left !important;

  display: flex;
  align-items: center;

  min-height: 35px;
  transition: height 0.3s;

  .hide-captions {
    min-height: 0 !important;
    background-color: rgba(0, 0, 0, 0);
  }
`;

const Captions = (): JSX.Element => {
  const lastPersonaUtterance = useSelector(
    (state: RootState) => state.digitalAssistant.lastPersonaUtterance
  );
  const speechState = useSelector((state: RootState) => state.digitalAssistant.speechState);
  const connected = useSelector((state: RootState) => state.digitalAssistant.connected);

  const [showCaptions, setShowCaptions] = useState(false);
  // if we have a very long response, we need to cycle the displayed content
  const [captionText, setCaptionText] = useState('');
  // keep track of when we first showed this caption. captions should be on screen min 1.5s
  const captionStartTimestamp = useRef<number>();
  const captionTimeout = useRef<NodeJS.Timeout>();
  const minCaptionDuration = 1500;

  useEffect(() => {
    if (!connected) {
      setShowCaptions(false);
    }
  }, [connected]);

  useEffect(() => {
    if (speechState === 'speaking') {
      // when a new utterance starts, show captions
      setShowCaptions(true);
      const sentences = lastPersonaUtterance.split('. ');

      // estimate how long each caption should be displayed based on # of syllables and punctuation
      const sentencesWithDurationEstimate = sentences.map((s) => {
        const millisPerSyllable = 210;
        const millisPerPunct = 330;
        const syllableCount = syllable(s);
        const regex = /[^\w ]/gm;
        const punctCount = [s.matchAll(regex)].length;
        const durationEstimate =
          syllableCount * millisPerSyllable +
          punctCount * millisPerPunct +
          // add one punct delay for the period that gets stripped when splitting the sentences
          millisPerPunct;

        return { text: s, durationEstimate };
      });

      // recursively cycle through sentences on very long captions

      const displayCaption = (i) => {
        const { text, durationEstimate } = sentencesWithDurationEstimate[i];
        setCaptionText(text);
        if (sentencesWithDurationEstimate[i + 1]) {
          setTimeout(() => displayCaption(i + 1), durationEstimate);
        }
      };

      displayCaption(0);
      // record when we put captions on the screen
      captionStartTimestamp.current = Date.now();

      // clear any previous timeout from previous captions.
      // this won't make the captions disappear, since we're overwriting the content
      clearTimeout(captionTimeout.current);
    }
  }, [speechState, lastPersonaUtterance]);

  useEffect(() => {
    if (speechState !== 'speaking') {
      // when the utterance ends:
      const captionsDisplayedFor = Date.now() - captionStartTimestamp.current;

      // check to see if the captions have been displayed for the min. amount of time
      if (captionsDisplayedFor > minCaptionDuration) {
        setShowCaptions(false);
      }

      // if not, set a timeout to hide them when it has elapsed
      else {
        const newCaptionTimeout = setTimeout(() => {
          setShowCaptions(false);
        }, minCaptionDuration - captionsDisplayedFor);
        captionTimeout.current = newCaptionTimeout;
      }
    }
  }, [speechState]);

  useEffect(() => {
    // sometimes we get blank input, hide that when it happens
    if (!captionText) {
      setShowCaptions(false);
    }
  }, [captionText]);

  return (
    <Container
      className={`captions ${showCaptions ? '' : 'hide-captions'} text-center`}
    >
      {showCaptions ? captionText : null}
    </Container>
  );
};

export default Captions;
