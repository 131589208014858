import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { RootState } from '../../store/store';

function FatalErrorModal(): JSX.Element {
  const error = useSelector((state: RootState) => state.digitalAssistant.error);
  const history = useHistory();
  return (
    <Modal
      isOpen={error.msg && error.msg !== 'permissionsDenied'}
      onClose={() => {
        // do nothing
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalBody>
          <Box>
            <VStack spacing="none">
              <Text as="h3" textStyle="text-lg" marginTop="4">
                Encountered fatal error!
              </Text>
              <VStack
                spacing="4"
                color="gray.500"
                textAlign="center"
                marginTop="2"
              >
                <Text>{JSON.stringify(error, null, '  ')}</Text>
              </VStack>
            </VStack>
          </Box>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="3" justify="flex-end">
            <Button onClick={() => history.go(0)} type="button">
              Reload
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default FatalErrorModal;
