import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  Text,
  AccordionPanel,
  Flex,
} from '@chakra-ui/react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import scoresDataArr from '../../../helpers/journal/scoresDataArr';
import { pieColors, pieLabels } from '../../../constants/JournalConstants';
import { SVG } from '../../icons/icons';
import TextConstants from '../../../constants/TextConstants';
import { RecordDataType } from '../../../global-components/GlobalTypes';

ChartJS.register(ArcElement, Tooltip, Legend);

const EntryDetails = ({
  eachEntryData,
  handleCloseDetails,
}: {
  eachEntryData: RecordDataType;
  handleCloseDetails: () => void;
} | null): JSX.Element => {
  const [pieData, setPieDate] = useState([]);
  const { emotionalScore, classificationScore } = eachEntryData;

  useEffect(() => {
    setPieDate(scoresDataArr(emotionalScore, classificationScore));
  }, [eachEntryData, emotionalScore, classificationScore]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        onClick: null,
        position: 'left' as const,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 8,
        },
      },
      tooltip: {
        callbacks: {
          label: (props) => {
            const summary = props.dataset.data.reduce((sum, el) => sum + el, 0);
            const percent = (props.parsed / summary) * 100;
            return ` ${percent.toFixed()}% ${props.label}`;
          },
        },
        usePointStyle: true,
        backgroundColor: '#FFFFFF',
        bodyColor: 'rgba(0, 0, 0, 0.8)',
        cornerRadius: 0,
        padding: 10,
      },
    },
  };

  const data = {
    labels: pieLabels,
    datasets: [
      {
        data: pieData,
        backgroundColor: pieColors,
        borderColor: pieColors,
        borderWidth: 1,
      },
    ],
  };
  return (
    <Box h="auto">
      <Box
        position="absolute"
        height="auto"
        top="10vh"
        background="#FFFFFF"
        width="90%"
        pt="9px"
        pl="20px"
        pr="20px"
        pb="20px"
        mb="-20px"
        borderTopRadius="5px"
        boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
      >
        <Flex
          cursor="pointer"
          onClick={() => handleCloseDetails()}
          alignItems="center"
          position="relative"
        >
          <Text
            mt="10px"
            lineHeight="20px"
            fontSize="13px"
            color="var(--chakra-colors-brand-600)"
          >
            {TextConstants.JOURNAL.ENTRY_TITLE}
          </Text>
          <Box right="10px" position="absolute">
            <SVG id="close-x" />
          </Box>
        </Flex>
        <Accordion defaultIndex={0} allowToggle>
          <AccordionItem border="none">
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  position="relative"
                  boxShadow="none !important"
                  pl="0"
                >
                  <Box
                    flex="1"
                    textAlign="left"
                    display="flex"
                    flexDirection="row"
                    gap="10px"
                    boxShadow="none"
                  >
                    <Text
                      fontFamily="inter"
                      fontStyle="normal"
                      fontWeight="500"
                      fontSize="14px"
                      color="var(--chakra-colors-gray-700)"
                      lineHeight="20px"
                    >
                      {TextConstants.JOURNAL.ENTRY_CONTENT}
                    </Text>
                    <Box right="9px" position="absolute">
                      <SVG id={`${isExpanded ? 'minus' : 'plus'}`} />
                    </Box>
                  </Box>
                </AccordionButton>
                <AccordionPanel
                  overflow="auto"
                  height="28vh"
                  justifyContent="space-around"
                  flexDirection="row"
                  alignItems="center"
                  gap="8px"
                  bgColor="var(--chakra-colors-gray-50)"
                  p="10px 5px 10px 14px"
                  border="1px solid var(--chakra-colors-gray-300)"
                >
                  {eachEntryData.text}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem border="none">
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  position="relative"
                  boxShadow="none !important"
                  pl="0"
                >
                  <Box
                    flex="1"
                    textAlign="left"
                    display="flex"
                    flexDirection="row"
                    gap="10px"
                    boxShadow="none"
                  >
                    <Text
                      fontFamily="inter"
                      fontStyle="normal"
                      fontWeight="500"
                      fontSize="14px"
                      color="var(--chakra-colors-gray-700)"
                      lineHeight="20px"
                    >
                      {TextConstants.JOURNAL.ENTRY_ANALYSIS}
                    </Text>
                    <Box right="9px" position="absolute">
                      <SVG id={`${isExpanded ? 'minus' : 'plus'}`} />
                    </Box>
                  </Box>
                </AccordionButton>
                <AccordionPanel
                  justifyContent="space-around"
                  flexDirection="row"
                  alignItems="center"
                  gap="8px"
                  p="10px 5px 10px 14px"
                >
                  {pieData.length > 0 ? (
                    <Pie options={options} data={data} />
                  ) : null}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  );
};

export default EntryDetails;
