import { MutableRefObject, useEffect } from 'react';

export const useOutsideHandler = (
  noteRef: MutableRefObject<any> | null,
  isEdit: boolean,
  callback: () => void,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (isEdit && noteRef?.current && !noteRef.current.contains(event.target)) {
        callback();
      }
    };

    const handleClickOnIframe = () => {
      if (isEdit) {
        callback();
      }
    };

    document.addEventListener('touchstart', handleClickOutside);
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('blur', handleClickOnIframe);

    return () => {
      document.removeEventListener('touchstart', handleClickOutside);
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('blur', handleClickOnIframe);
    };
  }, [noteRef, isEdit, callback]);
};
