/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Image, Text } from '@chakra-ui/react';
import Slider from 'react-slick';
import styled from 'styled-components';
import ProductInfoPopover from './ProductInfoPopover';
import leftArrowIcon from '../../../img/arrow-left-icon.svg';
import rightArrowIcon from '../../../img/arrow-right-icon.svg';
import { SVG } from '../../../components/icons/icons';
import { CustomizeMakeupProductType } from '../../../global-components/GlobalTypes';
import logo from '../../../assets/icons/rare-beauty.png';
import {
  checkIfIOSDevice,
  checkIfSafariBrowser,
} from '../../../helpers/checkDevicePlatform';
import { RootState } from '../../../store/store';

const SliderContainer = styled.div`
  padding: 0px 25px 0px 25px;

  // IOS safari position issue fix
  .info-icon-container {
    ${checkIfSafariBrowser() || checkIfIOSDevice() ? ' width: 10px;' : ''}
  }

  .slick-slider {
    .slick-slide {
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .slick-dots {
    display: none !important;
  }

  .image-button {
    position: relative;
    width: 67px;
    height: 67px !important;
    overflow: hidden;
    border-radius: 50%;
  }

  // fixed popover not showing in case of overflow:hidden

  .slick-initialized {
    .slick-list {
      .slick-track {
        width: auto !important;
        transform: translate3d(0, 0, 0) !important;
      }
      overflow: unset;
      .slick-slide {
        display: none;
        &.slick-active {
          display: block;
        }
      }
    }
  }
`;

function LeftArrow(props) {
  const { onClick } = props;
  return (
    <Image
      onClick={onClick}
      src={leftArrowIcon}
      style={{
        left: '-25px',
        position: 'absolute',
        top: '44%',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
      }}
    />
  );
}

function RightArrow(props) {
  const { onClick } = props;
  return (
    <Image
      onClick={onClick}
      src={rightArrowIcon}
      style={{
        right: '-25px',
        position: 'absolute',
        top: '44%',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
      }}
    />
  );
}

const ProductsSlider = ({
  products,
  nextStep,
  prevStep,
  setShadesLevelData,
}: {
  products: CustomizeMakeupProductType[];
  nextStep: () => void;
  prevStep: () => void;
  setShadesLevelData: (data: CustomizeMakeupProductType) => void;
}): JSX.Element => {
  const isAccordionOpen = useSelector(
    (state: RootState) => state.commerce.isAccordionOpen
  );

  const [swiped, setSwiped] = useState(false);
  const handleSwiped = useCallback(() => {
    setSwiped(true);
  }, [setSwiped]);

  const handleOnItemClick = useCallback(
    (event, product) => {
      if (swiped) {
        event.stopPropagation();
        event.preventDefault();
        setSwiped(false);
      } else {
        nextStep();
        setShadesLevelData(product);
      }
    },
    [swiped]
  );

  const maxCount = products?.length;
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: maxCount ? Math.min(maxCount, 2) : 0,
    slidesToScroll: maxCount ? Math.min(maxCount, 2) : 0,
    initialSlide: 0,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    onSwipe: handleSwiped,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: maxCount ? Math.min(maxCount, 1) : 0,
          slidesToScroll: maxCount ? Math.min(maxCount, 1) : 0,
        },
      },
    ],
  };

  // show product name after some delay
  const [renderProductVariantName, setRenderProductVariantName] =
    useState(false);

  useEffect(() => {
    let timeout;
    if (isAccordionOpen) {
      timeout = setTimeout(() => {
        setRenderProductVariantName(true);
      }, 400);
    } else {
      if (timeout) {
        clearTimeout(timeout);
      }
      setRenderProductVariantName(false);
    }
  }, [isAccordionOpen]);

  return (
    <>
      <Box
        cursor="pointer"
        alignItems="center"
        display="flex"
        mt="-5px"
        gap="6px"
        w="33px"
        h="33px"
        bg="#FFF6ED"
        borderRadius="50%"
        justifyContent="center"
        onClick={prevStep}
      >
        <SVG id="arrow-back" />
      </Box>
      <SliderContainer>
        <Slider {...settings}>
          {products?.length &&
            products?.map((product) => (
              <Box key={product?.id} display="flex !important">
                <Box position="relative">
                  <button
                    className="image-button"
                    type="button"
                    onClickCapture={(event) =>
                      handleOnItemClick(event, product)
                    }
                  >
                    <Image
                      src={
                        product?.productVariants?.length
                          ? product?.productVariants[0]?.image?.staticStorageUrl
                          : logo
                      }
                      alt={product?.name}
                      width="100%"
                      height="100%"
                    />
                  </button>
                  <Box
                    className="info-icon-container"
                    position="absolute"
                    right="-3px"
                    top="-4px"
                  >
                    <ProductInfoPopover
                      product={product}
                      gutter={8}
                      iconScale={0.7}
                    />
                  </Box>
                </Box>
                <Text fontSize="12px" textAlign="center">
                  {renderProductVariantName && product?.name}
                </Text>
              </Box>
            ))}
        </Slider>
      </SliderContainer>
    </>
  );
};

export default ProductsSlider;
