import { Button, Image, Text, Box, Tooltip } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { BookmarkPlus } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { mute, setShowTranscript } from '../../store/reducers/digitalAssistantReducer';
import chat from '../../img/chat.svg';
import { NOTE_MAX_AMOUNT } from '../../constants/Notes';
import { getNewNote } from '../../services/Notes';
import { addNote } from '../../store/reducers/noteManagerReducer';
import { getNotesMaxStackLevel } from '../../utils/Notes';
import { RootState } from '../../store/store';
import { SVG } from '../../components/icons/icons';
import HHIcon from '../../components/common/HHIcon/HHIcon';
import TextConstants from '../../constants/TextConstants';
import useAddProductToBag from '../../components/hooks/useAddProductToBag';
import {
  applySuggestionLook,
  removeActiveProductVariant,
  removeAllSelectedProductVariants,
  setSelectedProductVariant,
} from '../../store/reducers/commerceReducer';
import regexp from '../../constants/Regexp';
import findProductVariantBySku from '../../helpers/products/findProductVariantBySku';
import {
  getCuratedLooks,
  getRandomLooks,
} from '../../global-components/RequestFactory';
import { CustomizeMakeupProductType } from '../../global-components/GlobalTypes';
import useSaveCuratedRandomLooks from '../../hooks/useSaveCuratedRandomLooks';

declare const window;

const VirtualMirrorButtonsPopover = ({
  productsData,
  setVMAccordionZindex,
}: {
  productsData: CustomizeMakeupProductType[];
  setVMAccordionZindex: (num: number) => void;
}): JSX.Element => {
  const curatedRequestCount = 3;

  const [showMicr, setShowMicr] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [randomLookCount, setRandomLookCount] = useState(0);
  const [isPerfectCorpEngineLoaded, setPerfectCorpEngineLoaded] =
    useState(false);

  const [activeLookIndex, setActiveLookIndex] = useState(0);
  const [isAddAllButtonClicked, toggleAddAllButton] = useState(false);
  const [isLookButtonClicked, toggleLookButton] = useState(false);
  const [isPrevNextButtonClicked, togglePrevNextButton] = useState(false);
  const [latestLook, allLooks, setLook] = useSaveCuratedRandomLooks();

  const dispatch = useDispatch();

  const { notes, mirrorDimensions } = useSelector(
    (state: RootState) => state.noteManager
  );
  const selectedProductVariants = useSelector(
    (state: RootState) => state.commerce.selectedProductVariants
  );
  const suggestionLook = useSelector(
    (state: RootState) => state.commerce.suggestionLook
  );
  const transcript = useSelector((state: RootState) => state.digitalAssistant.transcript);
  const typingOnly = useSelector((state: RootState) => state.digitalAssistant.typingOnly);
  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );
  const addItemToBag = useAddProductToBag(selectedProductVariants);

  const handleCreate = () => {
    if (notes.length === NOTE_MAX_AMOUNT) {
      return;
    }
    const newNote = getNewNote(mirrorDimensions.width, mirrorDimensions.height);
    dispatch(addNote(newNote));
  };

  const toggleMicrophone = () => {
    dispatch(mute(!showMicr));
    setShowMicr(!showMicr);
  };

  const productMulticolourCase = useCallback((element) => {
    if (element.skuId) {
      const count = element.patternName.substring(
        0,
        element.patternName.indexOf(' ')
      );
      let skus = [];
      for (let j = 0; j < count; j++) {
        skus = [
          ...skus,
          {
            itemGuid: element.skuId,
            colorIndex: 0,
            colorIntensity: element.colorIntensity,
          },
        ];
      }
      window.YMK && window.YMK.applyMakeupByPattern(element.patternName, skus);
    } else {
      window.YMK && window.YMK.applyMakeupByPattern(element.patternName, [
        {
          itemGuid: element.sku,
          colorIndex: 0,
          colorIntensity: element.colorIntensity,
        },
      ]);
    }
  }, []);

  const setActiveIndexOnClickingLookButton = useCallback(() => {
    // set active look index to the latest one
    let lastIndex;
    if (allLooks?.length >= 1 && allLooks?.length <= 5) {
      lastIndex = allLooks?.length - 1;
    } else if (!allLooks?.length) {
      lastIndex = 0;
    }

    setActiveLookIndex(lastIndex);
  }, [allLooks?.length]);

  useEffect(() => {
    const handleCameraEngineClosedListener = () => {
      setPerfectCorpEngineLoaded(false);
    };
    const handleCameraLoadedListener = () => {
      setPerfectCorpEngineLoaded(true);
    };

    const cameraEngineClosedListener = window.YMK?.addEventListener(
      'engineClosed',
      handleCameraEngineClosedListener
    );
    const cameraLoadedListener = window.YMK?.addEventListener(
      'loaded',
      handleCameraLoadedListener
    );
    return () => {
      window.YMK?.removeEventListener(cameraEngineClosedListener);
      window.YMK?.removeEventListener(cameraLoadedListener);
    };
  }, [window.YMK]);

  const getRandomLooksRequestFunction = useCallback(() => {
    getRandomLooks('')
      .then((result) => {
        // save look via useSaveCuratedRandomLooks hook
        setLook(result);
        setIsRequesting(false);
      })
      .then(() => {
        // set active index to the latest one
        setActiveIndexOnClickingLookButton();
      })
      .catch((error) => {
        console.log(error);
        setIsRequesting(false);
      });
  }, [setLook, setActiveIndexOnClickingLookButton]);

  const getCuratedLooksRequestFunction = useCallback(() => {
    getCuratedLooks()
      .then((result) => {
        // save look via useSaveCuratedRandomLooks hook
        setLook(result);
        setIsRequesting(false);
      })
      .then(() => {
        // set active index to the latest one
        setActiveIndexOnClickingLookButton();
      })
      .catch((error) => {
        console.log(error);
        setIsRequesting(false);
      });
  }, [setLook, setActiveIndexOnClickingLookButton]);

  const handleClickLookButton = () => {
    toggleLookButton(true);
    setIsRequesting(true);
    window.YMK.reset();
    dispatch(removeActiveProductVariant());
    dispatch(removeAllSelectedProductVariants());
    if (randomLookCount === curatedRequestCount) {
      getRandomLooksRequestFunction();
    } else {
      setRandomLookCount(randomLookCount + 1);
      getCuratedLooksRequestFunction();
    }
  };

  useEffect(() => {
    // apply random/curated look by Watson flow
    if (suggestionLook) {
      setIsRequesting(true);

      if (!window.YMK?.isLoaded()) {
        window.YMK.open([true]);
      }
      // not the best solution, run function after live camera is opened
      setTimeout(() => {
        if (window.YMK?.isLoaded()) {
          window.YMK?.reset();
        }
      }, 1000);

      // run functions after YMK reset()
      setTimeout(() => {
        dispatch(removeActiveProductVariant());
        dispatch(removeAllSelectedProductVariants());
        if (suggestionLook === 'random') {
          getRandomLooksRequestFunction();
          dispatch(applySuggestionLook(null));
        }
        if (suggestionLook === 'curated') {
          getCuratedLooksRequestFunction();
          dispatch(applySuggestionLook(null));
        }
      }, 2000);
    }
  }, [
    suggestionLook,
    getCuratedLooksRequestFunction,
    getRandomLooksRequestFunction,
    dispatch,
  ]);

  const applyRandomLook = (data) => {
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (productsData) {
        dispatch(
          setSelectedProductVariant(
            findProductVariantBySku(element.sku, productsData)
          )
        );
      }
      if (element.patternName) {
        if (regexp.productPatternName.test(element.patternName)) {
          productMulticolourCase(element);
        } else {
          window.YMK && window.YMK.applyMakeupBySku(element.sku, element.patternName);
        }
      } else {
        window.YMK && window.YMK.applyMakeupBySku(element.sku);
      }
    }
  };

  const applyCuratedLook = (data) => {
    for (let i = 0; i < data.skus.length; i++) {
      const sku = data.skus[i];
      dispatch(
        setSelectedProductVariant(findProductVariantBySku(sku, productsData))
      );
    }
    window.YMK.applyMakeupByLook(data.guid);
  };

  useEffect(() => {
    const BUTTON_DISABLING_TIMEOUT = 2000;

    // enable buttons after 2000 mls

    if (isAddAllButtonClicked) {
      const timeout = setTimeout(() => {
        toggleAddAllButton(false);
      }, BUTTON_DISABLING_TIMEOUT);

      return () => {
        clearTimeout(timeout);
      };
    }

    if (isPrevNextButtonClicked) {
      const isPrevNextButtonClickedTimeout = setTimeout(() => {
        togglePrevNextButton(false);
      }, BUTTON_DISABLING_TIMEOUT);

      return () => {
        clearTimeout(isPrevNextButtonClickedTimeout);
      };
    }

    if (isLookButtonClicked) {
      const isLookButtonClickedTimeout = setTimeout(() => {
        toggleLookButton(false);
      }, BUTTON_DISABLING_TIMEOUT);

      return () => {
        clearTimeout(isLookButtonClickedTimeout);
      };
    }
  }, [isLookButtonClicked, isPrevNextButtonClicked, isAddAllButtonClicked]);

  useEffect(() => {
    // execute only if Look button is clicked
    if (isLookButtonClicked) {
      // random look data type is array
      if (Array.isArray(latestLook) && latestLook?.length) {
        applyRandomLook(latestLook);
      } else {
        // curated look data type is object
        if (latestLook?.skus) {
          applyCuratedLook(latestLook);
        }
      }
    }
  }, [latestLook]);

  useEffect(() => {
    // exclude Look button click case
    if (allLooks?.length && !isLookButtonClicked) {
      const res = allLooks[activeLookIndex];

      if (Array.isArray(res) && res?.length) {
        // random look data type is array
        applyRandomLook(res);
      } else {
        // curated look data type is object
        if (res?.skus) {
          applyCuratedLook(res);
        }
      }
    }
  }, [activeLookIndex]);

  const renderLookLabel = () => (
    <Text
      color="var(--chakra-colors-brand-600)"
      textStyle="button-label"
      fontSize={{ base: '14px', iPadPro: '18px' }}
      letterSpacing="0.15em"
      textTransform="uppercase"
      margin="auto"
    >
      {TextConstants.ACCORDION.CUSTOMIZE_MAKEUP.LOOK_BUTTON}
    </Text>
  );

  const renderCurateLookButton = () => (
    <Tooltip
      placement="top"
      hasArrow
      label={TextConstants.TOOLTIPS.CURATE_LOOK}
      aria-label={TextConstants.TOOLTIPS.CURATE_LOOK}
      bg="var(--chakra-colors-brand-600)"
      fontFamily="inter"
      fontStyle="normal"
      fontWeight="400"
      fontSize={{ base: '12px', md: '14px' }}
    >
      <Button
        _focus={{ boxShadow: 'none' }}
        width="2rem"
        height="2rem"
        gap="10px"
        bg="var(--chakra-colors-brand-600)"
        _hover={{
          _disabled: {
            bg: 'brand.500',
            color: 'white',
          },
        }}
        borderRadius="0"
        disabled={
          isRequesting ||
          isAddAllButtonClicked ||
          isLookButtonClicked ||
          isPrevNextButtonClicked
        }
        onClick={handleClickLookButton}
      >
        <HHIcon icon="refresh-cw" />
      </Button>
    </Tooltip>
  );

  const renderPreviousLookButton = () => (
    <Tooltip
      placement="top"
      hasArrow
      label={TextConstants.TOOLTIPS.PREVIOUS_LOOK}
      aria-label={TextConstants.TOOLTIPS.PREVIOUS_LOOK}
      bg="var(--chakra-colors-brand-600)"
      fontFamily="inter"
      fontStyle="normal"
      fontWeight="400"
      fontSize={{ base: '12px', md: '14px' }}
    >
      <Button
        _focus={{ boxShadow: 'none' }}
        width="2rem"
        height="2rem"
        gap="10px"
        bg="var(--chakra-colors-brand-600)"
        _hover={{
          _disabled: {
            bg: 'brand.500',
            color: 'white',
          },
        }}
        disabled={
          allLooks.length <= 1 ||
          activeLookIndex === 0 ||
          isRequesting ||
          isAddAllButtonClicked ||
          isLookButtonClicked ||
          isPrevNextButtonClicked ||
          !allLooks.length
        }
        borderRadius="0"
        onClick={() => {
          if (activeLookIndex > 0) {
            window.YMK.reset();
            dispatch(removeActiveProductVariant());
            dispatch(removeAllSelectedProductVariants());

            setActiveLookIndex(activeLookIndex - 1);
            togglePrevNextButton(true);
          }
        }}
      >
        <SVG id="arrow-left" />
      </Button>
    </Tooltip>
  );

  const renderNextLookButton = () => (
    <Tooltip
      placement="top"
      hasArrow
      label={TextConstants.TOOLTIPS.NEXT_LOOK}
      aria-label={TextConstants.TOOLTIPS.NEXT_LOOK}
      bg="var(--chakra-colors-brand-600)"
      fontFamily="inter"
      fontStyle="normal"
      fontWeight="400"
      fontSize={{ base: '12px', md: '14px' }}
    >
      <Button
        _focus={{ boxShadow: 'none' }}
        width="2rem"
        height="2rem"
        gap="10px"
        bg="var(--chakra-colors-brand-600)"
        _hover={{
          _disabled: {
            bg: 'brand.500',
            color: 'white',
          },
        }}
        disabled={
          // if last item is selected
          activeLookIndex === allLooks?.length - 1 ||
          isRequesting ||
          isAddAllButtonClicked ||
          isLookButtonClicked ||
          isPrevNextButtonClicked ||
          !allLooks?.length
        }
        borderRadius="0"
        onClick={() => {
          if (activeLookIndex < 4) {
            window.YMK.reset();
            dispatch(removeActiveProductVariant());
            dispatch(removeAllSelectedProductVariants());

            setActiveLookIndex(activeLookIndex + 1);
            togglePrevNextButton(true);
          }
        }}
      >
        <SVG id="arrow-right" />
      </Button>
    </Tooltip>
  );

  const renderRemoveLookButton = () => (
    <Tooltip
      placement="top"
      hasArrow
      label={TextConstants.TOOLTIPS.RESET_LOOK}
      aria-label={TextConstants.TOOLTIPS.RESET_LOOK}
      bg="var(--chakra-colors-brand-600)"
      fontFamily="inter"
      fontStyle="normal"
      fontWeight="400"
      fontSize={{ base: '12px', md: '14px' }}
    >
      <Button
        _focus={{ boxShadow: 'none' }}
        width="2rem"
        height="2rem"
        gap="10px"
        bg="var(--chakra-colors-brand-600)"
        _hover={{
          _disabled: {
            bg: 'brand.500',
            color: 'white',
          },
        }}
        disabled={
          isRequesting ||
          isAddAllButtonClicked ||
          isLookButtonClicked ||
          isPrevNextButtonClicked ||
          !allLooks.length
        }
        borderRadius="0"
        onClick={() => {
          window.YMK.reset();
          dispatch(removeActiveProductVariant());
          dispatch(removeAllSelectedProductVariants());
        }}
      >
        <HHIcon icon="reset-color-white" />
      </Button>
    </Tooltip>
  );

  const renderAddAllItemButton = () => (
    <Button
      _focus={{ boxShadow: 'none' }}
      height="2rem"
      gap="10px"
      bg="var(--chakra-colors-brand-600)"
      _hover={{
        _disabled: {
          bg: 'brand.500',
          color: 'white',
        },
      }}
      _disabled={{
        opacity: 1,
      }}
      onClick={() => {
        toggleAddAllButton(true);
        if (selectedProductVariants?.length) {
          addItemToBag();
          dispatch(removeActiveProductVariant());
          dispatch(removeAllSelectedProductVariants());
        }
      }}
      disabled={
        isRequesting ||
        isAddAllButtonClicked ||
        isLookButtonClicked ||
        isPrevNextButtonClicked
      }
      borderRadius="0"
    >
      <HHIcon icon="shopping-bag" />
      <Text
        display={{ base: 'none', iPadPro: 'block' }}
        textStyle="button-label"
        fontSize={{ base: '12px', sm: '14px' }}
        letterSpacing="0.15em"
        textTransform="uppercase"
      >
        {TextConstants.ACCORDION.CUSTOMIZE_MAKEUP.ADD_ALL}
      </Text>
    </Button>
  );

  const renderMicButton = () => {
    return typingOnly ? null : (
      <Button
        className="toggle-mic-button"
        bgColor="#FFFFFF"
        variant="circle-icon"
        size="md"
        mx="3px"
        borderRadius="50%"
        borderColor="white"
        border="1px solid var(--chakra-colors-gray-300)"
        _hover={{ background: '#ededed' }}
        data-place="bottom"
        _focus={{
          boxShadow: 'none',
        }}
        disabled={transcript.length === 0}
        onClick={() => {
          toggleMicrophone();
        }}
      >
        <SVG id={showMicr ? 'controls-mic-off-chat' : 'mic-on-spa-chat'} />
      </Button>
    );
  };

  const renderTranscriptButton = () => (
    <Button
      className="open-transcript-button"
      bgColor="#FFFFFF"
      variant="circle-icon"
      size="md"
      mx="3px"
      borderRadius="50%"
      borderColor="white"
      border="1px solid var(--chakra-colors-gray-300)"
      _hover={{ background: '#ededed' }}
      data-place="bottom"
      _focus={{
        boxShadow: 'none',
      }}
      onClick={() => {
        dispatch(setShowTranscript(!isTranscriptOpen));
      }}
      disabled={transcript.length === 0}
    >
      <Image src={chat} alt="Chat Icon" />
    </Button>
  );

  const renderNewStickyNoteButton = () => (
    <Button
      className="create-new-note-button"
      bgColor="#FFFFFF"
      variant="circle-icon"
      size="md"
      color="var(--chakra-colors-brand-600)"
      mx="3px"
      borderRadius="50%"
      borderColor="white"
      border="1px solid var(--chakra-colors-gray-300)"
      _hover={{ background: '#ededed' }}
      data-place="bottom"
      _focus={{
        boxShadow: 'none',
      }}
      disabled={transcript.length === 0}
      onClick={() => {
        handleCreate();
      }}
    >
      <BookmarkPlus />
    </Button>
  );

  useEffect(() => {
    // set ZIndex level for .customizeMakeup
    const ind = getNotesMaxStackLevel(notes);
    // +1 higher than buttons container
    setVMAccordionZindex(ind + 8);
  }, [notes]);

  return (
    <>
      <Box
        style={{
          zIndex: getNotesMaxStackLevel(notes) + 1,
        }}
        className="look-button-container"
        position="absolute"
        zIndex="1"
        right="5px"
        top="5px"
        width={{ base: '85%', iPhoneXR: '90%' }}
      >
        {isPerfectCorpEngineLoaded && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="8px"
          >
            <Box display="flex" gap="10px">
              {renderLookLabel()}
              {renderCurateLookButton()}
              {renderPreviousLookButton()}
              {renderNextLookButton()}
              {renderRemoveLookButton()}
            </Box>
            <Box>{renderAddAllItemButton()}</Box>
          </Box>
        )}
      </Box>
      <Box
        className="perfect-corp-buttons-container"
        zIndex={getNotesMaxStackLevel(notes) + 1 + '!important;'}
        width="90%"
        bottom="50px"
        position="absolute"
        display="flex"
        justifyContent="end"
        left="50%"
        transform="translateX(-50%)"
      >
        {renderTranscriptButton()}
        {renderMicButton()}
        {renderNewStickyNoteButton()}
      </Box>
    </>
  );
};

export default VirtualMirrorButtonsPopover;
