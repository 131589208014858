import {
  Box,
  FormControl,
  FormLabel,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FormikProps } from 'formik';
import { FormikSignUpValues } from './SignupStepsModal';
import TextConstants from '../../../../constants/TextConstants';

const SignupGenderStep = ({
  formik,
}: {
  formik: FormikProps<FormikSignUpValues>;
}): JSX.Element => (
  <Box>
    <Stack spacing="16px" mx="16px" justifyContent="center">
      <Text textStyle="title" textAlign="center">
        {TextConstants.MODALS.SIGN_UP_MODAL.ALMAST_THERE}
      </Text>
      <Text textStyle="subTitle" textAlign="center" mt="8px">
        {TextConstants.MODALS.SIGN_UP_MODAL.ALMAST_THERE_DESCRIPTION}
      </Text>
      <Box>
        <FormControl isInvalid={formik.errors.gender !== undefined} pt="20px">
          <FormLabel
            htmlFor="gender"
            fontFamily="Inter"
            fontSize="14px"
            lineHeight="20px"
            color="gray.700"
          >
            {TextConstants.PROFILE_MANAGMENT.PERSONAL_INFO_SECTION.GENDER_TITLE}
          </FormLabel>
          <Select
            className="gender-select"
            placeholder="Select your gender"
            name="gender"
            value={formik.values.gender}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            background="white"
            borderRadius="none"
            color="gray.900"
            borderWidth="1px"
            borderColor="gray.300"
            boxShadow="unset"
            fontFamily="third"
          >
            <option value="male" label="Male" />
            <option value="female" label="Female" />
            <option value="rather_not_say" label="Rather not say" />
            <option value="custom" label="Custom" />
          </Select>
        </FormControl>
      </Box>
    </Stack>
  </Box>
);

export default SignupGenderStep;
