import { Box } from '@chakra-ui/react';
import JournalWrapper from './JournalWrapper';

function ShareRecords(): JSX.Element {
  return (
    <Box justifyContent="center" display="flex" width="100%">
      <Box width="400px" height="89vh">
        <JournalWrapper />
      </Box>
    </Box>
  );
}

export default ShareRecords;
