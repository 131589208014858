import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@chakra-ui/react';
import { PannellumVideo } from '@erosargsyan/pannellum-react';
import { SVG } from '../components/icons/icons';
import {
  checkIfIOSDevice,
  checkIfMobile,
  checkIfSafariBrowser,
} from '../helpers/checkDevicePlatform';
import useIsGlobalMediaPlaying from '../hooks/useIsGlobalMediaPlaying';
import { RootState } from '../store/store';

type ModalOpenType = {
  isHappyHatsTOSModalOpen: boolean;
  isMediaTOSModalOpen: boolean;
};

const MutePanoramicVideoButton = ({
  videoRef,
  parentComponentType,
  isMuteButtonClicked,
  isModalsOpen,
}: {
  videoRef: React.MutableRefObject<PannellumVideo>;
  parentComponentType: 'default' | 'modal';
  isMuteButtonClicked?: React.MutableRefObject<boolean>;
  isModalsOpen?: ModalOpenType;
}) => {
  const isMediaGloballyPlaying = useIsGlobalMediaPlaying();

  const loading = useSelector((state: RootState) => state.digitalAssistant.loading);
  const connected = useSelector((state: RootState) => state.digitalAssistant.connected);

  // ititially muted for IOS and Safari browser only
  const [isMuted, setIsMuted] = useState(
    checkIfIOSDevice() || checkIfSafariBrowser()
  );

  useEffect(() => {
    if (parentComponentType === 'default') {
      // execute only for desert/city components
      if (
        // fixes video unmutes when modals are open or DP is loading/connected
        isModalsOpen?.isHappyHatsTOSModalOpen ||
        isModalsOpen?.isMediaTOSModalOpen ||
        loading ||
        connected ||
        // if global video is playing
        isMediaGloballyPlaying
      ) {
        if (isMediaGloballyPlaying) {
          // if condition fixes not playing sound when accepting permissions

          if (isMuteButtonClicked) {
            isMuteButtonClicked.current = isMuted;
          }
        }
        setIsMuted(true);
        videoRef?.current?.video.muted(true);
      } else {
        // set to initial state
        setIsMuted(isMuteButtonClicked.current);
        videoRef?.current?.video.muted(isMuteButtonClicked.current);
      }
    } else {
      return;
    }
  }, [isModalsOpen, loading, connected, isMediaGloballyPlaying]);

  return (
    <Button
      bottom={
        parentComponentType === 'modal' && checkIfMobile() ? '120px' : '30px'
      }
      right="30px"
      position="absolute"
      bgColor="#FFFFFF"
      variant="circle-icon"
      _hover={{ background: 'var(--chakra-colors-brand-100)  !important' }}
      size="md"
      onClick={() => {
        setIsMuted(!isMuted);
        if (isMuteButtonClicked) {
          isMuteButtonClicked.current = !isMuted;
        }
        videoRef?.current?.video.muted(!isMuted);
      }}
    >
      <SVG id={isMuted ? 'volume-x' : 'volume-2'} />
    </Button>
  );
};

export default MutePanoramicVideoButton;
