import {
  CommerceInitialState,
  DigitalAssistantState,
  VMInitialState,
} from '../global-components/GlobalTypes';

import {
  setIsHeaderModalsShown,
  openMakeupAccordionSliderByWatsonFeature,
  removeActiveProductVariant,
  setAddProductsToBag,
} from '../store/reducers/commerceReducer';

import {
  getUserFavoriteProductInput,
} from '../store/reducers/vmReducer';

import {
  setShowTranscript,
  setActiveCards,
  addConversationResult,
  setUserSpeaking,
  sendTextMessage,
  setShouldSendToneAnalyzerContext,
  closeAssistantChat,
} from '../store/reducers/digitalAssistantReducer';

import {
  setSignInModal,
} from '../store/reducers/signInModalReducer';

import {
  setSceneToOpen,
} from '../store/reducers/pannellumReducer';

import {
  setJournalOpenedWhileDPEnabled,
} from '../store/reducers/journalReducer';

import {
  openWellnessPanoramicVideoModal,
} from '../store/reducers/panoramicVideoReducer';

import {
  saveDPChatUserDataInLocalStorage,
} from '../helpers/DPNewUserFlowStorageHelper';

import calculateSpeechDuration from '../helpers/calculateSpeechDuration';

import TextConstants from '../constants/TextConstants';

let speechDurationInMiliseconds = 0;
let proceedToNextNodeTimeout = null;

const clearProceedToNextNodeTimeout = () => {
  if (proceedToNextNodeTimeout) {
    clearTimeout(proceedToNextNodeTimeout);
    // reset variables
    proceedToNextNodeTimeout = null;
    speechDurationInMiliseconds = 0;
  }
};

export const processWatsonMessage = async (watsonResponse: any, thunk: any) => {
  clearProceedToNextNodeTimeout();

  const messageData = parseMessageString(watsonResponse.textReponse);
  const { digitalAssistant } = thunk.getState() as { digitalAssistant: DigitalAssistantState; };
  const { vm } = thunk.getState() as { vm: VMInitialState };

  for (const specialCommand of messageData.specialCommands) {
    await processSpeechMarker(specialCommand.speechMarkerName, specialCommand.arguments, thunk, digitalAssistant, vm);
  }

  return messageData;
};

const processSpeechMarker = async (speechMarkerName: string, args: Array<string>, thunk: any, digitalAssistant: any, vm: any) => {
  console.log('processSpeechMarker()', speechMarkerName, args);

  switch (speechMarkerName) {
    case 'showcards': {
      // auto-open transcript in commerce page
      if (vm.showVirtualMirror) {
        thunk.dispatch(setShowTranscript(true));
      }

     
      // this will only ever be one card
      const newCards = args.map((a) => ({
          ...digitalAssistant.contentCards[a],
        }))
        .filter((a) => !!a && !!a.component);

      if (newCards.length > 0) {
        thunk.dispatch(setActiveCards({ activeCards: newCards }));
        // send card to transcript as well
        // thunk.dispatch(
        //   addConversationResult({
        //     source: 'persona',
        //     card: newCards[0],
        //   })
        // );
   
      }

      break;
    }
    case 'hidecards': {
      thunk.dispatch(setActiveCards({}));
      break;
    }
    case 'cinematic': {
      // fired when CUE changes camera angles
      break;
    }
    case 'feature': {
      const { commerce } = thunk.getState() as {
        commerce: CommerceInitialState;
      };
      const featureArgs = args;

      const feature = featureArgs[0];
      const featureState1 =
        featureArgs && featureArgs.length > 1 ? featureArgs[1] : null;
      const featureState2 =
        featureArgs && featureArgs.length > 2 ? featureArgs[2] : null;
      const featureState3 =
        featureArgs && featureArgs.length > 3 ? featureArgs[3] : null;

      switch (feature) {
        case 'microphone': {
          if (featureState1 === 'on') thunk.dispatch(setUserSpeaking({ userSpeaking: true }));
          else if (featureState1 === 'off') thunk.dispatch(setUserSpeaking({ userSpeaking: false }));
          else
            console.error(
              `state ${featureState1} not supported by @feature(microphone)!`
            );
          break;
        }
        case 'transcript': {
          if (featureState1 === 'on')
            thunk.dispatch(setShowTranscript(true));
          else if (featureState1 === 'off')
            thunk.dispatch(setShowTranscript(false));
          else
            console.error(
              `state ${featureState1} not supported by @feature(transcript)!`
            );
          break;
        }
        case 'isMyBagShown': {
          if (featureState1 === 'on')
            thunk.dispatch(
              setIsHeaderModalsShown({ myBagDrawer: true })
            );
          else if (featureState1 === 'off')
            thunk.dispatch(
              setIsHeaderModalsShown({ myBagDrawer: false })
            );
          else
            console.error(
              `state ${featureState1} not supported by @feature(openBag)!`
            );
          break;
        }

        case 'isSignInModalShown': {
          if (featureState1 === 'on')
            thunk.dispatch(setSignInModal({ isOpen: true }));
          else if (featureState1 === 'off')
            thunk.dispatch(setSignInModal({ isOpen: false }));
          else
            console.error(
              `state ${featureState1} not supported by @feature(openBag)!`
            );
          break;
        }

        case 'isSignUpModalShown': {
          if (featureState1 === 'on')
            thunk.dispatch(
              setIsHeaderModalsShown({ registerModal: true })
            );
          else if (featureState1 === 'off')
            thunk.dispatch(
              setIsHeaderModalsShown({ registerModal: false })
            );
          else
            console.error(
              `state ${featureState1} not supported by @feature(openBag)!`
            );
          break;
        }

        case 'isUserReturningCheckpointReached': {
          saveDPChatUserDataInLocalStorage({
            returning_user: 'true',
            stage: 'welcome',
          });
          break;
        }
        case 'isJournalDrawerShown': {
          if (featureState1 === 'on') {
            thunk.dispatch(
              setIsHeaderModalsShown({ journalDrawer: true })
            );
            thunk.dispatch(setJournalOpenedWhileDPEnabled(true));
          } else if (featureState1 === 'off') {
            thunk.dispatch(
              setIsHeaderModalsShown({ journalDrawer: false })
            );
            thunk.dispatch(setJournalOpenedWhileDPEnabled(false));
          } else
            console.error(
              `state ${featureState1} not supported by @feature(openBag)!`
            );
          break;
        }
        case 'redirectToLobby': {
          thunk.dispatch(closeAssistantChat());
          break;
        }
        case 'isProfileDrawerShown': {
          if (featureState1 === 'on')
            thunk.dispatch(
              setIsHeaderModalsShown({ profileDrawer: true })
            );
          else if (featureState1 === 'off')
            thunk.dispatch(
              setIsHeaderModalsShown({ profileDrawer: false })
            );
          else
            console.error(
              `state ${featureState1} not supported by @feature(openBag)!`
            );
          break;
        }

        case 'goToReceptionArea': {
          thunk.dispatch(setSceneToOpen('reception'));
          thunk.dispatch(closeAssistantChat());
          break;
        }

        case 'goToMentalWellnessRoom': {
          thunk.dispatch(setSceneToOpen('spa'));
          thunk.dispatch(closeAssistantChat());

          break;
        }

        case 'openBeachPanoramicVideo': {
          thunk.dispatch(
            openWellnessPanoramicVideoModal('st_martin_beach')
          );
          break;
        }

        case 'openBuddhistPanoramicVideo': {
          thunk.dispatch(
            openWellnessPanoramicVideoModal(
              'buddhist_guided_meditation_room'
            )
          );

          break;
        }

        case 'startToneAnalyzer': {
          console.log("setting startToneAnalyzer to true")
          thunk.dispatch(
            setShouldSendToneAnalyzerContext({
              shouldSendToneAnalyzerContext: true,
              watsonCurrentNode: featureState1,
            })
          );

          // like @feature(microphone,on)
          thunk.dispatch(setUserSpeaking({ userSpeaking: true }));

          break;
        }

        case 'openMakeupAccordionSliderByWatsonFeature': {
          thunk.dispatch(
            openMakeupAccordionSliderByWatsonFeature({
              category: featureState1,
              subcategory: featureState2,
              selectedProduct: featureState3,
            })
          );

          break;
        }

        case 'proceedToNextNode': {
          if (digitalAssistant.lastPersonaUtterance) {
            speechDurationInMiliseconds = calculateSpeechDuration(
              digitalAssistant.lastPersonaUtterance
            );
          }
          const defaultProceedText = 'proceed';
          // proceed to next node after timeout
          proceedToNextNodeTimeout = setTimeout(() => {
            thunk.dispatch(
              sendTextMessage({
                text: featureState1
                  ? `${featureState1}`
                  : defaultProceedText,
              })
            );
          }, TextConstants.COMMERCE.PROCEED_TO_NEXT_NODE_TIMEOUT + speechDurationInMiliseconds);

          break;
        }

        case 'getUserFavoriteProductInput': {
          thunk.dispatch(getUserFavoriteProductInput(true));

          break;
        }

        case 'addItemToBag': {
          const myBagVariants = commerce.myBagDataItems;
          const activeProductVariant = commerce.activeProductVariant;

          const addItemToBag = (
            selectedProductVariants: CommerceInitialState['activeProductVariant'][]
          ) => {
            if (selectedProductVariants.length) {
              if (!myBagVariants.length) {
                thunk.dispatch(
                  setAddProductsToBag(selectedProductVariants)
                );
              } else {
                let newMyBagData = myBagVariants;
                for (
                  let i = 0;
                  i < selectedProductVariants.length;
                  i++
                ) {
                  const selectedProductVariant =
                    selectedProductVariants[i];
                  const repeatingVariant =
                    myBagVariants.filter(
                      (variant) =>
                        variant.productVariant &&
                        variant.productVariant.sku ===
                          selectedProductVariant.productVariant.sku
                    ) || [];
                  if (!repeatingVariant.length) {
                    newMyBagData = [
                      ...newMyBagData,
                      selectedProductVariant,
                    ];
                  }
                }
                thunk.dispatch(setAddProductsToBag(newMyBagData));
              }
            }
          };

          if (featureState1 === 'true') {
            if (activeProductVariant) {
              addItemToBag([activeProductVariant]);
              thunk.dispatch(removeActiveProductVariant());
            }
          } else if (featureState1 === 'false') {
            if (activeProductVariant) {
              thunk.dispatch(removeActiveProductVariant());
            }
          }
          break;
        }

        default: {
          console.error(`@feature(${feature}) not recognized!`);
        }
      }
      break;
    }
    case 'close': {
      thunk.dispatch(closeAssistantChat());
      break;
    }

    default: {
      console.warn(`unregonized speech marker: ${speechMarkerName}`);
    }
  }
};

const parseMessageString = (messageString: string) => {
  const specialCommands = [];
  const specialCommandMatches = messageString.match(/\@([^(]+)\((.*?)\)/g);

  if (specialCommandMatches) {
    for (const specialCommandMatch of specialCommandMatches) {
      const specialCommandDetailsMatch = specialCommandMatch.match(/^[^@]*\@([^(]+)\((.*?)\)(?:\s*(.*))?$/);

      if (specialCommandDetailsMatch) {
        const speechMarkerName = specialCommandDetailsMatch[1];
        const parsedArgs = specialCommandDetailsMatch[2].split(',').map(arg => arg.trim());
        const finalArguments = (parsedArgs.length === 1 && parsedArgs[0] === '') ? [] : parsedArgs;

        specialCommands.push({
          speechMarkerName,
          arguments: finalArguments,
        });
      }
    }
  }

  return {
    specialCommands,
    text: messageString.replaceAll(/\@([^(]+)\((.*?)\)\s*/g, ''),
  };
};
