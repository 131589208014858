const generateRandomNumberBetween = (
  minNum: number,
  maxNum: number
): number[] => {
  //min included, max excluded
  const uniqueNumbers: Set<number> = new Set();

  while (uniqueNumbers.size < 3) {
    const randomNum: number =
      Math.floor(Math.random() * (maxNum - minNum)) + minNum;
    uniqueNumbers.add(randomNum);
  }

  return Array.from(uniqueNumbers);
};
export default generateRandomNumberBetween;
