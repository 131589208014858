import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../store/store';
import IconInCircles from '../common/IconInCircles/IconInCircles';
import ContentCardSwitch from '../ContentCardSwitch';
import { SVG } from '../icons/icons';
import { setShowTranscript } from '../../store/reducers/digitalAssistantReducer';
import tutlAvatar from '../../img/tutl-avatar.png';
import TextConstants from '../../constants/TextConstants';
import { stopTalking } from '../../utils/textToSpeech';

const TranscriptContainer = styled.div`
  ${({ showVirtualMirror }) =>
    showVirtualMirror ? 'margin-bottom: 30px; margin-top: 10px;' : ''};

  width: 100%;
  height: ${({ isSmallerThanIPadProSize, showVirtualMirror }) => {
    if (isSmallerThanIPadProSize) {
      if (showVirtualMirror) {
        return '70vh;';
      } else {
        return '100%;';
      }
    } else {
      return '60vh;';
    }
  }};

  ${({ showTranscriptHeader }) =>
    showTranscriptHeader ? '' : 'margin-top: 80px'};

  overflow: auto;

  .transcript-list-group {
    flex-shrink: 1;
    overflow-y: auto;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .transcript-entry {
    clear: both;
    display: flex;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    max-width: 90%;
    flex: auto;

    .chakra-avatar__badge {
      border: 0.1em solid white;
    }
  }

  .transcript-entry-user {
    align-self: flex-end;

    .transcript-entry-content {
      border-radius: 0.5rem 0px 0.5rem 0.5rem;
      background-color: var(--chakra-colors-brand-600);
      color: var(--chakra-colors-white);
    }
  }
`;

const weekday = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

interface TranscriptComponentProps {
  showTranscriptHeader?: boolean;
}

const Transcript: React.FC<TranscriptComponentProps> = ({ showTranscriptHeader = true }) => {
  const [isSmallerThanIPadProSize] = useMediaQuery(
    `(max-width: ${TextConstants.SIZES.IPAD_PRO_WIDTH}px)`
  );

  const transcript = useSelector((state: RootState) => state.digitalAssistant.transcript);
  const dispatch = useDispatch();
  const showVirtualMirror = useSelector(
    (state: RootState) => state.vm.showVirtualMirror
  );
  const transcriptDisplay = transcript.map(
    ({ source, text, card, timestamp }, index) => {
      // we don't want to wrap cards in a bubble, return as is w/ a key added
      if (card) {
        return (
          <ContentCardSwitch
            card={card}
            index={null}
            key={timestamp}
            // if last card => buttons should be enabled
            isCardInTranscript={transcript.length - 1 > index}
            renderMuteButton={renderMuteButton}
          />
        );
      }
      return (
        <Box
          flexDirection="row"
          alignItems={` ${source === TextConstants.CONVERSATION_RESULT_SOURCE.USER ? 'end' : 'start'}`}
          key={timestamp}
          className={`transcript-entry ${
            source === TextConstants.CONVERSATION_RESULT_SOURCE.USER ? 'transcript-entry-user' : ''
          }`}
        >
          {source === TextConstants.CONVERSATION_RESULT_SOURCE.PERSONA && (
            <Avatar size="sm" src={tutlAvatar}>
              <AvatarBadge boxSize="0.9em" bg="green.500" />
            </Avatar>
          )}
          <Box ml="5px">
            <Box
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              display="flex"
            >
              <Text
                lineHeight="20px"
                color="var(--chakra-colors-gray-700)"
                fontSize="14px"
                fontWeight="500"
              >
                {source === TextConstants.CONVERSATION_RESULT_SOURCE.USER ? 'You' : 'Tutl'}
              </Text>
              <Text
                display="inline-block"
                pr="10px"
                pl="10px"
                pb="3px"
                pt="3px"
                borderRadius="20px"
                color="var(--chakra-colors-gray-500)"
                lineHeight="20px"
                fontSize="13px"
              >
                {`${weekday[new Date(timestamp).getDay()]} ${new Date(
                  timestamp
                ).toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                })}`}
              </Text>
            </Box>

            <Box className="transcript-entry-content">
              {text || null}
              {source !== TextConstants.CONVERSATION_RESULT_SOURCE.USER && renderMuteButton()}
            </Box>
          </Box>
        </Box>
      );
    }
  );

  const showHideTranscriptHeader = () => {
    if (!showTranscriptHeader) {
      return 'none';
    }

    if (isSmallerThanIPadProSize && !showVirtualMirror) {
      return 'none';
    }
    // if is smaller than IPad Pro size => transcript header is hidden
    if (!isSmallerThanIPadProSize && showVirtualMirror) {
      return 'flex';
    }

    return 'flex';
  };

  // scroll to bottom of transcript whenever it updates
  const scrollRef = useRef(null);
  const [isMounting, setIsMounting] = useState(true);

  useEffect(() => {
    if (isMounting) {
      scrollRef.current?.scrollIntoView({ behavior: 'instant' });
    } else {
      // scroll to transcript bottom after 500mls (card is fully opened)
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
      }, 1000);
    }

    setIsMounting(false);
  }, [transcript, scrollRef, isMounting]);

  return (
    <>
      <Box
        className="transcript-header"
        py={showVirtualMirror ? '0px' : '20px'}
        ml="-3px"
        zIndex={3}
        bg="transparent"
        pl="10px"
        maxWidth={` ${showVirtualMirror ? '' : '510px'}`}
        w={`${!showVirtualMirror && '100%'}`}
        display={showHideTranscriptHeader()}
      >
        <IconInCircles icon="message-square" theme="primary" />
        <Box ml="15px">
          <Text
            lineHeight="28px"
            color="var(--chakra-colors-gray-900)"
            fontSize="15px"
            fontWeight="500"
          >
            Conversation transcript
          </Text>
          <Text
            lineHeight="20px"
            color="var(--chakra-colors-gray-500)"
            fontSize="13px"
            fontWeight="400"
          >
            Your recent dialogue with Tutl.{' '}
          </Text>
        </Box>
        <Box
          onClick={() => dispatch(setShowTranscript(false))}
          p="15px 20px"
          cursor="pointer"
        >
          <SVG id="close-x" />
        </Box>
      </Box>
      <TranscriptContainer
        showVirtualMirror={showVirtualMirror}
        isSmallerThanIPadProSize={isSmallerThanIPadProSize}
        showTranscriptHeader={showTranscriptHeader}
      >
        <div className={`transcript-list-group${transcriptDisplay.length < 2 ? ' welcome-chat' : ''}`}>
          {transcriptDisplay.length > 0 ? (
            transcriptDisplay
          ) : (
            <li className="list-group-item">
              No items to show, say something!
            </li>
          )}
          <div
            ref={(el) => {
              scrollRef.current = el;
            }}
          />
        </div>
      </TranscriptContainer>
    </>
  );
};

const renderMuteButton = () => (
  <Button
    position='absolute'
    right='-8px'
    bottom={0}
    bgColor='#FFFFFF'
    variant='circle-icon'
    size='md'
    mx='3px'
    borderRadius='50%'
    border='none'
    backgroundColor='transparent'
    title='Stop Talking'
    _hover={{ background: 'transparent' }}
    data-place='bottom'
    _focus={{
      boxShadow: 'none',
    }}
    onClick={stopTalking}
  >
    <SVG id='volume-x' />
  </Button>
);

export default Transcript;
