import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PannellumReducerInitialState } from '../../global-components/GlobalTypes';

const initialState: PannellumReducerInitialState = {
  sceneToOpen: 'default',
  is360VideoPageRedirected: false,
  isDesertPageRedirectedFromDP: false,
  isDesertRoomOpened: false,
};

export const PannellumReducer = createSlice({
  name: 'pannellumReducer',
  initialState,
  reducers: {
    setSceneToOpen: (
      state,
      { payload }: PayloadAction<'spa' | 'reception' | 'default'>
    ) => ({
      ...state,
      sceneToOpen: payload,
    }),
    // check if we have redirected to 360 video page (otherwise causes error connected with Panellum.Hotspots)
    set360VideoPageRedirected: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      is360VideoPageRedirected: payload,
    }),

    setDesertPageRedirectedFromAssistant: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      isDesertPageRedirectedFromDP: payload,
    }),

    setDesertRoomOpened: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDesertRoomOpened: payload,
    }),
  },
});

export const {
  setSceneToOpen,
  set360VideoPageRedirected,
  setDesertPageRedirectedFromAssistant,
  setDesertRoomOpened,
} = PannellumReducer.actions;

export default PannellumReducer.reducer;
