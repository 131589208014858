import { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import TextConstants from '../../constants/TextConstants';
import {
  addOrUpdateCart,
  fetchCartItems,
  getUserDataRequest,
  loginRequest,
  postGuestEntryData,
} from '../../global-components/RequestFactory';
import { setUserData } from '../../store/reducers/authenticationReducer';
import { RootState } from '../../store/store';
import ForgotPasswordModal from './ForgotPasswordModal';
import cookies from '../../helpers/addCookies';
import { removeGuestEntryData } from '../../store/reducers/journalReducer';
import { emailValidation } from '../../helpers/validation';
import { hasRoles } from '../../utils/RoleUtils';
import { Role } from '../../constants/Role';
import { setSignInModal } from '../../store/reducers/signInModalReducer';
import { CLOSE_SIGN_IN_MODAL_EVENT_NAME } from '../../hooks/useSignInModalCloseHandler';
import { executeAuthPromptModalCallback } from '../../store/reducers/authPromptReducer';
import { setAddProductsToBag } from '../../store/reducers/commerceReducer';
import { parsedAddCartRequest } from '../../utils/cartUtils';

const Container = styled.div``;

const SignInModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const [isRequesting, setIsRequesting] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);
  const [requestError, setRequestError] = useState({
    isErrorShown: false,
    errorText: '',
  });
  const signInModalState = useSelector((state: RootState) => state.signInModal);
  const myBagDataItems = useSelector(
    (state: RootState) => state.commerce.myBagDataItems
  );
  const validationSchema = Yup.object().shape({
    // TODO, temporary validation, import from TextConstants
    email: emailValidation(),
    password: Yup.string().required(TextConstants.ERROR_TEXT.REQUIRED),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    onSubmit: (values) => {
      setIsRequesting(true);
      loginRequest({
        email: values.email.toLowerCase(),
        password: values.password,
        rememberMe: values.rememberMe,
      })
        .then((result) => {
          const guestEntryData = cookies.get('guestEntryData');
          cookies.remove('guestEntryData', { path: '/' });
          dispatch(removeGuestEntryData());
          guestEntryData &&
            postGuestEntryData(guestEntryData, result.token)
              .then(() => {})
              .catch((error) => {
                console.log(error);
              });
          const createdDate = new Date().getTime();
          localStorage.setItem('createdDate', createdDate.toString());
          localStorage.setItem('refreshDate', createdDate.toString());
          setIsRequesting(false);
          setRequestError({ isErrorShown: false, errorText: '' });
          const userData = { ...result, isRememberMe: values.rememberMe };
          getUserDataRequest(result.user_id, result.token).then((data) => {
            dispatch(setUserData({ ...userData, ...data }));
            handleRedirectToAdmin();
            handleSkipClick();
          });
          manageCart(result.token)
          formik.resetForm();
          setTimeout(() => {
            dispatch(executeAuthPromptModalCallback(true));
          }, 500);
        })
        .catch((error) => {
          setIsRequesting(false);
          setRequestError({ isErrorShown: true, errorText: error.message });
        });
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const manageCart = async (token)=>{
    if(myBagDataItems && myBagDataItems.length){
      const paredReq = parsedAddCartRequest(myBagDataItems)
      await addOrUpdateCart(token, {cart_item: paredReq})
    }
    fetchCartItems(token).then(data => {            
      dispatch(setAddProductsToBag(data));
    })
  }

  const handleRedirectToAdmin = () => {
    if (hasRoles(Role.ADMIN)) {
      //window.location.assign(process.env.REACT_APP_ADMIN_URL);
    }
  };

  const handleCloseClick = () => dispatch(setSignInModal({ isOpen: false }));

  const closeForgotPasswordModal = () => {
    setIsForgotPasswordModalOpen(false);
    dispatch(executeAuthPromptModalCallback(true));
  };

  const handleSkipClick = useCallback(() => {
    handleCloseClick();
    document.dispatchEvent(new CustomEvent(CLOSE_SIGN_IN_MODAL_EVENT_NAME));
  }, []);

  return (
    <Container>
      <Modal
        onClose={() => {
          handleCloseClick();
          dispatch(executeAuthPromptModalCallback(true));
        }}
        isOpen={signInModalState.isOpen}
        isCentered
        blockScrollOnMount
        preserveScrollBarGap
      >
        <ModalOverlay
          backdropFilter="auto"
          backdropInvert="10%"
          backdropBlur="0px"
        />
        <ModalContent borderRadius="0">
          <ModalHeader mt="15px" textAlign="center">
            {TextConstants.MODALS.SIGN_IN_MODAL.TITLE}
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              handleCloseClick();
              formik.resetForm();
              setRequestError({ isErrorShown: false, errorText: '' });
              dispatch(executeAuthPromptModalCallback(true));
            }}
          />
          <Stack
            spacing={3}
            mt="10px"
            mb="40px"
            mx="45px"
            as="form"
            justifyContent="center"
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <FormControl isInvalid={formik.errors.email !== undefined}>
              <FormLabel>
                {TextConstants.MODALS.SIGN_IN_MODAL.EMAIL_INPUT_LABEL}
              </FormLabel>
              <Input
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder={
                  TextConstants.MODALS.SIGN_IN_MODAL.EMAIL_INPUT_PLACEHOLDER
                }
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formik.errors.password !== undefined}>
              <FormLabel>
                {TextConstants.MODALS.SIGN_IN_MODAL.PASSWORD_INPUT_LABEL}
              </FormLabel>
              <Input
                name="password"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                placeholder={
                  TextConstants.MODALS.SIGN_IN_MODAL.PASSWORD_INPUT_PLACEHOLDER
                }
              />
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl>
            <Stack spacing={7}>
              <Box d="flex" justifyContent="space-between">
                <Checkbox
                  id="rememberMe"
                  name="rememberMe"
                  onChange={formik.handleChange}
                >
                  {TextConstants.MODALS.SIGN_IN_MODAL.REMEMBER_ME_TEXT}
                </Checkbox>
                <Text
                  onClick={() => {
                    setIsForgotPasswordModalOpen(true);
                    handleCloseClick();
                  }}
                  color="var(--chakra-colors-brand-500)"
                  cursor="pointer"
                >
                  {TextConstants.MODALS.SIGN_IN_MODAL.FORGOT_PASSWORD}
                </Text>
              </Box>
              <Button type="submit" disabled={isRequesting}>
                {TextConstants.MODALS.SIGN_IN_MODAL.BUTTON_TEXT}
              </Button>
            </Stack>

            {requestError.isErrorShown && (
              <Alert status="error">
                <AlertIcon />
                {requestError.errorText}
              </Alert>
            )}

            {signInModalState.isSkipLoginButtonDisplayed ? (
              <Button variant="link" onClick={handleSkipClick}>
                {TextConstants.MODALS.SIGN_IN_MODAL.CONTINUE_WITHOUT_LOGIN}
              </Button>
            ) : null}
          </Stack>
        </ModalContent>
      </Modal>
      <ForgotPasswordModal
        isModalOpen={isForgotPasswordModalOpen}
        closeForgotPasswordModal={closeForgotPasswordModal}
      />
    </Container>
  );
};

export default SignInModal;
