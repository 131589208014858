const handleResponseErrorMessage = (
  errorArr: {
    code: string;
    message: string;
    propertyPath: string;
  }[]
): string => {
  let errorMessage = '';
  for (let i = 0; i < errorArr.length; i++) {
    errorMessage += errorArr[i].message;
  }
  return errorMessage;
};
export default handleResponseErrorMessage;
