import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { RootState } from "../../store/store";
import { setInfoModal } from "../../store/reducers/infoModalReducer";

function InfoModal(): JSX.Element {
  const dispatch = useDispatch();
  const infoModalState = useSelector((state: RootState) => state.infoModal);

  const handleCloseClick = () => {
    dispatch(setInfoModal({ isOpen: false }));
  };

  return (
    <Modal
      isOpen={infoModalState.isOpen}
      onClose={handleCloseClick}
      isCentered
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalOverlay
        backdropFilter="auto"
        backdropInvert="10%"
        backdropBlur="0px"
      />
      <ModalContent borderRadius="0">
        <ModalHeader mt="15px" textAlign="center">
          {infoModalState.title}
        </ModalHeader>
        <ModalCloseButton onClick={handleCloseClick} />
        <Stack
          spacing={7}
          mt="10px"
          mb="40px"
          mx="45px"
          as="form"
          justifyContent="center"
        >
          <Text sx={{ whiteSpace: 'pre-wrap' }}>
            {infoModalState.message}
          </Text>

          <Button onClick={handleCloseClick}>
            Close
          </Button>
        </Stack>
      </ModalContent>
    </Modal>
  );
}

export default InfoModal;
