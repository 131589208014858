const videoCloudURL = process.env.REACT_APP_CDN_URL;

const SceneConfigs = {
  MainSceneFar: {
    videoSource: `${videoCloudURL}/city/RB-Hall-Reception-injected-1080p.mp4`,
    autoLoad: true,
    showControls: false,
    pitch: 0,
    yaw: 0,
    hotSpots: [
      {
        name: 'customlHotSpot',
        pitch: -150,
        yaw: -1,
        nextStep: 'LobbySceneCenter',
        type: 'custom',
        cssClass:
          'navigation-hotspot-custom-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
      {
        name: 'customlHotSpot',
        pitch: -10,
        yaw: 23,
        nextStep: 'ShopSceneFar',
        type: 'custom',
        cssClass:
          'navigation-hotspot-far-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
      {
        name: 'tutorialHotSpot',
        pitch: 3.5,
        yaw: -0.5,
        type: 'custom',
        cssClass:
          'tutorialHotspotCustomStyle pnlm-hotspot pnlm-sprite pnlm-scene',
      },
      {
        name: 'customlHotSpot',
        pitch: -10,
        yaw: -24,
        nextStep: 'SpaSceneFar',
        type: 'custom',
        cssClass:
          'navigation-hotspot-far-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
    ],
  },

  SpaSceneFar: {
    videoSource: `${videoCloudURL}/city/RB-Mental-SPA-1080p.mp4`,
    autoLoad: true,
    showControls: false,
    pitch: 0,
    yaw: 90,
    hotSpots: [
      {
        name: 'openPersonHotspot',
        dialogType: 'wellness',
        // where user should be redirected after Lobby Exit
        sceneToOpen: 'spa',
        pitch: -5,
        yaw: 67,
        type: 'custom',
        cssClass: 'DPHotspotCustomStyle pnlm-hotspot pnlm-sprite pnlm-scene',
      },
      {
        name: 'customlHotSpot',
        pitch: -40,
        yaw: -177,
        nextStep: 'MainSceneFar',
        type: 'custom',
        cssClass:
          'navigation-hotspot-custom-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
    ],
  },

  ShopSceneFar: {
    videoSource: `${videoCloudURL}/city/RB-STORE-1080p.mp4`,
    autoLoad: true,
    showControls: false,
    pitch: 0,
    yaw: -90,
    hotSpots: [
      {
        name: 'customlHotSpot',
        pitch: -32,
        yaw: 180,
        nextStep: 'MainSceneFar',
        type: 'custom',
        cssClass:
          'navigation-hotspot-custom-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
      {
        name: 'openPersonHotspot',
        dialogType: 'e-commerce',
        // where user should be redirected after Lobby Exit
        sceneToOpen: 'reception',
        pitch: 1,
        yaw: -85,
        type: 'custom',
        cssClass: 'DPHotspotCustomStyle pnlm-hotspot pnlm-sprite pnlm-scene',
      },
    ],
  },

  LobbySceneCenter: {
    videoSource: `${videoCloudURL}/city/RB-Pool-Up-2-injected-1080p.mp4`,
    autoLoad: true,
    showControls: false,
    pitch: 0,
    yaw: 0,
    hotSpots: [
      {
        name: 'customlHotSpot',
        pitch: -17,
        yaw: 175,
        nextStep: 'LobbySceneEnd',
        type: 'custom',
        cssClass:
          'navigation-hotspot-far-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
      {
        name: 'customlHotSpot',
        pitch: -24,
        yaw: -1,
        nextStep: 'MainSceneFar',
        type: 'custom',
        cssClass:
          'navigation-hotspot-custom-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
    ],
  },

  LobbySceneEnd: {
    videoSource: `${videoCloudURL}/city/RB-Front-Pool-injected-1080p.mp4`,
    autoLoad: true,
    showControls: false,
    pitch: 0,
    yaw: 360,
    hotSpots: [
      {
        name: 'customlHotSpot',
        pitch: -10,
        yaw: -25,
        nextStep: 'LobbySceneCenter',
        type: 'custom',
        cssClass:
          'navigation-hotspot-far-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
      {
        name: 'customlHotSpot',
        pitch: -10,
        yaw: -180,
        nextStep: 'LobbySceneExit',
        type: 'custom',
        cssClass:
          'navigation-hotspot-far-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
      {
        name: 'customlHotSpot',
        pitch: 0,
        yaw: -0.5,
        nextStep: 'MainSceneFar',
        type: 'custom',
        cssClass:
          'navigation-hotspot-far-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
    ],
  },

  LobbySceneExit: {
    videoSource: `${videoCloudURL}/city/RB-Front-Plaque-injected-1080p.mp4`,
    autoLoad: true,
    showControls: false,
    pitch: 0,
    yaw: 0,
    hotSpots: [
      {
        name: 'customlHotSpot',
        pitch: -26,
        yaw: -1,
        nextStep: 'LobbySceneEnd',
        type: 'custom',
        cssClass:
          'navigation-hotspot-custom-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
    ],
  },

  DesertMain: {
    videoSource: `${videoCloudURL}/desert/Desert-Reception-Fountain-injected-1080p.mp4`,
    autoLoad: true,
    showControls: false,
    pitch: 0,
    yaw: 0,
    hotSpots: [
      {
        name: 'customlHotSpot',
        pitch: -13,
        yaw: -23,
        nextStep: 'DesertSceneSpa',
        type: 'custom',
        cssClass:
          'navigation-hotspot-far-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
      {
        name: 'customlHotSpot',
        pitch: -13,
        yaw: 23,
        nextStep: 'DesertSceneSpa',
        type: 'custom',
        cssClass:
          'navigation-hotspot-far-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
      {
        name: 'customlHotSpot',
        pitch: -15,
        yaw: -100,
        nextStep: 'DesertSceneShop',
        type: 'custom',
        cssClass:
          'navigation-hotspot-far-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
      {
        name: 'tutorialHotSpot',
        pitch: 4,
        yaw: -180,
        type: 'custom',
        cssClass:
          'tutorialHotspotCustomStyle pnlm-hotspot pnlm-sprite pnlm-scene',
      },
    ],
  },

  DesertSceneShop: {
    name: 'desert-room',
    videoSource: `${videoCloudURL}/desert/RB-DESERT-STORE-injected-1080p.mp4`,
    autoLoad: true,
    showControls: false,
    yaw: -90,
    pitch: 0,
    hotSpots: [
      {
        name: 'openPersonHotspot',
        dialogType: 'e-commerce',
        // where user should be redirected after Lobby Exit
        sceneToOpen: 'reception',
        pitch: -4,
        yaw: -64,
        type: 'custom',
        cssClass: 'DPHotspotCustomStyle pnlm-hotspot pnlm-sprite pnlm-scene',
      },
      {
        name: 'customlHotSpot',
        pitch: -25,
        yaw: 180,
        nextStep: 'DesertMain',
        type: 'custom',
        cssClass:
          'navigation-hotspot-custom-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
    ],
  },

  DesertSceneSpa: {
    name: 'desert-room',
    videoSource: `${videoCloudURL}/desert/RB-desert-spa.mp4`,
    autoLoad: true,
    showControls: false,
    pitch: 0,
    yaw: 0,
    hotSpots: [
      {
        name: 'customlHotSpot',
        pitch: -15,
        yaw: 110,
        nextStep: 'DesertMain',
        type: 'custom',
        cssClass:
          'navigation-hotspot-custom-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
      {
        name: 'customlHotSpot',
        pitch: -15,
        yaw: -110,
        nextStep: 'DesertMain',
        type: 'custom',
        cssClass:
          'navigation-hotspot-custom-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
      {
        name: 'openPersonHotspot',
        dialogType: 'wellness',
        // where user should be redirected after Lobby Exit
        sceneToOpen: 'spa',
        pitch: -12,
        yaw: 38,
        type: 'custom',
        cssClass: 'DPHotspotCustomStyle pnlm-hotspot pnlm-sprite pnlm-scene',
      },
    ],
  },

  BuddhistTempleMeditationAreaScene: {
    videoSource: `${process.env.REACT_APP_VIDEO_CLOUD_URL}/360-Buddhist-Temple-Meditation-Area--HD.mp4`,
    autoLoad: true,
    showControls: false,
    pitch: 90,
    yaw: 0,
    hotSpots: [
      {
        name: 'customlHotSpot',
        pitch: -15,
        yaw: 180,
        nextStep: 'BuddhistTempleEntranceScene',
        type: 'custom',
        cssClass:
          'navigation-hotspot-custom-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
      {
        name: 'customlHotSpot',
        pitch: -5.5,
        yaw: 60,
        nextStep: 'BuddhistTempleNeighborhoodScene',
        type: 'custom',
        cssClass:
          'navigation-hotspot-custom-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
    ],
  },

  BuddhistTempleEntranceScene: {
    videoSource: `${process.env.REACT_APP_VIDEO_CLOUD_URL}/360-Buddhist-Temple-Entrance-HD.mp4`,

    autoLoad: true,
    showControls: false,
    pitch: 0,
    yaw: 0,
    hotSpots: [
      {
        name: 'customlHotSpot',
        pitch: -25,
        yaw: -1,
        nextStep: 'BuddhistTempleMeditationAreaScene',
        type: 'custom',
        cssClass:
          'navigation-hotspot-custom-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
    ],
  },

  BuddhistTempleNeighborhoodScene: {
    videoSource: `${process.env.REACT_APP_VIDEO_CLOUD_URL}/360-Buddhist-Temple-Neighborhood-HD.mp4`,
    autoLoad: true,
    showControls: false,
    pitch: 0,
    yaw: -45,
    hotSpots: [
      {
        name: 'customlHotSpot',
        pitch: -10,
        yaw: 180,
        nextStep: 'BuddhistTempleMeditationAreaScene',
        type: 'custom',
        cssClass:
          'navigation-hotspot-custom-style pnlm-hotspot pnlm-sprite pnlm-scene',
      },
    ],
  },
};

export default SceneConfigs;
