import { useState } from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendTextMessage,
  stopSpeaking,
} from '../../../store/reducers/digitalAssistantReducer';
import { AppDispatch, RootState } from '../../../store/store';

const DirectionDescriptionCard = ({
  data,
  isCardInTranscript,
}: {
  data: {
    title: string;
    items: { heading: string; description: string }[];
    proceed_button_title: string;
  };
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );
  const handleClick = () => {
    dispatch(stopSpeaking());
    dispatch(sendTextMessage({ text: 'proceed' }));
    setIsButtonDisabled(true);
  };

  return (
    <Box
      d="block"
      flexDirection="column"
      alignItems={isTranscriptOpen ? 'start' : 'center'}
      data-sm-content="DPOverviewVideoModal"
      overflowY={isTranscriptOpen ? 'unset' : 'scroll'}
      maxH={isTranscriptOpen ? 'auto' : '55vh'}
      maxW={!isTranscriptOpen && { base: '80%', sm: '35vw' }}
      p={isTranscriptOpen ? '10px 0 10px 35px' : '0'}
      paddingBottom={isTranscriptOpen ? 0 : '5vh'}
      marginRight={!isTranscriptOpen && { base: '0', sm: '40px' }}
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      <Heading
        as="h3"
        fontSize="2xl"
        backgroundColor="var(--chakra-colors-brand-50) !important"
        className="transcript-entry-content"
      >
        {data.title}
      </Heading>
      {data.items.map((item) => (
        <Box
          key={JSON.stringify(item)}
          className="transcript-entry-content"
          my="20px"
        >
          <Heading as="h4" fontSize="l" fontWeight="bold">
            {item.heading}
          </Heading>
          <Text textAlign="justify" wordBreak="break-word">
            {item.description}
          </Text>
        </Box>
      ))}
      <Button
        zIndex="1"
        mt="20px"
        onClick={() => {
          handleClick();
        }}
        disabled={isButtonDisabled || isCardInTranscript}
      >
        {data.proceed_button_title}
      </Button>
    </Box>
  );
};

export default DirectionDescriptionCard;
