import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import backIcon from '../../../assets/icons/drawer-back-icon.png';
import { RootState } from '../../../store/store';
import { setIsHeaderModalsShown } from '../../../store/reducers/commerceReducer';
import JournalWrapper from './JournalWrapper';
import TextConstants from '../../../constants/TextConstants';
import { sendTextMessage } from '../../../store/reducers/digitalAssistantReducer';
import { setJournalOpenedWhileDPEnabled } from '../../../store/reducers/journalReducer';

function JournalDrawer(): JSX.Element {
  const dispatch = useDispatch();
  const isJournalDrawerOpen = useSelector(
    (state: RootState) => state.commerce.isHeaderModalsShown.journalDrawer
  );
  const isJournalOpenedWhileDPEnabled = useSelector(
    (state: RootState) => state.journalState.isJournalOpenedWhileDPEnabled
  );
  const closeHandlerFunction = () => {
    if (isJournalOpenedWhileDPEnabled) {
      dispatch(sendTextMessage({ text: 'proceed' }));
    }
    dispatch(setIsHeaderModalsShown({ journalDrawer: false }));
    dispatch(setJournalOpenedWhileDPEnabled(false));
  };

  const HeaderComponent = () => (
    <Box
      cursor="pointer"
      onClick={() => {
        closeHandlerFunction();
      }}
    >
      <HStack flexDirection="row">
        <Image src={backIcon} boxSize="12px" objectFit="contain" />
        <Text
          fontSize="14px"
          fontFamily="Inter"
          letterSpacing="0.1em"
          color="var(--chakra-colors-brand-700)"
          fontWeight="medium"
          textTransform="uppercase"
        >
          {TextConstants.DRAWER.PROFILE_GOBACK_TITLE}
        </Text>
      </HStack>
    </Box>
  );
  return (
    <Drawer
      isOpen={isJournalDrawerOpen}
      placement="right"
      onClose={closeHandlerFunction}
      size="sm"
    >
      <DrawerOverlay />
      <DrawerContent backgroundColor="#fdf6f0">
        <DrawerHeader pb="0px">
          <HeaderComponent />
        </DrawerHeader>
        <DrawerBody px="0" pb="0" marginTop="8px">
          <JournalWrapper />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default JournalDrawer;
