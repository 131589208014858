const getDateFormatForJournal = (date: Date) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const day = days[date.getDay()];
  const month = months[date.getMonth()];
  const dateNum = date.getDate();
  let suffix = 'th';
  if (dateNum === 1 || dateNum === 21 || dateNum === 31) {
    suffix = 'st';
  } else if (dateNum === 2 || dateNum === 22) {
    suffix = 'nd';
  } else if (dateNum === 3 || dateNum === 23) {
    suffix = 'rd';
  }
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedDate = `${day}, ${month} ${dateNum}${suffix}, ${year} ${
    hours === 0 ? 12 : hours > 12 ? hours - 12 : hours
  }:${minutes.toString().padStart(2, '0')} ${ampm}`;

  return formattedDate;
};

export default getDateFormatForJournal;
