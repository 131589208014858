import { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  startLogOutTimer,
  toggleSessionTimeoutModal,
} from '../../store/reducers/sessionTimeoutReducer';
import { logoutUser } from '../../store/reducers/authenticationReducer';
import { useIsUserLoggedIn } from '../../helpers/userInfo/UserInfo';
import IconInCircles from '../common/IconInCircles/IconInCircles';
import TextConstants from '../../constants/TextConstants';

const SessionTimeoutModal = () => {
  let logoutInterval;
  const initialCountdownValue = 20; //  seconds
  const isUserLoggedIn = useIsUserLoggedIn();
  const dispatch = useDispatch();

  const [logoutTimer, setLogoutTimer] = useState(initialCountdownValue);
  const isOpen = useSelector(
    (state: RootState) => state.sessionTimeoutState.isOpen
  );

  const handleLogout = () => {
    clearInterval(logoutInterval);
    dispatch(toggleSessionTimeoutModal(false));

    dispatch(logoutUser());
  };

  const handleStayInButtonClick = () => {
    if (isUserLoggedIn) {
      clearInterval(logoutInterval);
      dispatch(toggleSessionTimeoutModal(false));
      dispatch(startLogOutTimer());
    }
  };

  useEffect(() => {
    if (isOpen) {
      setLogoutTimer(initialCountdownValue);
      logoutInterval = setInterval(() => {
        setLogoutTimer((timer) => timer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(logoutInterval);
    };
  }, [isOpen]);

  useEffect(() => {
    if (logoutTimer === 0) {
      handleLogout();
    }
  }, [logoutTimer]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        // do nothing
      }}
      isCentered
    >
      <ModalOverlay
        backdropFilter="auto"
        backdropInvert="10%"
        backdropBlur="0px"
      />
      <ModalContent borderRadius="0" justifyContent="center">
        <Stack px="25px" py="25px" justifyContent="center" alignItems="center">
          <IconInCircles icon="warning" theme="warning" />

          <Text textStyle="title" textAlign="center">
            {TextConstants.MODALS.SESSION_TIMEOT_MODAL.TITLE}
          </Text>

          <Text textStyle="sub-title" textAlign="center">
            {TextConstants.MODALS.SESSION_TIMEOT_MODAL.DESCRIPTION(logoutTimer)}
          </Text>

          <Box pt="10px">
            <Button
              paddingInlineStart="10px"
              paddingInlineEnd="10px"
              letterSpacing="0.3px"
              fontSize="15px"
              color="#FFF"
              as={Button}
              _hover={{
                bg: 'brand.600',
              }}
              borderRadius="0"
              textTransform="uppercase"
              onClick={handleStayInButtonClick}
            >
              {TextConstants.MODALS.SESSION_TIMEOT_MODAL.BUTTON_LABEL}
            </Button>
          </Box>
        </Stack>
      </ModalContent>
    </Modal>
  );
};

export default SessionTimeoutModal;
