// estimate how long each caption should be displayed
const calculateSpeechDuration = (currentSpeech) => {
  if (currentSpeech) {
    // Calculate the number of words in the speech
    const words = currentSpeech.split(' ');
    const wordCharacters = words.length;

    const speakingRate = 160; // average speaking rate of 160 wpm
    const speakingTime = wordCharacters / speakingRate; // speaking time in minutes
    const speakingTimeMs = speakingTime * 60 * 1000; // speaking time in milliseconds

    return speakingTimeMs;
  } else {
    return 0;
  }
};

export default calculateSpeechDuration;
