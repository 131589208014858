import { Box, Text } from '@chakra-ui/react';
import getDateFormatForJournal from './getDateFormatForJournal';
import TextConstants from '../../../constants/TextConstants';
import {
  FormattedRecordDataType,
  RecordDataType,
} from '../../../global-components/GlobalTypes';
import findScoreColor from '../../../helpers/journal/findScoreColor';
import scoresDataArr from '../../../helpers/journal/scoresDataArr';

const getKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value);

const EachJournalEntry = ({
  data,
  handleEntryDetails,
}: {
  data: FormattedRecordDataType[];
  handleEntryDetails: (entry: RecordDataType) => void;
}): JSX.Element => (
  <>
    {data.map((day) =>
      day.records.map((entry) => {
        const date = new Date(entry.createdAt);
        const max = Math.max(
          ...scoresDataArr(entry.emotionalScore, entry.classificationScore)
        );
        const emotionalScore = entry.emotionalScore ? entry.emotionalScore : {};
        const classificationScore = entry.classificationScore
          ? entry.classificationScore
          : {};
        const scoresData = {
          ...emotionalScore,
          ...classificationScore,
        };
        const score = getKeyByValue(scoresData, max);
        const scoreColor = findScoreColor(score, 1);
        const scoreBGColor = findScoreColor(score, 0.1);
        return (
          <Box key={entry.createdAt} w="100%" pb="20px">
            {score ? (
              <Box
                display="inline-block"
                pr="10px"
                pl="10px"
                pb="3px"
                pt="3px"
                borderRadius="20px"
                color={scoreColor}
                background={scoreBGColor}
                lineHeight="20px"
                fontSize="13px"
              >
                <Text
                  bg={scoreColor}
                  display="inline-block"
                  borderRadius="50%"
                  height="7px"
                  width="7px"
                  mr="5px"
                >
                  {}
                </Text>
                {score}
              </Box>
            ) : null}
            <Text
              display="inline-block"
              pr="10px"
              pl="10px"
              pb="3px"
              pt="3px"
              borderRadius="20px"
              color="var(--chakra-colors-gray-500)"
              lineHeight="20px"
              fontSize="13px"
            >
              {getDateFormatForJournal(date)}
            </Text>
            <Box
              onClick={() => handleEntryDetails(entry)}
              borderBottomRadius="5px"
              borderTopLeftRadius="5px"
              lineHeight="20px"
              fontSize="13px"
              color="#FFFFFF"
              mt="4px"
              p="10px"
              w="100%"
              background="var(--chakra-colors-brand-600)"
              cursor="pointer"
            >
              {entry.text &&
              entry.text.length >
                TextConstants.JOURNAL.ADDED_JOURNAL_ENTRY_TEXT_LENGTH
                ? `${entry.text.slice(
                    0,
                    entry.text
                      .slice(
                        0,
                        TextConstants.JOURNAL.ADDED_JOURNAL_ENTRY_TEXT_LENGTH
                      )
                      .lastIndexOf(' ')
                  )} ...`
                : entry.text}
            </Box>
          </Box>
        );
      })
    )}
  </>
);

export default EachJournalEntry;
