import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import TextConstants from '../../../constants/TextConstants';

interface Props {
  value: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  isInvalid?: boolean;
  disabled?: boolean;
  errorMessage?: string;
}

function CountryDropdown({
  value,
  onChange,
  isInvalid,
  disabled,
  errorMessage,
}: Props) {
  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel>
        {TextConstants.ORDER_CONTACT_INFO.COUNTRY_INPUT_LABEL}
      </FormLabel>
      <Select
        colorScheme="brand"
        name="country"
        borderRadius="none"
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        <option value="" disabled>
          {TextConstants.ORDER_CONTACT_INFO.PLACEHOLDER}
        </option>
        <option value="US">{TextConstants.ORDER_CONTACT_INFO.US}</option>
        <option value="CA">{TextConstants.ORDER_CONTACT_INFO.CA}</option>
        <option value="MX">{TextConstants.ORDER_CONTACT_INFO.MX}</option>
      </Select>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

export default CountryDropdown;
