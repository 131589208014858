import { useRef, useState } from 'react';
import { Box, CloseButton, useMediaQuery } from '@chakra-ui/react';
import VideoHOCWithLogoutTimer from '../../components/HOC/VideoHOCWithLogoutTimer';
import TextConstants from '../../constants/TextConstants';
import calculateTutorialVideoComponentSize from '../../helpers/calculateTutorialVideoComponentSize';
import { SVG } from '../../components/icons/icons';
import { checkIfIOSDevice } from '../../helpers/checkDevicePlatform';

const TutorialVideoComponent = ({
  closeTutorialVideoComponent,
}: {
  closeTutorialVideoComponent: () => void;
}): JSX.Element => {
  const [isPlaying, setPlaying] = useState(true);
  const videoRef = useRef(null);

  const [isIPadProSize] = useMediaQuery(
    `(min-width: ${TextConstants.SIZES.IPAD_PRO_WIDTH}px)`
  );

  const isIOSDevice = checkIfIOSDevice();

  const { videoWidth } = calculateTutorialVideoComponentSize(isIPadProSize);

  const onEnded = () => {
    closeTutorialVideoComponent();
    setPlaying(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setPlaying(false);
    } else {
      videoRef.current.play();
      setPlaying(true);
    }
  };

  return (
    <Box
      width={videoWidth}
      position="absolute"
      zIndex="1"
      top="20px"
      left={{ base: '20px', md: '30px', lg: '50px' }}
      display={isIOSDevice ? 'flex' : 'unset'}
      flexDirection={isIOSDevice ? 'column' : 'unset'}
    >
      {isIOSDevice && (
        <CloseButton size="sm" zIndex="1" onClick={onEnded} alignSelf="end" />
      )}

      <Box position="relative">
        {!isIOSDevice && (
          <CloseButton
            position="absolute"
            size="sm"
            zIndex="1"
            right="0"
            onClick={onEnded}
          />
        )}

        <VideoHOCWithLogoutTimer
          className="tutorial-video"
          src={`${process.env.REACT_APP_VIDEO_CLOUD_URL}/tutorial-video.mov`}
          onEnded={onEnded}
          controlsList="nodownload noplaybackrate nofullscreen"
          autoPlay
          controls={false}
          disablePictureInPicture
          ref={videoRef}
        />
        {!isIOSDevice && (
          <Box
            position="absolute"
            bottom="10px"
            left="5px"
            onClick={handlePlayPause}
          >
            <SVG id={isPlaying ? 'pause' : 'play'} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TutorialVideoComponent;
