import { Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import StyleWrapper from './StyleWrapper';
import TextConstants from '../../../constants/TextConstants';
import { openMakeupAccordionSliderByWatsonFeature } from '../../../store/reducers/commerceReducer';
import { AppDispatch } from '../../../store/store';

const BronzerAndHighlighterOptions = ({
  isCardInTranscript,
}: {
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const handleClick = (
    category: 'Cheeks' | 'Lips' | 'Eyes' | 'Miscellaneous',
    subcategory: string
  ) => {
    dispatch(
      openMakeupAccordionSliderByWatsonFeature({
        category,
        subcategory,
        selectedProduct: null,
      })
    );
  };

  return (
    <StyleWrapper>
      <>
        <Button
          fontSize={{ base: 'small', sm: '1rem' }}
          variant="secondary"
          p="20px"
          w="100%"
          m="0 auto"
          borderRadius="0.5rem"
          _focus={{ boxShadow: 'none' }}
          onClick={() => {
            handleClick('Cheeks', 'Bronzer');
          }}
          disabled={isCardInTranscript}
        >
          {TextConstants.COMMERCE.BRONZER_PRODUCT}
        </Button>
        <Button
          fontSize={{ base: 'small', sm: '1rem' }}
          variant="secondary"
          p="20px"
          w="100%"
          m="0 auto"
          borderRadius="0.5rem"
          _focus={{ boxShadow: 'none' }}
          onClick={() => {
            handleClick('Cheeks', 'Highlighter');
          }}
          disabled={isCardInTranscript}
        >
          {TextConstants.COMMERCE.HIGHLIGHTER_PRODUCT}
        </Button>
      </>
    </StyleWrapper>
  );
};

export default BronzerAndHighlighterOptions;
