import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Text } from '@chakra-ui/react';
import LineChart from './LineChart';
import 'react-datepicker/dist/react-datepicker.css';
import capitalizeFirstLetter from '../../../helpers/capitalizeFirstLetter';
import TextConstants from '../../../constants/TextConstants';
import changeJournalDataFormat from '../../../helpers/journal/changeJournalDataFormat';
import { RootState } from '../../../store/store';
import { RecordDataType } from '../../../global-components/GlobalTypes';
import { calculateEntriesDataSummary } from '../../../helpers/journal/calculateEntriesDataSummary';

let allEntriesData = null;
const analysisChartOptions = {
  spanGaps: 1000 * 60 * 60 * 24 * 2,
  elements: {
    line: {
      tension: 0.3,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      align: 'start' as const,
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 7,
      },
    },
    tooltip: {
      callbacks: {
        // title: () => '',
        label: (props) => {
          const dayEntriesData = allEntriesData
            ? allEntriesData.filter((day) => day.date === props.raw.x)
            : null;
          if (dayEntriesData && dayEntriesData.length) {
            const summary = calculateEntriesDataSummary(
              dayEntriesData[0].average
            );
            const percent = (props.parsed.y / summary) * 100;
            return ` ${percent.toFixed()}% ${capitalizeFirstLetter(
              props.dataset.label
            )}`;
          }
          return props.label;
        },
      },
      titleColor: 'rgba(0, 0, 0, 0.7)',
      usePointStyle: true,
      backgroundColor: '#FFFFFF',
      bodyColor: 'rgba(0, 0, 0, 0.8)',
      cornerRadius: 0,
      padding: 10,
    },
  },

  responsive: true,
  scales: {
    y: { display: false },
    x: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: () => '',
      },
    },
  },
};

const Analysis = ({
  isUpdate,
  renderAnalysisDatePicker,
  shouldGetJournalAnalysisData,
  getJournalAnalysisData,
}: {
  isUpdate: boolean;
  renderAnalysisDatePicker: () => JSX.Element;
  shouldGetJournalAnalysisData: boolean;
  getJournalAnalysisData: () => Promise<{
    count: number;
    records: RecordDataType[];
  }>;
}): JSX.Element => {
  const entryData =
    useSelector((state: RootState) => state.journalState.entryData) || [];
  const [fetchEntryData, setFetchEntryData] = useState([]);

  useEffect(() => {
    allEntriesData = entryData;
    if (shouldGetJournalAnalysisData) {
      getJournalAnalysisData()
        .then((data) => {
          const formattedData = changeJournalDataFormat(data.records);
          allEntriesData = formattedData;
          setFetchEntryData(formattedData);
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  }, [isUpdate, entryData, shouldGetJournalAnalysisData]);

  return (
    <Box position="relative" height="100%">
      <Text
        mt="10px"
        mb="10px"
        lineHeight="20px"
        fontSize="13px"
        color="var(--chakra-colors-brand-600)"
      >
        {TextConstants.JOURNAL.ANALYSIS_TITLE}
      </Text>
      {renderAnalysisDatePicker && renderAnalysisDatePicker()}
      <Box position="absolute" mt="30px" width="100%" height="50%">
        {fetchEntryData &&
        (entryData.length > 0 || fetchEntryData.length > 0) ? (
          <LineChart
            options={analysisChartOptions}
            dataEntry={
              shouldGetJournalAnalysisData ? fetchEntryData : entryData
            }
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default Analysis;
