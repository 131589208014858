import { Box, Button, useMediaQuery } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import TextConstants from '../../../constants/TextConstants';
import { NOTE_MAX_AMOUNT } from '../../../constants/Notes';
import { RootState } from '../../../store/store';
import { addNote } from '../../../store/reducers/noteManagerReducer';
import { getNewNote } from '../../../services/Notes';
import { setIsHeaderModalsShown } from '../../../store/reducers/commerceReducer';
import { setJournalOpenedWhileDPEnabled } from '../../../store/reducers/journalReducer';
import { useState } from 'react';
import { sendTextMessage } from '../../../store/reducers/digitalAssistantReducer';

const HashtagAndJournalOptions = ({
  data,
  isCardInTranscript,
}: {
  data: {
    params: boolean;
  };
  isCardInTranscript: boolean;
}): JSX.Element => {
  const [isProceedButtonShown, showProceedButton] = useState(false);
  const dispatch = useDispatch();
  const [isLargerThanIPadPro] = useMediaQuery(
    `(min-width: ${TextConstants.SIZES.IPAD_PRO_WIDTH}px)`
  );

  const { notes, mirrorDimensions } = useSelector(
    (state: RootState) => state.noteManager
  );
  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );

  const createNote = () => {
    if (notes.length === NOTE_MAX_AMOUNT) {
      return;
    }
    const newNote = getNewNote(mirrorDimensions.width, mirrorDimensions.height);
    dispatch(addNote(newNote));
  };

  const renderOptions = () => (
    <>
      <Button
        fontSize={{ base: 'small', sm: '1rem' }}
        variant="secondary"
        p="20px"
        w="100%"
        m="0 auto"
        borderRadius="0.5rem"
        _focus={{ boxShadow: 'none' }}
        onClick={() => {
          createNote();
          showProceedButton(true);
        }}
        disabled={isCardInTranscript}
      >
        {TextConstants.COMMERCE.HASHTAG_REMINDER}
      </Button>
      {data?.params && (
        <Button
          fontSize={{ base: 'small', sm: '1rem' }}
          variant="secondary"
          p="20px"
          w="100%"
          m="0 auto"
          borderRadius="0.5rem"
          _focus={{ boxShadow: 'none' }}
          onClick={() => {
            dispatch(setJournalOpenedWhileDPEnabled(true));
            dispatch(setIsHeaderModalsShown({ journalDrawer: true }));
          }}
          disabled={isCardInTranscript}
        >
          {TextConstants.COMMERCE.JOURNAL}
        </Button>
      )}

      {isProceedButtonShown && (
        <Button
          fontSize={{ base: 'small', sm: '1rem' }}
          variant="secondary"
          p="20px"
          w="100%"
          m="0 auto"
          borderRadius="0.5rem"
          _focus={{ boxShadow: 'none' }}
          onClick={() => {
            dispatch(sendTextMessage({ text: 'proceed' }));
          }}
          disabled={isCardInTranscript}
        >
          {TextConstants.BUTTON_TEXT.PROCEED}
        </Button>
      )}
    </>
  );

  return (
    <Box
      d="flex"
      px="30px"
      gap="10px"
      flexDirection="column"
      justifyContent="center"
      marginBottom={isTranscriptOpen || isLargerThanIPadPro ? 'unset' : '5vh'}
      minW="100%"
      maxH="60vh"
      minH="15vh"
      overflowY={isTranscriptOpen ? 'unset' : 'scroll'}
      data-sm-content="HashtagAndJournalOptions"
    >
      {renderOptions()}
    </Box>
  );
};

export default HashtagAndJournalOptions;
