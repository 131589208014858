import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@chakra-ui/react';
import AudioPicker from './ContentCards/AudioPicker';
import ImageSlider from './ContentCards/ImageSlider';
import TherapyFeedback from './ContentCards/Wellness/TherapyFeedback';
import TherapyRecommendationQuestion from './ContentCards/Wellness/TherapyRecommendationQuestion';
import TherapyRecommendationResult from './ContentCards/Wellness/TherapyRecommendationResult';
import TherapyApproach from './ContentCards/Wellness/TherapyApproach';
import WellnessOnscreenOtherOptions from './ContentCards/Wellness/WellnessOnscreenOtherOptions';
import DPOverviewVideoCard from './ContentCards/Wellness/DPOverviewVideoCard';
import DirectionDescriptionCard from './ContentCards/Wellness/DirectionDescriptionCard';
import { setActiveCards } from '../store/reducers/digitalAssistantReducer';
import { AppDispatch, RootState } from '../store/store';
import { CardType } from '../global-components/GlobalTypes';
import CommerceOnscreenOtherOptions from './ContentCards/Ecommerce/CommerceOnscreenOtherOptions';
import HashtagAndJournalOptions from './ContentCards/Ecommerce/HashtagAndJournalOptions';
import SingleProductRecommendation from './ContentCards/Ecommerce/SingleProductRecommendation';
import MultipleProductRecommendation from './ContentCards/Ecommerce/MultipleProductRecommendation/MultipleProductRecommendation';
import SearchedProductRecommendation from './ContentCards/Ecommerce/SearchedProductRecommendation/SearchedProductRecommendation';
import RandomAndCuratedLookOptions from './ContentCards/Ecommerce/RandomAndCuratedLookOptions';
import BronzerAndHighlighterOptions from './ContentCards/Ecommerce/BronzerAndHighlighterOptions';
import BrowsAndVideoTutorialOptions from './ContentCards/Ecommerce/BrowsAndVideoTutorialOptions';
import GoToWellnessOption from './ContentCards/Ecommerce/GoToWellnessOption';
import MistAndDiffusingPrimerOptions from './ContentCards/Ecommerce/MistAndDiffusingPrimerOptions';
import RareReminderFromSelenaOptions from './ContentCards/Ecommerce/RareReminderFromSelenaOptions';
import SaveLookOption from './ContentCards/Ecommerce/SaveLookOption';
import MoveOnButton from './ContentCards/Ecommerce/MoveOnButton';
import FloatingIntentProduct from './ContentCards/Ecommerce/FloatingIntentProduct/FloatingIntentProduct';
import OpenCustomizeMakeupByFloatingIntent from './ContentCards/Ecommerce/OpenCustomizeMakeupByFloatingIntent/OpenCustomizeMakeupByFloatingIntent';
import OpenCustomizeMakeupByActiveProduct from './ContentCards/Ecommerce/OpenCustomizeMakeupByActiveProduct/OpenCustomizeMakeupOfActiveProduct';

type PropsType = {
  card: CardType;
  index: number;
  isCardInTranscript: boolean;
  renderMuteButton?: typeof Button;
};

const returnCardError = (errMsg) => (
  <div className="alert alert-danger" key={Math.random()}>
    {errMsg}
  </div>
);

const ContentCardSwitch: React.FC<PropsType> = ({
  card,
  index,
  isCardInTranscript,
  renderMuteButton,
}) => {
  const activeCards = useSelector((state: RootState) => state.digitalAssistant.activeCards);
  const dispatch = useDispatch<AppDispatch>();
  const showVirtualMirror = useSelector(
    (state: RootState) => state.vm.showVirtualMirror
  );
  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );
  const componentMap = {
    selfDiscovery: {
      types: {
        image: { element: ImageSlider, removeOnClick: true },
        audio: { element: AudioPicker, removeOnClick: true },
      },
    },
    wellnessOnscreenOtherOptions: {
      element: WellnessOnscreenOtherOptions,
      removeOnClick: false,
    },
    DPOverviewVideoCard: {
      element: DPOverviewVideoCard,
      removeOnClick: false,
    },
    therapyApproach: {
      element: TherapyApproach,
      removeOnClick: false,
    },
    therapyFeedback: {
      element: TherapyFeedback,
      removeOnClick: false,
    },
    directionDescriptionCard: {
      element: DirectionDescriptionCard,
      removeOnClick: false,
    },

    therapyRecommendationQuestion: {
      types: {
        text: { element: TherapyRecommendationQuestion, removeOnClick: true },
        range: { element: TherapyRecommendationQuestion, removeOnClick: true },
      },
    },
    therapyRecommendationResult: {
      element: TherapyRecommendationResult,
      removeOnClick: false,
    },
    commerceOnscreenOtherOptions: {
      element: CommerceOnscreenOtherOptions,
      removeOnClick: false,
    },
    hashtagAndJournalOptions: {
      element: HashtagAndJournalOptions,
      removeOnClick: false,
    },
    randomAndCuratedLookOptions: {
      element: RandomAndCuratedLookOptions,
      removeOnClick: false,
    },
    bronzerAndHighlighterOptions: {
      element: BronzerAndHighlighterOptions,
      removeOnClick: false,
    },
    browsAndVideoTutorialOptions: {
      element: BrowsAndVideoTutorialOptions,
      removeOnClick: false,
    },
    goToWellnessOption: {
      element: GoToWellnessOption,
      removeOnClick: false,
    },
    mistAndDiffusingPrimerOptions: {
      element: MistAndDiffusingPrimerOptions,
      removeOnClick: false,
    },
    rareReminderFromSelenaOptions: {
      element: RareReminderFromSelenaOptions,
      removeOnClick: false,
    },
    saveLookOption: {
      element: SaveLookOption,
      removeOnClick: false,
    },
    singleProductRecommendation: {
      element: SingleProductRecommendation,
      removeOnClick: false,
    },
    multipleProductRecommendation: {
      element: MultipleProductRecommendation,
      removeOnClick: false,
    },
    searchedProductRecommendation: {
      element: SearchedProductRecommendation,
      removeOnClick: false,
    },
    floatingIntentProduct: {
      element: FloatingIntentProduct,
      removeOnClick: false,
    },
    openCustomizeMakeupByFloatingIntent: {
      element: OpenCustomizeMakeupByFloatingIntent,
      removeOnClick: false,
    },
    openCustomizeMakeupByActiveProduct: {
      element: OpenCustomizeMakeupByActiveProduct,
      removeOnClick: false,
    },
    moveOnButton: {
      element: MoveOnButton,
      removeOnClick: false,
    },
  };

  if (card === undefined || null)
    return returnCardError(
      'unknown content card name! did you make a typo in @showCards()?'
    );
  let notEmptyCardObject;

  if (card !== null) {
    notEmptyCardObject = card;
  }
  const { component: componentName, data, id } = notEmptyCardObject;
  const componentType = data && data.type ? data.type : null;

  if (!(componentName in componentMap)) {
    return returnCardError(
      `component ${componentName} not found in componentMap!`
    );
  }
  const { element: Element, removeOnClick } = componentType
    ? // if action has a type condition
      componentMap[componentName].types[componentType]
    : componentMap[componentName];

  let removeElem;
  if (index) {
    // for some cards, we want them to be hidden after the user interacts w/ them
    // for others, we don't
    removeElem = (e) => {
      // we need to write our own handler, since this is not an interactive element by default
      if (e.type === 'click' || e.code === 'enter') {
        const newActiveCards = [
          ...activeCards.slice(0, index),
          ...activeCards.slice(index + 1),
        ];

        dispatch(setActiveCards({ newActiveCards, cardsAreStale: true }));
      }
    };
  } else {
    removeElem = null;
  }

  function handleJustifyContent() {
    if (showVirtualMirror) {
      return 'unset';
    }

    if (isTranscriptOpen) {
      return 'start';
    }
    return { base: 'center', iPadPro: 'end' };
  }

  const elem = (
    // disable no static element interactions bc if removeOnClick is true,
    // elem should have interactive children
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <Box
      onClick={removeOnClick ? removeElem : null}
      onKeyPress={removeOnClick ? removeElem : null}
      data-sm-content={id}
      className="content-card-switch"
      position='relative'
      height="100%"
      d="flex"
      maxW={isTranscriptOpen && '95%'}
      justifyContent={handleJustifyContent()}
      alignItems={
        showVirtualMirror ? 'unset' : { base: 'end', iPadPro: 'unset' }
      }
      overflowX="hidden"
      marginTop={{ base: 'unset', iPadPro: '20px' }}
      marginRight={
        showVirtualMirror ? 'unset' : { base: 'unset', iPadPro: '20px' }
      }
      marginLeft="5px"
    >
      {/* elements that are interactive but shouldn't be removed immediately
         can use triggerRemoval to have the card removed */}
      <Element
        data={{ id, ...data }}
        triggerRemoval={removeElem}
        isCardInTranscript={isCardInTranscript}
        componentName={componentName}
      />
      {renderMuteButton && renderMuteButton({})}
    </Box>
  );
  return elem;
};

export default ContentCardSwitch;
