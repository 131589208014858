import {
  BaseEditor, CustomTypes, Editor,
} from 'slate';

import {
  FontSizesEnum, MovementDirectionEnum,
  NOTE_DEFAULT_POSITION,
  NOTE_MOVEMENT_STEP,
  NoteHeightToMirrorSize,
  NoteWidthToMirrorSize, TextFormatEnum,
} from '../constants/Notes';
import {
  Marks, MovableFrame, NoteResponse,
  NoteTransformedResponse,
} from '../interfaces/Notes';
import { Rectangle, Point } from '../entities/Rectangle';
import mainStore from '../store/store';
import {NoteManagerState, setNotes} from "../store/reducers/noteManagerReducer";

export const getRectangleFromPosition = (
  position: Point,
): Rectangle => {
  const width = NoteWidthToMirrorSize.HIGH;
  const height = NoteHeightToMirrorSize.HIGH;

  return new Rectangle(position, width, height);
};

export const isMarkButtonActive = (editor: BaseEditor, format: TextFormatEnum): boolean => {
  const marks = Editor.marks(editor) as Marks;

  return marks ? (marks)[format] : false;
};

export const areConflictStates = (editor: BaseEditor, format: TextFormatEnum): boolean => {
  const marks = Editor.marks(editor) as Marks;

  if (!marks) {
    return false;
  }

  return (marks[TextFormatEnum.INCREASED] && format === TextFormatEnum.DECREASED)
    || (marks[TextFormatEnum.DECREASED] && format === TextFormatEnum.INCREASED);
};

export const getFontSizeDependOnLeaf = (leaf: CustomTypes) => {
  if (!leaf.increased && !leaf.decreased) {
    return FontSizesEnum.DEFAULT;
  }

  return leaf.decreased ? FontSizesEnum.SMALL : FontSizesEnum.HIGH;
};

export const isStringExceedsLimit = (limit: number,
  currentString: string,
  targetString: string): boolean => {
  const targetStringLength = targetString.length;
  const currentStringLength = currentString.length;

  return targetStringLength + currentStringLength > limit;
};

export const getSlicedStringDependsOnLimit = (limit: number,
  currentString: string,
  targetString: string) => {
  const currentStringLength = currentString.length;
  const lengthDifference = Math.abs(currentStringLength - limit);

  return targetString.slice(0, lengthDifference);
};

// TODO Implement correct responsiveness
export const recalculateNotesPositions = (
  mirrorWidth: number,
  mirrorHeight: number) => {
  const { notes } = mainStore.getState().noteManager as NoteManagerState;

  const recalculatedNotes = notes.map((note) => ({
    ...note,
    position: {
      x: note.position.x + NoteWidthToMirrorSize.HIGH > mirrorWidth?
        mirrorWidth - NoteWidthToMirrorSize.HIGH : note.position.x,
      y: note.position.y + NoteHeightToMirrorSize.HIGH > mirrorHeight?
        mirrorHeight - NoteHeightToMirrorSize.HIGH : note.position.y ,
    }
  }))

  mainStore.dispatch(setNotes(recalculatedNotes))
}

// TODO Implement correct responsiveness
export const getTransformedNote = (
  note: NoteResponse,
  mirrorWidth: number,
  mirrorHeight: number,
): NoteTransformedResponse => ({
  id: note.id,
  value: note.value,
  stackLevel: note.stackLevel,
  isFocusedOnInit: false,
  position: {
    x: note.positionX + NoteWidthToMirrorSize.HIGH > mirrorWidth?
      mirrorWidth - NoteWidthToMirrorSize.HIGH : note.positionX,
    y: note.positionY + NoteHeightToMirrorSize.HIGH > mirrorHeight?
      mirrorHeight - NoteHeightToMirrorSize.HIGH : note.positionY,
  },
});

export const isLineSegmentOverlapping = (start: number,
  end: number,
  testedStart: number,
  testedEnd: number): boolean => (
  start <= testedEnd && testedStart <= end
);

export const canRectangleBePlacedWithoutOverlapping = (
  position: Point,
  width: number,
  height: number,
  rectangles: Rectangle[],
): boolean => !rectangles.some((rectangle) => {
  const testedLeftUpperCorner = rectangle.getLeftUpperCorner();

  return isLineSegmentOverlapping(
    position.x,
    position.x + width,
    testedLeftUpperCorner.x,
    testedLeftUpperCorner.x + width,
  )
    && isLineSegmentOverlapping(
      position.y,
      position.y + height,
      testedLeftUpperCorner.y,
      testedLeftUpperCorner.y + height,
    );
});

export const isNoteOverlapsOthers = (noteId: number, notePosition: Point): boolean => {
  const { notes } = mainStore.getState().noteManager as NoteManagerState;
  const filteredRectangles = notes.filter((noteEl) => (
    noteEl.id !== noteId
  )).map(({ position }) => getRectangleFromPosition(position));

  return !canRectangleBePlacedWithoutOverlapping(
    notePosition,
    NoteWidthToMirrorSize.HIGH,
    NoteHeightToMirrorSize.HIGH,
    filteredRectangles,
  );
};

const calculateNextPosition = (position: Point,
  step: number,
  direction: MovementDirectionEnum): Point => {
  if (direction === MovementDirectionEnum.HORIZONTAL) {
    return {
      x: position.x + step,
      y: position.y,
    };
  }

  return {
    x: position.x,
    y: position.y + step,
  };
};

export const getNextSupposedPosition = (currentPosition: Point,
  containerWidth: number,
  containerHeight: number,
  width: number): Point => {
  const notePosition = { ...currentPosition };

  if (notePosition.x + width > containerWidth) {
    notePosition.x = 0;

    const nextPosition = calculateNextPosition(
      notePosition,
      NOTE_MOVEMENT_STEP,
      MovementDirectionEnum.VERTICAL,
    );

    notePosition.x = nextPosition.x;
    notePosition.y = nextPosition.y;
  } else {
    const nextPosition = calculateNextPosition(
      notePosition,
      NOTE_MOVEMENT_STEP,
      MovementDirectionEnum.HORIZONTAL,
    );

    notePosition.x = nextPosition.x;
    notePosition.y = nextPosition.y;
  }

  return notePosition;
};

export const getFreePoint = (containerWidth: number,
  containerHeight: number,
  notes: NoteTransformedResponse[]): Point => {
  if (notes.length === 0) {
    return NOTE_DEFAULT_POSITION;
  }

  const noteRectangles = notes.map((note) => (
    new Rectangle(
      note.position,
      NoteWidthToMirrorSize.HIGH,
      NoteHeightToMirrorSize.HIGH,
    )
  ));

  const { width, height } = noteRectangles[0].getParams();

  const notePosition: Point = {
    x: 0,
    y: 0,
  };

  let isAvailable: boolean;

  do {
    isAvailable = canRectangleBePlacedWithoutOverlapping(
      notePosition,
      width,
      height,
      noteRectangles,
    ) && notePosition.x + width <= containerWidth;

    if (isAvailable) {
      break;
    }

    if (notePosition.y + height > containerHeight) {
      return NOTE_DEFAULT_POSITION;
    }

    const nextPosition = getNextSupposedPosition(
      notePosition,
      containerWidth,
      containerHeight,
      width,
    );

    notePosition.x = nextPosition.x;
    notePosition.y = nextPosition.y;
  } while (!isAvailable);

  return notePosition;
};

export const getNotesMaxStackLevel = (notes: NoteTransformedResponse[]): number => {
  if (!notes.length) {
    return 1;
  }

  return Math.max(
    ...notes.map(
      (note) => note.stackLevel,
    ),
  );
};

export const getUniqIdForNote = (): number => {
  const { notes } = mainStore.getState().noteManager as NoteManagerState;

  const compareFn = (leftValue: number, rightValue: number): number => (
    leftValue - rightValue
  );

  if (notes.length === 0) {
    return 0;
  }

  const sortedIdsArray = notes.map((value) => value.id).sort(compareFn);

  return sortedIdsArray[sortedIdsArray.length - 1] + 1;
};

export const transformMovableFrameToPoint = (frame: MovableFrame): Point => (
  {
    x: frame.translate[0],
    y: frame.translate[1],
  }
);
