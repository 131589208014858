/* eslint-disable import/prefer-default-export */
import smLogo from './img/sm-logo-retina.webp';

// header will not take up vertical height when transparent, so you need to be mindful of overlap
export const transparentHeader = true;
export const headerHeight = '4.2rem';
export const logo = smLogo;
export const logoAltText = 'Soul Machines logo';
export const logoLink = '/';

// background image is positioned in a way that is best for pictures of the persona's face.
// adjust spacing as necessary in Landing.js for different images
// if you want just a color, set landingBackgroundImage to null
// if desired, a gradient can also be added to landingBackgroundColor
export const landingBackgroundColor = '#fff';
export const landingBackgroundImage = null;
