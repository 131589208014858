import jwtDecode from 'jwt-decode';
import { TokenData, UserDataType } from '../global-components/GlobalTypes';
import cookies from '../helpers/addCookies';
import { getDomain } from './DomainUtils';
import { CookieSetOptions } from 'universal-cookie/cjs/types';

export const REFRESH_TOKEN_TTL = 2592000;
const USER_DATA_COOKIE_NAME = 'userData';
const DEFAULT_COOKIE_OPTIONS: CookieSetOptions = {
  path: '/',
  domain: getDomain(),
  sameSite: 'strict',
  secure: true,
};

export const setUserDataCookie = (
  userData,
  options?: CookieSetOptions
): void => {
  cookies.set(USER_DATA_COOKIE_NAME, userData, {
    ...DEFAULT_COOKIE_OPTIONS,
    ...options,
  });
};

export const removeUserDataCookie = (): void => {
  cookies.remove(USER_DATA_COOKIE_NAME, DEFAULT_COOKIE_OPTIONS);
};

export const getUserDataCookie = (): UserDataType => {
  return cookies.get(USER_DATA_COOKIE_NAME);
};

export const getAccessToken = (): string => {
  return getUserDataCookie()?.token;
};

export const getTokenData = (): TokenData => {
  const token = getAccessToken();
  return token ? jwtDecode(token) : undefined;
};
