export interface Point {
  x: number,
  y: number
}

export interface RectangleParams {
  width: number;
  height: number
  leftUpperCorner: Point
}

export class Rectangle {
  private leftUpperCorner: Point;

  private readonly width: number;

  private readonly height: number;

  constructor(leftUpperCorner: Point, width: number, height: number) {
    this.leftUpperCorner = leftUpperCorner;
    this.width = width;
    this.height = height;
  }

  public getParams():RectangleParams {
    return {
      leftUpperCorner: this.leftUpperCorner,
      width: this.width,
      height: this.height,
    };
  }

  public getWidth(): number {
    return this.width;
  }

  public getHeight(): number {
    return this.height;
  }

  public getLeftUpperCorner(): Point {
    return this.leftUpperCorner;
  }

  public setPosition(newPosition: Point) {
    this.leftUpperCorner = newPosition;
  }
}
