const findScoreColor = (score: string, opacitylevel: number): string => {
  switch (score) {
    case 'joy':
      return `rgba(161, 104, 247, ${opacitylevel})`;
    case 'fear':
      return `rgba(232, 195, 32, ${opacitylevel})`;
    case 'anger':
      return `rgba(186, 8, 2, ${opacitylevel})`;
    case 'frustrated':
      return `rgba(232, 119, 32, ${opacitylevel})`;
    case 'sad':
      return `rgba(46, 32, 232, ${opacitylevel})`;
    case 'excited':
      return `rgba(104, 247, 243, ${opacitylevel})`;
    case 'satisfied':
      return `rgba(64, 186, 43, ${opacitylevel})`;
    default:
      return `rgba(76, 23, 44, 1)`;
  }
};

export default findScoreColor;
