import React, { useEffect, useState } from 'react';
import { useSpeechRecognitionContext } from './speechRecognitionContext';
import { pauseTalking, resumeTalking, stopTalking } from '../../../utils/textToSpeech';
import { setShouldSendToneAnalyzerContext, setUserSpeaking } from '../../../store/reducers/digitalAssistantReducer';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button } from '@chakra-ui/react';
import { SVG } from '../../icons/icons';
import { RootState } from '../../../store/store';

const Container = styled.div`
  .recording-active {
    background-color: var(--chakra-colors-primary-100);
    animation: pulse 1s infinite alternate;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  
    100% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

const VoiceAssistant = ({ handleMessageSubmit }) => {
  const { transcript, interimTranscript, finalTranscript, resetTranscript, startListening, stopListening } = useSpeechRecognitionContext();
  const [recognizedText, setRecognizedText] = useState('');
  const {
    userSpeaking, isDigitalAssistantSpeaking, micListenOn        
  } = useSelector((state: RootState) => state.digitalAssistant);

  const [isMicActivated, setIsMicActivated] = useState(false);


  const dispatch = useDispatch();

  useEffect(() => {
    startListening();

    return () => {
      stopListening();
    };
  }, [startListening, stopListening]);

  useEffect(() => {
    if (isMicActivated && finalTranscript !== '' && !finalTranscript.toLowerCase().includes('tutl') && !finalTranscript.toLowerCase().includes('total') && !finalTranscript.toLowerCase().includes('turtle')) {          
      console.log("setting mic to false")
      setIsMicActivated(false);
      setRecognizedText(finalTranscript);
      handleRecognizedText(finalTranscript);
      handleMessageSubmit("", finalTranscript);
      resetTranscript();
    }
  }, [finalTranscript, resetTranscript]);

  useEffect(() => {
    if (interimTranscript !== '') {        
      setRecognizedText(interimTranscript);      
      if (interimTranscript.toLowerCase().includes('tutl') || interimTranscript.toLowerCase().includes('total') || interimTranscript.toLowerCase().includes('turtle')) {
        activateMicrophone()        
      }
    }    
  }, [interimTranscript]);

  useEffect(() => {
    if (!isDigitalAssistantSpeaking && micListenOn) {                    
        activateMicrophone()              
    } else {
      resumeTalking();
      setIsMicActivated(false);
    }    
  }, [micListenOn]);

  function activateMicrophone(){
    console.log("tutl activated");
    setIsMicActivated(true);
    resetTranscript();
    stopTalking();
    dispatch(setUserSpeaking({userSpeaking: true}))
  }

  const handleRecognizedText = (text) => {
    console.log("__", text);   
  };

  return (
      <Container
       className="mic-component-container"
    >
      <Button
        disabled={false}
        bgColor="#FFFFFF"
        variant="circle-icon"
        size="md"
        borderRadius="50%"
        borderColor="white"
        border="1px solid var(--chakra-colors-gray-300)"
        _hover={{ background: '#ededed' }}
        data-place="bottom"
        _focus={{
          boxShadow: 'none',
        }}
        className={isMicActivated ? 'recording-active':''}
        onClick={()=>{}}
      >
        <SVG id="mic-on-spa-chat" />
      </Button>      
    </Container>
  );
};

export default VoiceAssistant;
