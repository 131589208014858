import { FC, useEffect, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import {
  analyzeAudio,
  analyzeText,
  sendEmotionalData,
} from '../global-components/RequestFactory';
import { PitchAnalyzerScore, ToneAnalyzerScore } from '../interfaces/Emotional';
import { v4 as uuid } from 'uuid';
import {
  sendUserToneToWatson,
  setShouldSendToneAnalyzerContext,
} from '../store/reducers/digitalAssistantReducer';
import { PitchEmotionalDataType } from '../global-components/GlobalTypes';
import TextConstants from '../constants/TextConstants';

const AUDIO_RECORDING_MAX_MSEC = 60000;
let audioRecordingTimeout = null;

type EmotionType = 'happy' | 'calm' | 'angry' | 'sad';

const findHighestNumberInObj = (obj: { [key: string]: number }): number => {
  const valuesArray = Object.values(obj);
  const maxValue = Math.max(...valuesArray);

  return maxValue;
};

const getHighestEmotion = (
  dataObject: {
    happy: number;
    sad: number;
    calm: number;
    angry: number;
  },
  highestValueInData: number
): EmotionType => {
  let key: keyof typeof dataObject;
  for (key in dataObject) {
    if (dataObject[key] === highestValueInData) {
      return key;
    }
  }
};

const MULTIPLYING_SCORE =
  TextConstants.COMMERCE
    .MULTIPLYING_SCORE_OF_COMPARING_EMOTIONS_IN_TONE_ANALYZER;

const TranscriptAnalyzer: FC = () => {
  const [sessionId] = useState<string>(uuid());
  const [hasAudio, setHasAudio] = useState<boolean>(false);
  const [audioAnalyzeResult, setAudioAnalyzeResult] =
    useState<PitchAnalyzerScore | null>(null);
  const [textAnalyzeResult, setTextAnalyzeResult] =
    useState<ToneAnalyzerScore | null>(null);

  const dispatch = useDispatch();

  const {
    userSpeaking,
    transcript,
    shouldSendToneAnalyzerContext,
    // when null => we aren't setting any 'tone' variable to Watson, but just for info collecting reason
    watsonCurrentNode,
  } = useSelector((state: RootState) => state.digitalAssistant);

  const toggleRecordingState = () => {
    if (userSpeaking) {
      setHasAudio(true);

      audioRecordingTimeout = setTimeout(
        () => userSpeaking === true && stopRecording(),
        AUDIO_RECORDING_MAX_MSEC
      );

      startRecording();
    } else {
      if (audioRecordingTimeout) {
        clearTimeout(audioRecordingTimeout);
        audioRecordingTimeout = null;
      }

      stopRecording();
    }
  };

  const determineUserToneByAnalyzingText = (
    obj: PitchEmotionalDataType
  ): EmotionType => {
    const modifiedObj = {
      happy: obj.classificationScore.excited + obj.emotionalScore.joy,
      sad: obj.classificationScore.sad + obj.emotionalScore.sadness,
      calm:
        obj.classificationScore.satisfied + obj.classificationScore.sympathetic,
      angry: obj.classificationScore.frustrated + obj.emotionalScore.anger,
    };

    const highestValueInNLUData = findHighestNumberInObj(modifiedObj);

    const highestEmotionInNLUData = getHighestEmotion(
      modifiedObj,
      highestValueInNLUData
    );

    return highestEmotionInNLUData;
  };

  const determineUserToneByAnalyzingAudio = (
    obj: PitchEmotionalDataType
  ): EmotionType => {
    if (hasAudio) {
      const NLUDataSummarized = {
        happy: obj.classificationScore.excited + obj.emotionalScore.joy,
        sad: obj.classificationScore.sad + obj.emotionalScore.sadness,
        calm:
          obj.classificationScore.satisfied +
          obj.classificationScore.sympathetic,
        angry: obj.classificationScore.frustrated + obj.emotionalScore.anger,
      };

      const pitchDataModified = {
        happy: obj.pitchAnalyzerScore.happy,
        sad: obj.pitchAnalyzerScore.sad,
        calm: obj.pitchAnalyzerScore.neutral,
        angry: obj.pitchAnalyzerScore.angry,
      };

      const highestValueInNLUData = findHighestNumberInObj(NLUDataSummarized);
      const highestValueInPitchData = findHighestNumberInObj(pitchDataModified);

      const highestEmotionInNLUData = getHighestEmotion(
        NLUDataSummarized,
        highestValueInNLUData
      );

      const highestEmotionInPitchData = getHighestEmotion(
        pitchDataModified,
        highestValueInPitchData
      );

      if (highestEmotionInPitchData === highestEmotionInNLUData) {
        // if highest emotion (by value) in emotionalData is same as in pitchData, send it
        return highestEmotionInNLUData;
      } else if (highestEmotionInPitchData !== highestEmotionInNLUData) {
        // if highest emotion (by value) in emotionalData is different than in pitchData, multiply value by some score and send highest one
        const highestValueInNLUMultiplied =
          findHighestNumberInObj(NLUDataSummarized) * MULTIPLYING_SCORE;
        if (highestValueInNLUMultiplied >= highestValueInPitchData) {
          return highestEmotionInNLUData;
        } else {
          return highestEmotionInPitchData;
        }
      }
    }
  };

  const analyzeUserText = async () => {
    try {
      const lastTranscript = transcript[transcript.length - 1];
      if (
        lastTranscript &&
        lastTranscript.source === TextConstants.CONVERSATION_RESULT_SOURCE.USER &&
        // not analyze text in this case
        lastTranscript.text !== 'proceed'
      ) {
        const newTextAnalyzeResult = await analyzeText({
          text: lastTranscript.text,
        });
        console.log("setting user text");
        setTextAnalyzeResult(newTextAnalyzeResult);
      }
    } catch (error) {
      if (watsonCurrentNode) {
        // if no watsonCurrentNode => we send emotionalData just for tone tracking, we don't change Watson flow

        // send default tone
        dispatch(
          sendUserToneToWatson({
            tone: 'calm',
            currentStage: watsonCurrentNode,
          })
        );
      }
      console.log(error);
    }
  };

  const analyzeUserAudio = async (blobUrl: string, audio: Blob) => {
    try {
      const newAudioAnalyzeResult = await analyzeAudio(audio);
      setAudioAnalyzeResult(newAudioAnalyzeResult);
    } catch (error) {
      if (watsonCurrentNode) {
        // if no watsonCurrentNode => we send emotionalData just for tone tracking, we don't change Watson flow

        // send default tone
        dispatch(
          sendUserToneToWatson({
            tone: 'calm',
            currentStage: watsonCurrentNode,
          })
        );
      }
      console.log(error);
    }
  };

  const sendUserEmotionalData = () => {
    return new Promise<PitchEmotionalDataType>(function (resolve, reject) {
      if (textAnalyzeResult && !hasAudio) {
        sendEmotionalData({ ...textAnalyzeResult, sessionId })
          .then((result) => resolve(result))
          .catch((err) => reject(err));
        setTextAnalyzeResult(null);
      }
      if (textAnalyzeResult && hasAudio && audioAnalyzeResult) {
        sendEmotionalData({
          ...textAnalyzeResult,
          sessionId,
          pitchAnalyzerScore: audioAnalyzeResult,
        })
          .then((result) => resolve(result))
          .catch((err) => reject(err));
        setTextAnalyzeResult(null);
        setAudioAnalyzeResult(null);
      }
    });
  };

  const { startRecording, stopRecording } = useReactMediaRecorder({
    audio: true,
    video: false,
    onStop: analyzeUserAudio,
  });

  useEffect(() => {
    toggleRecordingState();
  }, [userSpeaking]);

  useEffect(() => {
    if (shouldSendToneAnalyzerContext) {
      analyzeUserText();
    }
  }, [transcript.length]);

  useEffect(() => {
    sendUserEmotionalData()
      .then((result) => {
        const determinedToneByTextAnalyze =
          determineUserToneByAnalyzingText(result);
        const determinedToneByAudioAnalyze =
          determineUserToneByAnalyzingAudio(result);

        if (watsonCurrentNode) {
          // if no watsonCurrentNode => we send emotionalData just for tone tracking, we don't change Watson flow

          dispatch(
            sendUserToneToWatson({
              tone: hasAudio
                ? determinedToneByAudioAnalyze
                : determinedToneByTextAnalyze,
              currentStage: watsonCurrentNode,
            })
          );
        }

        dispatch(
          setShouldSendToneAnalyzerContext({
            shouldSendToneAnalyzerContext: false,
            watsonCurrentNode: null,
          })
        );

        setHasAudio(false);
      })
      .catch((err) => {
        if (watsonCurrentNode) {
          // if no watsonCurrentNode => we send emotionalData just for tone tracking, we don't change Watson flow

          // send default tone
          dispatch(
            sendUserToneToWatson({
              tone: 'calm',
              currentStage: watsonCurrentNode,
            })
          );
        }

        console.log(err);
      });
  }, [audioAnalyzeResult, textAnalyzeResult]);

  return null;
};

export default TranscriptAnalyzer;
