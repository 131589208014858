import { ReactEditor, RenderLeafProps } from 'slate-react';
import {
  CustomTypes,
  Editor,
} from 'slate';
import { CSSProperties, ReactElement } from 'react';

import {
  NOTE_DEFAULT_VALUE,
  NOTE_CHARACTER_LIMIT,
  NOTE_DEFAULT_STACK_LEVEL,
  NoteHeightToMirrorSize,
  NoteWidthToMirrorSize, NOTE_MAX_BREAKS_AMOUNT,
} from '../constants/Notes';
import {
  canRectangleBePlacedWithoutOverlapping,
  getFontSizeDependOnLeaf,
  getFreePoint,
  getNotesMaxStackLevel,
  getRectangleFromPosition,
  getSlicedStringDependsOnLimit,
  getUniqIdForNote,
  isNoteOverlapsOthers,
  isStringExceedsLimit,
} from '../utils/Notes';
import mainStore from '../store/store';
import { NoteTransformedResponse } from '../interfaces/Notes';
import { Point } from '../entities/Rectangle';
import {NoteManagerState} from "../store/reducers/noteManagerReducer";

export const withTextLimit = () => function Plugin(
  editor: ReactEditor,
  onInsertCallback: () => void,
) {
  const {
    insertText, deleteBackward, deleteFragment, insertBreak,
  } = editor;

  editor.insertBreak = () => {
    if (editor.children.length === NOTE_MAX_BREAKS_AMOUNT) {
      return;
    }

    insertBreak();
  };

  editor.deleteFragment = (unit) => {
    deleteFragment(unit);

    if (Editor.string(editor, []).length === 0) {
      editor.children = NOTE_DEFAULT_VALUE;
    }
  };

  editor.deleteBackward = (unit) => {
    deleteBackward(unit);

    if (Editor.string(editor, []).length === 0) {
      editor.children = NOTE_DEFAULT_VALUE;
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text');

    if (isStringExceedsLimit(NOTE_CHARACTER_LIMIT, Editor.string(editor, []), text)) {
      editor.insertText(getSlicedStringDependsOnLimit(NOTE_CHARACTER_LIMIT,
        Editor.string(editor, []),
        text));

      onInsertCallback();

      return;
    }

    onInsertCallback();

    editor.insertText(text);
  };

  editor.insertText = (text) => {
    insertText(text);

    if (Editor.string(editor, []).length > NOTE_CHARACTER_LIMIT) {
      editor.deleteBackward('character');
    }
  };

  return editor;
};

export const getLeafElement = ({ attributes, children, leaf }: RenderLeafProps & {
  leaf: CustomTypes
}): ReactElement => {
  const style: CSSProperties = {
    fontSize: getFontSizeDependOnLeaf(leaf),
  };

  const renderResultElement = (leaf: CustomTypes) => {
    let resultElement = children;

    if (leaf.bold) {
      resultElement = <strong>{resultElement}</strong>;
    }
    if (leaf.italic) {
      resultElement = <em>{resultElement}</em>;
    }
    if (leaf.underline) {
      resultElement = <u>{resultElement}</u>;
    }

    return resultElement
  }

  return (
    <span
      style={style}
      {...attributes}
    >
      { renderResultElement(leaf) }
    </span>
  );
};

export const getNewNote = (mirrorWidth: number, mirrorHeight: number): NoteTransformedResponse => {
  const { notes } = mainStore.getState().noteManager as NoteManagerState;
  const targetPoint = getFreePoint(mirrorWidth, mirrorHeight, notes);
  const noteRectangles = notes.map((note) => (
    getRectangleFromPosition(note.position)
  ));
  const isOverlapping = !canRectangleBePlacedWithoutOverlapping(
    { ...targetPoint },
    NoteWidthToMirrorSize.HIGH,
    NoteHeightToMirrorSize.HIGH,
    noteRectangles,
  );

  return {
    id: getUniqIdForNote(),
    value: NOTE_DEFAULT_VALUE,
    position: {
      x: targetPoint.x,
      y: targetPoint.y,
    },
    stackLevel: isOverlapping ? getNotesMaxStackLevel(notes) + 1 : NOTE_DEFAULT_STACK_LEVEL,
    isFocusedOnInit: true,
  };
};

export const getAppropriateStackLevel = (currentNoteId: number, currentPosition: Point): number => {
  const { notes } = mainStore.getState().noteManager as NoteManagerState;
  const currentNote = notes.find((note) => note.id === currentNoteId);
  const maxStackLevel = getNotesMaxStackLevel(notes);
  const isOverlapped = isNoteOverlapsOthers(currentNoteId, currentPosition);

  if (!isOverlapped) {
    return NOTE_DEFAULT_STACK_LEVEL;
  }

  if (currentNote!.stackLevel !== maxStackLevel
    || currentNote!.stackLevel === NOTE_DEFAULT_STACK_LEVEL) {
    return maxStackLevel + 1;
  }

  return currentNote!.stackLevel;
};
