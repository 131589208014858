import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  HStack,
  Flex,
  useDisclosure,
  IconButton,
  Collapse,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import s from './Header.module.scss';
import UserNav from './UserNav/UserNav';
import MobileNav from './MobileNav/MobileNav';
import Logo from '../common/Logo/Logo';
import SignInModal from '../modals/SignInModal';
import { setIsHeaderModalsShown } from '../../store/reducers/commerceReducer';
import SignupContainer from '../signupContainer/SignupContainer';
import MyBagDrawer from '../drawer/myBag/MyBagDrawer';
import { RootState } from '../../store/store';
import JournalDrawer from '../drawer/journal/JournalDrawer';
import ProfileDrawer from '../drawer/profile/ProfileDrawer';
import ExitToLobbyModal from '../modals/ExitToLobbyModal';
import { SVG } from '../icons/icons';
import BeachVideoModal from '../modals/BeachVideoModal';
import InfoModal from '../modals/InfoModal';
import BuddhistMeditationRoomVideoModal from '../modals/BuddhistMeditationRoomVideoModal';
import AuthPromptModal from '../modals/signUp/AuthPromptModal';
import { useIsUserLoggedIn } from '../../helpers/userInfo/UserInfo';
import SessionTimeoutModal from '../modals/SessionTimeoutModal';
import {
  resetLogOutTimer,
  startLogOutTimer,
} from '../../store/reducers/sessionTimeoutReducer';
import {
  executeAuthPromptModalCallback,
  setAuthPromptOpened,
} from '../../store/reducers/authPromptReducer';

const CollapseContainer = styled.div`
  @media screen and (min-width: 889px) {
    display: none;
  }
`;

export type HeaderAuthPromptActionType = 'Journal' | 'MyBag' | null;
const Header = (): JSX.Element => {
  const history = useHistory();

  const isUserLoggedIn = useIsUserLoggedIn();

  const myBagDataItems = useSelector(
    (state: RootState) => state.commerce.myBagDataItems
  );
  const connected = useSelector((state: RootState) => state.digitalAssistant.connected);

  const { isOpen, onToggle } = useDisclosure();
  const dispatch = useDispatch();
  const totalProductsAmount = myBagDataItems.reduce(
    (previousValue, el) => previousValue + Number(el.quantity),
    0
  );
  const panoramicVideoModalToOpen = useSelector(
    (state: RootState) => state.panoramicVideoModalState
  );

  const isAuthPromptOpened = useSelector(
    (state: RootState) => state.authPromptState.isOpened
  );
  const isExecuteAuthPromptModalCallback = useSelector(
    (state: RootState) => state.authPromptState.isExecuteAuthPromptModalCallback
  );

  const [isExitToLobbyModalOpen, setIsExitToLobbyModalOpen] = useState(false);
  // when setting state AuthPromptModal is opened (1st time only)
  const [actionType, setActionType] =
    useState<HeaderAuthPromptActionType>(null);
  const openExitToLobbyFunction = () => {
    setIsExitToLobbyModalOpen(true);
  };
  const closeExitToLobbyFunction = () => {
    setIsExitToLobbyModalOpen(false);
  };

  const setAuthPromptAction = (action: HeaderAuthPromptActionType) => {
    setActionType(action);
  };

  const closeAuthPrompt = () => {
    dispatch(setAuthPromptOpened(true));
  };

  const authPromptCallback = () => {
    switch (actionType) {
      case 'Journal': {
        dispatch(setIsHeaderModalsShown({ journalDrawer: true }));
        break;
      }
      case 'MyBag': {
        dispatch(setIsHeaderModalsShown({ myBagDrawer: true }));
        break;
      }
    }
  };

  useEffect(() => {
    if (isExecuteAuthPromptModalCallback && actionType) {
      authPromptCallback();
      dispatch(executeAuthPromptModalCallback(false));
      setActionType(null);
    }
  }, [isExecuteAuthPromptModalCallback]);

  useEffect(() => {
    // if user is logged in not open AuthPromptModal anymore
    if (isUserLoggedIn) {
      dispatch(setAuthPromptOpened(true));
    }
  }, [isUserLoggedIn]);

  useEffect(
    () =>
      history.listen(() => {
        if (isOpen) {
          // close mobile menu on changing route
          onToggle();
        }
      }),
    [isOpen, history, onToggle]
  );

  useEffect(() =>
    // reset logout timer
    {
      const handleEventFunction = () => dispatch(resetLogOutTimer());

      // reset timer on every 10 seconds of typing
      let timerId;
      const handleInput = () => {
        clearTimeout(timerId);
        timerId = setTimeout(handleEventFunction, 10000);
      };

      // reset logout timer when navigating through pages
      const unlisten = history.listen(handleEventFunction);

      // start timer whenever user logs in
      if (isUserLoggedIn) {
        dispatch(startLogOutTimer());

        window.addEventListener('click', handleEventFunction);
        window.addEventListener('input', handleInput);
        // window.addEventListener('touchstart', resetTimer);

        return () => {
          window.removeEventListener('click', handleEventFunction);
          window.removeEventListener('input', handleInput);
          unlisten;
        };
      } else {
        window.removeEventListener('click', handleEventFunction);
        window.removeEventListener('input', handleInput);
        unlisten;
      }
    }, [isUserLoggedIn]);

  return (
    <Box
      as="header"
      className={s.header}
      p={{ base: '25px 0', bigDevice: '20px 0' }}
    >
      <Container maxW="container.xl" className={s.headerContainer}>
        <HStack spacing={8} align="center" justify="space-between">
          <Logo />
          <HStack
            spacing={8}
            align="center"
            display={{ base: 'none', bigDevice: 'flex' }}
          >
            <UserNav
              setAuthPromptAction={setAuthPromptAction}
              isAuthPromptOpened={isAuthPromptOpened}
              openExitToLobbyFunction={openExitToLobbyFunction}
              totalProductsAmount={totalProductsAmount}
            />
          </HStack>
          <Flex display={{ base: 'flex', bigDevice: 'none' }}>
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? <SVG id="close-x-lg" /> : <SVG id="hamburger-menu" />
              }
              _focus={{ boxShadow: 'none' }}
              variant="no-style"
              aria-label="Toggle Navigation"
              w="30px"
              h="30px"
            />
          </Flex>
        </HStack>
      </Container>

      <CollapseContainer>
        <Collapse in={isOpen} animateOpacity>
          <MobileNav
            isAuthPromptOpened={isAuthPromptOpened}
            setAuthPromptAction={setAuthPromptAction}
            totalProductsAmount={totalProductsAmount}
            openExitToLobbyFunction={openExitToLobbyFunction}
          />
        </Collapse>
      </CollapseContainer>

      <SignInModal />
      <SignupContainer />
      <MyBagDrawer />
      <JournalDrawer />
      <ProfileDrawer />
      <InfoModal />
      <SessionTimeoutModal />
      <AuthPromptModal
        isOpen={!isAuthPromptOpened && Boolean(actionType)}
        closeAuthPrompt={() => {
          closeAuthPrompt();
        }}
      />
      {
        // close modal when DP is disconnected
        connected &&
          // modal is default opened (isOpen={true}, (couldn't get video element))
          panoramicVideoModalToOpen === 'st_martin_beach' && <BeachVideoModal />
      }
      {
        // close modal when DP is disconnected
        connected &&
          panoramicVideoModalToOpen === 'buddhist_guided_meditation_room' && (
            <BuddhistMeditationRoomVideoModal />
          )
      }
      <ExitToLobbyModal
        isExitToLobbyModalOpen={isExitToLobbyModalOpen}
        closeExitToLobbyFunction={closeExitToLobbyFunction}
      />
    </Box>
  );
};

export default Header;
