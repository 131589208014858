const storageKey = 'isAuthPromptOpened';

export const setAuthPromptStateInLocalStorage = (param: boolean): void => {
  sessionStorage.setItem(storageKey, JSON.stringify(param));
};

export const getAuthPromptStateFromLocalStorage = (): boolean => {
  if (sessionStorage.getItem(storageKey) === null) {
    return false;
  }
  return JSON.parse(sessionStorage.getItem(storageKey)) as boolean;
};
