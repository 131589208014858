import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import MultiStepContainer from './MultiStepComponents/MultiStepContainer';
import makeup from '../../img/customize-makeup.svg';
import TextConstants from '../../constants/TextConstants';
import { SVG } from '../../components/icons/icons';
import eyesIcon from '../../assets/icons/customizeMakeupIcons/eyes.png';
import faceIcon from '../../assets/icons/customizeMakeupIcons/face.png';
import lipstickIcon from '../../assets/icons/customizeMakeupIcons/lipstick.png';
import miscellaneousIcon from '../../assets/icons/customizeMakeupIcons/miscellaneous.png';
import { RootState } from '../../store/store';
import { openAccordion } from '../../store/reducers/commerceReducer';

declare const window;

const categoriesIcons = {
  Face: faceIcon,
  Lips: lipstickIcon,
  Eye: eyesIcon,
  Miscellaneous: miscellaneousIcon,
};

const CustomizeMakeupAccordion = (): JSX.Element => {
  const isAccordionOpen = useSelector(
    (state: RootState) => state.commerce.isAccordionOpen
  );
  const categorizedProductsData = useSelector(
    (state: RootState) => state.commerce.categorizedProductsData
  );
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const openTabByWatsonFeature = useCallback(
    (text: 'Cheeks' | 'Face' | 'Lips' | 'Eyes' | 'Eye' | 'Miscellaneous') => {
      switch (text) {
        // Watson category type
        case 'Cheeks':

        case 'Face':
          setTabIndex(0);
          break;
        case 'Lips':
          setTabIndex(1);
          break;
        // Watson category type
        case 'Eyes':

        case 'Eye':
          setTabIndex(2);
          break;
        case 'Miscellaneous':
          setTabIndex(3);
          break;
        default:
          break;
      }
    },
    []
  );

  const renderAccordionButton = (isExpanded) => (
    <AccordionButton
      py="3px"
      boxShadow="none !important"
      onClick={() => dispatch(openAccordion(!isAccordionOpen))}
    >
      <Box
        flex="1"
        textAlign="left"
        display="flex"
        flexDirection="row"
        gap="16px"
      >
        {!isExpanded && (
          <>
            <Image src={makeup} />
            <Text textStyle="button-label" fontSize="16px">
              {TextConstants.ACCORDION.CUSTOMIZE_MAKEUP.TOGGLE_BUTTON}
            </Text>
          </>
        )}
      </Box>
      {isExpanded ? (
        <Box py="5px">
          <SVG id="down-arrow" />
        </Box>
      ) : (
        <SVG id="arrow-up-acc" />
      )}
    </AccordionButton>
  );

  const tabLists = () =>
    categorizedProductsData?.map((el) => {
      if (el.name && el.children?.length) {
        return (
          <Tab
            paddingInlineEnd="0"
            paddingInlineStart="0"
            width="35px"
            height="35px"
            key={el.id}
            id={el.id}
            textStyle="tab-label"
            fontSize="14px"
            _selected={{
              bg: 'primary.50',
            }}
            _focus={{ boxShadow: 'none' }}
            p="0"
          >
            <Image p="3px" src={categoriesIcons[el.name]} alt="Category Icon" />
          </Tab>
        );
      }
      return null;
    });

  const tabPanels = () =>
    categorizedProductsData?.map((el) => (
      <TabPanel
        justifyContent="space-between"
        minHeight="100px"
        key={el.id}
        paddingTop="0px"
        paddingBottom="0px"
        display="flex"
        flexDirection="column"
        px="0px"
        py="3px"
      >
        <MultiStepContainer
          selectedCategory={el.name}
          subcategories={el.children}
          handleTabChange={openTabByWatsonFeature}
        />
      </TabPanel>
    ));

  useEffect(() => {
    // open Live camera every time Customize makeup accordion opens
    if (isAccordionOpen) {
      if (!window.YMK?.isLoaded()) {
        window.YMK?.open([true]);
      }
    }
  }, [isAccordionOpen]);

  return (
    <Box className="customizeMakeup">
      <Accordion
        allowToggle
        index={[
          // accordion opening state is indexed (0 - open, 1 - closed)
          Number(!isAccordionOpen),
        ]}
      >
        <AccordionItem borderWidth="0px !important">
          {({ isExpanded }) => (
            <>
              {renderAccordionButton(isExpanded)}
              <AccordionPanel p="0">
                <Tabs
                  orientation="vertical"
                  className="makeupProducts"
                  pt="5px"
                  isFitted
                  variant="unstyled"
                  isLazy
                  onChange={(e) => {
                    // on tab change reset to disabled button/ unselect item
                    handleTabsChange(e);
                  }}
                  index={tabIndex}
                >
                  <TabList
                    display="flex"
                    justifyContent="space-around"
                    mt="-28px" // accordion button size + paddings
                  >
                    {tabLists()}
                  </TabList>
                  {categorizedProductsData &&
                    categorizedProductsData.length && (
                      <Box w={{ base: '90%', sm: '93%' }}>
                        <TabPanels px="10px" pb="10px">
                          {tabPanels()}
                        </TabPanels>
                      </Box>
                    )}
                </Tabs>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default CustomizeMakeupAccordion;
