import { Box, Text, VStack, Divider } from '@chakra-ui/react';
import TextConstants from '../../../constants/TextConstants';
import PersonalInfo from './PersonalInfo';
import ProfileAddresses from './ProfileAddresses';
import ProfileInformation from './ProfileInformation';
import ProfilePasswordUpdate from './ProfilePasswordUpdate';

const ProfileContainer = (): JSX.Element => {
  const data = [
    {
      ComponentTitle:
        TextConstants.PROFILE_MANAGMENT.PROFILE_INFORMATION_SECTION
          .COMPONENT_TITLE,
      ComponentSubtitle:
        TextConstants.PROFILE_MANAGMENT.PROFILE_INFORMATION_SECTION
          .COMPONENT_SUBTITLE,
      Component: <ProfileInformation />,
    },

    {
      ComponentTitle:
        TextConstants.PROFILE_MANAGMENT.PERSONAL_INFO_SECTION.COMPONENT_TITLE,
      ComponentSubtitle:
        TextConstants.PROFILE_MANAGMENT.PERSONAL_INFO_SECTION
          .COMPONENT_SUBTITLE,
      Component: <PersonalInfo />,
    },
    {
      ComponentTitle:
        TextConstants.PROFILE_MANAGMENT.PASSWORD_SECTION.COMPONENT_TITLE,
      ComponentSubtitle:
        TextConstants.PROFILE_MANAGMENT.PASSWORD_SECTION.COMPONENT_SUBTITLE,
      Component: <ProfilePasswordUpdate />,
    },

    {
      ComponentTitle:
        TextConstants.PROFILE_MANAGMENT.ADDRESS_SECTION.COMPONENT_TITLE,
      ComponentSubtitle:
        TextConstants.PROFILE_MANAGMENT.ADDRESS_SECTION.COMPONENT_SUBTITLE,
      Component: <ProfileAddresses />,
    },
  ];

  const ItemsToRender = data.map((element) => (
    <Box key={element.ComponentTitle}>
      <Box flex="1" textAlign="left" py="20px">
        <Text textStyle="title" color="gray.900">
          {element.ComponentTitle}
        </Text>

        <Text lineHeight="24px" textStyle="subTitle">
          {element.ComponentSubtitle}
        </Text>
      </Box>
      <Divider color="gray.200" />
      <Box p="0px 20px 0px 20px" mt="30px" bg="#FFFFFF" boxShadow="sm">
        <Box>{element.Component}</Box>
      </Box>
    </Box>
  ));

  return (
    <VStack
      px="10px"
      width="100%"
      height="auto"
      bg="#FDF6F0"
      overflowY="scroll"
      data-sm-content="profile-management"
    >
      <Box w="100%" p="10px">
        {ItemsToRender}
      </Box>
    </VStack>
  );
};

export default ProfileContainer;
