import { Box, Button, HStack, Input, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SVG } from '../../icons/icons';
import {
  sendTextMessage,
  stopSpeaking,
} from '../../../store/reducers/digitalAssistantReducer';
import { RootState } from '../../../store/store';

const TherapyFeedback = ({
  data,
  isCardInTranscript,
}: {
  data: {
    id: number;
    question: string;
    answers: {
      id: number;
      answer: string;
    }[];
    isFreeAnswer: boolean;
  };
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleInput = (e) => setInputValue(e.target.value);
  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );
  const handleInputSubmit = (e) => {
    e.preventDefault();
    dispatch(stopSpeaking());
    dispatch(sendTextMessage({ text: inputValue }));
    setIsButtonDisabled(true);
  };

  const handleOptionClick = (param: string) => {
    dispatch(stopSpeaking());
    dispatch(sendTextMessage({ text: param }));
    setIsButtonDisabled(true);
  };

  const renderAnswers = () => (
    <Box d="flex" flexDirection="column" gap="20px">
      {data.answers &&
        data.answers.map((eachAnswer) => (
          <Button
            variant="secondary"
            p="20px"
            m="5px"
            key={JSON.stringify(eachAnswer)}
            borderRadius="0.5rem"
            w="100%"
            margin="0 auto"
            onClick={() => {
              handleOptionClick(eachAnswer.answer);
            }}
            disabled={isButtonDisabled || isCardInTranscript}
          >
            {eachAnswer.answer}
          </Button>
        ))}
    </Box>
  );

  const renderOptions = () => {
    if (data.isFreeAnswer) {
      return (
        <HStack as="form" onSubmit={handleInputSubmit}>
          <Input
            type="text"
            placeholder="Type here"
            bgColor="#fff"
            value={inputValue}
            onChange={handleInput}
            aria-label="Keyboard input"
            required
          />
          <Button
            variant="circle-icon"
            size="md"
            onClick={handleInputSubmit}
            type="submit"
            disabled={!inputValue || isButtonDisabled || isCardInTranscript}
          >
            <SVG id="send" />
          </Button>
        </HStack>
      );
    }
    return renderAnswers();
  };

  return (
    <Box
      width="500px"
      d="flex"
      flexDirection="column"
      justifyContent="center"
      data-sm-content="TherapyFeedback"
      alignItems={isTranscriptOpen ? 'start' : 'center'}
      p={isTranscriptOpen ? '10px 0 10px 35px' : '0'}
      paddingBottom={isTranscriptOpen ? 0 : '5vh'}
      marginRight={!isTranscriptOpen && { base: '0', sm: '40px' }}
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      {!isTranscriptOpen && (
        <Text className="transcript-entry-content" mb="20px">
          {data.question}
        </Text>
      )}
      <Box minWidth="250px">{renderOptions()}</Box>
    </Box>
  );
};

export default TherapyFeedback;
