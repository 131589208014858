/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box } from '@chakra-ui/react';
import EachSearchedProductCard from './EachSearchedProductCard';
import { setIsHeaderModalsShown } from '../../../../store/reducers/commerceReducer';
import { AppDispatch, RootState } from '../../../../store/store';

declare const window;

const Container = styled.div`
  @media only screen and (max-width: 1024px) {
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 630px) {
    width: 95%;
  }

  .searched-product-recommendation-slider {
    width: 230px;
    margin-left: var(--chakra-sizes-4);

    // hide product-info button if current slider is inactive
    .product-info-button {
      display: none;
    }
    .slick-current {
      .product-info-button {
        display: unset;
      }
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slickSlide {
    transition: 0.5s;
    display: block;
    height: 100%;
  }

  .slick-current div {
    opacity: 1;
  }

  .slick-next:before,
  .slick-prev:before {
    color: #000;
  }

  @media (max-width: 1300px) {
    .imageContainer {
      width: 600px;
    }
  }
  @media (max-width: 1100px) {
    .imageContainer {
      width: 700px;
    }
  }
  @media (max-width: 950px) {
    .imageContainer {
      width: 500px;
    }
  }
`;

const SearchedProductRecommendation = ({
  isCardInTranscript,
}: {
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const searchedProductsData = useSelector(
    (state: RootState) => state.commerce.searchedProductsData
  );

  const sliderSettings = {
    slidesToShow: searchedProductsData?.length > 1 ? 1.1 : 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    appendDots: (dots) => (
      <ul
        className="slick-dots"
        style={{
          width: '85%',
        }}
      >
        {' '}
        {dots}{' '}
      </ul>
    ),
  };

  useEffect(() => {
    // auto-opens live camera
    if (!window.YMK?.isLoaded()) {
      window.YMK?.open([true]);
    }
  }, []);

  useEffect(() => {
    // close drawers when this card is opened
    dispatch(
      setIsHeaderModalsShown({
        myBagDrawer: false,
        journalDrawer: false,
        profileDrawer: false,
      })
    );
  }, [dispatch]);

  return (
    <Container data-sm-content="products">
      <Slider
        {...sliderSettings}
        className="searched-product-recommendation-slider slick-dotted"
      >
        {searchedProductsData?.map((el, index) => (
          <Box className="slickSlide" key={JSON.stringify(el)}>
            <EachSearchedProductCard
              data={el}
              index={index}
              isCardInTranscript={isCardInTranscript}
            />
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default SearchedProductRecommendation;
