/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  setVirtualMirrorVisibility,
} from './vmReducer';
import {
  AuthState,
  CommerceInitialState,
  PermissionType,
  DigitalAssistantState,
  DigitalCharacterAnimationStage,
} from '../../global-components/GlobalTypes';
import {
  getDPChatUserDataFromLocalStorage,
  saveDPChatUserDataInLocalStorage,
} from '../../helpers/DPNewUserFlowStorageHelper';
import {
  acceptPermissionAndSetInLocalStorage,
  getPermissionAcceptance,
} from '../../helpers/storage-helper/permissionsStorageHelper';
import { getContentCardsFromContext, sendChatBotRequest, sendUpdateContextRequest } from '../../utils/digitalAssistantUtils';

import { speakText, requestSpeechAudioAccess } from '../../utils/textToSpeech';
import WebSpeechRecognizer from '../../utils/webSpeechRecognizer';

import * as watsonFeaturesProcessor from '../../services/WatsonFeaturesProcessor';

import TextConstants from '../../constants/TextConstants';
import { object } from 'prop-types';
const digitalCharacterAnimationStages = TextConstants.DIGITAL_CHARACTER_ANIMATION_STAGE;

declare const window;

const initialState: DigitalAssistantState = {
  digitalCharacterAnimationStage: digitalCharacterAnimationStages.HELLO_POSITIVE_SPEAKING as DigitalCharacterAnimationStage,
  assistantSessionId: '',
  connected: false,
  disconnected: false,
  isRedirectionAllowed: false,
  loading: false,
  error: null,
  isMuted: false,
  micListenOn: false,
  isDigitalAssistantSpeaking: false,
  typingOnly: false,
  videoHeight: window.innerHeight,
  videoWidth: window.innerWidth,
  transcript: [],
  activeCards: [],
  watsonContextParameters: {},
  // lets us keep track of whether the content cards were added in this turn
  cardsAreStale: false,
  contentCards: [],
  speechState: 'idle',
  // NLP gives us results as it processes final user utterance
  intermediateUserUtterance: '',
  userSpeaking: false,
  lastUserUtterance: '',
  lastPersonaUtterance: '',
  user: {
    activity: {
      isAttentive: 0,
      isTalking: 0,
    },
    emotion: {
      confusion: 0,
      negativity: 0,
      positivity: 0,
      confidence: 0,
    },
    conversation: {
      turn: '',
      context: {
        FacePresent: 0,
        PersonaTurn_IsAttentive: 0,
        PersonaTurn_IsTalking: null,
        Persona_Turn_Confusion: null,
        Persona_Turn_Negativity: null,
        Persona_Turn_Positivity: null,
        UserTurn_IsAttentive: 0,
        UserTurn_IsTalking: null,
        User_Turn_Confusion: null,
        User_Turn_Negativity: null,
        User_Turn_Positivity: null,
      },
    },
  },
  callQuality: {
    audio: {
      bitrate: null,
      packetsLost: null,
      roundTripTime: null,
    },
    video: {
      bitrate: null,
      packetsLost: null,
      roundTripTime: null,
    },
  },
  cameraOn: true,
  // default to 1 because these values are used to compute an aspect ratio,
  // so if for some reason the camera is disabled, it will default to a square (1:1)
  cameraWidth: 1,
  cameraHeight: 1,
  showTranscript: false,
  shouldSendToneAnalyzerContext: false,
  watsonCurrentNode: null,
  areMediaPermissionsAccepted: getPermissionAcceptance(
    'areMediaPermissionsAccepted'
  ),
  isHappyHatsTOSAccepted: getPermissionAcceptance('isHappyHatsTOSAccepted'),
};

// host actions object since we need the types to be available for
// async calls later, e.g. handling messages from persona
let actions;

// tells persona to stop listening to mic input
export const mute = createAsyncThunk(
  'digitalAssistant/mute',
  async (specifiedMuteState: boolean | string, thunk) => {
  }
);

export const closeAssistantChat = createAsyncThunk(
  'digitalAssistant/closeAssistantChat',
  async (_, thunk) => {
    const { commerce } = thunk.getState() as {
      commerce: CommerceInitialState;
    };

    if (commerce.dialogType === 'e-commerce') {
      window.YMK?.close();
      window.YMK?.closeEngine();

      // set returning_user to 'true' every time we disconnect from DP
      saveDPChatUserDataInLocalStorage({
        returning_user: 'true',
        stage: 'welcome',
      });
    }

    thunk.dispatch(setVirtualMirrorVisibility(false));
    // TODO remove timeout later
    setTimeout(() => {
      thunk.dispatch(actions.closeAssistantChat());
    }, 500);
  }
);

export const sendUserToneToWatson = createAsyncThunk(
  'digitalAssistant/sendUserToneToWatson',
  async (
    {
      tone,
      currentStage,
    }: {
      tone: 'happy' | 'calm' | 'sad' | 'frustrated' | 'angry';
      currentStage: string;
    },
    thunk
  ) => {
    const { digitalAssistant: { assistantSessionId } } = thunk.getState() as { digitalAssistant: DigitalAssistantState; };
    const { commerce: { dialogType } } = thunk.getState() as { commerce: CommerceInitialState; };
    const { digitalAssistant: { transcript } } = thunk.getState() as { digitalAssistant: DigitalAssistantState; };
    const lastTranscript = transcript[transcript.length - 1];

    const DPChatUserData = getDPChatUserDataFromLocalStorage();
    let contextVariables = { ...DPChatUserData, tone, stage: currentStage }
    // await sendUpdateContextRequest(assistantSessionId, dialogType,contextVariables)    
    thunk.dispatch(sendTextMessage({ text: lastTranscript.text, contextVariables }));
    //  thunk.dispatch(setShouldSendToneAnalyzerContext({
    //       shouldSendToneAnalyzerContext: false,
    //       watsonCurrentNode: ''
    //     }))
    // console.log("DPChatUserData ",DPChatUserData, tone, currentStage)
  }
);

export const openAssistantChat = createAsyncThunk(
  'digitalAssistant/openAssistantChat',
  async (options: any, thunk) => {
    // @TODO properly reintegrate @feature stuff from dialog text messages
    thunk.dispatch(actions.setShowTranscript(true));

    // fulfill promise, reducer sets state to indicate loading and connection are complete
    return thunk.fulfillWithValue({});
  }
);

const _sendTextMessageAndProcessResponse = async (text: string, token: string, thunk, contextVariables ?: object) => {
  const { digitalAssistant: { assistantSessionId, watsonContextParameters } } = thunk.getState() as { digitalAssistant: DigitalAssistantState; };
  const { commerce: { dialogType } } = thunk.getState() as { commerce: CommerceInitialState; };
  const watsonResponse = await sendChatBotRequest(token, text, assistantSessionId, dialogType, {...contextVariables,...watsonContextParameters});

  thunk.dispatch(setAssistantSessionId({ assistantSessionId: watsonResponse.sessionId }));

  console.log('_sendTextMessageAndProcessResponse() watsonResponse = ', watsonResponse);
  const messageData = await watsonFeaturesProcessor.processWatsonMessage(watsonResponse, thunk);
  console.log('_sendTextMessageAndProcessResponse() messageData = ', messageData);

  thunk.dispatch(
    actions.addConversationResult({
      source: TextConstants.CONVERSATION_RESULT_SOURCE.PERSONA,
      text: messageData.text,
    })
  );

  const contentCards = getContentCardsFromContext(watsonResponse.context);
  // thunk.dispatch(actions.addContentCards({ contentCards: contentCards.contentCards }));
  thunk.dispatch(actions.setActiveCards({ activeCards: contentCards.activeCards }));
  thunk.dispatch(actions.setContextParameters({ watsonContextParameters: watsonResponse.watsonContextParameters }));  

  if (contentCards.activeCards.length >= 1) {
    thunk.dispatch(
      actions.addConversationResult({
        source: TextConstants.CONVERSATION_RESULT_SOURCE.PERSONA,
        card: contentCards.cardInput,
      })
    );
  }

  thunk.dispatch(
  actions.switchMicListenState({
    micListenOn: false,
  })
);
  await speakText(messageData.text, {
    onstart: () => {
      thunk.dispatch(
        actions.switchMicListenState({
          micListenOn: false,
        })
      );
      thunk.dispatch(
        actions.setDigitalAssistantSpeakingState({
          isDigitalAssistantSpeaking: true,
        })
      );
      thunk.dispatch(
        actions.setDigitalCharacterAnimationStage({
          digitalCharacterAnimationStage: digitalCharacterAnimationStages.REGULAR_POSITIVE_SPEAKING,
        })
      );
    },
    onend: (event, isFakedEvent = false) => {
      // @TODO: onend is triggered between phrases now, which causes "Send" button to un-disable for a short period of time
      // this still doesn't affect character animations thanks to debounce()
      thunk.dispatch(
        actions.setDigitalAssistantSpeakingState({
          isDigitalAssistantSpeaking: false,
        })
      );
      thunk.dispatch(
        actions.setDigitalCharacterAnimationStage({
          digitalCharacterAnimationStage: digitalCharacterAnimationStages.REGULAR_NEUTRAL_IDLE,
        })
      );

      if (isFakedEvent) { // the actual end of speech on iOS, we should set micListenOn specifically here
        thunk.dispatch(
          actions.switchMicListenState({
            micListenOn: true,
          })
        );
      }
    },
  });

  thunk.dispatch(
    actions.switchMicListenState({
      micListenOn: true,
    })
  );
};


export const startConversation = createAsyncThunk(
  'digitalAssistant/startConversation',
  async (options: any, thunk) => {
    const { authenticationState: { userData } } = thunk.getState() as { authenticationState: AuthState; };

    await _sendTextMessageAndProcessResponse(' ', userData?.token, thunk);
  }
);

/**
 * Send plain text to the Watson
 */
export const sendTextMessage = createAsyncThunk(
  'digitalAssistant/sendTextMessage',
  async ({ text, contextVariables={} }: { text: string, contextVariables?: object }, thunk) => {
    const { authenticationState: { userData } } = thunk.getState() as { authenticationState: AuthState; };
    const { digitalAssistant: { shouldSendToneAnalyzerContext } } = thunk.getState() as { digitalAssistant: DigitalAssistantState; };

    if(!shouldSendToneAnalyzerContext)
    thunk.dispatch(
      actions.addConversationResult({
        source: TextConstants.CONVERSATION_RESULT_SOURCE.USER,
        text,
      })
    );

    // like @feature(microphone,off)
    thunk.dispatch(setUserSpeaking({ userSpeaking: false }));

    await _sendTextMessageAndProcessResponse(text, userData?.token, thunk, contextVariables);
  }
);

const digitalAssistantSlice = createSlice({
  name: 'digitalAssistant',
  initialState,
  reducers: {
    setShowTranscript: (state, { payload }) => ({
      ...state,
      showTranscript: payload,
    }),
    setTypingOnly: (state) => ({
      ...state,
      typingOnly: true,
    }),
    allowRedirection: (state, { payload }) => ({
      ...state,
      isRedirectionAllowed: payload,
    }),
    setCameraState: (state, { payload }) => ({
      ...state,
      cameraOn: payload.cameraOn,
      cameraWidth: payload.cameraWidth || state.cameraWidth,
      cameraHeight: payload.cameraHeight || state.cameraHeight,
    }),
    setActiveCards: (state, { payload }) => ({
      ...state,
      activeCards: payload.activeCards || [],
      cardsAreStale: payload.cardsAreStale || false,
    }),
    setContextParameters: (state, { payload }) => ({
      ...state,
      watsonContextParameters: payload.watsonContextParameters || {}    
    }),
    // content cards with the same key may get overwritten, so when the card is called
    // in @showCards(), we copy to transcript: [] and activeCards: []
    addContentCards: (state, { payload }) => ({
      ...state,
      contentCards: { ...state.contentCards, ...payload.contentCards },
    }),
    stopSpeaking: (state) => ({
      ...state,
      userSpeaking: false,
    }),
    setMute: (state, { payload }) => ({
      ...state,
      isMuted: payload.isMuted,
    }),
    setIntermediateUserUtterance: (state, { payload }) => ({
      ...state,
      intermediateUserUtterance: payload.text,
      userSpeaking: true,
    }),
    setUserSpeaking: (state, { payload }) => ({
      ...state,
      userSpeaking: payload.userSpeaking,
    }),
    setDigitalCharacterAnimationStage: (state, { payload }) => ({
      ...state,
      digitalCharacterAnimationStage: payload.digitalCharacterAnimationStage,
    }),
    switchMicListenState: (state, { payload }) => ({
      ...state,
      micListenOn: payload.micListenOn,
    }),
    setDigitalAssistantSpeakingState: (state, { payload }) => ({
      ...state,
      isDigitalAssistantSpeaking: payload.isDigitalAssistantSpeaking,
    }),
    addConversationResult: (state, { payload }) => {
      // we record both text and content cards in the transcript
      if (payload.text !== '' || 'card' in payload !== false) {
        const { source } = payload;
        const newEntry = {
          source,
          timestamp: new Date().toISOString(),
          text: '',
          card: null,
        };
        // handle entering either text or card into transcript array
        if ('text' in payload) newEntry.text = payload.text;
        if ('card' in payload) newEntry.card = payload.card;
        const out = {
          ...state,
          transcript: [...state.transcript, { ...newEntry }],
          intermediateUserUtterance: '',
        };
        // copy any text to last___Utterance, used for captions and user confirmation of STT
        if ('text' in payload) {
          out[
            payload.source === TextConstants.CONVERSATION_RESULT_SOURCE.USER
              ? 'lastUserUtterance'
              : 'lastPersonaUtterance'
          ] = payload.text;
        }
        return out;
      }
    },
    setSpeechState: (state, { payload }) => ({
      ...state,
      speechState: payload.speechState,
    }),
    setEmotionState: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        emotion: payload.emotion,
      },
    }),
    setConversationState: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        conversation: payload.conversation,
      },
    }),
    setActivityState: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        activity: payload.activity,
      },
    }),
    setCallQuality: (state, { payload }) => ({
      ...state,
      callQuality: payload.callQuality,
    }),
    setVideoDimensions: (state, { payload }) => {
      const { videoWidth, videoHeight } = payload;

      let deviceWidth;
      let deviceHeight;

      // update video dimensions in persona
      // calc resolution w/ device pixel ratio

      deviceWidth = videoWidth
        ? Math.round(videoWidth * window.devicePixelRatio)
        : 0;

      deviceHeight = videoHeight
        ? Math.round(videoHeight * window.devicePixelRatio)
        : 0;

      return { ...state, videoWidth, videoHeight };
    },
    closeAssistantChat: (state) => {
      const {
        error,
        isHappyHatsTOSAccepted,
        areMediaPermissionsAccepted,
      } = state;
      return {
        // @TODO all disconnect-related code to be removed
        // completely reset SM state on disconnect, except for errors and permissions
        ...initialState,
        areMediaPermissionsAccepted,
        isHappyHatsTOSAccepted,
        disconnected: true,
        error,
      };
    },

    acceptMediaPermissions: (
      state,
      { payload }: PayloadAction<PermissionType>
    ) => {
      if (payload === 'agree') {
        requestSpeechAudioAccess();
        WebSpeechRecognizer.requestMicAccess();
      }

      acceptPermissionAndSetInLocalStorage(
        'areMediaPermissionsAccepted',
        payload
      );
      return {
        ...state,
        areMediaPermissionsAccepted: payload,
      };
    },

    acceptHappyHatsTOS: (state, { payload }: PayloadAction<PermissionType>) => {
      acceptPermissionAndSetInLocalStorage('isHappyHatsTOSAccepted', payload);
      return {
        ...state,
        isHappyHatsTOSAccepted: payload,
      };
    },

    setShouldSendToneAnalyzerContext: (
      state,
      {
        payload: { shouldSendToneAnalyzerContext, watsonCurrentNode },
      }: {
        payload: {
          shouldSendToneAnalyzerContext: boolean;
          watsonCurrentNode: string | null;
        };
      }
    ) => ({
      ...state,
      shouldSendToneAnalyzerContext: shouldSendToneAnalyzerContext,
      watsonCurrentNode: watsonCurrentNode,
    }),

    setAssistantSessionId: (state, { payload }) => ({
      ...state,
      assistantSessionId: payload.assistantSessionId,
    }),
  },

  extraReducers: (builder) => {
    builder.addCase(openAssistantChat.pending, (state) => ({
      ...state,
      loading: true,
      disconnected: false,
      error: null,
    }));
    builder.addCase(openAssistantChat.fulfilled, (state) => ({
      ...state,
      loading: false,
      connected: true,
      error: null,
    }));
  },
});

// hoist actions to top of file so thunks can access
actions = digitalAssistantSlice.actions;

export const {
  setVideoDimensions,
  stopSpeaking,
  setActiveCards,
  setCameraState,
  setShowTranscript,
  allowRedirection,
  setShouldSendToneAnalyzerContext,
  acceptMediaPermissions,
  acceptHappyHatsTOS,
  setAssistantSessionId,
  switchMicListenState,
  setUserSpeaking,
  addConversationResult,
  setDigitalCharacterAnimationStage
} = digitalAssistantSlice.actions;

export default digitalAssistantSlice.reducer;
