/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box } from '@chakra-ui/react';
import EachFloatingIntentProduct from './EachFloatingIntentProduct';
import { AppDispatch } from '../../../../store/store';
import { setIsHeaderModalsShown } from '../../../../store/reducers/commerceReducer';
import { SearchedProductType } from '../../../../global-components/GlobalTypes';
import { searchInProducts } from '../../../../global-components/RequestFactory';
import MoveOnButton from '../MoveOnButton';

declare const window;

const Container = styled.div`
  @media only screen and (max-width: 1024px) {
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 630px) {
    width: 95%;
  }

  .floating-intent-products-slider {
    width: 230px;
    margin-left: var(--chakra-sizes-4);

    // hide product-info button if current slider is inactive
    .product-info-button {
      display: none;
    }
    .slick-current {
      .product-info-button {
        display: unset;
      }
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slickSlide {
    transition: 0.5s;
    display: block;
    height: 100%;
  }

  .slick-current div {
    opacity: 1;
  }

  .slick-next:before,
  .slick-prev:before {
    color: #000;
  }

  @media (max-width: 1300px) {
    .imageContainer {
      width: 600px;
    }
  }
  @media (max-width: 1100px) {
    .imageContainer {
      width: 700px;
    }
  }
  @media (max-width: 950px) {
    .imageContainer {
      width: 500px;
    }
  }
`;

const FloatingIntentProduct = ({
  data,
  isCardInTranscript,
}: {
  data: {
    requested_product: string;
  };
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const [products, setProducts] = useState<SearchedProductType[] | null>(null);

  const sliderSettings = {
    slidesToShow: products?.length > 1 ? 1.1 : 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    appendDots: (dots) => (
      <ul
        className="slick-dots"
        style={{
          width: '85%',
        }}
      >
        {' '}
        {dots}{' '}
      </ul>
    ),
  };

  useEffect(() => {
    // auto-opens live camera
    if (!window.YMK?.isLoaded()) {
      window.YMK?.open([true]);
    }
  }, []);

  useEffect(() => {
    searchInProducts(data.requested_product).then(
      (res: SearchedProductType[]) => {
        if (res?.length) {
          setProducts(res);
        } else {
          setProducts(null);
        }
      }
    );
  }, [data.requested_product]);

  useEffect(() => {
    // close drawers when this card is opened
    dispatch(
      setIsHeaderModalsShown({
        myBagDrawer: false,
        journalDrawer: false,
        profileDrawer: false,
      })
    );
  }, [dispatch]);

  return (
    <Container data-sm-content="floating-intent-products">
      <Slider
        {...sliderSettings}
        className="floating-intent-products-slider slick-dotted"
      >
        {products?.map(
          (el: SearchedProductType, index) =>
            el && (
              <Box className="slickSlide" key={uuid()}>
                <EachFloatingIntentProduct
                  data={el}
                  index={index}
                  isCardInTranscript={isCardInTranscript}
                />
              </Box>
            )
        )}
      </Slider>
      <Box paddingTop="10px">
        <MoveOnButton isCardInTranscript={isCardInTranscript} />
      </Box>
    </Container>
  );
};

export default FloatingIntentProduct;
