import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import ReactPhoneInput from 'react-phone-input-2';
import {
  Alert,
  AlertIcon,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  InputLeftElement,
  InputGroup,
} from '@chakra-ui/react';
import styled from 'styled-components';
import ProfileSectionButtons from './ProfileSectionButtons';
import { SVG } from '../../icons/icons';
import TextConstants from '../../../constants/TextConstants';
import { updateProfileInformation } from '../../../global-components/RequestFactory';
import { setUserData } from '../../../store/reducers/authenticationReducer';
import { UserDataType } from '../../../global-components/GlobalTypes';
import { useGetUserInfo } from '../../../helpers/userInfo/UserInfo';
import { getUserDataCookie } from '../../../utils/CookieUtils';
import { profileInformationValidationSchema } from '../../../helpers/validation';

const phoneUtil =
  require('google-libphonenumber').PhoneNumberUtil.getInstance();

const Container = styled.div`
  .form-control {
    width: 100%;
    height: 44px;
    border-radius: 0px;

    &:focus {
      border-color: var(--chakra-colors-brand-300);
      box-shadow: 0px 1px 2px rgb(16 24 40 / 5%), 0px 0px 0px 4px #f4ebff;
    }
  }

  .is-invalid {
    border-color: var(--chakra-colors-error-300);
    &:focus {
      box-shadow: 0px 1px 2px rgb(16 24 40 / 5%), 0px 0px 0px 4px #fee4e2;
      border-color: var(--chakra-colors-error-300);
    }
  }
`;

const ProfileInformation = (): JSX.Element => {
  const dispatch = useDispatch();

  // const [avatar, setAvatar] = useState<string | ArrayBuffer>(defaultAvatar);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [requestError, setRequestError] = useState({
    isErrorShown: false,
    errorText: '',
  });

  const [countryCode, setCountryCode] = useState('');
  const [isInitialValueChanged, setInitialValueChange] = useState(false);

  const userData = useGetUserInfo();

  const setUserDataInRedux = (result: UserDataType) =>
    dispatch(setUserData(result));

  const hideResponseError = () => {
    setRequestError({ isErrorShown: false, errorText: '' });
  };

  const isPhoneNumberValidationPassed = (phoneNumber) => {
    // countryCode is cleared after reload, on submitting we are getting warning
    if (countryCode) {
      const number = phoneUtil.parseAndKeepRawInput(
        phoneNumber,
        countryCode.toUpperCase()
      );
      const isValidPhone = phoneUtil.isValidNumberForRegion(
        number,
        countryCode.toUpperCase()
      );
      return isValidPhone;
    }
    return true;
  };

  const formik = useFormik({
    initialValues: {
      firstName: userData.firstName ? userData.firstName : '',
      lastName: userData.lastName ? userData.lastName : '',
      email: userData.email ? userData.email : '',
      phoneNumber: userData.phoneNumber ? userData.phoneNumber.slice(1) : '',
    },
    onSubmit: () => {
      const valuesToSubmit = {
        firstName: formik.values.firstName.trim() || null,
        lastName: formik.values.lastName.trim() || null,
        phoneNumber: formik.values.phoneNumber
          ? `+${formik.values.phoneNumber}`
          : '',

        // TODO BE temporary doesn't provide email change
        // ...(formik.values.email && { email: formik.values.email }),
      };

      const handleSubmitCondition = () => {
        if (
          formik.values.phoneNumber !== formik.initialValues.phoneNumber &&
          formik.values.phoneNumber
        ) {
          return isPhoneNumberValidationPassed(formik.values.phoneNumber);
        }
        if (
          formik.values.phoneNumber === formik.initialValues.phoneNumber ||
          !formik.values.phoneNumber
        ) {
          return true;
        }
        return true;
      };

      if (handleSubmitCondition()) {
        formik.setFieldError('phoneNumber', '');
        setIsRequesting(true);
        updateProfileInformation(valuesToSubmit, userData, setUserDataInRedux)
          .then((patchedUserData) => {
            setIsRequestSuccess(true);
            setTimeout(
              () => setIsRequestSuccess(false),
              TextConstants.ALERT_MESSAGE_TRASHHOLD
            );
            hideResponseError();
            setIsRequesting(false);
            setRequestError({ isErrorShown: false, errorText: '' });
            // getUserDataCookie() is userData (userData not refreshing while running promise)
            setUserDataInRedux({ ...getUserDataCookie(), ...patchedUserData });
            formik.resetForm();
          })
          .catch((error) => {
            setIsRequesting(false);
            setIsRequestSuccess(false);
            setRequestError({
              isErrorShown: true,
              errorText: error.violations
                ? error.violations[0].message
                : error.message,
            });
            setTimeout(
              () => hideResponseError(),
              TextConstants.ALERT_MESSAGE_TRASHHOLD
            );
          });
      }
      if (
        formik.values.phoneNumber &&
        !isPhoneNumberValidationPassed(formik.values.phoneNumber)
      ) {
        formik.setFieldError(
          'phoneNumber',
          TextConstants.ERROR_TEXT.PHONE_NUMBER
        );
      }
    },
    validationSchema: profileInformationValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  const { resetForm, setTouched } = formik;

  const handleResetForm = () => {
    resetForm();
    setTouched({}, false);
    setRequestError({ isErrorShown: false, errorText: '' });
  };

  useEffect(() => {
    // show buttons only after initial value change
    if (
      formik.values.firstName.trim() !==
        formik.initialValues.firstName.trim() ||
      formik.values.lastName.trim() !== formik.initialValues.lastName.trim() ||
      formik.values.email.trim() !== formik.initialValues.email.trim() ||
      formik.values.phoneNumber !== formik.initialValues.phoneNumber
    ) {
      setInitialValueChange(true);
    } else {
      setInitialValueChange(false);
    }
  }, [formik.values, formik.initialValues]);

  // const handleChangeImage = (e) => {
  //   const reader = new FileReader();
  //   const { files } = e.target;
  //   reader.onload = () => {
  //     setAvatar(reader.result);
  //   };
  //   reader.readAsDataURL(files[0]);
  // };

  useEffect(() => {
    if (
      !formik.values.firstName &&
      !formik.values.lastName &&
      !formik.values.email &&
      !formik.values.phoneNumber
    ) {
      resetForm();
      setTouched({}, false);
      setRequestError({ isErrorShown: false, errorText: '' });
    }
  }, [formik.values, resetForm, setTouched]);

  const handleOnChange = (value, data) => {
    formik.setFieldValue('phoneNumber', value);
    setCountryCode(data.countryCode.toUpperCase());
  };

  const renderInputs = () => (
    <Box py="20px">
      <FormControl isInvalid={formik.errors.firstName !== undefined}>
        <FormLabel
          fontFamily="Inter"
          fontSize="14px"
          lineHeight="20px"
          color="gray.700"
        >
          {
            TextConstants.PROFILE_MANAGMENT.PROFILE_INFORMATION_SECTION
              .FIRST_NAME_TITLE
          }
        </FormLabel>
        <Input
          name="firstName"
          onChange={formik.handleChange}
          value={formik.values.firstName}
          boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
          borderRadius="none"
        />
        <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={formik.errors.lastName !== undefined} pt="20px">
        <FormLabel
          fontFamily="Inter"
          fontSize="14px"
          lineHeight="20px"
          color="gray.700"
        >
          {
            TextConstants.PROFILE_MANAGMENT.PROFILE_INFORMATION_SECTION
              .LAST_NAME_TITLE
          }
        </FormLabel>
        <Input
          name="lastName"
          type="lastName"
          onChange={formik.handleChange}
          value={formik.values.lastName}
          boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
          borderRadius="none"
        />
        <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={formik.errors.email !== undefined} pt="20px">
        <FormLabel
          fontFamily="Inter"
          fontSize="14px"
          lineHeight="20px"
          color="gray.700"
        >
          {
            TextConstants.PROFILE_MANAGMENT.PROFILE_INFORMATION_SECTION
              .EMAIL_TITLE
          }
        </FormLabel>
        <InputGroup>
          <InputLeftElement color="gray.500" mt="3px">
            <SVG id="email" />
          </InputLeftElement>
          <Input
            name="email"
            type="email"
            color="#808080 !important"
            onChange={formik.handleChange}
            value={formik.values.email}
            boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
            borderRadius="none"
            disabled
          />
        </InputGroup>
        <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={formik.errors.phoneNumber !== undefined}
        pt="20px"
      >
        <FormLabel
          htmlFor="phoneNumber"
          fontFamily="Inter"
          fontSize="14px"
          lineHeight="20px"
          color="gray.700"
        >
          {TextConstants.PROFILE_MANAGMENT.PERSONAL_INFO_SECTION.PHONE_NUMBER}
        </FormLabel>
        <ReactPhoneInput
          inputClass={formik.errors.phoneNumber ? 'is-invalid' : ''}
          defaultMask=".. (...) .. .. .."
          onChange={handleOnChange}
          country="us"
          value={`${formik.values.phoneNumber}`}
        />
        <FormErrorMessage>{formik.errors.phoneNumber}</FormErrorMessage>
      </FormControl>

      {/* 
      // TODO temporary disable image input
      <Image
        borderRadius="full"
        boxSize="80px"
        src={String(avatar)}
        alt="avatar-image"
        mt="30px !important"
      />
      <Box
        border="1px solid #EAECF0"
        height="auto"
        w="100%"
        position="relative"
        d="flex"
        flexDirection="column"
        mt="40px !important"
      >
        <Box justifySelf="center" alignSelf="center" pt="20px">
          <IconInCircles icon="upload-cloud" theme="noColor" />
        </Box>

        <Box p="20px" alignSelf="center">
          <Box textAlign="center">
            <Text as="span" textStyle="paragraph" textColor="brand.600">
              {
                TextConstants.PROFILE_MANAGMENT.PROFILE_INFORMATION_SECTION
                  .IMAGE_UPLOAD_TEXT.FIRST_PART
              }
            </Text>{' '}
            <Text as="span" textStyle="paragraph">
              {
                TextConstants.PROFILE_MANAGMENT.PROFILE_INFORMATION_SECTION
                  .IMAGE_UPLOAD_TEXT.SECOND_PART
              }
            </Text>{' '}
            <Text as="span" textStyle="paragraph">
              {
                TextConstants.PROFILE_MANAGMENT.PROFILE_INFORMATION_SECTION
                  .IMAGE_UPLOAD_TEXT.THIRD_PART
              }
            </Text>
          </Box>
        </Box>

        <Input
          type="file"
          onChange={handleChangeImage}
          accept="image/*"
          position="absolute"
          height="100%"
          bottom="0px"
          opacity="0"
          cursor="pointer"
        />
      </Box> */}
    </Box>
  );

  return (
    <Container>
      <Stack
        mt="10px"
        justifyContent="center"
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        {renderInputs()}
        {isInitialValueChanged && (
          <ProfileSectionButtons
            cancelButtonTitle={TextConstants.BUTTON_TEXT.CANCEL}
            saveButtonTitle={TextConstants.BUTTON_TEXT.SAVE_PROFILE_DATA}
            isDisabled={isRequesting}
            resetForm={handleResetForm}
          />
        )}

        {requestError.isErrorShown && (
          <Box pb="30px">
            <Alert status="error">
              <AlertIcon />
              {requestError.errorText}
            </Alert>
          </Box>
        )}

        {isRequestSuccess && (
          <Box pb="30px">
            <Alert
              status="success"
              backgroundColor="var(--chakra-colors-brand-500)"
              color="#fff"
            >
              <AlertIcon color="#fff" />
              {
                TextConstants.PROFILE_MANAGMENT.PROFILE_INFORMATION_SECTION
                  .UPDATE_PROFILE_INFORMATION_SUCCESS
              }
            </Alert>
          </Box>
        )}
      </Stack>
    </Container>
  );
};

export default ProfileInformation;
