import { Modal, ModalCloseButton, ModalContent } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import TextConstants from '../../../constants/TextConstants';
import {
  set360VideoPageRedirected,
  setSceneToOpen,
} from '../../../store/reducers/pannellumReducer';
import VideoHOCWithLogoutTimer from '../../HOC/VideoHOCWithLogoutTimer';
import { checkIfIOSDevice } from '../../../helpers/checkDevicePlatform';

const Container = styled.div`
  ${({ isControlsShown }) =>
    !isControlsShown &&
    `video::-webkit-media-controls-fullscreen-button {
      display: none;
    }
    video::-webkit-media-controls-mute-button {
      display: none;
    }
    video::-webkit-media-controls-volume-slider {
      display:none;
    }`};
`;

const IntroVideoModal = ({
  isModalsOpen,
  srcVideo,
  handleCloseModals,
  isControlsShown,
  pageToRedirect,
}: {
  isModalsOpen: boolean;
  srcVideo: string;
  handleCloseModals: () => void;
  isControlsShown: boolean;
  pageToRedirect: 'foyer-welcome' | 'desert';
}): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  const iPhoneStyles = {
    position: 'absolute',
    top: '30px',
    right: '0',
  };

  const onEnded = () => {
    if (pageToRedirect === 'foyer-welcome') {
      // load foyer-welcome's MainSceneFar
      dispatch(setSceneToOpen('reception'));
    }
    setTimeout(() => {
      handleCloseModals();
      if (window.location.pathname !== `/${TextConstants.PATHNAMES.DP_PAGE}`) {
        // set state before pushing route to 360 video page (otherwise hotspots aren't shown)
        dispatch(set360VideoPageRedirected(true));
        // check if DP page not opened and redirect
        history.push(`/${pageToRedirect}`);
      }
    }, 1200);
  };

  return (
    <Modal
      onClose={() => {
        handleCloseModals();
      }}
      isOpen={isModalsOpen}
      size="full"
      isCentered
      scrollBehavior="inside"
      blockScrollOnMount
      preserveScrollBarGap
      id="intro-video-modal"
    >
      <ModalContent p="0px">
        <ModalCloseButton
          _focus={{ boxShadow: 'none' }}
          zIndex="22"
          size={checkIfIOSDevice() ? 'lg' : 'md'}
          sx={checkIfIOSDevice() ? { ...iPhoneStyles } : {}}
        />
        <Container isControlsShown={isControlsShown}>
          <VideoHOCWithLogoutTimer
            className="intro-video"
            src={srcVideo}
            disablePictureInPicture
            controlsList="noplaybackrate nodownload"
            autoPlay
            controls
            onEnded={onEnded}
          />
        </Container>
      </ModalContent>
    </Modal>
  );
};

export default IntroVideoModal;
