import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
  ModalCloseButton
} from '@chakra-ui/react';
import { SVG } from './../components/icons/icons';
import TextConstants from './../constants/TextConstants';
import { RootState } from './../store/store';
import IconInCircles from './../components/common/IconInCircles/IconInCircles';

const RBTOSModal = ({
  isOpen,
  handleAcceptContinue,
}: {
  isOpen: boolean;
  handleAcceptContinue: () => void;
}): JSX.Element => {
  const connected = useSelector((state: RootState) => state.digitalAssistant.connected);

  const renderModalBody = () => (
    <VStack spacing="none">
      <IconInCircles icon="settings" theme="success" />
      <Text as="h3" textStyle="text-lg" marginTop="4">
        First things first
      </Text>
      <VStack spacing="4" color="gray.500" textAlign="center" marginTop="2">
        <Text textStyle="text-sm">
          Our virtual wellness experience utilizes your device camera and
          microphone to operate. In order to proceed, please turn on access to
          your microphone and camera when we request it.
        </Text>
        <Box textStyle="text-sm">
          If you’re happy to proceed on this basis, please confirm your
          acceptance of our{' '}
          <Text display="inline-block" color="var(--chakra-colors-brand-600)">
            Privacy Policy
          </Text>{' '}
          and{' '}
          <Text display="inline-block" color="var(--chakra-colors-brand-600)">
            End User Agreement
          </Text>{' '}
          by clicking the button below.
        </Box>
      </VStack>
    </VStack>
  );

  const renderModalButton = () => (
    <Button
      gap="10px"
      w="100%"
      borderRadius="0"
      onClick={handleAcceptContinue}
      type="button"
      disabled={connected}
    >
      <SVG id="accept" />
      {TextConstants.MODALS.WELCOME_PAGE_MODAL_BUTTON}
    </Button>
  );

  return (
    <Modal
      isOpen={isOpen}
      isCentered
      onClose={() => {
        // do nothing
      }}
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent borderRadius="0">
      <ModalCloseButton
          _focus={{ boxShadow: 'none' }}
          zIndex="22"
          size='lg'        
        />
        <ModalBody>{renderModalBody()}</ModalBody>

        <ModalFooter>{renderModalButton()}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RBTOSModal;
