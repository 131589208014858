import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { PannellumVideo, Pannellum } from '@erosargsyan/pannellum-react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@chakra-ui/react';
import ConfirmationModal from './ConfirmationModal';
import PermissionDeniedModal from './PermissionDeniedModal';
import FatalErrorModal from './FatalErrorModal';
import TutorialStepsModals from './TutorialStepsModals';
import TutorialVideoComponent from './TutorialVideoComponent';
import SceneConfigs from '../../helpers/SceneConfigs';
import { RootState } from '../../store/store';
import hotspotImageOne from '../../assets/icons/hotspot1.png';
import hotspotImageTwo from '../../assets/icons/rare-beauty.png';
import { WhereToLeadType } from '../AuthenticationFlow/welcome/WelcomePage';
import {
  allowRedirection,
  acceptHappyHatsTOS,
  acceptMediaPermissions,
  openAssistantChat
} from '../../store/reducers/digitalAssistantReducer';
import LoadingModal from './LoadingModal';
import { VideoHotspotType } from '../../global-components/GlobalTypes';
import { setSceneToOpen } from '../../store/reducers/pannellumReducer';
import MutePanoramicVideoButton from '../../components/MutePanoramicVideoButton';
import { setDialogType } from '../../store/reducers/commerceReducer';
import TextConstants from '../../constants/TextConstants';
import { setVirtualMirrorVisibility } from '../../store/reducers/vmReducer';
import {
  checkIfIOSDevice,
  checkIfSafariBrowser,
} from '../../helpers/checkDevicePlatform';
import RBTOSModal from '../RBTOSModal';

declare const window;

const Container = styled.div`
  height: 100%;

  .pnlm-tooltip span {
    width: 100px;
  }
  .pnlm-controls-container {
    top: 10px;
  }
  .pnlm-load-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #344054b2;
    height: 100%;
    width: 100%;
    border-radius: 3px;
    font-size: 20px;
    color: #fff;
    top: 0;
    left: 0;
    margin: 0;
  }
  .pnlm-about-msg {
    display: none !important;
  }
  .pnlm-load-box p {
    margin-top: 40vh;
    font-family: Ogg;
    font-size: 22px;
  }

  .pnlm-lbar {
    width: 30%;
    height: 1.5%;
  }
  .pnlm-lmsg {
    display: none;
  }
  .hotspot {
    display: none;
  }

  .pnlm-hotspot-base {
    display: ${({ isVideoLoaded }) => (isVideoLoaded ? 'flex' : 'none')};
  }

  .navigation-hotspot-custom-style {
    background-image: url(${hotspotImageOne});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 55px;
    width: 90px;
    border-radius: 100px;
    :hover {
      background-color: transparent;
    }
  }

  .navigation-hotspot-far-style {
    background-image: url(${hotspotImageOne});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 35px;
    width: 65px;
    border-radius: 100px;
    :hover {
      background-color: transparent;
    }
  }

  .DPHotspotCustomStyle {
    background-image: url(${hotspotImageTwo});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    height: 50px;
    width: 50px;
    border-radius: 100px;
  }

  .tutorialHotspotCustomStyle {
    background-image: url(${hotspotImageTwo});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    height: 50px;
    width: 50px;
    border-radius: 100px;
  }

  @media screen and (max-width: ${TextConstants.SIZES.IPAD_WIDTH}px) {
    .navigation-hotspot-custom-style {
      height: 40px;
      width: 65px;
    }
    .DPHotspotCustomStyle {
      height: 45px;
      width: 45px;
    }
    .tutorialHotspotCustomStyle {
      height: 45px;
      width: 45px;
    }
  }
  @media screen and (max-width: ${TextConstants.SIZES.IPHONE_XR_WIDTH}px) {
    .navigation-hotspot-custom-style {
      height: 30px;
      width: 50px;
    }
    .DPHotspotCustomStyle {
      height: 40px;
      width: 40px;
    }
    .tutorialHotspotCustomStyle {
      height: 40px;
      width: 40px;
    }
  }

  // make progress bar/text larger on mobile
  @media only screen and (max-width: 900px) {
    .pnlm-load-box p {
      font-size: 30px;
    }
    .pnlm-lbar {
      width: 90%;
      height: 2%;
    }
  }
`;

type ModalOpenType = {
  isHappyHatsTOSModalOpen: boolean;
  isMediaTOSModalOpen: boolean;
};

const FoyerWelcome = (): JSX.Element => {
  const [isMounted, setMounted] = useState(false);
  const videoRef = useRef<PannellumVideo | null>(null);
  // ititially muted for IOS and Safari browser  only
  const isMuteButtonClicked = useRef<boolean>(
    checkIfIOSDevice() || checkIfSafariBrowser()
  );
  const [isSmallerThanIPhoneXR] = useMediaQuery(
    `(max-width: ${TextConstants.SIZES.IPHONE_XR_WIDTH}px)`
  );
  const [isModalsOpen, setIsModalsOpen] = useState<ModalOpenType>({
    isHappyHatsTOSModalOpen: false,
    isMediaTOSModalOpen: false,
  });
  const [isTutorialVideoComponentOpen, setIsTutorialVideoComponentOpen] =
    useState(false);
  const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  // prevent from redirecting to DP page
  const isRedirectionAllowed = useSelector(
    (state: RootState) => state.digitalAssistant.isRedirectionAllowed
  );
  const error = useSelector((state: RootState) => state.digitalAssistant.error);
  const connected = useSelector((state: RootState) => state.digitalAssistant.connected);
  const areMediaPermissionsAccepted = useSelector(
    (state: RootState) => state.digitalAssistant.areMediaPermissionsAccepted
  );
  const sceneToOpen = useSelector(
    (state: RootState) => state.pannellumReducerState.sceneToOpen
  );
  const is360VideoPageRedirected = useSelector(
    (state: RootState) => state.pannellumReducerState.is360VideoPageRedirected
  );
  const isHappyHatsTOSAccepted = useSelector(
    (state: RootState) => state.digitalAssistant.isHappyHatsTOSAccepted
  );

  const [isDPLoadingModalOpen, setIsDPLoadingModalOpen] = useState(false);

  const dialogType = useSelector(
    (state: RootState) => state.commerce.dialogType
  );
  const config = () => {
    if (sceneToOpen === 'reception' || sceneToOpen === 'spa') {
      return SceneConfigs.MainSceneFar;
    }

    return SceneConfigs.LobbySceneEnd;
  };
  const [videoDataState, setVideoDataState] = useState(config());

  const openDP = () => {
    if (
      areMediaPermissionsAccepted === 'agree' &&
      isHappyHatsTOSAccepted === 'agree'
    ) {
      dispatch(openAssistantChat({}));
      setIsDPLoadingModalOpen(true);
      dispatch(allowRedirection(true));
    }
  };

  const openTutorialVideoComponent = () => {
    setIsTutorialVideoComponentOpen(true);
  };

  const closeTutorialVideoComponent = () => {
    setIsTutorialVideoComponentOpen(false);
  };

  const openFirstModal = () => {
    setIsModalsOpen(() => ({
      isHappyHatsTOSModalOpen: true,
      isMediaTOSModalOpen: false,
    }));
  };

  const openThirdModal = () => {
    setIsModalsOpen(() => ({
      isHappyHatsTOSModalOpen: false,
      isMediaTOSModalOpen: true,
    }));
  };

  const closeModals = () => {
    setIsTutorialVideoComponentOpen(false);
    setIsModalsOpen(() => ({
      isHappyHatsTOSModalOpen: false,
      isMediaTOSModalOpen: false,
    }));
  };

  const checkAllPermissionsAndRunCallback = (callback: () => void) => {
    if (isHappyHatsTOSAccepted === 'disagree') {
      openFirstModal();
      return;
    } else if (areMediaPermissionsAccepted === 'disagree') {
      openThirdModal();
      return;
    } else {
      callback();
    }
  };

  const handleFirstModalAcceptClick = () => {
    dispatch(acceptHappyHatsTOS('agree'));
    localStorage.setItem('whereToLead', WhereToLeadType.desert);

    if (areMediaPermissionsAccepted === 'disagree') {
      openThirdModal();
    } else {
      closeModals();
    }
  };

  const handleThirdModalAcceptClick = () => {
    closeModals();
    dispatch(allowRedirection(true));
    dispatch(acceptMediaPermissions('agree'));
  };

  useEffect(() => {
    localStorage.setItem('whereToLead', WhereToLeadType.city);

    // otherwise when redirecting from Intro video modal, page gets white
    setMounted(true);

    // add playsinline attribute to 360 video
    const videos = document.getElementsByTagName('video');
    if (videos && videos.length) {
      videos[0].onloadeddata = () => {
        setIsVideoLoaded(true);
      };

      videos[0].setAttribute('playsinline', '');
    }
  }, []);

  useEffect(() => {
    // check if TOS accepted create scene
    if (connected && isRedirectionAllowed) {
      //  TODO remove setTimeout
      setTimeout(() => {
        history.push(`/${TextConstants.PATHNAMES.DP_PAGE}`);
        if (dialogType === TextConstants.DIALOG_TYPE.ECOMMERCE) {
          if (!window.YMK?.isLoaded()) {
            window.YMK?.open();
          }

          dispatch(setVirtualMirrorVisibility(true));
        } else if (dialogType === TextConstants.DIALOG_TYPE.WELLNESS) {
          dispatch(setVirtualMirrorVisibility(false));
        }
      }, 1500);
    }
  }, [connected, isRedirectionAllowed, history]);

  useEffect(() => {
    // open specific modal if one of the permissions is not accepted
    checkAllPermissionsAndRunCallback(() => {
      // do nothing
    });
  }, []);

  return (
    <Container isVideoLoaded={isVideoLoaded}>
      <TutorialStepsModals
        isTutorialModalOpen={isTutorialModalOpen}
        closeTurorialModal={() => setIsTutorialModalOpen(false)}
      />
      <PannellumVideo
        // this package has an issue on mobile devices (muted={false} isn't working)

        // ititially muted for IOS and Safari browser  only
        muted={checkIfIOSDevice() || checkIfSafariBrowser()}
        id="city-panellum-video"
        ref={videoRef}
        video={videoDataState.videoSource}
        loop
        autoplay
        width="100%"
        height="100%"
        pitch={videoDataState.pitch}
        yaw={videoDataState.yaw}
        hfov={125}
        mouseZoom={false}
        controls={false}
        playsinline
        // maximum zoom in percent
        maxHfov={
          isSmallerThanIPhoneXR
            ? TextConstants.PANORAMIC_VIDEOS.MOBILE_MAX_ZOOM
            : TextConstants.PANORAMIC_VIDEOS.BIG_SCREEN_MAX_ZOOM
        }
      >
        {
          // check if Panellum video is loaded (otherwise causes error when reloading page)
          //check if we have redirected (history.push) to 360 video page (otherwise Panellum.Hotspots aren't shown)
          (videoRef?.current && isMounted) ||
          (is360VideoPageRedirected && isMounted) ? (
            videoDataState?.hotSpots?.map((hotspot: VideoHotspotType) => (
              <Pannellum.Hotspot
                key={JSON.stringify(hotspot)}
                type={hotspot.type}
                pitch={hotspot.pitch}
                yaw={hotspot.yaw}
                handleClick={() => {
                  if (hotspot.name === 'openPersonHotspot') {
                    closeTutorialVideoComponent();
                    openDP();
                    dispatch(setDialogType(hotspot.dialogType));
                    if (hotspot.sceneToOpen) {
                      dispatch(setSceneToOpen(hotspot.sceneToOpen));
                    }
                  } else if (hotspot.name === 'tutorialHotSpot') {
                    openTutorialVideoComponent();
                  } else {
                    setVideoDataState(SceneConfigs[hotspot.nextStep]);
                    setIsVideoLoaded(false);
                  }
                }}
                cssClass={hotspot.cssClass}
                name={hotspot.name}
              />
            ))
          ) : (
            <Pannellum.Hotspot />
          )
        }
      </PannellumVideo>
      <MutePanoramicVideoButton
        videoRef={videoRef}
        isMuteButtonClicked={isMuteButtonClicked}
        isModalsOpen={isModalsOpen}
        parentComponentType="default"
      />
      {isTutorialVideoComponentOpen && (
        <TutorialVideoComponent
          closeTutorialVideoComponent={closeTutorialVideoComponent}
        />
      )}

      <RBTOSModal
        isOpen={isModalsOpen.isHappyHatsTOSModalOpen}
        handleAcceptContinue={handleFirstModalAcceptClick}
      />

      {isDPLoadingModalOpen && (
        <LoadingModal
          modalToOpen="DP"
          isModalOpen={isDPLoadingModalOpen}
          closeModal={() => {
            // do nothing
          }}
        />
      )}
      {!error ? (
        <ConfirmationModal
          isOpen={isModalsOpen.isMediaTOSModalOpen}
          isCloseButtonDisabled
          onClose={() => {
            // prevent from closing
          }}
          handleConfirm={handleThirdModalAcceptClick}
        />
      ) : (
        <>
          <PermissionDeniedModal /> : <FatalErrorModal />
        </>
      )}
    </Container>
  );
};
export default FoyerWelcome;
