import { NoteValue } from '../interfaces/Notes';
import { Point } from '../entities/Rectangle';

export enum NoteWidthToMirrorSize {
  HIGH = 240,
}

export enum NoteHeightToMirrorSize {
  HIGH = 168,
}

export enum FontSizesEnum {
  HIGH = 14,
  SMALL = 9,
  DEFAULT = 12,
}

export const NOTE_CHARACTER_LIMIT = 100;

export const NOTE_MOVEMENT_STEP = 30;

export const NOTE_CARD_CLASSNAME = 'note-card';

export const NOTE_CONTAINER_ACTIVE_CLASSNAME = 'sticky-notes-container-active';

export const NOTE_CONTAINER_DEFAULT_CLASSNAME = 'sticky-notes-container';

export const NOTE_DEFAULT_VALUE: NoteValue[] = [
  {
    type: 'paragraph',
    children: [
      {
        text: '',
      },
    ],
  },
];

export const NOTE_DEFAULT_STACK_LEVEL = 1;

export const NOTE_DEFAULT_POSITION: Point = {
  x: 0,
  y: 0,
};

export const NOTE_MAX_AMOUNT = 50;

export const NOTE_MAX_BREAKS_AMOUNT = 2;

export enum IconButtonTypeEnum {
  BOLD = 'bold',
  UNDERLINE = 'underline',
  ITALIC = 'italic',
  INCREASED = 'increased',
  DECREASED = 'decreased',
  DELETE = 'delete',
  ADD = 'add',
}

export enum MovementDirectionEnum {
  HORIZONTAL = 'x',
  VERTICAL = 'y',
}

export enum TextFormatEnum {
  BOLD = 'bold',
  UNDERLINE = 'underline',
  ITALIC = 'italic',
  INCREASED = 'increased',
  DECREASED = 'decreased',
}

export const RARE_REMINDER_LINK = 'https://twitter.com/search?q=%23rareminder%20OR%20%23rareminders&src=typed_query&f=live';
