import { useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { SVG } from '../../../components/icons/icons';
import TextConstants from '../../../constants/TextConstants';
import {
  CustomizeMakeupProductType,
  CustomizeMakeupVariantType,
} from '../../../global-components/GlobalTypes';
import PatternSlider from './PatternSlider';
import ProductShadeVariantsSlider from './ProductShadeVariantsSlider';
import ProductSizeVariantsSlider from './ProductSizeVariantsSlider';
import {
  setActiveProductVariant,
  setSelectedProductVariant,
} from '../../../store/reducers/commerceReducer';

declare const window;

const SimpleProductsSlider = ({
  selectedProductData,
  prevStep,
}: {
  selectedProductData: CustomizeMakeupProductType;
  prevStep: () => void;
}): JSX.Element => {
  const [selectedPattern, setSelectedPattern] = useState(null);
  const dispatch = useDispatch<AppDispatch>();
  const [shadeProductVariants, setShadeProductVariants] = useState([]);
  const [sizeProductVariants, setSizeProductVariants] = useState([]);
  const [selectedShadeProductVariant, setSelectedShadeProductVariant] =
    useState<CustomizeMakeupVariantType>(null);
  const [selectedSizeProductVariant, setSelectedSizeProductVariant] =
    useState(null);
  // save selected product shade index
  const [shadeVariantIndex, setShadeVariantIndex] = useState<number>(0);
  const activeProductVariant = useSelector(
    (state: RootState) => state.commerce.activeProductVariant
  );
  const setSelectedPatternFunction = (pattern) => {
    setSelectedPattern(pattern);
  };

  const setShadeVariantIndexOnItemClick = (index: number) => {
    setShadeVariantIndex(index);
  };

  const handleClickShadeProductVariant = (shadeProductVariant) => {
    dispatch(
      setActiveProductVariant({
        productId: selectedProductData.id,
        quantity: 1,
        name: selectedProductData.name,
        productVariant: shadeProductVariant,
      })
    );
    if (selectedProductData?.patternsData?.patterns?.length) {
      setSelectedShadeProductVariant(shadeProductVariant);
    } else {
      dispatch(
        setSelectedProductVariant({
          productId: selectedProductData.id,
          quantity: 1,
          name: selectedProductData.name,
          productVariant: shadeProductVariant,
        })
      );
      // when clicking Try it On shade/pattern isn't applied if Live Camera isn't open
      setTimeout(
        () => {
          window.YMK && window.YMK.applyMakeupBySku(
            shadeProductVariant.sku,
            selectedProductData.name
          );
        },
        // apply immediately if clicking in CustomizemMakeup
        // apply after 4 seconds when clicking Try it On
        window.YMK && window.YMK?.isLoaded() ? 0 : 4000
      );
    }
  };

  const handleClickSizeProductVariant = (sizeProductVariant) => {
    if (sizeProductVariant) {
      const variant = {
        productId: selectedProductData.id,
        quantity: 1,
        name: selectedProductData.name,
        productVariant: sizeProductVariant,
      };
      dispatch(setActiveProductVariant(variant));
    }
    setSelectedSizeProductVariant(sizeProductVariant);
  };

  useEffect(() => {
    if (selectedProductData?.productVariants?.length === 1) {
      const variant = {
        productId: selectedProductData.id,
        quantity: 1,
        name: selectedProductData.name,
        productVariant: selectedProductData.productVariants[0],
      };
      dispatch(setActiveProductVariant(variant));
    }
  }, []);

  useEffect(() => {
    if (selectedProductData?.productVariants?.length) {
      let shadeData = [];
      let sizeData = [];
      for (let i = 0; i < selectedProductData.productVariants.length; i++) {
        const productVariant = selectedProductData.productVariants[i];
        if (productVariant.optionValues && productVariant.optionValues.length) {
          // check if product variant has optionValues
          for (let j = 0; j < productVariant.optionValues.length; j++) {
            const optionValue = productVariant.optionValues[j];
            if (optionValue.option.name === 'Shade') {
              const repeatingVariant =
                shadeData.filter((el) => el.id === productVariant.id) || []; // check if product variant is already in shade data
              if (!repeatingVariant.length) {
                shadeData.push(productVariant); // add product variant to shadeProductVariants if it's type is Shade
              }
            } else if (optionValue.option.name === 'Size') {
              const repeatingVariant =
                sizeData.filter((el) => el.id === productVariant.id) || []; // check if product variant is already in size data
              if (!repeatingVariant.length) {
                sizeData.push(productVariant); // add product variant to sizeProductVariants if it's type is Size
              }
            }
          }
        }
      }

      setShadeProductVariants(shadeData);
      setSizeProductVariants(sizeData);
      if (shadeData.length) {
        setSelectedShadeProductVariant(shadeData[0]);
      }
      if (sizeData.length) {
        setSelectedSizeProductVariant(sizeData[0]);
      }
    }
  }, [selectedProductData]);

  useEffect(() => {
    //this case is applied when shade and pattern sliders are shown in one component

    if (selectedPattern && selectedShadeProductVariant) {
      // set red-active border for product shade by its index when selecting pattern
      const element = document.querySelector(
        `[data-shade-slider=index${shadeVariantIndex}]`
      );

      element?.classList.add('isActive');

      const variant = {
        productId: selectedProductData.id,
        quantity: 1,
        name: selectedProductData.name,
        productVariant: selectedShadeProductVariant,
      };
      dispatch(setActiveProductVariant(variant));
      dispatch(setSelectedProductVariant(variant));
      // when clicking Try it On shade/pattern isn't applied if Live Camera isn't open
      setTimeout(
        () => {
          window.YMK && window.YMK.applyMakeupBySku(
            selectedShadeProductVariant.sku,
            selectedPattern.name
          );
        },
        // apply immediately if clicking in CustomizemMakeup
        // apply after 4 seconds when clicking Try it On
        window.YMK && window.YMK?.isLoaded() ? 0 : 4000
      );
    }
  }, [selectedPattern, selectedShadeProductVariant]);

  useEffect(() => {
    //this case is applied when size and pattern sliders are shown in one component

    if (selectedPattern && activeProductVariant) {
      // when clicking Try it On shade/pattern isn't applied if Live Camera isn't open
      setTimeout(
        () => {
          window.YMK && window.YMK.applyMakeupBySku(
            activeProductVariant.productVariant.sku,
            selectedPattern.name
          );
        },
        // apply immediately if clicking in CustomizemMakeup
        // apply after 4 seconds when clicking Try it On
        window.YMK && window.YMK?.isLoaded() ? 0 : 4000
      );
    }
  }, [selectedPattern, activeProductVariant]);

  return (
    <>
      <Box display="flex">
        <Box
          cursor="pointer"
          alignItems="center"
          display="flex"
          mt="-5px"
          gap="6px"
          w="33px"
          h="33px"
          bg="#FFF6ED"
          borderRadius="50%"
          justifyContent="center"
          onClick={prevStep}
        >
          <SVG id="arrow-back" />
        </Box>
        <Box justifyContent="center" display="flex" width="100%" px="20px">
          <Text
            color="var(--chakra-colors-brand-900)"
            alignSelf="center"
            fontFamily="inter"
            fontStyle="normal"
            fontWeight="400"
            fontSize={{ base: '12px', md: '14px' }}
          >
            {selectedProductData?.name}
            {TextConstants.COMMERCE.DOLLAR_TEXT}
            {selectedShadeProductVariant?.price ||
              selectedSizeProductVariant?.price ||
              selectedProductData?.productVariants[0]?.price}
          </Text>
        </Box>
      </Box>
      {shadeProductVariants?.length &&
      selectedShadeProductVariant?.swatchImage ? (
        <ProductShadeVariantsSlider
          handleClickShadeProductVariant={handleClickShadeProductVariant}
          shadeProductVariants={shadeProductVariants}
          isShadeFinderAvailable={selectedProductData?.isShadeFinderAvailable}
          setShadeVariantIndexOnItemClick={setShadeVariantIndexOnItemClick}
        />
      ) : (
        ''
      )}
      {selectedProductData?.patternsData?.patterns?.length ? (
        <PatternSlider
          selectedProductData={selectedProductData}
          setSelectedPatternFunction={setSelectedPatternFunction}
        />
      ) : (
        ''
      )}
      {sizeProductVariants.length ? (
        <ProductSizeVariantsSlider
          sizeProductVariants={sizeProductVariants}
          handleClickSizeProductVariant={handleClickSizeProductVariant}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default SimpleProductsSlider;
