import {
  CustomizeMakeupProductType,
  CustomizeMakeupVariantType,
} from '../../global-components/GlobalTypes';

const findProductVariantByID = (
  productID: number,
  productName: string,
  productsData: CustomizeMakeupProductType[]
): CustomizeMakeupVariantType => {
  let result = null;
  if (!productID || !productName) {
    return null;
  }
  for (let i = 0; i < productsData.length; i++) {
    if (productsData[i].name === productName) {
      const product = productsData[i];

      for (let j = 0; j < product.productVariants.length; j++) {
        if (product.productVariants[j].id === productID) {
          const productVariant = product.productVariants[j];

          result = productVariant;

          break;
        }
      }
    }
  }

  return result;
};

export default findProductVariantByID;
