import { Box, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import PatternSlider from './PatternSlider';
import { SVG } from '../../../components/icons/icons';
import TextConstants from '../../../constants/TextConstants';
import { CustomizeMakeupProductType } from '../../../global-components/GlobalTypes';
import ColorSlider from './ColorSlider';
import regexp from '../../../constants/Regexp';
import removeActiveClassHelper from '../../../helpers/products/removeActiveClassHelper';
import {
  setActiveProductVariant,
  setSelectedProductVariant,
} from '../../../store/reducers/commerceReducer';

declare const window;

const ProductsSettingsContainer = ({
  selectedProductData,
  prevStep,
}: {
  selectedProductData: CustomizeMakeupProductType;
  prevStep: () => void;
}): JSX.Element => {
  const [selectedColor, setSelectedColor] = useState(null);
  const [changeIndex, setChangeIndex] = useState(0);
  const [updateColorSlider, setUpdateColorSlider] = useState(true);
  const [selectedPattern, setSelectedPattern] = useState(null);
  const [activeProductVariant, setSActiveProductVariant] = useState(null);
  const [skus, setSkus] = useState([]);
  const dispatch = useDispatch<AppDispatch>();

  const setSelectedPatternFunction = (pattern) => {
    setChangeIndex(0);
    setSelectedPattern(pattern);
  };

  const handleResetButton = () => {
    setChangeIndex(0);
  };

  const setSelectedColorFunction = (color) => {
    setUpdateColorSlider((updateColorSlider) => !updateColorSlider);
    setSelectedColor(color);
  };

  useEffect(() => {
    if (selectedProductData?.productVariants?.length) {
      const variant = {
        productId: selectedProductData.id,
        quantity: 1,
        name: selectedProductData.name,
        productVariant: selectedProductData.productVariants[0],
      };
      dispatch(setActiveProductVariant(variant));
      setSActiveProductVariant(variant);
    }
  }, []);

  useEffect(() => {
    if (activeProductVariant) {
      dispatch(setActiveProductVariant(activeProductVariant));
      dispatch(setSelectedProductVariant(activeProductVariant));
    }
    if (selectedProductData.isMulticolorPerSku) {
      if (
        selectedPattern &&
        regexp.productPatternName.test(selectedPattern.name)
      ) {
        const count = selectedPattern.name.substring(
          0,
          selectedPattern.name.indexOf(' ')
        );
        let skus = [];
        const removingElements =
          document.querySelectorAll(`[data-color-slider]`);
        removeActiveClassHelper(removingElements);
        for (let i = 0; i < count; i++) {
          const element = document.querySelector(
            `[data-color-slider=${selectedProductData.patternsData.colors[i].skuId}]`
          );
          element.classList.add('isActive');
          skus = [
            ...skus,
            {
              itemGuid: selectedProductData.patternsData.colors[i].skuId,
              colorIndex: 0,
              colorIntensity:
                selectedPattern.colorIntensities[
                  selectedProductData.patternsData.colors[i].skuId
                ],
            },
          ];
        }
        setSkus(skus);
        window.YMK && window.YMK.applyMakeupByPattern(selectedPattern.name, skus);
      }
    } else if (
      selectedPattern &&
      regexp.productPatternName.test(selectedPattern.name) &&
      selectedProductData.productVariants.length
    ) {
      window.YMK && window.YMK.applyMakeupByPattern(selectedPattern.name, [
        {
          itemGuid: selectedProductData.productVariants[0].sku,
          colorIndex: 0,
          colorIntensity:
            selectedPattern.colorIntensities[
              selectedProductData.productVariants[0].sku
            ],
        },
      ]);
    } else if (selectedPattern && selectedProductData.productVariants.length) {
      window.YMK && window.YMK.applyMakeupBySku(
        selectedProductData.productVariants[0].sku,
        selectedPattern.name
      );
    }
  }, [selectedPattern]);

  useEffect(() => {
    if (skus.length && selectedColor && selectedPattern) {
      let newSkus = skus;
      newSkus.splice(changeIndex, 1, {
        itemGuid: selectedColor.skuId,
        colorIndex: 0,
        colorIntensity: selectedPattern.colorIntensities[selectedColor.skuId],
      });
      const removingElements = document.querySelectorAll(`[data-color-slider]`);
      removeActiveClassHelper(removingElements);
      for (let i = 0; i < newSkus.length; i++) {
        const item = newSkus[i];
        const element = document.querySelector(
          `[data-color-slider=${item.itemGuid}]`
        );
        element?.classList?.add('isActive');
      }
      if (changeIndex < newSkus.length - 1) {
        setChangeIndex(changeIndex + 1);
      } else {
        setChangeIndex(0);
      }
      setSkus(newSkus);
      window.YMK && window.YMK.applyMakeupByPattern(selectedPattern.name, newSkus);
    }
  }, [updateColorSlider, selectedColor]);

  return (
    <>
      <Box display="flex">
        <Box
          cursor="pointer"
          alignItems="center"
          display="flex"
          mt="-5px"
          gap="6px"
          w="33px"
          h="33px"
          bg="#FFF6ED"
          borderRadius="50%"
          justifyContent="center"
          onClick={prevStep}
        >
          <SVG id="arrow-back" />
        </Box>
        <Box justifyContent="center" display="flex" width="100%" px="20px">
          <Text
            color="var(--chakra-colors-brand-900)"
            alignSelf="center"
            fontFamily="inter"
            fontStyle="normal"
            fontWeight="400"
            fontSize={{ base: '12px', md: '14px' }}
          >
            {selectedProductData?.name} {TextConstants.COMMERCE.DOLLAR_TEXT}
            {selectedProductData?.productVariants?.length &&
              selectedProductData?.productVariants[0]?.price}
          </Text>
        </Box>
      </Box>

      {selectedProductData?.isMulticolorPerSku && (
        <ColorSlider
          selectedProductData={selectedProductData}
          setSelectedColorFunction={setSelectedColorFunction}
          handleResetButton={handleResetButton}
        />
      )}

      {selectedProductData?.patternsData?.patterns?.length && (
        <PatternSlider
          selectedProductData={selectedProductData}
          setSelectedPatternFunction={setSelectedPatternFunction}
        />
      )}
    </>
  );
};

export default ProductsSettingsContainer;
