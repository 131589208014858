/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Button, Center, Image, Text } from '@chakra-ui/react';
import {
  setActiveProductVariant,
  setIsHeaderModalsShown,
} from '../../../store/reducers/commerceReducer';
import { AppDispatch, RootState } from '../../../store/store';
import HHIcon from '../../common/HHIcon/HHIcon';
import TextConstants from '../../../constants/TextConstants';
import logo from '../../../assets/icons/rare-beauty.png';
import getRandomProducts from '../../../helpers/products/getRandomProducts';
import useAddProductToBag from '../../hooks/useAddProductToBag';
import useOpenCustomizeMakeup from '../../../helpers/products/openCustomizeMakeup';

declare const window;

const SingleProductRecommendation = ({
  isCardInTranscript,
}: {
  isCardInTranscript: boolean;
}): JSX.Element => {
  const ymkModuleLoadedListener = useRef(null);
  const dispatch = useDispatch<AppDispatch>();
  const [isAddItemButtonClicked, setIsAddItemButtonClicked] = useState(false);
  const [product, setProduct] = useState(null);
  const categorizedProductsData = useSelector(
    (state: RootState) => state.commerce.categorizedProductsData
  );

  useEffect(() => {
    // save product as active to use in future AddToBag functionality
    if (product) {
      dispatch(
        setActiveProductVariant({
          productId: product && product.id,
          quantity: 1,
          name: product && product.name,
          productVariant: product && product.productVariants[0],
        })
      );
    }
  }, [product, dispatch]);

  const openProductinCustomizeMakeup = useOpenCustomizeMakeup(
    'product',
    product
  );

  const addItemToBag = useAddProductToBag([
    {
      productId: product && product.id,
      quantity: 1,
      name: product && product.name,
      productVariant: product && product.productVariants[0],
    },
  ]);

  useEffect(() => {
    ymkModuleLoadedListener.current = window.YMK?.addEventListener(
      'cameraOpened',
      () => {
        // auto-opens product in customize makeup and applies shade
        openProductinCustomizeMakeup();
      }
    );
  }, []);

  useEffect(() => {
    // remove ymk module loaded listener when flow moves forward
    if (isCardInTranscript && ymkModuleLoadedListener.current) {
      window.YMK?.removeEventListener(ymkModuleLoadedListener.current);
    }
  }, [isCardInTranscript]);

  const handleTryButtonClick = useCallback(() => {
    if (!window.YMK?.isLoaded()) {
      window.YMK?.open([true]);
    }

    openProductinCustomizeMakeup();
  }, [openProductinCustomizeMakeup]);

  useEffect(() => {
    // auto-opens product in customize makeup and applies shade
    if (product && !isCardInTranscript) {
      handleTryButtonClick();
    }
  }, [product, isCardInTranscript, handleTryButtonClick]);

  useEffect(() => {
    // reset checkmark after 3 seconds
    if (isAddItemButtonClicked) {
      const timeout = setTimeout(() => {
        setIsAddItemButtonClicked(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
    return null;
  }, [isAddItemButtonClicked]);

  useEffect(() => {
    const randomProduct = getRandomProducts('single', categorizedProductsData);
    setProduct(randomProduct[0]);
  }, [categorizedProductsData]);

  useEffect(() => {
    // close drawers when this card is opened
    dispatch(
      setIsHeaderModalsShown({
        myBagDrawer: false,
        journalDrawer: false,
        profileDrawer: false,
      })
    );
  }, [dispatch]);

  const renderCardContent = () => (
    <Box className="card-content" pt="0.5rem">
      <Text as="h3" fontFamily="Ogg" fontSize="16px" fontWeight="400">
        {product?.name}
      </Text>
      <Text
        textStyle="text-sm"
        className="card-price"
        paddingTop="0.5rem"
        fontWeight="500"
      >
        ${product?.productVariants ? product?.productVariants[0]?.price : null}
      </Text>
    </Box>
  );

  const renderCardImage = () => (
    <Image
      src={
        product?.productVariants?.length
          ? product?.productVariants[0]?.image?.staticStorageUrl
          : logo
      }
      alt={product?.name}
      objectFit="fill"
      mx="auto"
      w="100%"
      h="20vh"
    />
  );

  const renderMobileButtons = () => (
    <Box className="card-buttons" display="flex" gap="10px">
      <Button
        className="circle-button"
        variant="circle-icon"
        size="lg"
        onClick={handleTryButtonClick}
        border="1px solid var(--chakra-colors-brand-500)"
        background="transparent !important"
        _hover={{ background: 'var(--chakra-colors-brand-100)  !important' }}
      >
        <HHIcon icon="vm-mobile-mirror" />
      </Button>
      <Button
        variant="circle-icon"
        size="lg"
        bg={isAddItemButtonClicked ? 'brand.700' : 'brand.600'}
        onClick={() => {
          addItemToBag();
          setIsAddItemButtonClicked(true);
        }}
      >
        <HHIcon icon={isAddItemButtonClicked ? 'check' : 'shopping-bag'} />
      </Button>
    </Box>
  );

  const renderDesktopButtons = () => (
    <>
      <Button
        variant="no-radius"
        onClick={handleTryButtonClick}
        py="0"
        px="15px"
        height="auto"
      >
        {TextConstants.COMMERCE.TRY_IT_BUTTON}
      </Button>

      <Button
        variant="circle-icon"
        size="lg"
        border="1px solid var(--chakra-colors-brand-500)"
        bg={isAddItemButtonClicked ? 'brand.700' : 'transparent'}
        onClick={() => {
          addItemToBag();
          setIsAddItemButtonClicked(true);
        }}
        _hover={{ background: 'var(--chakra-colors-brand-100)  !important' }}
      >
        <HHIcon
          icon={isAddItemButtonClicked ? 'check' : 'shopping-bag-brand-color'}
        />
      </Button>
    </>
  );

  return (
    <Center
      as="section"
      w="100%"
      h="100%"
      justifyContent="start"
      marginLeft="var(--chakra-sizes-8)"
    >
      <Box
        maxW="200px"
        bg="white"
        p="15px"
        position={{ xl: 'unset', base: 'relative' }}
        borderRadius="0.5rem"
      >
        {renderCardImage()}
        {renderCardContent()}
        <Center>
          <Box
            display={{ xl: 'flex', base: 'none' }}
            justifyContent="space-between"
            mt="15px"
            w="100%"
          >
            {renderDesktopButtons()}
          </Box>
          <Box
            display={{ base: 'block', xl: 'none' }}
            position="absolute"
            top="12px"
            right="12px"
          >
            {renderMobileButtons()}
          </Box>
        </Center>
      </Box>
    </Center>
  );
};

export default SingleProductRecommendation;
