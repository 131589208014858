import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../store/store';
import CustomizeMakeupAccordion from './CustomizeMakeupAccordion';
import VirtualMirrorButtonsPopover from './VirtualMirrorButtonsPopover';
import StickyNotesContainer from '../StickyNotes/StickyNotesContainer';
import calculateVMDimensions from '../../helpers/calculateVMDimensions';
import {
  getProductAllData,
  getProductsCategories,
} from '../../global-components/RequestFactory';
import changeProductsDataFormat from '../../helpers/products/changeProductsDataFormat';
import {
  setCategorizedProductsData,
  setUncategorizedProductsData,
} from '../../store/reducers/commerceReducer';
import TextConstants from '../../constants/TextConstants';

declare const window;

interface PerfectCorpBounds {
  width: number;
  height: number;
}

const Container = styled.div`
  height: 100%;
  position: relative;

  // fixes ShadeFinder initialization broken issue
  background-image: url('https://plugins-media.makeupar.com/webconsultation/images/layout1-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: ${({ mirrorWidth }) => mirrorWidth}px;

  // not show VM on small devices when transcript is open
  @media (max-width: ${TextConstants.SIZES.IPAD_PRO_WIDTH}px) {
    ${({ showTranscript }) => (showTranscript ? `display:none` : '')}
  }

  .perfect-corp-mirror {
    display: flex;
    position: relative;
    height: 100%;
    iframe {
      z-index: 0 !important;
    }
  }

  .customizeMakeup {
    position: absolute;
    bottom: 0;
    z-index: ${({ customizeMakeupAccordionZindex }) =>
      customizeMakeupAccordionZindex};
    background-color: rgba(253, 246, 240, 0.9);
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const VirtualMirror = (): JSX.Element => {
  const [productsData, setProductsData] = useState(null);

  const showVirtualMirror = useSelector(
    (state: RootState) => state.vm.showVirtualMirror
  );
  const areMediaPermissionsAccepted = useSelector(
    (state: RootState) => state.digitalAssistant.areMediaPermissionsAccepted
  );

  const categorizedProductsData = useSelector(
    (state: RootState) => state.commerce.categorizedProductsData
  );

  const showTranscript = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );

  const [perfectCorpBounds, setPerfectCorpBounds] =
    useState<PerfectCorpBounds | null>(null);
  const [customizeMakeupAccordionZindex, setCustomizeMakeupAccordionZindex] =
    useState<number>(8);

  const dispatch = useDispatch();

  const setVMAccordionZindex = (num: number) => {
    setCustomizeMakeupAccordionZindex(num);
  };

  useEffect(() => {
    Promise.all([getProductsCategories(), getProductAllData()])
      .then((values) => {
        setProductsData(values[1]);

        dispatch(setUncategorizedProductsData(values[1]));

        dispatch(
          setCategorizedProductsData(
            changeProductsDataFormat(values[0], values[1])
          )
        );
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  useEffect(() => {
    const [perfectCorpWidth, perfectCorpHeight] = calculateVMDimensions();

    setPerfectCorpBounds({
      width: perfectCorpWidth,
      height: perfectCorpHeight,
    });
    if (!window.YMK) {
      (function createYMKScript(d, k) {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = ``; // @TODO perfect corp mirror to be removed
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      })(document, process.env.REACT_APP_PERFECT_CORP_APIKEY || ''); // @TODO remove all perfectcorp stuff

      window.ymkAsyncInit = () => {
        window.YMK?.init({
          autoOpen: true,
          height: perfectCorpHeight,
          width: perfectCorpWidth,
          userConsent: areMediaPermissionsAccepted,
        });
      };
    }
  }, []);

  return (
    <Container
      className="perfect-corp-module-container"
      showTranscript={showTranscript}
      mirrorWidth={perfectCorpBounds?.width}
      customizeMakeupAccordionZindex={customizeMakeupAccordionZindex}
    >
      <div className="perfect-corp-mirror" hidden={!showVirtualMirror}>
        {perfectCorpBounds && (
          <StickyNotesContainer
            mirrorWidth={perfectCorpBounds.width}
            mirrorHeight={perfectCorpBounds.height}
          />
        )}
        <div id="YMK-module" />

        <VirtualMirrorButtonsPopover
          productsData={productsData}
          setVMAccordionZindex={setVMAccordionZindex}
        />

        {categorizedProductsData?.length ? <CustomizeMakeupAccordion /> : null}
      </div>
    </Container>
  );
};

export default VirtualMirror;
