import { ClipboardEvent, DragEvent, useCallback } from 'react';
import { Editable, ReactEditor, RenderLeafProps } from 'slate-react';
import { BaseText, CustomTypes, Editor, Transforms } from 'slate';

import {
  getSlicedStringDependsOnLimit,
  isStringExceedsLimit,
} from '../../../../utils/Notes';
import { NOTE_CHARACTER_LIMIT } from '../../../../constants/Notes';
import { getLeafElement } from '../../../../services/Notes';

type NoteEditorProps = {
  editor: ReactEditor;
};

const NoteEditor = ({ editor }: NoteEditorProps): JSX.Element => {
  const handlePaste = (event: ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    const text = event.clipboardData.getData('Text');
    const { selection } = editor;

    if (selection) {
      Transforms.delete(editor, {
        at: selection,
      });
    }

    if (!text) {
      return;
    }

    if (
      isStringExceedsLimit(
        NOTE_CHARACTER_LIMIT,
        Editor.string(editor, []),
        text
      )
    ) {
      editor.insertText(
        getSlicedStringDependsOnLimit(
          NOTE_CHARACTER_LIMIT,
          Editor.string(editor, []),
          text
        )
      );

      return;
    }

    editor.insertText(text);
  };

  const Leaf = getLeafElement;

  const renderLeaf = useCallback(
    ({ children, attributes, leaf, text }: RenderLeafProps) => (
      <Leaf
        text={text}
        attributes={attributes}
        leaf={leaf as BaseText & CustomTypes}
      >
        {children}
      </Leaf>
    ),
    []
  );

  const preventTextDragging = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className="note-body">
      <Editable
        onDragStart={preventTextDragging}
        onPaste={handlePaste}
        placeholder="Enter some text"
        renderLeaf={renderLeaf}
      />
    </div>
  );
};

export default NoteEditor;
