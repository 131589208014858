import { Image } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import s from './Logo.module.scss';
import { closeAssistantChat } from '../../../store/reducers/digitalAssistantReducer';
import { RootState } from '../../../store/store';
import logo from '../../../assets/icons/rare-beauty-logo.png';
import TextConstants from '../../../constants/TextConstants';
import { WhereToLeadType } from '../../../routes/AuthenticationFlow/welcome/WelcomePage';

const Logo = (): JSX.Element => {
  const connected = useSelector((state: RootState) => state.digitalAssistant.connected);

  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <button
      type="button"
      className={s.logoBlock}
      onClick={() => {
        if (connected) {
          localStorage.setItem('whereToLead', WhereToLeadType.mainPage);
          dispatch(closeAssistantChat());
        } else {
          history.push(TextConstants.PATHNAMES.MAIN_PAGE);
        }
      }}
    >
      <Image src={logo} alt="rare beauty logo" />
    </button>
  );
};

export default Logo;
