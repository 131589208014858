import { createRef, useEffect, useRef, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Image, List, ListItem } from '@chakra-ui/react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { sendTextMessage, stopSpeaking } from '../../store/reducers/digitalAssistantReducer';
import TextConstants from '../../constants/TextConstants';
import audioPlayIcon from '../../assets/icons/audio-play-icon.webp';
import audioPauseIcon from '../../assets/icons/pause-icon.png';
import { RootState } from '../../store/store';
import leftArrowIcon from '../../assets/icons/arrow-left-circle.png';
import rightArrowIcon from '../../assets/icons/arrow-right-circle.png';

const Container = styled.div`
  padding: 0px 75px 15px 75px;
  max-width: 410px;
  max-height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;

  //hides scrollbar for webkit browsers
  &::-webkit-scrollbar {
    display: none;
  }

  //hides scrollbar for not webkit browsers
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  .slick-slide {
    padding: unset;
  }

  @media (max-width: ${TextConstants.SIZES.IPAD_PRO_WIDTH}px) {
    margin-bottom: 10px;
  }

  @media (max-width: ${TextConstants.SIZES.IPHONE_XR_WIDTH}px) {
    padding: 0px 60px 15px 60px;
    margin-bottom: 10px;
  }

  audio {
    width: 100%;
  }

  .rhap_controls-section {
    flex: none;
  }

  .rhap_container {
    background-color: transparent;
    box-shadow: none;
  }

  .rhap_progress-indicator {
    width: 10px;
    height: 10px;
    margin-left: -9px;
    top: -3px;
  }

  .imageContainer {
    transition: 0.5s;
    display: block;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);

    .audio-controls-icon {
      width: 100px;
      margin: auto;
      padding-top: 30px;
      &:hover {
        cursor: pointer;
      }
    }
    .audio-body {
      background: linear-gradient(
        180deg,
        rgba(102, 30, 58, 1),
        rgba(102, 30, 58, 0.8)
      );
    }
    audio {
      height: 25px;
    }
    audio::-webkit-media-controls-current-time-display,
    audio::-webkit-media-controls-time-remaining-display,
    audio::-webkit-media-controls-volume-slider,
    audio::-webkit-media-controls-mute-button,
    audio::-webkit-media-controls-volume-slider-container,
    audio::-webkit-media-controls-volume-slider {
      display: none;
    }
    audio::-webkit-media-controls-panel {
    }
  }
  .slick-current div {
    opacity: 1;
  }
`;

const AudioPicker = ({
  data,
  isCardInTranscript,
}: {
  data: {
    id: number;
    question: string;
    followUpQuestion: string;
    type: 'audio';
    noneOfThisAnswers: string[];
    items: {
      contentUrl: string;
      staticStorageUrl: string;
      originalName: string;      
      id: number;
    }[];
  };
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // to hide right arrow on last item
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );

  const imagesCount = data?.items?.length || 0;

  const handleClick = (param: string) => {
    dispatch(stopSpeaking());
    dispatch(sendTextMessage({ text: param }));
    setIsButtonDisabled(true);
  };

  const LeftArrow = (props) => {
    const { onClick } = props;
    return (
      <Image
        // hides if first item
        display={currentIndex === 0 ? 'none' : 'block'}
        onClick={onClick}
        src={leftArrowIcon}
        boxSize={{ base: '50px', iPad: '70px' }}
        objectFit="contain"
        left={{ base: '-55px', iPad: '-75px' }}
        position="absolute"
        top="50%"
        cursor="pointer"
        border="none"
        outline="none"
        transform="translate(0, -50%)"
      />
    );
  };

  const RightArrow = (props) => {
    const { onClick } = props;
    return (
      <Image
        // hides if last item
        display={currentIndex === imagesCount - 1 ? 'none' : 'block'}
        onClick={onClick}
        src={rightArrowIcon}
        boxSize={{ base: '50px', iPad: '70px' }}
        objectFit="contain"
        right={{ base: '-55px', iPad: '-75px' }}
        position="absolute"
        top="50%"
        cursor="pointer"
        border="none"
        outline="none"
        transform="translate(0, -50%)"
      />
    );
  };

  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    appendDots: (dots) => <ul className="slick-dots">{dots}</ul>,
    afterChange: (index) => setCurrentIndex(index),
  };

  let arrRefs = [];
  const [isPlaying, setIsPlaying] = useState([]);

  useEffect(() => {
    let initialValue = [];
    for (let i = 0; i < data?.items?.length; i++) {
      arrRefs.push(createRef());
      initialValue = [...initialValue, false];
    }
    setIsPlaying(initialValue);
  }, []);

  let refs = useRef(arrRefs);

  const play = (i) => {
    const newArr = isPlaying;
    refs.current[i].current.play();
    newArr[i] = true;
    setIsPlaying([...newArr]);
  };

  const pause = (i) => {
    const newArr = isPlaying;
    refs.current[i].current.pause();
    newArr[i] = false;
    setIsPlaying([...newArr]);
  };

  const pauseAllAudiosExceptCurrentSelected = (index) => {
    const newArr = isPlaying;
    for (let i = 0; i < data?.items?.length; i += 1) {
      if (i !== index) {
        refs.current[i].current.pause();
        newArr[i] = false;
      }
    }
    setIsPlaying([...newArr]);
  };

  const pauseAllAudios = () => {
    const newArr = isPlaying;
    for (let i = 0; i < data?.items?.length; i += 1) {
      refs.current[i].current.pause();
      newArr[i] = false;
    }
    setIsPlaying([...newArr]);
  };

  const renderAudioComponents = data?.items?.map((audioComponent, i) => (
    <Box
      key={audioComponent?.id}
      maxW="sm"
      borderRadius="lg"
      overflow="hidden"
      data-sm-content={audioComponent?.id}
      className="imageContainer"
      cursor="grab"
      _active={{ cursor: 'grabbing' }}
    >
      <Box
        d="flex"
        flexDirection="column"
        position="relative"
        boxSize="200px"
        w="100%"
        backgroundSize="contain"
        background-repeat="no-repeat"
        className="audio-body"
      >
        {isPlaying[i] ? (
          <img
            className="audio-controls-icon"
            src={audioPauseIcon}
            alt="audio-pause-icon"
            onClick={() => pause(i)}
          />
        ) : (
          <img
            className="audio-controls-icon"
            src={audioPlayIcon}
            alt="audio-play-icon"
            onClick={() => play(i)}
          />
        )}
        <Box w="95%" margin="auto">
          <audio
            ref={refs.current[i]}
            controlsList="noplaybackrate nodownload"
            onPlay={() => {
              pauseAllAudiosExceptCurrentSelected(i);
            }}
          >
            <source src={audioComponent?.staticStorageUrl} type="audio/mpeg" />
          </audio>
        </Box>
      </Box>

      <Box p="3" className="buttonContainer" mt="1">
        <Button
          fontFamily="Inter"
          fontStyle="normal"
          fontWeight="500"
          fontSize="14px"
          lineHeight="20px"
          padding="8px 14px 8px 14px"
          letterSpacing="0.15em"
          textTransform="uppercase"
          onClick={() => {
            handleClick(`audio ${i + 1}`);
            pauseAllAudios();
          }}
          disabled={isButtonDisabled || isCardInTranscript}
        >
          {TextConstants.BUTTON_TEXT.SELECT}
        </Button>
      </Box>
    </Box>
  ));

  const renderOtherOptions = data?.noneOfThisAnswers?.map((el) => (
    <ListItem key={el}>
      <Button
        key={el}
        py="8px"
        height="auto"
        cursor="pointer"
        variant="ghost"
        bg="#fff"
        whiteSpace="normal"
        w="100%"
        m="0 auto"
        data-trigger-text={el}
        onClick={() => {
          handleClick(el);
          pauseAllAudios();
        }}
        disabled={isButtonDisabled || isCardInTranscript}
      >
        {el}
      </Button>
    </ListItem>
  ));

  return (
    <Container
      isTranscriptOpen={isTranscriptOpen}
      className="audio-picker-container"
    >
      <Slider {...sliderSettings} className="slider slick-dotted">
        {renderAudioComponents}
      </Slider>

      <List mt="45px" spacing={3}>
        {renderOtherOptions}
      </List>
    </Container>
  );
};

export default AudioPicker;
