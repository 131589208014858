import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';
import styled from 'styled-components';
import RenderCustomizeMakeupSubcategoryProducts from './RenderCustomizeMakeupSubcategoryProducts';
import MoveOnButton from '../MoveOnButton';
import { SearchedProductType } from '../../../../global-components/GlobalTypes';
import { searchInProducts } from '../../../../global-components/RequestFactory';
import useOpenCustomizeMakeup from '../../../../helpers/products/openCustomizeMakeup';
import { sendTextMessage } from '../../../../store/reducers/digitalAssistantReducer';

const Container = styled.div`
  @media only screen and (max-width: 1024px) {
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 630px) {
    width: 95%;
  }

  .floating-intent-products-slider {
    width: 230px;
    margin-left: var(--chakra-sizes-4);

    // hide product-info button if current slider is inactive
    .product-info-button {
      display: none;
    }
    .slick-current {
      .product-info-button {
        display: unset;
      }
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slickSlide {
    transition: 0.5s;
    display: block;
    height: 100%;
  }

  .slick-current div {
    opacity: 1;
  }

  .slick-next:before,
  .slick-prev:before {
    color: #000;
  }

  @media (max-width: 1300px) {
    .imageContainer {
      width: 600px;
    }
  }
  @media (max-width: 1100px) {
    .imageContainer {
      width: 700px;
    }
  }
  @media (max-width: 950px) {
    .imageContainer {
      width: 500px;
    }
  }
`;

const OpenCustomizeMakeupByFloatingIntent = ({
  data,
  isCardInTranscript,
}: {
  data: {
    requested_product: string;
    level: 'subcategory' | 'product';
  };
  isCardInTranscript: boolean;
}): JSX.Element | null => {
  const { requested_product = '', level = 'subcategory' } = data;

  const dispatch = useDispatch();
  const [product, setProduct] = useState<SearchedProductType | null>(null);
  const [productSearchData, setProductSearchData] = useState<
    SearchedProductType[] | null
  >(null);

  const sliderSettings = {
    slidesToShow: productSearchData?.length > 1 ? 1.1 : 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    appendDots: (dots) => (
      <ul
        className="slick-dots"
        style={{
          width: '85%',
        }}
      >
        {' '}
        {dots}{' '}
      </ul>
    ),
  };

  useEffect(() => {
    searchInProducts(requested_product).then((res: SearchedProductType[]) => {
      if (res?.length) {
        setProduct(res[0]);
        setProductSearchData(res);
      } else {
        setProduct(null);
        dispatch(sendTextMessage({ text: 'proceed' }));
      }
    });
  }, [requested_product, dispatch]);

  // we are selecting first product
  const openCustomizeMakeup = useOpenCustomizeMakeup(level, product);

  useEffect(() => {
    if (!isCardInTranscript) {
      openCustomizeMakeup();
    }
  }, [openCustomizeMakeup, isCardInTranscript]);

  if (level === 'subcategory') {
    return (
      <Container data-sm-content="floating-intent-products">
        <Slider
          {...sliderSettings}
          className="floating-intent-products-slider slick-dotted"
        >
          {productSearchData?.map(
            (el: SearchedProductType) =>
              el && (
                <Box className="slickSlide" key={JSON.stringify(el)}>
                  <RenderCustomizeMakeupSubcategoryProducts data={el} />
                </Box>
              )
          )}
        </Slider>
        <Box paddingTop="10px">
          <MoveOnButton isCardInTranscript={isCardInTranscript} />
        </Box>
      </Container>
    );
  }
  return null;
};
export default OpenCustomizeMakeupByFloatingIntent;
