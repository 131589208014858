import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Container,
  Text,
  Button,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
} from '@chakra-ui/react';
import './Welcome.scss';
import HomeScreenOne from '../../../img/home-screen-one.png';
import HomeScreenTwo from '../../../img/home-screen-two.jpeg';
import { RootState } from '../../../store/store';
import LoadingModal from '../../FoyerWelcome/LoadingModal';
import { setVirtualMirrorVisibility } from '../../../store/reducers/vmReducer';
import ConfirmationModal from '../../FoyerWelcome/ConfirmationModal';
import PermissionDeniedModal from '../../FoyerWelcome/PermissionDeniedModal';
import FatalErrorModal from '../../FoyerWelcome/FatalErrorModal';
import HappyHatsTOSModal from './HappyHatsTOSModal';
import {
  openAssistantChat,
  allowRedirection,
  acceptHappyHatsTOS,
  acceptMediaPermissions,
} from '../../../store/reducers/digitalAssistantReducer';
import {
  set360VideoPageRedirected,
  setDesertPageRedirectedFromAssistant,
  setSceneToOpen,
} from '../../../store/reducers/pannellumReducer';
import { setDialogType } from '../../../store/reducers/commerceReducer';
import TextConstants from '../../../constants/TextConstants';
import AuthPromptModal from '../../../components/modals/signUp/AuthPromptModal';
import { useIsUserLoggedIn } from '../../../helpers/userInfo/UserInfo';
import {
  executeAuthPromptModalCallback,
  setAuthPromptOpened,
} from '../../../store/reducers/authPromptReducer';
import { requestSpeechAudioAccess } from '../../../utils/textToSpeech';
import WebSpeechRecognizer from '../../../utils/webSpeechRecognizer';

declare const window;

export enum WhereToLeadType {
  city = 'city',
  desert = 'desert',
  shop = 'shop',
  wellness = 'wellness',
  mainPage = '',
}

export type WelcomePageModalType = {
  isHappyHatsTOSModalOpen: boolean;
  isMediaTOSModalOpen: boolean;
};

export type WelcomePageAuthPromptActionType =
  | 'FastLaneToShop'
  | 'ExpressToSpa'
  | 'City'
  | 'Desert'
  | null;

type ConfirmActionType = 'open_assistant' | 'open_city' | 'open_desert' | null;

const Welcome = (): JSX.Element => {
  const isUserLoggedIn = useIsUserLoggedIn();

  const history = useHistory();
  const dispatch = useDispatch();

  // when setting state AuthPromptModal is opened (1st time only)
  const [actionType, setActionType] =
    useState<WelcomePageAuthPromptActionType>(null);

  // should redirect to city/desert or open DP
  const [confirmActionType, setConfirmActionType] =
    useState<ConfirmActionType>(null);

  const [isDPLoadingModalOpen, setIsDPLoadingModalOpen] = useState(false);
  const [isModalsOpen, setIsModalsOpen] = useState<WelcomePageModalType>({
    isHappyHatsTOSModalOpen: false,
    isMediaTOSModalOpen: false,
  });
  const [selectedValues, setSelectedValues] = useState(null);
  const connected = useSelector((state: RootState) => state.digitalAssistant.connected);
  // prevent from redirecting to DP page
  const isRedirectionAllowed = useSelector(
    (state: RootState) => state.digitalAssistant.isRedirectionAllowed
  );
  const error = useSelector((state: RootState) => state.digitalAssistant.error);
  const showVirtualMirror = useSelector(
    (state: RootState) => state.vm.showVirtualMirror
  );
  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );
  const areMediaPermissionsAccepted = useSelector(
    (state: RootState) => state.digitalAssistant.areMediaPermissionsAccepted
  );
  const isHappyHatsTOSAccepted = useSelector(
    (state: RootState) => state.digitalAssistant.isHappyHatsTOSAccepted
  );

  const isAuthPromptOpened = useSelector(
    (state: RootState) => state.authPromptState.isOpened
  );
  const isExecuteAuthPromptModalCallback = useSelector(
    (state: RootState) => state.authPromptState.isExecuteAuthPromptModalCallback
  );

  const closeModals = () => {
    setIsModalsOpen(() => ({
      isHappyHatsTOSModalOpen: false,
      isMediaTOSModalOpen: false,
    }));
  };

  const openFirstModal = () => {
    setIsModalsOpen(() => ({
      isHappyHatsTOSModalOpen: true,
      isMediaTOSModalOpen: false,
    }));
  };

  const openThirdModal = () => {
    setIsModalsOpen(() => ({
      isHappyHatsTOSModalOpen: false,
      isMediaTOSModalOpen: true,
    }));
  };

  const checkAllPermissionsAndRunCallback = (callback: () => void) => {
    if (isHappyHatsTOSAccepted === 'disagree') {
      openFirstModal();
      return;
    } else if (areMediaPermissionsAccepted === 'disagree') {
      openThirdModal();
      return;
    } else {
      callback();
    }
  };

  const handleThirdModalAcceptClick = () => {
    closeModals();
    dispatch(acceptMediaPermissions('agree'));
    dispatch(allowRedirection(true));
    if (confirmActionType === 'open_assistant') {
      dispatch(openAssistantChat({}));
      setIsDPLoadingModalOpen(true);
      return;
    } else if (confirmActionType === 'open_city') {
      history.push(`/${TextConstants.PATHNAMES.CITY_PAGE}`);
      return;
    } else if (confirmActionType === 'open_desert') {
      history.push(`/${TextConstants.PATHNAMES.DESERT_PAGE}`);
      return;
    } else {
      return;
    }
  };

  const handleFirstModalAcceptClick = (param: WhereToLeadType) => {
    dispatch(acceptHappyHatsTOS('agree'));
    switch (param) {
      case WhereToLeadType.city:
        // set state before pushing route to 360 video page (otherwise hotspots aren't shown)
        dispatch(set360VideoPageRedirected(true));

        localStorage.setItem('whereToLead', WhereToLeadType.city);

        checkAllPermissionsAndRunCallback(() => {
          handleThirdModalAcceptClick();
        });

        break;
      case WhereToLeadType.desert:
        // set state before pushing route to 360 video page (otherwise hotspots aren't shown)
        dispatch(set360VideoPageRedirected(true));

        localStorage.setItem('whereToLead', WhereToLeadType.desert);

        checkAllPermissionsAndRunCallback(() => {
          handleThirdModalAcceptClick();
        });

        break;
      case WhereToLeadType.shop:
        localStorage.setItem('whereToLead', WhereToLeadType.desert);

        checkAllPermissionsAndRunCallback(() => {
          handleThirdModalAcceptClick();
        });
        break;
      case WhereToLeadType.wellness:
        localStorage.setItem('whereToLead', WhereToLeadType.desert);

        checkAllPermissionsAndRunCallback(() => {
          handleThirdModalAcceptClick();
        });
        break;
      default:
        localStorage.setItem('whereToLead', WhereToLeadType.mainPage);
    }
  };

  const checkPermissionsAndCreateAssistant = () => {
    checkAllPermissionsAndRunCallback(() => {
      dispatch(openAssistantChat({}));
      setIsDPLoadingModalOpen(true);
      dispatch(allowRedirection(true));
    });
  };

  const closeAuthprompt = () => {
    dispatch(setAuthPromptOpened(true));
  };

  const handleCityClick = () => {
    setSelectedValues(WhereToLeadType.city);

    // set state before pushing route to 360 video page (otherwise hotspots aren't shown)
    dispatch(set360VideoPageRedirected(true));

    setConfirmActionType('open_city');

    checkAllPermissionsAndRunCallback(() => {
      localStorage.setItem('whereToLead', WhereToLeadType.city);
      history.push(`/${TextConstants.PATHNAMES.CITY_PAGE}`);
    });
  };

  const handleDesertClick = () => {
    setSelectedValues(WhereToLeadType.desert);

    // set state before pushing route to 360 video page (otherwise hotspots aren't shown)
    dispatch(set360VideoPageRedirected(true));

    setConfirmActionType('open_desert');

    checkAllPermissionsAndRunCallback(() => {
      localStorage.setItem('whereToLead', WhereToLeadType.desert);
      history.push(`/${TextConstants.PATHNAMES.DESERT_PAGE}`);
    });
  };

  const handleFastLaneToShopClick = () => {
    setConfirmActionType('open_assistant');
    setSelectedValues(WhereToLeadType.shop);
    checkPermissionsAndCreateAssistant();
    localStorage.setItem('whereToLead', WhereToLeadType.desert);
    dispatch(setDialogType(TextConstants.DIALOG_TYPE.ECOMMERCE));
  };

  const handleExpressToSpaClick = () => {
    setConfirmActionType('open_assistant');
    setSelectedValues(WhereToLeadType.wellness);
    checkPermissionsAndCreateAssistant();

    localStorage.setItem('whereToLead', WhereToLeadType.desert);

    //used when directing to shop room when clicking Exit to lobby (in desert 360 video)
    // dispatch(setSceneToOpen('spa'));
    dispatch(setDesertPageRedirectedFromAssistant(true));

    dispatch(setDialogType(TextConstants.DIALOG_TYPE.WELLNESS));
  };

  const authPromptCallback = () => {
    switch (actionType) {
      case 'FastLaneToShop': {
        handleFastLaneToShopClick();
        break;
      }
      case 'ExpressToSpa': {
        handleExpressToSpaClick();
        break;
      }

      case 'City': {
        handleCityClick();
        break;
      }

      case 'Desert': {
        handleDesertClick();
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (isExecuteAuthPromptModalCallback && actionType) {
      authPromptCallback();
      dispatch(executeAuthPromptModalCallback(false));
      setActionType(null);
    }
  }, [isExecuteAuthPromptModalCallback]);

  useEffect(() => {
    dispatch(setSceneToOpen('default'));
  }, [dispatch]);

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(setAuthPromptOpened(true));
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    // check if TOS accepted create scene
    if (connected && isRedirectionAllowed) {
      setTimeout(() => {
        history.push(`/${TextConstants.PATHNAMES.DP_PAGE}`);
        if (selectedValues === WhereToLeadType.shop) {
          if (!window.YMK?.isLoaded()) {
            window.YMK?.open();
          }

          dispatch(setVirtualMirrorVisibility(true));
        } else if (selectedValues === WhereToLeadType.wellness) {
          dispatch(setVirtualMirrorVisibility(false));
        }
      }, 1500);
    }
  }, [
    connected,
    history,
    isTranscriptOpen,
    selectedValues,
    showVirtualMirror,
    isRedirectionAllowed,
    dispatch,
  ]);

  const sectionOne = () => (
    <Box as="section" className="welcomeSection" pb="55px">
      <Container maxW="5xl">
        <Box
          className="welcome-first-section"
          marginTop={{ base: '1', sm: '1' }}
          display="flex"
          flexDirection={{ base: 'column', sm: 'row' }}
          justifyContent="space-between"
        >
          <Box
            color="var(--chakra-colors-brand-600)"
            display="flex"
            flex="1"
            flexDirection="column"
            pr="65px"
            marginTop={{ base: '3', sm: '10' }}
          >
            <Text
              letterSpacing="15%"
              fontStyle="medium"
              lineHeight="28px"
              fontFamily="inter"
              fontWeight="500"
            >
              NEW
            </Text>
            <Text
              lineHeight="60px"
              fontWeight="400"
              fontStyle="normal"
              fontFamily="Ogg"
              marginTop="1"
              fontSize={{ base: '30px', md: '35px', lg: '40px' }}
            >
              Mental Wellness is Beautiful
            </Text>
            <Text
              fontFamily="inter"
              lineHeight="30px"
              as="p"
              marginTop="2"
              fontSize="lg"
              fontStyle="regular"
              fontWeight="400"
            >
              Welcome to the Rare Beauty virtual wellness experience. Inside
              awaits a relaxing mental health spa and personal mental wellness
              guide.
            </Text>
            <Text
              fontFamily="inter"
              lineHeight="30px"
              as="p"
              marginTop="2"
              fontSize="lg"
              fontStyle="regular"
              fontWeight="400"
            >
              You deserve to feel happy, healthy, and beautiful. Let's make this
              journey better together.
            </Text>
            <Box gap="8px" display="flex" mt="30px" flexWrap="wrap">
              <Menu>
                <MenuButton
                  paddingInlineStart="10px"
                  paddingInlineEnd="10px"
                  letterSpacing="0.3px"
                  fontSize="15px"
                  color="#FFF"
                  as={Button}
                  _hover={{
                    bg: 'brand.600',
                  }}
                  borderRadius="0"
                >
                  BEGIN JOURNEY
                </MenuButton>
                <MenuList zIndex="20">
                  <MenuGroup>
                    <MenuItem
                      _focus={{ bg: 'var(--chakra-colors-brand-50)' }}
                      onClick={() => {
                        if (isAuthPromptOpened) {
                          handleCityClick();
                        } else {
                          setActionType('City');
                        }
                      }}
                      color="var(--chakra-colors-brand-700)"
                    >
                      City
                    </MenuItem>
                    <MenuItem
                      _focus={{ bg: 'var(--chakra-colors-brand-50)' }}
                      onClick={() => {
                        if (isAuthPromptOpened) {
                          handleDesertClick();
                        } else {
                          setActionType('Desert');
                        }
                      }}
                      color="var(--chakra-colors-brand-700)"
                    >
                      Desert
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>
              <Button
                paddingInlineStart="10px"
                paddingInlineEnd="10px"
                borderRadius="0"
                bg="transparent"
                border="1px solid var(--chakra-colors-brand-500)"
                color="var(--chakra-colors-brand-500)"
                onClick={() => {
                  requestSpeechAudioAccess();
                  if (isAuthPromptOpened) {
                    handleFastLaneToShopClick();
                  } else {
                    setActionType('FastLaneToShop');
                  }
                }}
                _hover={{
                  color: '#fff',
                  bg: 'var(--chakra-colors-brand-600)',
                }}
              >
                Fast Lane To Shop
              </Button>
              <Button
                paddingInlineStart="10px"
                paddingInlineEnd="10px"
                borderRadius="0"
                bg="transparent"
                border="1px solid var(--chakra-colors-brand-500)"
                color="var(--chakra-colors-brand-500)"
                onClick={() => {
                  requestSpeechAudioAccess();
                  if (isAuthPromptOpened) {
                    handleExpressToSpaClick();
                  } else {
                    setActionType('ExpressToSpa');
                  }
                }}
                _hover={{
                  color: '#fff',
                  bg: 'var(--chakra-colors-brand-600)',
                }}
              >
                Express To Spa
              </Button>
            </Box>
            <Text
              fontFamily="inter"
              lineHeight="30px"
              as="p"
              mt="30px"
              fontSize="lg"
              fontStyle="regular"
              fontWeight="400"
            >
              If you would like to or need to speak to someone, please proceed
              to the diary/journal and find a link to{' '}
              <Link
                textDecoration="underline"
                color="var(--chakra-colors-brand-600)"
                fontWeight="500"
                href={TextConstants.MODALS.MODAL_988.LINK_CHAT}
                _focus={{ boxShadow: 'unset' }}
                _hover={{ color: 'var(--chakra-colors-brand-600)' }}
                letterSpacing="1px"
                isExternal
              >
                988 mental health assistance
              </Link>
              .
            </Text>
          </Box>
          <Box display="flex" flex="1" position="relative" alignItems="center">
            <Box
              height="420px"
              zIndex="2"
              marginLeft={{ base: '0', sm: '5%' }}
              marginTop="5%"
            >
              <Image
                objectFit="cover"
                height="100%"
                src={HomeScreenOne}
                alt="some good alt text"
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );

  const sectionTwo = () => (
    <Box as="section" backgroundColor="#FDFBF8">
      <Container maxW="5xl">
        <Box
          marginTop={{ base: '0', sm: '1' }}
          display="flex"
          flexDirection={{ base: 'column', sm: 'row' }}
          justifyContent="space-between"
        >
          <Box display="flex" flex="1" position="relative" alignItems="center">
            <Box
              className="welcome-second-section-img-body"
              height="auto"
              display="flex"
              justifyContent="center"
              width="100%"
              zIndex="2"
            >
              <Image
                maxHeight="400px"
                width="80%"
                mt="10px"
                borderRadius="50%"
                objectFit="cover"
                src={HomeScreenTwo}
                alt="some good alt text"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            marginTop={{ base: '3', sm: '10' }}
          >
            <Box marginLeft={{ base: '0', sm: '15%' }}>
              <Text
                lineHeight="60px"
                fontSize="30px"
                fontWeight="400"
                fontStyle="normal"
                fontFamily="Ogg"
              >
                What makes us Rare
              </Text>
              <Text
                as="p"
                marginTop="2"
                fontSize="16px"
                fontWeight="400"
                fontStyle="normal"
                fontFamily="inter"
                pr="50px"
              >
                Together we&apos;re building a safe, welcoming space in beauty
                and beyond. This is makeup made to feel good in, without hiding
                what makes you unique. And it&apos;s all vegan and cruelty free.
              </Text>
              <Box>
                <Text
                  fontSize="14px"
                  fontWeight="600"
                  fontStyle="normal"
                  fontFamily="inter"
                  color="var(--chakra-colors-brand-600)"
                  letterSpacing="3px"
                >
                  <Link
                    textDecoration="underline"
                    _hover={{ color: 'var(--chakra-colors-brand-600)' }}
                    href="https://www.rarebeauty.com/pages/about"
                    target="_blank"
                  >
                    {TextConstants.WELCOME_PAGE.OUR_STORY}
                  </Link>
                </Text>
              </Box>
              <Box>
                <Text
                  className="welcome-second-section-button"
                  fontSize="14px"
                  fontWeight="600"
                  fontStyle="normal"
                  fontFamily="inter"
                  color="var(--chakra-colors-brand-600)"
                  letterSpacing="3px"
                >
                  <Link
                    textDecoration="underline"
                    _hover={{ color: 'var(--chakra-colors-brand-600)' }}
                    href="https://www.rarebeauty.com/blogs/news/from-selena"
                    target="_blank"
                  >
                    {TextConstants.WELCOME_PAGE.SELENA_STORY}
                  </Link>
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );

  return (
    <>
      {sectionOne()}
      {sectionTwo()}

      <HappyHatsTOSModal
        isOpen={isModalsOpen.isHappyHatsTOSModalOpen}
        onClose={closeModals}
        selectedValues={selectedValues}
        handleSelectContinue={handleFirstModalAcceptClick}
      />

      {isDPLoadingModalOpen && (
        <LoadingModal
          modalToOpen="DP"
          isModalOpen={isDPLoadingModalOpen}
          closeModal={() => {
            // do nothing
          }}
        />
      )}

      {!error ? (
        <ConfirmationModal
          isOpen={isModalsOpen.isMediaTOSModalOpen}
          isCloseButtonDisabled={false}
          onClose={closeModals}
          handleConfirm={handleThirdModalAcceptClick}
        />
      ) : (
        <>
          <PermissionDeniedModal /> : <FatalErrorModal />
        </>
      )}

      <AuthPromptModal
        isOpen={!isAuthPromptOpened && Boolean(actionType)}
        closeAuthPrompt={() => {
          closeAuthprompt();
        }}
      />
    </>
  );
};

export default Welcome;
