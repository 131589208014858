import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { PannellumVideo, Pannellum } from '@erosargsyan/pannellum-react';
import hotspotImageOne from '../../assets/icons/hotspot1.png';
import { RootState } from '../../store/store';
import { openWellnessPanoramicVideoModal } from '../../store/reducers/panoramicVideoReducer';
import {
  mute,
  sendTextMessage,
  stopSpeaking,
} from '../../store/reducers/digitalAssistantReducer';
import MutePanoramicVideoButton from '../MutePanoramicVideoButton';
import TextConstants from '../../constants/TextConstants';
import { VideoHotspotType } from '../../global-components/GlobalTypes';
import SceneConfigs from '../../helpers/SceneConfigs';
import {
  checkIfIOSDevice,
  checkIfSafariBrowser,
} from '../../helpers/checkDevicePlatform';

const Container = styled.div`
  height: 100%;

  .pnlm-tooltip span {
    width: 100px;
  }

  .pnlm-controls-container {
    top: 10px;
  }

  .hotspot {
    display: none;
  }

  .pnlm-hotspot-base {
    display: ${({ isVideoLoaded }) => (isVideoLoaded ? 'flex' : 'none')};
  }
  .pnlm-load-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #344054b2;
    height: 100%;
    width: 100%;
    border-radius: 3px;
    font-size: 20px;
    color: #fff;
    top: 0;
    left: 0;
    margin: 0;
  }
  .pnlm-about-msg {
    display: none !important;
  }
  .pnlm-load-box p {
    margin-top: 40vh;
    font-family: Ogg;
    font-size: 22px;
  }

  .pnlm-lbar {
    width: 30%;
    height: 1.5%;
  }
  .pnlm-lmsg {
    display: none;
  }

  .navigation-hotspot-custom-style {
    background-image: url(${hotspotImageOne});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 55px;
    width: 90px;
    border-radius: 100px;
    :hover {
      background-color: transparent;
    }
  }

  @media screen and (max-width: ${TextConstants.SIZES.IPAD_WIDTH}px) {
    .navigation-hotspot-custom-style {
      height: 36px;
      width: 60px;
    }
  }
  @media screen and (max-width: 451px) {
    .navigation-hotspot-custom-style {
      height: 27px;
      width: 45px;
    }
  }

  // make progress bar/text larger on mobile
  @media only screen and (max-width: 900px) {
    .pnlm-load-box p {
      font-size: 30px;
    }
    .pnlm-lbar {
      width: 90%;
      height: 2%;
    }
  }
`;

const BuddhistMeditationRoomVideoModal = (): JSX.Element => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(true);
  const [videoRef, setVideoRef] = useState(null);
  const panellumVideoRef = useRef<PannellumVideo>(null);

  const [videoDataState, setVideoDataState] = useState(
    SceneConfigs.BuddhistTempleEntranceScene
  );

  const dispatch = useDispatch();
  const [isSmallerThanIPhoneXR] = useMediaQuery(
    `(max-width: ${TextConstants.SIZES.IPHONE_XR_WIDTH}px)`
  );
  const panoramicVideoModalToOpen = useSelector(
    (state: RootState) => state.panoramicVideoModalState
  );

  useEffect(() => {
    if (panoramicVideoModalToOpen) {
      dispatch(stopSpeaking());
      dispatch(mute(true));
    }
  }, [panoramicVideoModalToOpen, dispatch]);

  useEffect(() => {
    setVideoRef(document.getElementsByClassName('vjs-tech'));
  }, [panoramicVideoModalToOpen]);

  useEffect(() => {
    // add playsinline attribute to 360 video
    if (videoRef?.length) {
      videoRef[0].onloadeddata = () => {
        setIsVideoLoaded(true);
      };
      videoRef[0].setAttribute('playsinline', '');
    }
  }, [videoRef]);

  return (
    <Modal
      onClose={() => {
        dispatch(openWellnessPanoramicVideoModal(null));
        dispatch(sendTextMessage({ text: 'proceed' }));
      }}
      isOpen
      size="full"
      scrollBehavior="inside"
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalContent>
        <ModalCloseButton
          w="auto"
          mt="2vh"
          mr="15px"
          zIndex="22"
          _focus={{ boxShadow: 'none', bg: 'transparent' }}
          _hover={{ bg: 'transparent' }}
        >
          <Text
            display="inline-block"
            whiteSpace="nowrap"
            color="white"
            fontSize={{ base: 'md', iPad: 'xl' }}
            p="7px"
            bg="var(--chakra-colors-brand-600)"
            border="1px solid var(--chakra-colors-brand-500)"
          >
            {TextConstants.MODALS.CLOSE_BUDDHIST_ROOM_BUTTON}
          </Text>
        </ModalCloseButton>
        <Container isVideoLoaded={isVideoLoaded}>
          <PannellumVideo
            id="buddhist-panellum-video"
            ref={panellumVideoRef}
            video={videoDataState.videoSource}
            loop
            autoplay
            width="100vw"
            height="100vh"
            pitch={0}
            yaw={0}
            hfov={140}
            mouseZoom={false}
            controls={false}
            // ititially muted for IOS and Safari browser only
            muted={checkIfIOSDevice() || checkIfSafariBrowser()}
            playsinline
            // maximum zoom in percent
            maxHfov={
              isSmallerThanIPhoneXR
                ? TextConstants.PANORAMIC_VIDEOS.MOBILE_MAX_ZOOM
                : TextConstants.PANORAMIC_VIDEOS.BIG_SCREEN_MAX_ZOOM
            }
          >
            {videoDataState.hotSpots.map((hotspot: VideoHotspotType) => (
              <Pannellum.Hotspot
                key={hotspot}
                type={hotspot.type}
                pitch={hotspot.pitch}
                yaw={hotspot.yaw}
                handleClick={() => {
                  setVideoDataState(SceneConfigs[hotspot.nextStep]);
                  setIsVideoLoaded(false);
                }}
                cssClass={hotspot.cssClass}
                name={hotspot.name}
              />
            ))}
          </PannellumVideo>
          <MutePanoramicVideoButton
            videoRef={panellumVideoRef}
            parentComponentType="modal"
          />
        </Container>
      </ModalContent>
    </Modal>
  );
};

export default BuddhistMeditationRoomVideoModal;
