import {
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Image,
} from '@chakra-ui/react';
import TextConstants from '../../../constants/TextConstants';
import successIcon from '../../../assets/icons/success-icon.png';

const VerificationSuccessModal = ({
  isModalOpen,
}: {
  isModalOpen: boolean;
}): JSX.Element => (
  <Modal
    isOpen={isModalOpen}
    onClose={() => {
      // do nothing
    }}
    isCentered
  >
    <ModalOverlay
      backdropFilter="auto"
      backdropInvert="10%"
      backdropBlur="0px"
    />
    <ModalContent borderRadius="0" justifyContent="center">
      <Box>
        <Stack spacing="16px" mx="16px" mb="20px" justifyContent="center">
          <Image
            mt="20px"
            alignSelf="center"
            boxSize="48px"
            objectFit="cover"
            src={successIcon}
          />
          <Box>
            <Text textStyle="title" textAlign="center">
              {TextConstants.MODALS.SIGN_UP_MODAL.ACCOUNT_CREATED}
            </Text>
            <Text textStyle="subTitle" textAlign="center" mt="8px" mb="20px">
              {TextConstants.MODALS.SIGN_UP_MODAL.THANK_YOU}
            </Text>
          </Box>
        </Stack>
      </Box>
    </ModalContent>
  </Modal>
);

export default VerificationSuccessModal;
