import { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  Alert,
  AlertIcon,
  Box,
  Checkbox,
  Divider,
  HStack,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import ProfileSectionButtons from './ProfileSectionButtons';
import TextConstants from '../../../constants/TextConstants';
import { updateProfileInformation } from '../../../global-components/RequestFactory';
import { useGetUserInfo } from '../../../helpers/userInfo/UserInfo';
import { setUserData } from '../../../store/reducers/authenticationReducer';
import {
  AddressFormFields,
  UserDataType,
} from '../../../global-components/GlobalTypes';
import { getUserDataCookie } from '../../../utils/CookieUtils';
import CountryDropdown from '../../common/CountryDropdown/CountryDropdown';
import AddressForm from '../../common/AddressForm/AddressForm';
import { FormikContextType } from 'formik/dist/types';
import xIcon from '../../../assets/icons/x-icon.png';
import { TEXT_FIELD_VALIDATION_RULES } from '../../../helpers/validation';

const ADDRESSES_MAX_COUNT = 5;

const validationSchema = Yup.object().shape({
  country: TEXT_FIELD_VALIDATION_RULES,
  addresses: Yup.array().of(
    Yup.object().shape({
      state: TEXT_FIELD_VALIDATION_RULES,
      city: TEXT_FIELD_VALIDATION_RULES,
      zipcode: TEXT_FIELD_VALIDATION_RULES,
      addressLine1: TEXT_FIELD_VALIDATION_RULES,
      addressLine2: TEXT_FIELD_VALIDATION_RULES,
      isDefault: Yup.boolean(),
    })
  ),
});

const getAddressFieldsProps = (
  formik: FormikContextType<any>,
  prefix: string
): AddressFormFields => {
  return {
    state: {
      ...formik.getFieldProps(`${prefix}.state`),
      ...formik.getFieldMeta(`${prefix}.state`),
    },
    city: {
      ...formik.getFieldProps(`${prefix}.city`),
      ...formik.getFieldMeta(`${prefix}city`),
    },
    zipcode: {
      ...formik.getFieldProps(`${prefix}zipcode`),
      ...formik.getFieldMeta(`${prefix}zipcode`),
    },
    addressLine1: {
      ...formik.getFieldProps(`${prefix}addressLine1`),
      ...formik.getFieldMeta(`${prefix}addressLine1`),
    },
    addressLine2: {
      ...formik.getFieldProps(`${prefix}addressLine2`),
      ...formik.getFieldMeta(`${prefix}addressLine2`),
    },
  };
};

const ProfileAddresses = (): JSX.Element => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [requestError, setRequestError] = useState({
    isErrorShown: false,
    errorText: '',
  });
  const userData = useGetUserInfo();

  const dispatch = useDispatch();
  const [isInitialValueChanged, setInitialValueChange] = useState(false);

  const setUserDataInRedux = (result: UserDataType) => {
    dispatch(setUserData(result));
  };

  const hideResponseError = () => {
    setRequestError({ isErrorShown: false, errorText: '' });
  };

  const formik = useFormik({
    initialValues: {
      country: userData.country === null ? '' : userData.country,
      addresses: userData.addresses,
    },
    onSubmit: (values) => {
      setIsRequesting(true);
      updateProfileInformation(values, userData, setUserDataInRedux)
        .then((patchedUserData) => {
          setIsRequestSuccess(true);
          setTimeout(
            () => setIsRequestSuccess(false),
            TextConstants.ALERT_MESSAGE_TRASHHOLD
          );
          hideResponseError();
          setIsRequesting(false);
          setRequestError({ isErrorShown: false, errorText: '' });
          // getUserDataCookie() is userData (userData not refreshing while running promise)
          setUserDataInRedux({ ...getUserDataCookie(), ...patchedUserData });
          formik.resetForm();
        })
        .catch((error) => {
          setIsRequesting(false);
          setIsRequestSuccess(false);
          setRequestError({
            isErrorShown: true,
            errorText: error.violations
              ? error.violations[0].message
              : error.message,
          });
          setTimeout(
            () => hideResponseError(),
            TextConstants.ALERT_MESSAGE_TRASHHOLD
          );
        });
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  const { resetForm } = formik;

  const handleResetForm = useCallback(() => {
    resetForm();
    setIsRequestSuccess(false);
    hideResponseError();
  }, [resetForm]);

  useEffect(() => {
    if (
      formik.values.country !== formik.initialValues.country ||
      formik.values.addresses.length !==
        formik.initialValues.addresses.length ||
      formik.values.addresses.some(
        (address, index) =>
          address.state !== formik.initialValues.addresses[index].state ||
          address.city !== formik.initialValues.addresses[index].city ||
          address.zipcode !== formik.initialValues.addresses[index].zipcode ||
          address.addressLine1 !==
            formik.initialValues.addresses[index].addressLine1 ||
          address.addressLine2 !==
            formik.initialValues.addresses[index].addressLine2
      )
    ) {
      setInitialValueChange(true);
    } else {
      hideResponseError();
      setInitialValueChange(false);
    }
  }, [formik.values]);

  const addAddress = () => {
    const newAddress = {
      id: Date.now(),
      state: '',
      city: '',
      zipcode: '',
      addressLine1: '',
      addressLine2: '',
      isDefault: !formik.values.addresses.length,
    };
    formik.setFieldValue('addresses', [...formik.values.addresses, newAddress]);
  };

  const removeAddress = (id: number) => {
    formik.setFieldValue(
      'addresses',
      formik.values.addresses.filter((address) => address.id !== id)
    );
  };

  const setDefaultAddress = (id: number) => {
    formik.setFieldValue(
      'addresses',
      formik.values.addresses.map((address) => ({
        ...address,
        isDefault: address.id === id,
      }))
    );
  };

  return (
    <Stack
      spacing={2}
      mt="10px"
      mb="20px"
      justifyContent="center"
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <Box pt="20px">
        <CountryDropdown
          value={formik.values.country}
          onChange={formik.handleChange}
          isInvalid={!!formik.errors.country}
          errorMessage={formik.errors.country}
        />
        {formik.values.addresses.map((address, index) => {
          const prefix = `addresses[${index}]`;
          return (
            <Box key={address.id}>
              <Divider bg="gray.300" mt="30px" />
              <HStack
                flexDirection="row"
                justifyContent="flex-end"
                marginTop="20px"
              >
                <Image
                  src={xIcon}
                  cursor="pointer"
                  onClick={() => removeAddress(address.id)}
                />
              </HStack>
              <AddressForm
                fields={getAddressFieldsProps(formik, prefix)}
                fieldNamePrefix={prefix}
              />
              <Checkbox
                py="20px"
                colorScheme="brand"
                name={`${prefix}.isDefault`}
                isChecked={address.isDefault}
                onChange={() => setDefaultAddress(address.id)}
              >
                {TextConstants.PROFILE_ADDRESSES.DEFAULT_ADDRESS}
              </Checkbox>
            </Box>
          );
        })}
        {formik.values.addresses.length < ADDRESSES_MAX_COUNT && (
          <Box cursor="pointer" onClick={() => addAddress()}>
            <Text
              fontSize="14px"
              fontFamily="Inter"
              letterSpacing="0.1em"
              color="#661E3A"
              fontWeight="medium"
              textTransform="uppercase"
              textAlign="right"
              py="20px"
            >
              {TextConstants.PROFILE_ADDRESSES.ADD_ADDRESS}
            </Text>
          </Box>
        )}
      </Box>

      {isInitialValueChanged && (
        <ProfileSectionButtons
          cancelButtonTitle={TextConstants.BUTTON_TEXT.CANCEL}
          saveButtonTitle={TextConstants.BUTTON_TEXT.UPDATE_ADDRESS}
          isDisabled={isRequesting}
          resetForm={handleResetForm}
        />
      )}

      {requestError.isErrorShown && (
        <Box pb="30px">
          <Alert status="error">
            <AlertIcon />
            {requestError.errorText}
          </Alert>
        </Box>
      )}

      {isRequestSuccess && (
        <Box pb="30px">
          <Alert
            status="success"
            backgroundColor="var(--chakra-colors-brand-500)"
            color="#fff"
          >
            <AlertIcon color="#fff" />
            {
              TextConstants.PROFILE_MANAGMENT.PROFILE_INFORMATION_SECTION
                .UPDATE_PROFILE_INFORMATION_SUCCESS
            }
          </Alert>
        </Box>
      )}
    </Stack>
  );
};

export default ProfileAddresses;
