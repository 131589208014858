import { Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import StyleWrapper from './StyleWrapper';
import TextConstants from '../../../constants/TextConstants';
import { sendTextMessage } from '../../../store/reducers/digitalAssistantReducer';

const MoveOnButton = ({
  isCardInTranscript,
}: {
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();

  return (
    <StyleWrapper>
      <Button
        fontSize={{ base: 'small', sm: '1rem' }}
        variant="secondary"
        p="20px"
        w="100%"
        m="0 auto"
        borderRadius="0.5rem"
        _focus={{ boxShadow: 'none' }}
        onClick={() => {
          dispatch(sendTextMessage({ text: 'proceed' }));
        }}
        disabled={isCardInTranscript}
      >
        {TextConstants.BUTTON_TEXT.MOVE_ON}
      </Button>
    </StyleWrapper>
  );
};

export default MoveOnButton;
