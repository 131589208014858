import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer from './reducers/authenticationReducer';
import journalReducer from './reducers/journalReducer';
import commerceReducer from './reducers/commerceReducer';
import digitalAssistantReducer from './reducers/digitalAssistantReducer';
import vmReducer from './reducers/vmReducer';
import noteManagerReducer, {
  NoteManagerState,
} from './reducers/noteManagerReducer';
import {
  CommerceInitialState,
  PannellumReducerInitialState,
  FormattedRecordDataType,
  DigitalAssistantState,
  VMInitialState,
  PanoramicVideoModalState,
  SignInModalState,
  InfoModalState,
  AuthState,
  SessionTimeoutState,
  MediaMuteState,
  AuthPromptState,
} from '../global-components/GlobalTypes';
import pannellumReducer from './reducers/pannellumReducer';
import panoramicVideoReducer from './reducers/panoramicVideoReducer';
import signInModalReducer from './reducers/signInModalReducer';
import infoModalReducer from './reducers/infoModalReducer';
import sessionTimeoutModalReducer from './reducers/sessionTimeoutReducer';
import mediaMuteReducer from './reducers/mediaMuteReducer';
import authPromptReducer from './reducers/authPromptReducer';

interface StoreType {
  digitalAssistant: DigitalAssistantState;
  vm: VMInitialState;
  authenticationState: AuthState;
  journalState: {
    entryData: FormattedRecordDataType[];
    isJournalOpenedWhileDPEnabled: boolean;
  };
  commerce: CommerceInitialState;
  noteManager: NoteManagerState;
  pannellumReducerState: PannellumReducerInitialState;
  panoramicVideoModalState: PanoramicVideoModalState;
  signInModal: SignInModalState;
  infoModal: InfoModalState;
  sessionTimeoutState: SessionTimeoutState;
  mediaMuteState: MediaMuteState;
  authPromptState: AuthPromptState;
}

const store = configureStore<StoreType>({
  reducer: {
    digitalAssistant: digitalAssistantReducer,
    vm: vmReducer,
    authenticationState: authenticationReducer,
    journalState: journalReducer,
    commerce: commerceReducer,
    noteManager: noteManagerReducer,
    pannellumReducerState: pannellumReducer,
    panoramicVideoModalState: panoramicVideoReducer,
    signInModal: signInModalReducer,
    infoModal: infoModalReducer,
    sessionTimeoutState: sessionTimeoutModalReducer,
    mediaMuteState: mediaMuteReducer,
    authPromptState: authPromptReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
