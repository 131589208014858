/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box } from '@chakra-ui/react';
import {
  setActiveProductVariant,
  setIsHeaderModalsShown,
} from '../../../../store/reducers/commerceReducer';
import { AppDispatch, RootState } from '../../../../store/store';
import EachMultipleRecommendationCard from './EachMultipleRecommendationCard';
import getRandomProducts from '../../../../helpers/products/getRandomProducts';
import { CustomizeMakeupProductType } from '../../../../global-components/GlobalTypes';

declare const window;

const Container = styled.div`
  @media only screen and (max-width: 1024px) {
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 630px) {
    width: 95%;
  }

  .multiple-product-recommendation-slider {
    width: 230px;
    margin-left: var(--chakra-sizes-4);

    // hide product-info button if current slider is inactive
    .product-info-button {
      display: none;
    }
    .slick-current {
      .product-info-button {
        display: unset;
      }
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slickSlide {
    transition: 0.5s;
    display: block;
    height: 100%;
  }

  .slick-current div {
    opacity: 1;
  }

  .slick-next:before,
  .slick-prev:before {
    color: #000;
  }

  @media (max-width: 1300px) {
    .imageContainer {
      width: 600px;
    }
  }
  @media (max-width: 1100px) {
    .imageContainer {
      width: 700px;
    }
  }
  @media (max-width: 950px) {
    .imageContainer {
      width: 500px;
    }
  }
`;

const MultipleProductRecommendation = ({
  data,
  isCardInTranscript,
}: {
  data?: { advancedSelectedCategory: 'Face' | 'Lips' };
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const [isAddItemButtonClicked, setIsAddItemButtonClicked] = useState(false);
  const [products, setProducts] = useState<CustomizeMakeupProductType[]>(null);
  const categorizedProductsData = useSelector(
    (state: RootState) => state.commerce.categorizedProductsData
  );

  const sliderSettings = {
    slidesToShow: products?.length > 1 ? 1.1 : 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    appendDots: (dots) => (
      <ul
        className="slick-dots"
        style={{
          width: '85%',
        }}
      >
        {' '}
        {dots}{' '}
      </ul>
    ),
  };

  useEffect(() => {
    // auto-opens live camera
    if (!window.YMK?.isLoaded()) {
      window.YMK?.open([true]);
    }
  }, []);

  useEffect(() => {
    // reset checkmark after 3 seconds
    if (isAddItemButtonClicked) {
      const timeout = setTimeout(() => {
        setIsAddItemButtonClicked(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
    return null;
  }, [isAddItemButtonClicked]);

  useEffect(() => {
    // save first product as active to use in future TryIt functionality (node_3.5.2E/H)
    if (products?.length) {
      const product = products[0];
      dispatch(
        setActiveProductVariant({
          productId: product.id,
          quantity: 1,
          name: product.name,
          productVariant: product.productVariants[0],
        })
      );
    }
  }, [products, dispatch]);

  useEffect(() => {
    const randomProducts = getRandomProducts(
      'multiple',
      categorizedProductsData,
      data?.advancedSelectedCategory ?? null
    );
    setProducts(randomProducts);
  }, [categorizedProductsData, data.advancedSelectedCategory]);

  useEffect(() => {
    // close drawers when this card is opened
    dispatch(
      setIsHeaderModalsShown({
        myBagDrawer: false,
        journalDrawer: false,
        profileDrawer: false,
      })
    );
  }, [dispatch]);

  return (
    <Container data-sm-content="products">
      <Slider
        {...sliderSettings}
        className="multiple-product-recommendation-slider slick-dotted"
      >
        {products?.map((el, index) => (
          <Box className="slickSlide" key={JSON.stringify(el)}>
            <EachMultipleRecommendationCard
              data={el}
              index={index}
              isCardInTranscript={isCardInTranscript}
            />
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default MultipleProductRecommendation;

MultipleProductRecommendation.defaultProps = {
  data: null,
};
