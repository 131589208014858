import { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, useMediaQuery } from '@chakra-ui/react';
import VirtualMirror from './VirtualMirror/VirtualMirror';
import { allowRedirection, closeAssistantChat, startConversation } from '../store/reducers/digitalAssistantReducer';
import { AppDispatch, RootState } from '../store/store';
import DigitalAssistantOverlay from '../components/DigitalAssistantOverlay';
import Transcript from '../components/ContentCards/Transcript';
import VMChatControls from '../components/VMChatControls';
import ContentCardSwitch from '../components/ContentCardSwitch';
import calculateVMDimensions from '../helpers/calculateVMDimensions';
import TextConstants from '../constants/TextConstants';
import { CardType } from '../global-components/GlobalTypes';
import TranscriptAnalyzer from '../components/TranscriptAnalyzer';
import { callOrchestrationOnDPMount } from '../global-components/RequestFactory';
import {
  useGetUserInfo,
  useIsUserLoggedIn,
} from '../helpers/userInfo/UserInfo';
import { setUserData } from '../store/reducers/authenticationReducer';
import {
  set360VideoPageRedirected,
  setDesertPageRedirectedFromAssistant,
} from '../store/reducers/pannellumReducer';
import { resetLogOutTimer } from '../store/reducers/sessionTimeoutReducer';
import { WhereToLeadType } from './AuthenticationFlow/welcome/WelcomePage';

declare const window;

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;

  .transcript-entry-content {
    position: relative;
    box-shadow: var(--chakra-shadows-sm);
    padding: 10px 14px;
    padding-right: 20px;
    border-radius: 0px 0.5rem 0.5rem 0.5rem;
    background-color: var(--chakra-colors-gray-100);
    color: var(--chakra-colors-gray-900);
  }

  ${({ showVirtualMirror, isIPadSize, showTranscript, isIPadProSize }) => {
    if (showTranscript && showVirtualMirror && isIPadProSize) {
      // show blured background image when transcript is opened
      return `
    background-image: url(${process.env.PUBLIC_URL}/images/VM-background-image-blurred.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      `;
    }
    if (showVirtualMirror && isIPadSize) {
      return `
    background-color:#fdf6f0;
    background-image: url(${process.env.PUBLIC_URL}/images/VM-background-image.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    `;
    }
    if (showVirtualMirror && !isIPadSize) {
      return `
    padding-top: 35px;
    padding-bottom: 18px;
    background-color:#fdf6f0;
    background-image: url(${process.env.PUBLIC_URL}/images/VM-background-image.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    `;
    }
    return '';
  }}
`;

const DigitalAssistantChatContainer = styled.div`
  ${({
    showVirtualMirror,
    isIPadSize,
    isLaptopSize,
    isLargerThan1050,
    vmWidth,
    vmHeight,
    isSmallerThan600,
  }) => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const handlePositionLeft = () => {
      // PerfectCorp max width
      if (isSmallerThan600) {
        return '5px';
      }
      if (isIPadSize) {
        // transcript is closed on this size
        return `calc(${(windowWidth - vmWidth) / 3}px + 5px)`;
      }
      if (isLaptopSize) {
        // transcript is closed on this size
        return `calc(${(windowWidth - vmWidth) / 2}px + 5px)`;
      }
      // transcript + VM (transcript is smaller)
      return `calc(${(windowWidth - vmWidth * 1.6) / 2}px + 5px)`;
    };

    if (showVirtualMirror && isLargerThan1050) {
      return `
      max-height: 900px;
      width: 200px !important;
      padding-left: 20px;
      padding-right: 20px;
      `;
    }

    if (showVirtualMirror && (isIPadSize || isLaptopSize)) {
      return `
      position: absolute;
      z-index: 1;
      width: 100px !important;
      height: 150px !important;
      top: ${
        isIPadSize
          ? '50px'
          : // calculate top padding/margin
            `calc(${(windowHeight - vmHeight) / 2}px + 20px)`
      };
      left:${handlePositionLeft()};
      `;
    }

    return `height: 100%`;
  }};
`;

const VMContainer = styled.div`
  display: flex;
  height: 100%;
  position: relative;

  ${({ isIPadProSize, vmWidth }) => {
    // set width for transcript on mobile devices
    if (isIPadProSize) {
      return `width:${vmWidth}px;
    `;
    }
  }};

  ${({ showVirtualMirror, isIPadSize, isLaptopSize, isLargerThan1050 }) => {
    if (showVirtualMirror && isLargerThan1050) {
      return `
      max-height: 900px;
      `;
    }

    if (showVirtualMirror && isLaptopSize) {
      return `
      max-height: 900px;
      margin:auto;
     `;
    }
    if (showVirtualMirror && isIPadSize) {
      return `
      margin-top: 0;`;
    }

    return '';
  }};
`;

const VMTranscriptContainer = styled.div`
  overflow: hidden;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 24px 24px;
  max-height: 900px;
  min-width: 352px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  ${({ showVirtualMirror, isLaptopSize }) => {
    if (showVirtualMirror && isLaptopSize) {
      return `
      max-width: 370px;
      height: 100%;`;
    }

    return 'max-width: 450px;';
  }};

  ${({ isIPadProSize }) => {
    if (!isIPadProSize) {
      return `
    background: #f8dbc9;
    `;
    }

    return ` position: absolute;
    max-width: 600px;
    align-self: center;
    width: 100%;
    z-index: 2;`;
  }};
`;

const DigitalAssistantChat = (): JSX.Element => {
  const [isSmallerThan600] = useMediaQuery('(max-width: 600px)');
  const [isIPadSize] = useMediaQuery(
    `(max-width: ${TextConstants.SIZES.IPAD_WIDTH}px)`
  );
  const [isIPadProSize] = useMediaQuery(
    `(max-width: ${TextConstants.SIZES.IPAD_PRO_WIDTH}px)`
  );
  const [isLaptopSize] = useMediaQuery([
    '(min-width: 769px)',
    '(max-width: 1050px)',
  ]);
  const [isLargerThan1050] = useMediaQuery('(min-width: 1050px)');

  const [vmWidth, vmHeight] = calculateVMDimensions();

  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();

  const showVirtualMirror = useSelector(
    (state: RootState) => state.vm.showVirtualMirror
  );
  const userData = useGetUserInfo();
  const isUserLoggedIn = Boolean(useIsUserLoggedIn());
  const isUserLoggedInBeforeDPOpening = useSelector(
    (state: RootState) =>
      state.authenticationState.isUserLoggedInBeforeDPOpening
  );
  const error = useSelector((state: RootState) => state.digitalAssistant.error);
  const disconnected = useSelector((state: RootState) => state.digitalAssistant.disconnected);
  const connected = useSelector((state: RootState) => state.digitalAssistant.connected);
  const activeCards = useSelector((state: RootState) => state.digitalAssistant.activeCards);
  const showTranscript = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );
  const speechState = useSelector((state: RootState) => state.digitalAssistant.speechState);

  // video elem ref used to link proxy video element to displayed video
  const videoRef = useRef<HTMLVideoElement>(null);

  const setUserDataInRedux = (result) => {
    dispatch(setUserData(result));
  };

  const cleanup = useCallback(() => {
    dispatch(closeAssistantChat());
  }, [dispatch]);

  // eslint-disable-next-line
  useEffect(() => {
    return () => cleanup();
  }, [cleanup]);

  window.onbeforeunload = () => {
    cleanup();
  };

  useEffect(() => {
    if (error !== null) history.push('/loading?error=true');
  }, [error, history]);

  useEffect(() => {
    // handle disconnected condition
    if (disconnected) {
      if (localStorage.getItem('whereToLead') === WhereToLeadType.mainPage) {
        history.push(TextConstants.PATHNAMES.MAIN_PAGE);
      } else if (localStorage.getItem('whereToLead') === WhereToLeadType.city) {
        // set state before pushing route to 360 video page (otherwise hotspots aren't shown)
        dispatch(set360VideoPageRedirected(true));

        history.push(`/${TextConstants.PATHNAMES.CITY_PAGE}`);
      } else if (
        localStorage.getItem('whereToLead') === WhereToLeadType.desert
      ) {
        // set state before pushing route to 360 video page (otherwise hotspots aren't shown)
        dispatch(set360VideoPageRedirected(true));

        dispatch(setDesertPageRedirectedFromAssistant(true));

        history.push(`/${TextConstants.PATHNAMES.DESERT_PAGE}`);
      }
    }
  }, [disconnected, history, dispatch]);

  useEffect(() => {
    dispatch(allowRedirection(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetLogOutTimer());
  }, [dispatch, speechState === 'speaking']);

  useEffect(() => {
    if (isUserLoggedInBeforeDPOpening && isUserLoggedIn) {
      callOrchestrationOnDPMount(userData, setUserDataInRedux).catch((err) => {
        console.log(err);
      });
    }
  }, []);

  useEffect(() => {
    dispatch(startConversation({}));
  }, [dispatch]);

  const CardDisplay = activeCards.map((card: CardType, index: number) => (
    <Box
      key={JSON.stringify(card)}
      className="content-card-switch-container"
      d="flex"
      justifyContent="center"
      alignItems="end"
      height="100%"
      pb="20px"
    >
      <ContentCardSwitch card={card} index={index} isCardInTranscript={false} />
    </Box>
  ));

  const renderVMTranscriptContent = () => {
    if (showTranscript) {
      return <Transcript />;
    }

    if (activeCards.length) {
      console.log("Active cards length is greater than ", activeCards.length);
      return CardDisplay;
    }

    return <Box w="100%" h="100%" />;
  };

  if (connected) {
    return (
      <MainContainer
        className="MainDPChatContainer"
        showVirtualMirror={showVirtualMirror}
        isIPadSize={isIPadSize}
        showTranscript={showTranscript}
        isIPadProSize={isIPadProSize}
      >
        {!showVirtualMirror && (
          <DigitalAssistantChatContainer
            className="DigitalAssistantChatContainer"
            showVirtualMirror={showVirtualMirror}
            isIPadSize={isIPadSize}
            isLaptopSize={isLaptopSize}
            isLargerThan1050={isLargerThan1050}
            vmWidth={vmWidth}
            vmHeight={vmHeight}
            isSmallerThan600={isSmallerThan600}
          >
            <DigitalAssistantOverlay />
            <TranscriptAnalyzer />
          </DigitalAssistantChatContainer>
        )}

        {showVirtualMirror && (
          <>
            <VMContainer
              className="vmcontainer"
              vmWidth={vmWidth}
              showVirtualMirror={showVirtualMirror}
              isIPadSize={isIPadSize}
              isLaptopSize={isLaptopSize}
              isIPadProSize={isIPadProSize}
              isLargerThan1050={isLargerThan1050}
            >
              <VirtualMirror />
              {showTranscript && (
                <VMTranscriptContainer
                  className="vm-transcript-container"
                  showVirtualMirror={showVirtualMirror}
                  isLaptopSize={isLaptopSize}
                  isIPadProSize={isIPadProSize}
                >
                  {renderVMTranscriptContent()}
                  <VMChatControls />
                </VMTranscriptContainer>
              )}
            </VMContainer>
            <TranscriptAnalyzer />
          </>
        )}
      </MainContainer>
    );
  } else {
    return <Box />;
  }
};

export default DigitalAssistantChat;
