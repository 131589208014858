import { Button, Link } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import StyleWrapper from './StyleWrapper';
import TextConstants from '../../../constants/TextConstants';
import { openMakeupAccordionSliderByWatsonFeature } from '../../../store/reducers/commerceReducer';
import { AppDispatch } from '../../../store/store';

const BrowsAndVideoTutorialOptions = ({
  isCardInTranscript,
}: {
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const handleClick = (
    category: 'Cheeks' | 'Lips' | 'Eyes' | 'Miscellaneous',
    subcategory: string,
    selectedProduct: string
  ) => {
    dispatch(
      openMakeupAccordionSliderByWatsonFeature({
        category,
        subcategory,
        selectedProduct,
      })
    );
  };

  return (
    <StyleWrapper>
      <>
        <Button
          fontSize={{ base: 'small', sm: '1rem' }}
          variant="secondary"
          p="20px"
          w="100%"
          m="0 auto"
          borderRadius="0.5rem"
          _focus={{ boxShadow: 'none' }}
          onClick={() => {
            handleClick(
              'Eyes',
              'Brow Pencil/Gel',
              'Brow Harmony Pencil And Gel'
            );
          }}
          disabled={isCardInTranscript}
        >
          {TextConstants.COMMERCE.BROW_HARMONY_PENCIL_AND_GEL_PRODUCT}
        </Button>
        <Link
          href={TextConstants.COMMERCE.LINKS.BROW_HARMONY_PENCIL_AND_GEL}
          isExternal
        >
          <Button
            fontSize={{ base: 'small', sm: '1rem' }}
            variant="secondary"
            p="20px"
            w="100%"
            m="0 auto"
            borderRadius="0.5rem"
            _focus={{ boxShadow: 'none' }}
            disabled={isCardInTranscript}
          >
            {TextConstants.COMMERCE.VIDEO_TUTORIAL}
          </Button>
        </Link>
      </>
    </StyleWrapper>
  );
};

export default BrowsAndVideoTutorialOptions;
