import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalContent,
  Text,
  ModalOverlay,
  Stack,
  HStack,
  Image,
} from '@chakra-ui/react';
import TextConstants from '../../../constants/TextConstants';
import signupIcon from '../../../assets/icons/signup-icon.png';
import {
  emailValidation,
  passwordValidation,
} from '../../../helpers/validation';

type CreateAccountValues = {
  email: string;
  password: string;
};
type CreateAccountType = {
  isModalOpen: boolean;
  closeModal: (param: boolean) => void;
  openLogin: () => void;
  openSignupSteps: (data: CreateAccountValues) => void;
  signUpResponseError: string;
};

const SignUpModal = ({
  isModalOpen,
  closeModal,
  openLogin,
  openSignupSteps,
  signUpResponseError,
}: CreateAccountType): JSX.Element => {
  const [requestError, setRequestError] = useState({
    isErrorShown: false,
    errorText: '',
  });

  const closeHandlerFunction = () => {
    closeModal(true);
  };

  const validationSchema = Yup.object().shape({
    // TODO, temporary validation, import from TextConstants
    email: emailValidation(),
    password: passwordValidation(),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      openSignupSteps(values);
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });
  const { setFieldError, setErrors } = formik;
  useEffect(() => {
    if (signUpResponseError) {
      setFieldError('password', signUpResponseError);
      setFieldError('email', ''); // if there's an error, make email field red and show the error only in the bottom field
    } else {
      setErrors({});
    }
  }, [isModalOpen, signUpResponseError, setFieldError, setErrors]);

  return (
    <Modal
      onClose={() => {
        closeHandlerFunction();
        formik.resetForm();
        setRequestError({ isErrorShown: false, errorText: '' });
      }}
      isOpen={isModalOpen}
      isCentered
    >
      <ModalOverlay
        backdropFilter="auto"
        backdropInvert="10%"
        backdropBlur="0px"
      />
      <ModalContent borderRadius="0">
        <Image
          mt="20px"
          alignSelf="center"
          boxSize="48px"
          objectFit="cover"
          src={signupIcon}
        />
        <Text textStyle="title" textAlign="center">
          {TextConstants.MODALS.SIGN_UP_MODAL.TITLE}
        </Text>
        <Text textStyle="subTitle" textAlign="center" mt="8px">
          {TextConstants.MODALS.SIGN_UP_MODAL.SUBTITLE}{' '}
        </Text>
        <Stack
          spacing="16px"
          mt="16px"
          mb="16px"
          mx="16px"
          as="form"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
          justifyContent="center"
        >
          <FormControl isInvalid={formik.errors.email !== undefined}>
            <FormLabel fontSize="sm" mb="6px" color="gray.700">
              {' '}
              {TextConstants.MODALS.SIGN_UP_MODAL.EMAIL_LABEL}
            </FormLabel>
            <Input
              borderRadius="none"
              textStyle="title"
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              placeholder={TextConstants.MODALS.SIGN_UP_MODAL.EMAIL_PLACEHOLDER}
              color="gray.900"
              fontSize="md"
            />
            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
          </FormControl>

          <Stack spacing={7}>
            <FormControl isInvalid={formik.errors.password !== undefined}>
              <FormLabel fontSize="sm" mb="6px" color="gray.700">
                {TextConstants.MODALS.SIGN_IN_MODAL.PASSWORD_INPUT_LABEL}
              </FormLabel>
              <Input
                name="password"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                placeholder={
                  TextConstants.MODALS.SIGN_IN_MODAL.PASSWORD_INPUT_PLACEHOLDER
                }
                color="gray.900"
                fontSize="md"
                borderRadius="none"
              />
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl>

            <Button type="submit">
              {TextConstants.MODALS.SIGN_UP_MODAL.BUTTON_TEXT}
            </Button>
            <HStack mt="24px" justifyContent="center">
              <Text color="gray.500" fontSize="sm">
                {TextConstants.MODALS.SIGN_UP_MODAL.ALREADY_HAVE_ACCOUNT}
              </Text>
              <Button
                variant="link"
                onClick={() => {
                  closeModal(false);
                  openLogin();
                }}
              >
                {TextConstants.MODALS.SIGN_UP_MODAL.Login}
              </Button>
            </HStack>
          </Stack>
          {requestError.isErrorShown && (
            <Alert status="error">
              <AlertIcon />
              {requestError.errorText}
            </Alert>
          )}
        </Stack>
      </ModalContent>
    </Modal>
  );
};

export default SignUpModal;
