import { MutableRefObject, useState } from 'react';
import Movable, { OnDrag, OnDragEnd, OnDragStart } from 'react-moveable';
import { MovableBounds } from '../../../interfaces/Notes';

type MovableNoteProps = {
  targetElementRef: MutableRefObject<HTMLDivElement | null>,
  mirrorWidth: number,
  mirrorHeight: number,
  onDragStart: (e: OnDragStart) => void,
  onDragEnd: (e: OnDragEnd) => void,
  onDrag: (e: OnDrag) => void
};

const MovableNote= ({
  mirrorWidth,
  mirrorHeight,
  targetElementRef,
  onDragStart,
  onDragEnd,
  onDrag,
}: MovableNoteProps): JSX.Element => {
  const [bounds] = useState<MovableBounds>({
    left: 0,
    right: mirrorWidth,
    bottom: mirrorHeight,
    top: 0,
  });

  return (
    <Movable
      clickable={false}
      hideDefaultLines
      snappable
      draggable
      origin={false}
      target={targetElementRef}
      bounds={bounds}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDrag={onDrag}
    />
  );
};

export default MovableNote;
