import { Stack, Text } from '@chakra-ui/react';
import { FormikProps } from 'formik';
import { FormikSignUpValues } from './SignupStepsModal';
import TextConstants from '../../../../constants/TextConstants';
import CountryDropdown from '../../../common/CountryDropdown/CountryDropdown';

const SignupCountryStep = ({ formik }: { formik: FormikProps<FormikSignUpValues> }): JSX.Element => {
  return (
    <Stack spacing="16px" mx="16px" justifyContent="center">
      <Text textStyle="title" textAlign="center">
        {TextConstants.MODALS.SIGN_UP_MODAL.ALMAST_THERE}
      </Text>
      <Text textStyle="subTitle" textAlign="center" mt="8px">
        {TextConstants.MODALS.SIGN_UP_MODAL.ALMAST_THERE_DESCRIPTION}
      </Text>
      <CountryDropdown
        value={formik.values.country}
        onChange={(event) => {
          formik.setFieldValue('country', event.target.value);
        }}
      />
    </Stack>
  );
};

export default SignupCountryStep;
