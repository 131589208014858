import { useState, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Tab,
  TabPanel,
  Text,
} from '@chakra-ui/react';
import TextConstants from '../../../constants/TextConstants';
import {
  getEntryDetailsData,
  getShareData,
} from '../../../global-components/RequestFactory';
import checkShareRecordsUrl from '../../../helpers/journal/checkShareRecordsUrl';
import {
  useGetUserInfo,
  useIsUserLoggedIn,
} from '../../../helpers/userInfo/UserInfo';
import { setUserData } from '../../../store/reducers/authenticationReducer';
import Journal from './Journal';
import { SVG } from '../../icons/icons';
import Share from './Share';

function JournalWrapper(): JSX.Element {
  const dispatch = useDispatch();
  const { token } = useParams();
  const isSharePageOpened = checkShareRecordsUrl();
  const islogin = useIsUserLoggedIn();
  const userData = useGetUserInfo();
  const [isSelected, setIsSelected] = useState(0);
  const [isOpenNewEntry, setIsOpenNewEntry] = useState(false);
  const days = 365;
  const date = new Date();
  const dateEnd = new Date(date.getTime() + 2 * 24 * 60 * 60 * 1000);
  const dateStart = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
  const [dateRange, setDateRange] = useState([dateStart, dateEnd]);
  const [startDate, endDate] = dateRange;

  const setUserDataInRedux = (result) => {
    dispatch(setUserData(result));
  };

  const getEntryDetailsDataFunction = () =>
    getEntryDetailsData(
      {
        dateStart: dateStart.toISOString().split('T')[0],
        dateEnd: dateEnd.toISOString().split('T')[0],
        limit: TextConstants.JOURNAL.JOURNAL_DATA_LIMIT,
        offset: TextConstants.JOURNAL.JOURNAL_DATA_OFFSET,
      },
      userData,
      setUserDataInRedux
    );

  const getShareDataFunction = () => getShareData(token);

  const renderShareTab = (isEntryData) => (
    <Tab
      isDisabled={!isEntryData}
      display="flex"
      gap="5px"
      fontFamily="Inter"
      fontStyle="normal"
      fontWeight="500"
      fontSize="14px"
      width="100%"
      border="1px solid var(--chakra-colors-gray-300)"
      color="var(--chakra-colors-gray-700)"
      _selected={{ bg: 'var(--chakra-colors-gray-50)' }}
    >
      <SVG id="journal-share" />
      {TextConstants.JOURNAL.SHARE_TAB}
    </Tab>
  );

  const renderJournalDetails = () => (
    <Flex
      alignItems="center"
      display="flex"
      gap="5px"
      position="absolute"
      right="20px"
      bottom="10px"
      lineHeight="20px"
      fontSize="14px"
      color="var(--chakra-colors-gray-500)"
      letterSpacing="0.15em"
      onClick={() => setIsSelected(2)}
      cursor="pointer"
    >
      <SVG id="journal-details" />
      {TextConstants.JOURNAL.JOURNAL_DETAILS}
    </Flex>
  );

  const renderNewEntryButton = () => (
    <Box pt="35px" pr="20px" pl="20px" pb="20px">
      <Button
        variant="no-radius"
        onClick={() => {
          setIsOpenNewEntry(true);
        }}
        width="100%"
        height="40px"
        gap="10px"
        background="#fdf6f0"
        border="1px solid var(--chakra-colors-brand-600)"
        color="var(--chakra-colors-brand-600)"
        _hover={{ background: '#fdf6f0' }}
      >
        <SVG id="journal-add-icon" />
        <Text
          fontFamily="inter"
          fontStyle="normal"
          fontWeight="500"
          fontSize="14px"
          letterSpacing="0.15em"
        >
          {TextConstants.JOURNAL.ENTRIES_ADD_BUTTON}
        </Text>
      </Button>
    </Box>
  );

  const renderModal988Button = (handleClick: () => void) => (
    <Box px="20px" pb="20px">
      <Button
        variant="no-radius"
        onClick={() => {
          handleClick();
        }}
        width="100%"
        height="40px"
        gap="10px"
        background="#fdf6f0"
        border="1px solid var(--chakra-colors-brand-600)"
        color="var(--chakra-colors-brand-600)"
        _hover={{ background: '#fdf6f0' }}
      >
        <SVG id="phone-forwarded" />
        <Text
          fontFamily="inter"
          fontStyle="normal"
          fontWeight="500"
          fontSize="14px"
          letterSpacing="0.15em"
        >
          {TextConstants.JOURNAL.MODAL_988_BUTTON}
        </Text>
      </Button>
    </Box>
  );

  const handleCloseNewEntry = () => {
    setIsOpenNewEntry(false);
  };

  const renderShareTabPanel = () => (
    <TabPanel
      paddingTop="15px"
      paddingBottom="0px"
      display="flex"
      flexDirection="column"
      pl="0px"
      pr="0px"
      height="67vh"
      position="relative"
    >
      <Share />
    </TabPanel>
  );

  const renderCustomDateInput = (
    props,
    ref: React.LegacyRef<HTMLInputElement>
  ) => (
    <Input
      disabled={!islogin}
      placeholder="Select dates"
      background="#FFFFFF"
      value={props.value}
      ref={ref}
      onClick={props.onClick}
      onChange={props.onChange}
      pl="40px"
      boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
      borderRadius="none"
      width="100%"
    />
  );
  const CustomInput = forwardRef(renderCustomDateInput);

  const renderAnalysisDatePicker = () => (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <SVG id="calendar" />
      </InputLeftElement>
      <DatePicker
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        placeholderText={TextConstants.JOURNAL.ANALYSIS_PLACEHOLDER}
        customInput={<CustomInput />}
      />
    </InputGroup>
  );

  const getJournalAnalysisDataFunction = () => {
    const requestStartDate = new Date(startDate);
    requestStartDate.setDate(requestStartDate.getDate() + 1);
    const requestEndDate = new Date(endDate);
    requestEndDate.setDate(requestEndDate.getDate() + 1);
    return getEntryDetailsData(
      {
        dateStart: requestStartDate.toISOString().split('T')[0],
        dateEnd: requestEndDate.toISOString().split('T')[0],
        limit: TextConstants.JOURNAL.JOURNAL_DATA_LIMIT,
        offset: TextConstants.JOURNAL.JOURNAL_DATA_OFFSET,
      },
      userData,
      setUserDataInRedux
    );
  };
  return isSharePageOpened ? (
    <Journal
      getJournalData={getShareDataFunction}
      isSelected={isSelected}
      setIsSelected={setIsSelected}
      isOpenNewEntry={isOpenNewEntry}
      handleCloseNewEntry={handleCloseNewEntry}
      getJournalAnalysisData={getShareDataFunction}
    />
  ) : (
    <Journal
      shouldGetJournalData={Boolean(islogin)}
      getJournalData={getEntryDetailsDataFunction}
      isSelected={isSelected}
      setIsSelected={setIsSelected}
      isOpenNewEntry={isOpenNewEntry}
      handleCloseNewEntry={handleCloseNewEntry}
      getJournalAnalysisData={getJournalAnalysisDataFunction}
      shouldGetJournalAnalysisData={Boolean(islogin && startDate && endDate)}
      renderModal988Button={renderModal988Button}
      renderShareTab={renderShareTab}
      renderJournalDetails={renderJournalDetails}
      renderNewEntryButton={renderNewEntryButton}
      renderShareTabPanel={renderShareTabPanel}
      renderAnalysisDatePicker={renderAnalysisDatePicker}
    />
  );
}

export default JournalWrapper;
