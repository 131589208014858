import { useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import TextConstants from '../../constants/TextConstants';
import { forgotPasswordRequest } from '../../global-components/RequestFactory';
import { emailValidation } from '../../helpers/validation';

const Container = styled.div``;

const ForgotPasswordModal = ({
  isModalOpen,
  closeForgotPasswordModal,
}: {
  isModalOpen: boolean;
  closeForgotPasswordModal: () => void;
}): JSX.Element => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [requestResponse, setRequestResponse] = useState({
    isAlertShown: false,
    alertText: '',
  });

  const validationSchema = Yup.object().shape({
    email: emailValidation(),
  });

  const hideAlertResponse = () => {
    setRequestResponse({ isAlertShown: false, alertText: '' });
  };
  const showAlertResponse = (alertMessage) => {
    setRequestResponse({ isAlertShown: true, alertText: alertMessage });
  };
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      setIsRequesting(true);
      forgotPasswordRequest({
        email: values.email,
      })
        .then(() => {
          showAlertResponse(
            TextConstants.MODALS.FORGOT_PASSWORD_MODAL.FORGOT_PASSWORD_SUCCESS
          );
          setIsRequestSuccess(true);
          setTimeout(() => {
            hideAlertResponse();
            closeForgotPasswordModal();
            setIsRequesting(false);
          }, TextConstants.ALERT_MESSAGE_TRASHHOLD);
          formik.resetForm();
        })
        .catch((err) => {
          showAlertResponse(err.message);
          setIsRequestSuccess(false);
          setTimeout(() => {
            hideAlertResponse();
            setIsRequesting(false);
          }, TextConstants.ALERT_MESSAGE_TRASHHOLD);
        });
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Container>
      <Modal
        onClose={() => {
          closeForgotPasswordModal();
          formik.resetForm();
          hideAlertResponse();
        }}
        isOpen={isModalOpen}
        isCentered
        blockScrollOnMount
        preserveScrollBarGap
      >
        <ModalOverlay
          backdropFilter="auto"
          backdropInvert="10%"
          backdropBlur="0px"
        />
        <ModalContent borderRadius="0">
          <ModalHeader mt="15px" textAlign="center">
            {TextConstants.MODALS.FORGOT_PASSWORD_MODAL.TITLE}
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              closeForgotPasswordModal();
              formik.resetForm();              
            }}
          />
          <Stack
            spacing={3}
            mt="10px"
            mb="40px"
            mx="45px"
            as="form"
            justifyContent="center"
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <FormControl isInvalid={formik.errors.email !== undefined}>
              <FormLabel>
                {TextConstants.MODALS.FORGOT_PASSWORD_MODAL.EMAIL_INPUT_LABEL}
              </FormLabel>
              <Input
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder={
                  TextConstants.MODALS.FORGOT_PASSWORD_MODAL
                    .EMAIL_INPUT_PLACEHOLDER
                }
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
            <Stack spacing={7}>
              <Button my={3} type="submit" disabled={isRequesting}>
                {TextConstants.MODALS.FORGOT_PASSWORD_MODAL.BUTTON_TEXT}
              </Button>
            </Stack>

            {requestResponse.isAlertShown && (
              <Alert status={isRequestSuccess ? 'success' : 'error'}>
                <AlertIcon />
                {requestResponse.alertText}
              </Alert>
            )}
          </Stack>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default ForgotPasswordModal;
