import { Button, Link } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import StyleWrapper from './StyleWrapper';
import TextConstants from '../../../constants/TextConstants';
import { AppDispatch, RootState } from '../../../store/store';
import { getNewNote } from '../../../services/Notes';
import { addNote } from '../../../store/reducers/noteManagerReducer';
import { NOTE_MAX_AMOUNT } from '../../../constants/Notes';
import { sendTextMessage } from '../../../store/reducers/digitalAssistantReducer';

const RareReminderFromSelenaOptions = ({
  isCardInTranscript,
}: {
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const { notes, mirrorDimensions } = useSelector(
    (state: RootState) => state.noteManager
  );

  const createNote = () => {
    if (notes.length === NOTE_MAX_AMOUNT) {
      return;
    }
    const newNote = getNewNote(mirrorDimensions.width, mirrorDimensions.height);
    dispatch(addNote(newNote));
  };

  return (
    <StyleWrapper>
      <>
        <Link
          href={TextConstants.COMMERCE.LINKS.RARE_REMINDER_TWITTER}
          isExternal
        >
          <Button
            fontSize={{ base: 'small', sm: '1rem' }}
            variant="secondary"
            p="20px"
            w="100%"
            m="0 auto"
            borderRadius="0.5rem"
            _focus={{ boxShadow: 'none' }}
            disabled={isCardInTranscript}
          >
            {TextConstants.COMMERCE.RARE_REMINDERS}
          </Button>
        </Link>
        <Button
          fontSize={{ base: 'small', sm: '1rem' }}
          variant="secondary"
          p="20px"
          w="100%"
          m="0 auto"
          borderRadius="0.5rem"
          _focus={{ boxShadow: 'none' }}
          onClick={createNote}
          disabled={isCardInTranscript}
        >
          {TextConstants.COMMERCE.WRITE_NEW_REMINDER}
        </Button>

        <Button
          fontSize={{ base: 'small', sm: '1rem' }}
          variant="secondary"
          p="20px"
          w="100%"
          m="0 auto"
          borderRadius="0.5rem"
          _focus={{ boxShadow: 'none' }}
          onClick={() => {
            dispatch(sendTextMessage({ text: 'proceed' }));
          }}
          disabled={isCardInTranscript}
        >
          {TextConstants.BUTTON_TEXT.MOVE_ON}
        </Button>
      </>
    </StyleWrapper>
  );
};

export default RareReminderFromSelenaOptions;
