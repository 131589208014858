import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Image, Text, Tooltip, useMediaQuery } from '@chakra-ui/react';
import Slider from 'react-slick';
import styled from 'styled-components';
import leftArrowIcon from '../../../img/arrow-left-icon.svg';
import rightArrowIcon from '../../../img/arrow-right-icon.svg';
import { SVG } from '../../../components/icons/icons';
import {
  CustomizeMakeupColorType,
  CustomizeMakeupProductType,
} from '../../../global-components/GlobalTypes';
import faceFilterIcon from '../../../img/face-filter-icon.png';
import removeActiveClassHelper from '../../../helpers/products/removeActiveClassHelper';
import checkSliderArrowDisabledOrNo from '../../../helpers/checkSliderArrowDisabledOrNo';
import {
  removeActiveProductVariant,
  removeSelectedProductVariant,
} from '../../../store/reducers/commerceReducer';
import { AppDispatch, RootState } from '../../../store/store';
import TextConstants from '../../../constants/TextConstants';
import { checkIfMobile } from '../../../helpers/checkDevicePlatform';

declare const window;

const SliderContainer = styled.div`
  padding: 0px 25px 0px 25px;
  display: flex;
  margin-top: 10px;

  .slick-slide {
    padding-bottom: 4px;
  }

  .slick-list {
    padding-top: 2px;
  }
  .color-button {
    display: flex;
    justify-content: center;
    width: 33px;
    height: 33px;
    margin-left: 8px;
    outline: none;
  }

  .isActive {
    border: 1px solid #fdf7f952;
    box-shadow: 0px 0px 3px 3px rgb(127 37 73);
  }
  .color-span {
    border: 1px solid #8c3c5b52;
  }
  .slick-dots {
    display: none !important;
  }
`;

const Circle = styled.div`
  height: 30px;
  width: 30px !important;
  border-radius: 50%;
  display: inline-block;
  ${({ color }) => ` background-color:${color};`};
`;

function LeftArrow(props) {
  const { onClick, className } = props;
  return (
    <Image
      onClick={onClick}
      src={leftArrowIcon}
      style={{
        display: checkSliderArrowDisabledOrNo(className),
        left: '-25px',
        fontSize: '0',
        lineHeight: 0,
        position: 'absolute',
        top: '40%',
        width: '20px',
        height: '20px',
        padding: '0',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
      }}
    />
  );
}

function RightArrow(props) {
  const { onClick, className } = props;
  return (
    <Image
      onClick={onClick}
      src={rightArrowIcon}
      style={{
        display: checkSliderArrowDisabledOrNo(className),
        right: '-25px',
        fontSize: '0',
        lineHeight: 0,
        position: 'absolute',
        top: '40%',
        width: '20px',
        height: '20px',
        padding: '0',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
      }}
    />
  );
}

const ColorSlider = ({
  selectedProductData,
  setSelectedColorFunction,
  handleResetButton,
}: {
  selectedProductData: CustomizeMakeupProductType;
  setSelectedColorFunction: (color: CustomizeMakeupColorType) => void;
  handleResetButton: () => void;
}): JSX.Element => {
  const [isShadeFinderTooltipOpened, setIsShadeFinderTooltipOpened] =
    useState(false);

  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );
  const [isSmallerThanIPadProSize] = useMediaQuery(
    `(max-width: ${TextConstants.SIZES.IPAD_PRO_WIDTH}px)`
  );

  const dispatch = useDispatch<AppDispatch>();
  const isShadeFinderCompleted = useSelector(
    (state: RootState) => state.commerce.isShadeFinderCompleted
  );
  const activeProductVariant = useSelector(
    (state: RootState) => state.commerce.activeProductVariant
  );

  const [swiped, setSwiped] = useState(false);

  const isActiveRef = useRef(false);

  const handleSwiped = useCallback(() => {
    setSwiped(true);
  }, [setSwiped]);

  const clickResetButton = () => {
    if (selectedProductData?.patternsData?.colors?.length) {
      window.YMK.reset(selectedProductData.patternsData.colors[0].skuId);
      if (activeProductVariant) {
        dispatch(
          removeSelectedProductVariant(activeProductVariant.productVariant.sku)
        );
      }
      dispatch(removeActiveProductVariant());
    }
    handleResetButton();
    const removingElements = document.querySelectorAll(`[data-color-slider]`);
    removeActiveClassHelper(removingElements);
    setSelectedColorFunction(null);
  };

  const handleShadeFinderTooltip = () => {
    if (isShadeFinderTooltipOpened) {
      if (isSmallerThanIPadProSize && isTranscriptOpen) {
        // fixes tooltip opens when fullscreen transcript is open (small devices)
        return false;
      } else {
        return true;
      }
    }
  };

  const handleOnItemClick = useCallback(
    (event, color) => {
      if (swiped) {
        event.stopPropagation();
        event.preventDefault();
        setSwiped(false);
      } else {
        setSelectedColorFunction(color);
      }
    },
    [swiped]
  );

  const maxCount = selectedProductData?.patternsData?.colors?.length + 2;

  useEffect(() => {
    const allDataPatternSliders =
      document.querySelectorAll(`[data-color-slider]`);
    allDataPatternSliders[0].classList.add('isActive');
    if (maxCount) {
      setSelectedColorFunction(selectedProductData.patternsData.colors[0]);
    }
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 2000,
    slidesToShow: maxCount ? Math.min(maxCount, 7) : 0,
    slidesToScroll: maxCount ? Math.min(maxCount, 7) : 0,
    initialSlide: 0,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    onSwipe: handleSwiped,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: maxCount ? Math.min(maxCount, 6) : 0,
          slidesToScroll: maxCount ? Math.min(maxCount, 6) : 0,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: maxCount ? Math.min(maxCount, 4) : 0,
          slidesToScroll: maxCount ? Math.min(maxCount, 4) : 0,
        },
      },
    ],
  };

  useEffect(() => {
    //hide shadefinder tooltip onclicking outside element

    // fixes initially clicked
    const timeout = setTimeout(() => {
      isActiveRef.current = true;
    });

    // fixes can't listen PerfectCorp module click
    const iframe = document.getElementById(
      'YMK-module-iframe'
    ) as HTMLIFrameElement;
    const iframeWindow = iframe?.contentWindow;

    const handleClick = () => {
      if (isActiveRef.current) {
        setIsShadeFinderTooltipOpened(false);
      }
    };

    document.addEventListener('click', handleClick);
    document.addEventListener('touchstart', handleClick);

    iframeWindow?.addEventListener('click', handleClick);
    iframeWindow?.addEventListener('touchstart', handleClick);

    return () => {
      clearTimeout(timeout);
      document.removeEventListener('touchstart', handleClick);
      document.removeEventListener('click', handleClick);
      iframeWindow?.removeEventListener('click', handleClick);
      iframeWindow?.removeEventListener('touchstart', handleClick);
    };
  }, []);

  useEffect(() => {
    // delay shadefinder tooltip opening, fixes when opened through openMakeupAccordionSliderByWatsonFeature()
    const delay = setTimeout(() => {
      setIsShadeFinderTooltipOpened(true);
    }, 500);

    return () => clearTimeout(delay);
  }, []);

  return (
    <>
      <SliderContainer>
        <Slider {...settings}>
          <Tooltip
            placement="top"
            hasArrow
            label={TextConstants.TOOLTIPS.RESET_SHADE_BUTTON}
            aria-label={TextConstants.TOOLTIPS.RESET_SHADE_BUTTON}
            bg="var(--chakra-colors-brand-600)"
            fontFamily="inter"
            fontStyle="normal"
            fontWeight="400"
            fontSize={{ base: '12px', md: '14px' }}
          >
            <Box ml="12px" cursor="pointer" onClick={clickResetButton}>
              <SVG id="reset-color" />
            </Box>
          </Tooltip>
          {selectedProductData?.isShadeFinderAvailable ? (
            <Box
              width="30px"
              mt={{ base: '-9px', md: '-9px' }}
              cursor="pointer"
              outline="none"
              // open/close on hover
              onMouseEnter={() => setIsShadeFinderTooltipOpened(true)}
              onMouseLeave={() => setIsShadeFinderTooltipOpened(false)}
            >
              <Tooltip
                isOpen={handleShadeFinderTooltip()}
                placement="top"
                hasArrow
                label={TextConstants.TOOLTIPS.SHADE_BUTTON}
                aria-label={TextConstants.TOOLTIPS.SHADE_BUTTON}
                bg="var(--chakra-colors-brand-600)"
                fontFamily="inter"
                fontStyle="normal"
                fontWeight="400"
                fontSize={{ base: '12px', md: '14px' }}
              >
                <Image width="50px" src={faceFilterIcon} />
              </Tooltip>
            </Box>
          ) : null}
          {selectedProductData?.patternsData?.colors.map((color, index) => (
            <Box key={index}>
              <Box>
                <button
                  key={color?.skuId}
                  type="button"
                  className="color-button"
                  onClickCapture={(event) => handleOnItemClick(event, color)}
                >
                  <Circle
                    className="color-span"
                    data-color-slider={`${color?.skuId}`}
                    color={color?.hexCode}
                  />
                </button>
              </Box>
              <Text
                w={color?.skuId?.length > 6 ? 'fit-content' : 'auto'}
                textAlign="center"
                alignSelf="center"
                fontFamily="inter"
                fontStyle="normal"
                fontWeight="400"
                fontSize={color?.skuId?.length > 6 ? '9px' : '10px'}
                textTransform="uppercase"
              >
                {color?.skuId}
              </Text>
            </Box>
          ))}
        </Slider>
      </SliderContainer>
    </>
  );
};

export default ColorSlider;
