import { useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import MyBagDrawerProduct from './MyBagDrawerProduct';
import backIcon from '../../../assets/icons/drawer-back-icon.png';
import bagIcon from '../../../assets/icons/bag-icon.png';
import { RootState } from '../../../store/store';
import {
  setAddProductsToBag,
  setIsHeaderModalsShown,
} from '../../../store/reducers/commerceReducer';
import TextConstants from '../../../constants/TextConstants';
import OrderContactInfo from './OrderContactInfo';
import { setSignInModal } from '../../../store/reducers/signInModalReducer';
import { useIsUserLoggedIn } from '../../../helpers/userInfo/UserInfo';
import useSignInModalCloseHandler from '../../../hooks/useSignInModalCloseHandler';
import { addOrUpdateCart, removeProductFromCart } from '../../../global-components/RequestFactory';
import { parsedAddCartRequest, parsedRemoveCartRequest } from '../../../utils/cartUtils';

function MyBagDrawer(): JSX.Element {
  const dispatch = useDispatch();
  const [isOrderContactInfoVisible, setIsOrderContactInfoVisible] =
    useState<boolean>(false);

  const isLoggedIn = useIsUserLoggedIn();
  const isMyBagDrawerOpen = useSelector(
    (state: RootState) => state.commerce.isHeaderModalsShown.myBagDrawer
  );
  const closeHandlerFunction = () => {
    dispatch(setIsHeaderModalsShown({ myBagDrawer: false }));
    setIsOrderContactInfoVisible(false);
  };

  const myBagDataItems = useSelector(
    (state: RootState) => state.commerce.myBagDataItems
  );

  const totalAmount = myBagDataItems.reduce(
    (previousValue, el) =>
      previousValue + el.productVariant?.price * el.quantity,
    0
  );

  useSignInModalCloseHandler(() => {
    if (isMyBagDrawerOpen) {
      setIsOrderContactInfoVisible(true);
    }
  }, [isMyBagDrawerOpen]);

  const handleCheckoutClick = () => {
    isLoggedIn
      ? setIsOrderContactInfoVisible(true)
      : dispatch(
          setSignInModal({
            isOpen: true,
            isSkipLoginButtonDisplayed: true,
          })
        );
  };

  const productVariantUpdateQuantity = (productVariant) => {
    const product = productVariant.productVariant;
    const newMyBagDataItems = [];
    myBagDataItems.forEach((el) => {
      if (product && el.productVariant) {
        if (el.productVariant.sku !== product.sku) {
          newMyBagDataItems.push(el);
        } else {
          newMyBagDataItems.push(productVariant);
        }
      }
    });
    if(isLoggedIn){
      const parsedCartReq = parsedAddCartRequest(newMyBagDataItems)
      addOrUpdateCart(isLoggedIn, {"cart_item": parsedCartReq})
    }
    dispatch(setAddProductsToBag(newMyBagDataItems));
  };

  function removeProduct(productVariant) {
    const product = productVariant.productVariant;
    const filteredArray = myBagDataItems.filter(
      (el) => el.productVariant.sku !== product.sku
    );
    if(isLoggedIn) {
       const parsedCartReq = parsedRemoveCartRequest(productVariant)
       removeProductFromCart(isLoggedIn, parsedCartReq)
    }
    dispatch(setAddProductsToBag(filteredArray));
  }

  const HeaderComponent = () => (
    <Box
      cursor="pointer"
      onClick={() => {
        closeHandlerFunction();
      }}
    >
      <HStack flexDirection="row">
        <Image src={backIcon} boxSize="12px" objectFit="contain" />
        <Text
          fontSize="14px"
          fontFamily="Inter"
          letterSpacing="0.1em"
          color="var(--chakra-colors-brand-700)"
          fontWeight="medium"
          textTransform="uppercase"
        >
          {TextConstants.DRAWER.MY_BAG_GOBACK_TITLE}
        </Text>
      </HStack>
    </Box>
  );

  const YourBagComponent = () => (
    <HStack flexDirection="row">
      <Image src={bagIcon} />
      <Text
        fontSize="14px"
        fontFamily="Inter"
        letterSpacing="0.1em"
        color="var(--chakra-colors-brand-700)"
        fontWeight="medium"
      >
        YOUR BAG
      </Text>
    </HStack>
  );

  const FooterComponent = () => (
    <VStack width="100%">
      <Text
        fontSize="18px"
        fontFamily="Inter"
        letterSpacing="0.1em"
        color="var(--chakra-colors-brand-700)"
        fontWeight="medium"
      >
        ${totalAmount}
      </Text>
      <Button
        colorScheme="brand"
        size="xl"
        borderRadius="0"
        fontWeight="500"
        width="100%"
        fontSize="14px"
        fontFamily="Inter"
        letterSpacing="0.18em"
        onClick={handleCheckoutClick}
        disabled={totalAmount === 0}
      >
        CHECKOUT
      </Button>
    </VStack>
  );

  return (
    <Drawer
      isOpen={isMyBagDrawerOpen}
      placement="right"
      onClose={closeHandlerFunction}
      size="sm"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <HeaderComponent />
        </DrawerHeader>

        {!isOrderContactInfoVisible && (
          <>
            <DrawerBody marginTop="15px">
              <YourBagComponent />
              {myBagDataItems
                ? myBagDataItems.map((productVariant) => (
                    <Box key={productVariant.productVariant.sku}>
                      <MyBagDrawerProduct
                        removeProduct={removeProduct}
                        productVariantUpdateQuantity={
                          productVariantUpdateQuantity
                        }
                        productVariant={productVariant}
                      />
                    </Box>
                  ))
                : null}
            </DrawerBody>
            <DrawerFooter>
              <FooterComponent />
            </DrawerFooter>
          </>
        )}
        {isOrderContactInfoVisible && (
          <OrderContactInfo
            cartItems={myBagDataItems}
            cancelCallback={() => setIsOrderContactInfoVisible(false)}
          />
        )}
      </DrawerContent>
    </Drawer>
  );
}

export default MyBagDrawer;
