import { useCallback, MouseEvent } from 'react';
import { BaseEditor, Editor, Transforms } from 'slate';

import { ReactEditor } from 'slate-react';
import { areConflictStates, isMarkButtonActive } from '../../../../utils/Notes';
import {IconButtonTypeEnum, TextFormatEnum} from "../../../../constants/Notes";
import IconButton from "./IconButton";

type ToolbarMarkButtonProps = {
  format: TextFormatEnum;
  editor: BaseEditor;
  buttonIconType: IconButtonTypeEnum
};

const ToolbarButton = ({
  format,
  editor,
  buttonIconType,
}: ToolbarMarkButtonProps): JSX.Element => {
  const handleApply = useCallback((event: MouseEvent) => {
    event.preventDefault();

    const isActive = isMarkButtonActive(editor, format);

    if (editor.selection) {
      ReactEditor.focus(editor as ReactEditor);

      Transforms.select(editor, editor.selection);
    }

    if (areConflictStates(editor, format)) {
      if (format === TextFormatEnum.INCREASED) {
        Editor.removeMark(editor, TextFormatEnum.DECREASED);
      } else {
        Editor.removeMark(editor, TextFormatEnum.INCREASED);
      }
    }

    if (isActive) {
      Editor.removeMark(editor, format);

      return;
    }

    Editor.addMark(editor, format, true);
  }, [format, editor]);

  return (
    <IconButton
      isDisableFocusOnClick
      buttonIconType={buttonIconType}
      onClick={handleApply}
      className={`toolbar-icon-button ${isMarkButtonActive(editor, format) ? 'active' : ''}`}
    />
  );
};

export default ToolbarButton;
