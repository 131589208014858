import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import Controls from './Controls';
import Transcript from './ContentCards/Transcript';
import DigitalCharacter from './DigitalCharacter';
import { RootState } from '../store/store';
import { stopTalking } from '../utils/textToSpeech';

const media = {
  maxw860: (...args) => css`
    @media (max-width: 860px) {
      ${css(...args)}
    }
  `,
  minw860maxh800: (...args) => css`
    @media (min-width: 860px) and (max-height: 800px) {
      ${css(...args)}
    }
  `,
  maxw1024: (...args) => css`
    @media (max-width: 1024px) {
      ${css(...args)}
    }`,
};

const DigitalAssistantOverlayContainer = styled.div`
  ${({ showVirtualMirror }) => ` 
  display: ${showVirtualMirror ? 'none' : ''};
  overflow: hidden;
  position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;  
    `}

  width: 800px;
  margin-left: auto;
  margin-right: auto;

  ${media.maxw860`
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;

    .digital-character-wrapper {
      position: absolute !important;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      -webkit-align-self: center !important;
      -ms-flex-item-align: center !important;
      align-self: center !important;
      overflow: hidden;
      width: 100% !important;
      height: 100% !important;
      z-index: -1;
    }

    .video-component-container {
      position: static !important;
      width: 100% !important;
      height: 100% !important;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
    }

    .video-component-container video {
      position: absolute !important;
    }

    .transcript-wrapper {
      opacity: 0.8;
      position: absolute;
      top: 0;
      width: 100% !important;
      height: 100% !important;
      padding-bottom: 80px !important;
    }

    .transcript-wrapper .transcript-list-group.welcome-chat {
      position: absolute;
      bottom: 0;
    }

    .footer-container {
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 0;
      margin: auto;
    }
  `}

  ${media.minw860maxh800`
    .digital-character-wrapper {
      width: 180px;
      height: 320px;
      max-width: 180px;
    }

    .video-component-container {
      width: 180px;
      height: 320px;
    }
  `}

  .contentcard-captions-controls-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .digital-character-with-transcript-wrapper {
    display: flex;
    flex-direction: row;
  }

  .card-wrapper {
    flex: 1;
  }

  .digital-character-wrapper {
    flex: 1;
  }

  .transcript-wrapper {
    flex: 1;
    align-self: start;
    padding-left: 15px;
    padding-right: 15px;
    height: calc(var(--page-content-height) - 60px);
    max-width: 500px;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    min-width: 352px;

    // hides scrollbar for not webkit browsers
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .transcript-wrapper::-webkit-scrollbar {
    // hides scrollbar for  webkit browsers
    display: none;
  }

  ${media.maxw1024`
    .imageContainer {
      background: rgba(255, 255, 255, 0.7);
    }

    .transcript-wrapper {
      max-width: 600px;
      align-self: start;
      padding-top: 40px;
      padding-bottom: 40px;
      width: 100%;
    }
  `}

  .footer-container {
    flex: 0;
  }
`;

const DigitalAssistantOverlay = (): JSX.Element => {
  const showVirtualMirror = useSelector(
    (state: RootState) => state.vm.showVirtualMirror
  );
  const showTranscript = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );

  useEffect(() => {
    return () => {
      stopTalking();
    };
  }, []);

  return (
    <DigitalAssistantOverlayContainer
      className="digital-assistant-overlay"
      showTranscript={showTranscript}
      showVirtualMirror={showVirtualMirror}
    >
      <div className="contentcard-captions-controls-container">
        <div className="digital-character-with-transcript-wrapper">
          <div className="digital-character-wrapper">
            <DigitalCharacter />
          </div>
          {!showVirtualMirror && !showTranscript && (
            <div className="card-wrapper">
              { /* @TODO ContentCardDisplay component was here, revise what to use here instead */ }
            </div>
          )}
          {!showVirtualMirror && showTranscript && (
            <div className="transcript-wrapper" data-sm-content="transcript">
              <Transcript showTranscriptHeader={false} />
            </div>
          )}
        </div>

        <div className="footer-container">
          <Controls />
        </div>
      </div>
    </DigitalAssistantOverlayContainer>
  );
};

export default DigitalAssistantOverlay;
