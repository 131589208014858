import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthPromptState } from '../../global-components/GlobalTypes';
import {
  getAuthPromptStateFromLocalStorage,
  setAuthPromptStateInLocalStorage,
} from '../../helpers/storage-helper/authPromptStorageHelper';

const initialState: AuthPromptState = {
  isOpened: getAuthPromptStateFromLocalStorage(),
  isExecuteAuthPromptModalCallback: false,
};

const authPromptSlice = createSlice({
  name: 'authPrompt',
  initialState,
  reducers: {
    setAuthPromptOpened: (state, { payload }: PayloadAction<boolean>) => {
      setAuthPromptStateInLocalStorage(payload);
      return {
        ...state,
        isOpened: payload,
      };
    },

    executeAuthPromptModalCallback: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      return {
        ...state,
        isExecuteAuthPromptModalCallback: payload,
      };
    },
  },
});

export const { setAuthPromptOpened, executeAuthPromptModalCallback } =
  authPromptSlice.actions;

export default authPromptSlice.reducer;
