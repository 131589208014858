import styled from 'styled-components';
import { useEffect, useState } from 'react';
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import EachJournalEntry from './EachJournalEntry';
import EntryDetails from './EntryDetails';
import LineChart from './LineChart';
import AddNewEntry from './AddNewEntry';
import Analysis from './Analysis';
import { SVG } from '../../icons/icons';
import TextConstants from '../../../constants/TextConstants';
import { RecordDataType } from '../../../global-components/GlobalTypes';
import changeJournalDataFormat from '../../../helpers/journal/changeJournalDataFormat';
import { RootState } from '../../../store/store';
import Modal988 from '../../modals/Modal988';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #fdf6f0;

  .new-entry-form {
    position: relative;
    height: 100%;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: rgba(127, 38, 73, 1);
  }

  .react-datepicker__navigation {
    top: 9px;
  }
  .react-datepicker__day--in-range {
    background-color: rgba(127, 38, 73, 1);
  }
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: rgba(127, 38, 73, 0.5);
  }
  .entries-line-chart {
    canvas {
      height: 100% !important;
    }
  }
`;
const entryChartOptions = {
  elements: {
    line: {
      tension: 0.3,
    },
    point: {
      pointStyle: 'line',
      borderWidth: 0,
      hoverRadius: 0,
    },
  },
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  scales: {
    y: { display: false },
    x: {
      display: false,
      adapters: {
        date: '',
        type: 'time',
        distribution: 'linear',
        time: {
          parser: 'yyyy-MM-dd',
          unit: 'month',
        },
      },
    },
  },
};

const Journal = ({
  getJournalData,
  isSelected,
  setIsSelected,
  isOpenNewEntry,
  handleCloseNewEntry,
  getJournalAnalysisData,
  shouldGetJournalData,
  shouldGetJournalAnalysisData,
  renderAnalysisDatePicker,
  renderShareTab,
  renderNewEntryButton,
  renderJournalDetails,
  renderModal988Button,
  renderShareTabPanel,
}: {
  getJournalData: () => Promise<{ count: number; records: RecordDataType[] }>;
  isSelected: number;
  setIsSelected: (number) => void;
  isOpenNewEntry: boolean;
  handleCloseNewEntry: () => void;
  getJournalAnalysisData: () => Promise<{
    count: number;
    records: RecordDataType[];
  }>;
  shouldGetJournalData?: boolean;
  shouldGetJournalAnalysisData?: boolean;
  renderAnalysisDatePicker?: () => JSX.Element;
  renderNewEntryButton?: () => JSX.Element;
  renderShareTabPanel?: () => JSX.Element;
  renderJournalDetails?: () => JSX.Element;
  renderShareTab?: (isEntryData: boolean) => JSX.Element;
  renderModal988Button?: (handleClick: () => void) => JSX.Element;
}): JSX.Element => {
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [isModal988Open, setIsModal988Open] = useState(false);
  const entryData =
    useSelector((state: RootState) => state.journalState.entryData) || [];
  const [fetchEntryData, setFetchEntryData] = useState([]);
  const [eachEntryData, setEachEntryData] = useState<RecordDataType | null>(
    null
  );
  const [isEntryData, setIsEntryData] = useState(
    entryData.length || (fetchEntryData && fetchEntryData.length)
  );

  useEffect(() => {
    setIsEntryData(
      entryData.length || (fetchEntryData && fetchEntryData.length)
    );
  }, [entryData, fetchEntryData]);

  useEffect(() => {
    if (shouldGetJournalData) {
      getJournalData()
        .then((data) => {
          setFetchEntryData(changeJournalDataFormat(data.records));
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  }, [isUpdate, entryData.length]);

  const handleTabsChange = (index) => {
    setIsSelected(index);
    setIsOpenDetails(false);
  };
  const handleEntryDetails = (entry: RecordDataType) => {
    setIsOpenDetails(true);
    setEachEntryData(entry);
  };
  const handleCloseDetails = () => {
    setIsOpenDetails(false);
  };

  const handleUpdate = () => {
    setIsUpdate(!isUpdate);
  };

  const closeModal988 = () => {
    setIsModal988Open(false);
  };

  return (
    <Container data-sm-content="journal">
      <>
        {!isOpenNewEntry ? (
          <Box position="relative" pr="30px" pl="30px" pb="20px" height="100%">
            <Tabs
              height="100%"
              index={isSelected}
              onChange={handleTabsChange}
              className="makeupProducts"
              pt="10px"
              pb="10px"
            >
              <TabList background="#FFFFFF">
                <Tab
                  display="flex"
                  gap="5px"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="500"
                  fontSize="14px"
                  width="100%"
                  border="1px solid var(--chakra-colors-gray-300)"
                  color="var(--chakra-colors-gray-700)"
                  _selected={{ bg: 'var(--chakra-colors-gray-50)' }}
                >
                  <SVG id="journal-entry" />
                  {TextConstants.JOURNAL.ENTRIE_TAB}
                </Tab>
                {renderShareTab(Boolean(isEntryData))}
                <Tab
                  isDisabled={!isEntryData}
                  display="flex"
                  gap="5px"
                  fontFamily="Inter"
                  fontStyle="normal"
                  fontWeight="500"
                  fontSize="14px"
                  width="100%"
                  border="1px solid var(--chakra-colors-gray-300)"
                  color="var(--chakra-colors-gray-700)"
                  _selected={{ bg: 'var(--chakra-colors-gray-50)' }}
                >
                  <SVG id="journal-analysis" />
                  {TextConstants.JOURNAL.ANALYSIS_TAB}
                </Tab>
              </TabList>
              {!isOpenDetails ? (
                <TabPanels height="100%">
                  <TabPanel
                    paddingTop="10px"
                    paddingBottom="0px"
                    display="flex"
                    flexDirection="column"
                    pl="0px"
                    pr="0px"
                    height="100%"
                  >
                    {isEntryData ? (
                      <>
                        <Text
                          mt="10px"
                          lineHeight="20px"
                          fontSize="13px"
                          color="var(--chakra-colors-brand-600)"
                        >
                          {TextConstants.JOURNAL.ENTRIES_TITLE}
                        </Text>
                        <Box
                          className="entries-line-chart"
                          position="relative"
                          mt="10px"
                          boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
                          background="#FFFFFF"
                          height="28vh"
                        >
                          {isEntryData ? (
                            <LineChart
                              options={entryChartOptions}
                              dataEntry={
                                shouldGetJournalData
                                  ? fetchEntryData
                                  : entryData
                              }
                            />
                          ) : null}
                          {renderJournalDetails()}
                        </Box>
                        <Box
                          pr="10px"
                          pl="5px"
                          pb="20px"
                          mt="10px"
                          boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
                          height="36vh"
                        >
                          <Text
                            lineHeight="20px"
                            fontSize="13px"
                            color="var(--chakra-colors-brand-600)"
                          >
                            {TextConstants.JOURNAL.ENTRIES_SUBTITLE}
                          </Text>
                          <Box w="100%" h="100%" overflow="auto">
                            {isEntryData ? (
                              <EachJournalEntry
                                data={
                                  shouldGetJournalData
                                    ? fetchEntryData
                                    : entryData
                                }
                                handleEntryDetails={handleEntryDetails}
                              />
                            ) : null}
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <Text
                        mt="10px"
                        lineHeight="20px"
                        fontSize="13px"
                        color="var(--chakra-colors-brand-600)"
                      >
                        {TextConstants.JOURNAL.JOURNAL_NULLABLE_DATA}
                      </Text>
                    )}
                    {renderNewEntryButton()}
                    {renderModal988Button(() => setIsModal988Open(true))}
                  </TabPanel>
                  {renderShareTabPanel()}
                  <TabPanel
                    paddingTop="10px"
                    paddingBottom="0px"
                    display="flex"
                    flexDirection="column"
                    pl="0px"
                    pr="0px"
                    height="100%"
                  >
                    <Analysis
                      renderAnalysisDatePicker={renderAnalysisDatePicker}
                      isUpdate={isUpdate}
                      getJournalAnalysisData={getJournalAnalysisData}
                      shouldGetJournalAnalysisData={
                        shouldGetJournalAnalysisData
                      }
                    />
                  </TabPanel>
                </TabPanels>
              ) : null}
            </Tabs>

            {isOpenDetails && eachEntryData ? (
              <EntryDetails
                eachEntryData={eachEntryData}
                handleCloseDetails={handleCloseDetails}
              />
            ) : null}
          </Box>
        ) : (
          <AddNewEntry
            handleEntryDetails={handleEntryDetails}
            handleUpdate={handleUpdate}
            handleCloseNewEntry={handleCloseNewEntry}
          />
        )}
      </>
      <Modal988 isModal988Open={isModal988Open} closeModal988={closeModal988} />
    </Container>
  );
};
export default Journal;

Journal.defaultProps = {
  shouldGetJournalData: true,
  shouldGetJournalAnalysisData: true,
  renderAnalysisDatePicker: () => {},
  renderShareTab: () => {},
  renderNewEntryButton: () => {},
  renderJournalDetails: () => {},
  renderModal988Button: () => {},
  renderShareTabPanel: () => {},
};
