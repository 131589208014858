import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box, Image, Button, Text, useMediaQuery } from '@chakra-ui/react';
import HHIcon from '../../../common/HHIcon/HHIcon';
import { AppDispatch, RootState } from '../../../../store/store';
import TextConstants from '../../../../constants/TextConstants';
import { setAddProductsToBag } from '../../../../store/reducers/commerceReducer';
import logo from '../../../../assets/icons/rare-beauty.png';
import {
  CustomizeMakeupProductType,
  SearchedProductType,
} from '../../../../global-components/GlobalTypes';
import useOpenCustomizeMakeup from '../../../../helpers/products/openCustomizeMakeup';

declare const window;

const Container = styled.div`
  height: 100%;
  padding: 15px;
  background: #fdfbf8;
  border-radius: 0.5rem;
  box-shadow: var(--chakra-shadows-sm);
  display: flex;
  flex-direction: column;
  position: relative;

  .circle-button {
    border-radius: 50%;
    color: var(--chakra-colors-brand-700);
    display: flex;
    width: calc(11 * 0.25rem);
    height: calc(11 * 0.25rem);

    &:hover {
      background-color: var(--chakra-colors-brand-100);
    }
  }

  @media (max-height: 667px) {
    width: 95%;
  }
`;

const EachSearchedProductCard = ({
  data,
  index,
  isCardInTranscript,
}: {
  data: SearchedProductType;
  index: number;
  isCardInTranscript: boolean;
}): JSX.Element => {
  const ymkModuleLoadedListener = useRef(null);
  const dispatch = useDispatch<AppDispatch>();

  const [isSmallerThanIphoneSEHeight] = useMediaQuery(
    `(max-height:${TextConstants.SIZES.IPHONE_SE_HEIGHT}px)`
  );
  const [isAddItemButtonClicked, setIsAddItemButtonClicked] = useState(false);

  const categorizedProductsData = useSelector(
    (state: RootState) => state.commerce.categorizedProductsData
  );
  const myBagVariants = useSelector(
    (state: RootState) => state.commerce.myBagDataItems
  );

  const [product, setProduct] = useState<CustomizeMakeupProductType | null>(
    null
  );

  const openProductinCustomizeMakeup = useOpenCustomizeMakeup('product', data);

  useEffect(() => {
    ymkModuleLoadedListener.current = window.YMK?.addEventListener(
      'cameraOpened',
      () => {
        if (data && index === 0) {
          // auto-opens first product in customize makeup and applies shade
          openProductinCustomizeMakeup();
        }
      }
    );
  }, []);

  useEffect(() => {
    // remove ymk module loaded listener when flow moves forward
    if (isCardInTranscript && ymkModuleLoadedListener.current) {
      window.YMK?.removeEventListener(ymkModuleLoadedListener.current);
    }
  }, [isCardInTranscript]);

  const handleTryButtonClick = useCallback(() => {
    if (!window.YMK?.isLoaded()) {
      window.YMK?.open([true]);
    }

    openProductinCustomizeMakeup();
  }, [openProductinCustomizeMakeup]);

  useEffect(() => {
    // auto-opens first product in customize makeup and applies shade
    if (data && index === 0 && !isCardInTranscript) {
      handleTryButtonClick();
    }
  }, [handleTryButtonClick, isCardInTranscript, index, data]);

  const addItemToBag = useCallback(
    (productData) => {
      if (productData && productData.length) {
        if (!myBagVariants || !myBagVariants.length) {
          dispatch(setAddProductsToBag(productData));
        } else {
          let newMyBagData = myBagVariants;
          for (let i = 0; i < productData.length; i++) {
            const selectedProductVariant = productData[i];
            const repeatingVariant =
              myBagVariants.filter(
                (variant) =>
                  variant.productVariant &&
                  variant.productVariant.sku ===
                    selectedProductVariant.productVariant.sku
              ) || [];
            if (!repeatingVariant.length) {
              newMyBagData = [...newMyBagData, selectedProductVariant];
            }
          }
          dispatch(setAddProductsToBag(newMyBagData));
        }
      }
    },
    [myBagVariants, dispatch]
  );

  useEffect(() => {
    if (categorizedProductsData) {
      categorizedProductsData.forEach((category) => {
        category.children.forEach((subcategory) => {
          subcategory.children.forEach((iteratedProduct) => {
            if (iteratedProduct.id === data?.categoryId) {
              setProduct(iteratedProduct);
            }
          });
        });
      });
    }
  }, [categorizedProductsData, data.categoryId]);

  useEffect(() => {
    if (isAddItemButtonClicked) {
      const timeout = setTimeout(() => {
        setIsAddItemButtonClicked(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
    return null;
  }, [isAddItemButtonClicked]);

  const renderCardContent = () => (
    <Box className="card-content" flex="auto" paddingTop="0.5rem">
      <Text
        as="h3"
        className="card-title"
        fontFamily="Ogg"
        fontSize="16px"
        fontWeight="400"
      >
        {data?.name}
      </Text>
      <Text
        className="card-price"
        textStyle="text-sm"
        marginTop="0.75rem"
        fontWeight="500"
        fontFamily="var(--chakra-fonts-third)"
      >
        ${data?.price}
      </Text>
    </Box>
  );

  const renderCardImage = () => (
    <Box
      className="card-image-container"
      height={isSmallerThanIphoneSEHeight ? '150px' : 'unset'}
      position="relative"
    >
      <Image
        src={data ? data?.image?.staticStorageUrl : logo}
        alt={data?.name}
        height="20vh"
        width="100%"
        objectFit="fill"
        mx="auto"
      />
    </Box>
  );

  const renderMobileButtons = () => (
    <Box
      className="card-buttons"
      display="flex"
      gap="10px"
      position="absolute"
      left="-10px"
    >
      <Button
        className="circle-button"
        onClick={handleTryButtonClick}
        border="1px solid"
        background="transparent !important"
        _hover={{ background: 'var(--chakra-colors-brand-100)  !important' }}
      >
        <HHIcon icon="vm-mobile-mirror" />
      </Button>
      <Button
        className="circle-button"
        bg={isAddItemButtonClicked ? 'brand.100' : 'brand.50'}
        onClick={() => {
          addItemToBag([
            {
              productId: product && product.id,
              quantity: 1,
              name: product && product.name,
              productVariant: product && product.productVariants[0],
            },
          ]);
          setIsAddItemButtonClicked(true);
        }}
      >
        <HHIcon icon={isAddItemButtonClicked ? 'check' : 'shopping-bag'} />
      </Button>
    </Box>
  );

  const renderDesktopButtons = () => (
    <>
      <Button
        variant="no-radius"
        onClick={handleTryButtonClick}
        py="0"
        px="15px"
        height="auto"
      >
        {TextConstants.COMMERCE.TRY_IT_BUTTON}
      </Button>
      <Button
        className="circle-button"
        bg={isAddItemButtonClicked ? 'brand.100' : 'brand.50'}
        onClick={() => {
          addItemToBag([
            {
              productId: product && product.id,
              quantity: 1,
              name: product && product.name,
              productVariant: product && product.productVariants[0],
            },
          ]);
          setIsAddItemButtonClicked(true);
        }}
      >
        <HHIcon icon={isAddItemButtonClicked ? 'check' : 'shopping-bag'} />
      </Button>
    </>
  );

  return (
    <Container className="each-searched-products-card-container">
      {renderCardImage()}
      {renderCardContent()}
      <Box
        display={{ xl: 'flex', base: 'none' }}
        justifyContent="space-between"
        mt="15px"
      >
        {renderDesktopButtons()}
      </Box>

      <Box
        display={{ base: 'block', xl: 'none' }}
        position="absolute"
        left="50%"
      >
        {renderMobileButtons()}
      </Box>
    </Container>
  );
};

export default EachSearchedProductCard;
