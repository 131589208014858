import { useSelector } from 'react-redux';
import { UserDataType } from '../../global-components/GlobalTypes';
import { RootState } from '../../store/store';

export const useIsUserLoggedIn = (): string => {
  const data = useSelector(
    (state: RootState) => state.authenticationState.userData
  );
  return data && data.token;
};

export const useGetUserInfo = (): UserDataType | null => {
  const data = useSelector(
    (state: RootState) => state.authenticationState.userData
  );
  return data || null;
};
