import { useSlate } from 'slate-react';

import ToolbarButton from './ToolbarButton';
import {IconButtonTypeEnum, TextFormatEnum} from "../../../../constants/Notes";

const Toolbar = (
): JSX.Element => {
  const editor = useSlate();

  return (
    <div className="d-flex justify-content-between align-items-start">
      <div className="d-flex">
        <ToolbarButton
          format={TextFormatEnum.BOLD}
          editor={editor}
          buttonIconType={IconButtonTypeEnum.BOLD}
        />
        <ToolbarButton
          format={TextFormatEnum.ITALIC}
          editor={editor}
          buttonIconType={IconButtonTypeEnum.ITALIC}
        />

        <ToolbarButton
          format={TextFormatEnum.UNDERLINE}
          editor={editor}
          buttonIconType={IconButtonTypeEnum.UNDERLINE}
        />
        <ToolbarButton
          format={TextFormatEnum.INCREASED}
          editor={editor}
          buttonIconType={IconButtonTypeEnum.INCREASED}
        />
        <ToolbarButton
          format={TextFormatEnum.DECREASED}
          editor={editor}
          buttonIconType={IconButtonTypeEnum.DECREASED}
        />
      </div>
    </div>
  );
};

export default Toolbar;
