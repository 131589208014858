const localDomainRegExp = /(localhost|([0-9]{1,3}.){3}[0-9]{1,3})(:[0-9]{4})?/;
const ROOT_DOMAIN_PARTS_COUNT = 2;

export const isLocalDomain = (): boolean => {
  return localDomainRegExp.test(window.location.host);
}

export const getRootDomain = (): string => {
  return window.location.host.split('.').slice(-ROOT_DOMAIN_PARTS_COUNT).join('.');
}

export const getDomain = (): string | undefined => {
  return isLocalDomain() ? undefined : getRootDomain();
}
