import { forwardRef } from 'react';
import { FormControl, FormLabel, Input, Stack, Text } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import { FormikSignUpValues } from './SignupStepsModal';
import TextConstants from '../../../../constants/TextConstants';

const Container = styled.div`
  .react-datepicker-popper {
    position: unset !important;
    transform: unset !important;
    .react-datepicker__day--keyboard-selected {
      background-color: rgba(127, 38, 73, 1);
    }
    .react-datepicker__day--in-range {
      background-color: rgba(127, 38, 73, 1);
    }
    .react-datepicker__day--selected {
      background-color: var(--chakra-colors-brand-500);
    }
  }
  .react-datepicker__navigation {
    top: 9px;
  }
  .react-datepicker__year-read-view--down-arrow {
    top: 2px;
  }
`;

const SignupBirthdayStep = ({
  formik,
}: {
  formik: FormikProps<FormikSignUpValues>;
}): JSX.Element => {
  const customDateInput = (props, ref: React.LegacyRef<HTMLInputElement>) => (
    <Input
      type="button"
      placeholder="MM/DD/YYYY"
      background="#FFFFFF"
      value={props.value ? props.value : 'MM/DD/YYYY'}
      ref={ref}
      onClick={props.onClick}
      onChange={props.onChange}
      _placeholder={{ color: 'gray.900' }}
      borderWidth="1px"
      borderColor="gray.300"
      borderRadius="0px"
      boxShadow="unset"
      fontFamily="third"
    />
  );
  const CustomInput = forwardRef(customDateInput);

  return (
    <Container>
      <Stack spacing="16px" mx="16px" justifyContent="center">
        <Text textStyle="title" textAlign="center">
          {TextConstants.MODALS.SIGN_UP_MODAL.ALMAST_THERE}
        </Text>
        <Text textStyle="subTitle" textAlign="center" mt="8px">
          {TextConstants.MODALS.SIGN_UP_MODAL.ALMAST_THERE_DESCRIPTION}
        </Text>
        <FormControl
          isInvalid={formik.errors.dateOfBirth !== undefined}
          pt="20px"
        >
          <FormLabel
            htmlFor="dateOfBirth"
            fontFamily="Inter"
            fontSize="14px"
            lineHeight="20px"
            color="gray.700"
          >
            {TextConstants.MODALS.SIGN_UP_MODAL.BIRTHDAY_LABEL}
          </FormLabel>
          <DatePicker
            name="dateOfBirth"
            placeholderText="MM/DD/YYYY"
            selected={formik.values.dateOfBirth}
            clearButtonClassName="datepicker-clear-button"
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={100}
            maxDate={new Date()}
            onChange={(value) => {
              if (value === null) {
                formik.setFieldValue('dateOfBirth', '');
              } else {
                formik.setFieldValue('dateOfBirth', value);
              }
            }}
            showPopperArrow
            customInput={<CustomInput />}
          />
          {/* <FormErrorMessage>{formik.errors.dateOfBirth}</FormErrorMessage> */}
        </FormControl>
      </Stack>
    </Container>
  );
};

export default SignupBirthdayStep;
