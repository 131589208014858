import {useCallback, useEffect} from "react";

export const CLOSE_SIGN_IN_MODAL_EVENT_NAME = 'closeSignInModal';

const useSignInModalCloseHandler = (callback: VoidFunction, dependencies: unknown[]) => {
  const handleClose = useCallback(callback, dependencies);

  useEffect(() => {
    document.addEventListener(CLOSE_SIGN_IN_MODAL_EVENT_NAME, handleClose);

    return () => {
      document.removeEventListener(CLOSE_SIGN_IN_MODAL_EVENT_NAME, handleClose);
    }
  }, [handleClose]);
};

export default useSignInModalCloseHandler;
