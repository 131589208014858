import { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Button, Image, List, ListItem } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { sendTextMessage, stopSpeaking } from '../../store/reducers/digitalAssistantReducer';
import TextConstants from '../../constants/TextConstants';
import { RootState } from '../../store/store';
import leftArrowIcon from '../../assets/icons/arrow-left-circle.png';
import rightArrowIcon from '../../assets/icons/arrow-right-circle.png';

const Container = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  padding: 0px 75px 15px 75px;
  max-width: 410px;
  max-height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
  ${({ isTranscriptOpen }) => `
    margin-left:${isTranscriptOpen ? '30px' : 'unset'};
  `}

  .slick-slide {
    padding: unset;
  }

  @media (max-width: ${TextConstants.SIZES.IPAD_PRO_WIDTH}px) {
    margin-bottom: 10px;
  }

  @media (max-width: ${TextConstants.SIZES.IPHONE_XR_WIDTH}px) {
    padding: 0px 60px 15px 60px;
    margin-bottom: 10px;
  }

  .slider-image-component {
    height: 300px;
  }

  // decrease card height (card is covered by feedback speech/captions )
  @media (max-height: 850px) {
    .slider-image-component {
      height: 250px;
    }
  }

  @media (max-height: 750px) {
    max-height: 65vh;
    .slider-image-component {
      height: 200px;
    }
    .buttonContainer {
      padding: 10px;
    }
  }

  @media (max-height: 650px) {
    max-height: 60vh;
  }

  .imageContainer {
    transition: 0.5s;
    display: block;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
  .slick-current div {
    opacity: 1;
  }
`;

const ImageSlider = ({
  data,
  isCardInTranscript,
}: {
  data: {
    id: number;
    question: string;
    followUpQuestion: string;
    type: 'image';
    noneOfThisAnswers: string[];
    items: {
      staticStorageUrl: string;
      contentUrl: string;
      originalName: string;
      id: number;
    }[];
  };
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // to hide right arrow on last item
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );
  const handleClick = (param: string) => {
    dispatch(stopSpeaking());
    dispatch(sendTextMessage({ text: param }));
    setIsButtonDisabled(true);
  };

  const imagesCount = data?.items?.length || 0;

  const LeftArrow = (props) => {
    const { onClick } = props;
    return (
      <Image
        // hides if first item
        display={currentIndex === 0 ? 'none' : 'block'}
        onClick={onClick}
        src={leftArrowIcon}
        boxSize={{ base: '50px', iPad: '70px' }}
        objectFit="contain"
        left={{ base: '-55px', iPad: '-75px' }}
        position="absolute"
        top="50%"
        cursor="pointer"
        border="none"
        outline="none"
        transform="translate(0, -50%)"
      />
    );
  };

  const RightArrow = (props) => {
    const { onClick } = props;
    return (
      <Image
        // hides if last item
        display={currentIndex === imagesCount - 1 ? 'none' : 'block'}
        onClick={onClick}
        src={rightArrowIcon}
        boxSize={{ base: '50px', iPad: '70px' }}
        objectFit="contain"
        right={{ base: '-55px', iPad: '-75px' }}
        position="absolute"
        top="50%"
        cursor="pointer"
        border="none"
        outline="none"
        transform="translate(0, -50%)"
      />
    );
  };

  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    appendDots: (dots) => <ul className="slick-dots">{dots}</ul>,
    afterChange: (index) => setCurrentIndex(index),
  };

  const renderImages = data?.items?.map((el, i) => (
    <Box
      key={el.id}
      maxW="sm"
      borderRadius="lg"
      overflow="hidden"
      data-sm-content={el.id}
      className="imageContainer"
      cursor="grab"
      _active={{ cursor: 'grabbing' }}
    >
      <Image
        className="slider-image-component"
        src={el?.staticStorageUrl}
        alt={el?.originalName}
        width="100%"
        objectFit="cover"
      />
      <Box p="3" className="buttonContainer" mt="1">
        <Button
          fontFamily="Inter"
          fontStyle="normal"
          fontWeight="500"
          fontSize="14px"
          lineHeight="20px"
          padding="8px 14px 8px 14px"
          letterSpacing="0.15em"
          textTransform="uppercase"
          disabled={isButtonDisabled || isCardInTranscript}
          onClick={() => handleClick(`image ${i + 1}`)}
        >
          {TextConstants.BUTTON_TEXT.SELECT}
        </Button>
      </Box>
    </Box>
  ));

  const renderOtherOptions = data?.noneOfThisAnswers?.map((otherOption) => (
    <ListItem key={otherOption}>
      <Button
        py="8px"
        disabled={isButtonDisabled || isCardInTranscript}
        cursor="pointer"
        variant="ghost"
        bg="#fff"
        w="100%"
        m="0 auto"
        height="auto"
        data-trigger-text={otherOption}
        key={otherOption}
        onClick={() => {
          handleClick(otherOption);
        }}
        whiteSpace="normal"
      >
        {otherOption}
      </Button>
    </ListItem>
  ));

  return (
    <Container
      isTranscriptOpen={isTranscriptOpen}
      className="image-slider-container"
    >
      <Slider {...sliderSettings} className="slider slick-dotted">
        {renderImages}
      </Slider>

      <List mt="45px" spacing={3}>
        {renderOtherOptions}
      </List>
    </Container>
  );
};

export default ImageSlider;
