import { Box, Button } from '@chakra-ui/react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0px 20px 0px;
  z-index: 1;
  border-top: 1px solid var(--chakra-colors-gray-200);
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const ProfileSectionButtons = ({
  cancelButtonTitle,
  saveButtonTitle,
  isDisabled,
  resetForm,
}: {
  cancelButtonTitle: string;
  saveButtonTitle: string;
  isDisabled: boolean;
  resetForm: () => void;
}): JSX.Element => (
  <Container>
    <Button
      size="sm"
      type="button"
      disabled={isDisabled}
      variant="secondary-gray"
      borderRadius="none"
      textTransform="uppercase"
      onClick={resetForm}
    >
      {cancelButtonTitle}
    </Button>
    <Box w="20px" />
    <Box h="20px" />
    <Button
      size="sm"
      type="submit"
      disabled={isDisabled}
      borderRadius="none"
      variant="squared"
      textTransform="uppercase"
    >
      {saveButtonTitle}
    </Button>
  </Container>
);

export default ProfileSectionButtons;
