import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MediaMuteState } from '../../global-components/GlobalTypes';

const initialState: MediaMuteState = {
  currentlyPlayingVideoID: null,
};

const MediaMuteSlice = createSlice({
  name: 'mediaMute',
  initialState,
  reducers: {
    setCurrentlyPlayingVideoID: (
      state,
      { payload }: PayloadAction<string | null>
    ) => ({
      ...state,
      currentlyPlayingVideoID: payload,
    }),
  },
});

export const { setCurrentlyPlayingVideoID } = MediaMuteSlice.actions;

export default MediaMuteSlice.reducer;
