import { createSlice } from '@reduxjs/toolkit';
import { NoteTransformedResponse } from '../../interfaces/Notes';

export interface MirrorDimensions {
  width: number;
  height: number;
}

export interface NoteManagerState {
  notes: NoteTransformedResponse[];
  mirrorDimensions: MirrorDimensions;
}

const initialState: NoteManagerState = {
  notes: [],
  mirrorDimensions: {
    width: 0,
    height: 0,
  },
};

export const noteManagerReducer = createSlice({
  name: 'noteManagerReducer',
  initialState,
  reducers: {
    setNotes: (state, { payload }) => ({
      ...state,
      notes: payload,
    }),
    addNote: (state, { payload }) => ({
      ...state,
      notes: [...state.notes, payload],
    }),
    changeNotePosition: (state, { payload }) => {
      const currentNote = state.notes.find(
        (note: NoteTransformedResponse) => note.id === payload.id
      );

      const filteredNotes = state.notes.filter(
        (note: NoteTransformedResponse) => note.id !== payload.id
      );

      const newNote: NoteTransformedResponse = {
        ...currentNote,
        position: payload.newPosition,
      };

      return {
        ...state,
        notes: [...filteredNotes, newNote],
      };
    },
    changeStackLevel: (state, { payload }) => {
      const currentNote = state.notes.find(
        (element: NoteTransformedResponse) => element.id === payload.id
      );

      const filteredNotes = state.notes.filter(
        (element: NoteTransformedResponse) => element.id !== payload.id
      );

      const resultNote: NoteTransformedResponse = {
        ...currentNote,
        stackLevel: payload.newStackLevel,
      };

      return {
        ...state,
        notes: [...filteredNotes, resultNote],
      };
    },
    changeNoteValue: (state, { payload }) => {
      const currentNote = state.notes.find(
        (element: NoteTransformedResponse) => element.id === payload.id
      );

      const filteredNotes = state.notes.filter(
        (element: NoteTransformedResponse) => element.id !== payload.id
      );

      const resultNote: NoteTransformedResponse = {
        ...currentNote,
        value: payload.value,
      };

      return {
        ...state,
        notes: [...filteredNotes, resultNote],
      };
    },
    deleteNote: (state, { payload }) => {
      const filteredNotes = state.notes.filter(
        (element: NoteTransformedResponse) => element.id !== payload
      );

      return {
        ...state,
        notes: [...filteredNotes],
      };
    },
    replaceNote: (state, { payload }) => {
      const filteredNotes = state.notes.filter(
        (element: NoteTransformedResponse) => element.id !== payload.id
      );

      return {
        ...state,
        notes: [...filteredNotes, payload.note],
      };
    },
    setMirrorDimensions: (state, { payload }) => ({
      ...state,
      mirrorDimensions: {
        width: payload.width,
        height: payload.height,
      },
    }),
  },
});

export const {
  setNotes,
  setMirrorDimensions,
  addNote,
  deleteNote,
  changeNoteValue,
  changeNotePosition,
  replaceNote,
  changeStackLevel,
} = noteManagerReducer.actions;

export default noteManagerReducer.reducer;
