import { debounce } from 'lodash';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DigitalCharacterAnimationStage } from '../global-components/GlobalTypes';
import { RootState } from '../store/store';

import TextConstants from '../constants/TextConstants';
const animationStages = TextConstants.DIGITAL_CHARACTER_ANIMATION_STAGE;

import characterHelloPositiveSpeakingUrl from '../assets/digital-character/hh-character-hello-positive-speaking.mp4';
import characterRegularNeutralIdleUrl from '../assets/digital-character/hh-character-regular-neutral-idle.mp4';
import characterRegularNeutralSpeakingUrl from '../assets/digital-character/hh-character-regular-neutral-speaking.mp4';
import characterRegularPositiveSpeakingUrl from '../assets/digital-character/hh-character-regular-positive-speaking.mp4';

const DigitalCharacterContainer = styled.div`
  .video-component-container {
    position: relative;
    width: 360px;
    height: 640px;
    overflow: hidden;
    background-color: transparent;
  }

  .video-component-container video {
    object-fit: cover;
    -webkit-object-fit: cover;
    -moz-object-fit: cover;
    -ms-object-fit: cover;

    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 97;
  }
`;

interface VideoComponentProps {
  videoSrc: string;
  isActive: boolean;
}

const VideoComponent: React.FC<VideoComponentProps> = ({ videoSrc, isActive }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current.defaultMuted = true;
    videoRef.current.muted = true;

    videoRef.current.load();
  }, [videoSrc]);

  return (
    <div className='video-component-container' style={ { display: isActive ? 'block' : 'none' } }>
      <video
        ref={videoRef}
        autoPlay
        muted
        playsInline
        loop
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
    </div>
  );
};

const getCharacterAnimationUrlByStage = (stage: DigitalCharacterAnimationStage) => {
  switch (stage) {
    case animationStages.HELLO_POSITIVE_SPEAKING: return characterHelloPositiveSpeakingUrl;
    case animationStages.REGULAR_NEUTRAL_IDLE: return characterRegularNeutralIdleUrl;
    case animationStages.REGULAR_NEUTRAL_SPEAKING: return characterRegularNeutralSpeakingUrl;
    case animationStages.REGULAR_POSITIVE_SPEAKING: return characterRegularPositiveSpeakingUrl;

    default: throw new Error('unknown animation stage');
  }
}

const DigitalCharacter: React.FC = () => {
  const [animationStage, setAnimationStage] = useState(animationStages.HELLO_POSITIVE_SPEAKING as DigitalCharacterAnimationStage);
  const [initialHelloStageActive, setInitialHelloStageActive] = useState(true);

  const desiredAnimationStage = useSelector((state: RootState) => state.digitalAssistant.digitalCharacterAnimationStage);

  const debouncedSetAnimationStage = useCallback(
    debounce((animationStage) => {
      setAnimationStage(animationStage);
    }, 1000),
    []
  );

  useEffect(() => {
    setTimeout(() => {
      if (initialHelloStageActive && animationStage === animationStages.HELLO_POSITIVE_SPEAKING) {
        setAnimationStage(animationStages.REGULAR_POSITIVE_SPEAKING as DigitalCharacterAnimationStage);
        setInitialHelloStageActive(false);

        return true;
      }
    }, 3330);
  }, []);

  useEffect(() => {
    if (!initialHelloStageActive) {
      debouncedSetAnimationStage(desiredAnimationStage);
    }
  }, [desiredAnimationStage]);

  const animationUrl = getCharacterAnimationUrlByStage(animationStage);

  return (
    <DigitalCharacterContainer>
      <VideoComponent videoSrc={characterHelloPositiveSpeakingUrl} isActive={animationUrl === characterHelloPositiveSpeakingUrl} />
      <VideoComponent videoSrc={characterRegularNeutralIdleUrl} isActive={animationUrl === characterRegularNeutralIdleUrl} />
      <VideoComponent videoSrc={characterRegularNeutralSpeakingUrl} isActive={animationUrl === characterRegularNeutralSpeakingUrl} />
      <VideoComponent videoSrc={characterRegularPositiveSpeakingUrl} isActive={animationUrl === characterRegularPositiveSpeakingUrl} />
    </DigitalCharacterContainer>
  );
};

export default DigitalCharacter;
