import { useEffect, useState } from 'react';
import { Modal, ModalContent, Progress, Text, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import TextConstants from '../../constants/TextConstants';

declare const window;

function LoadingModal({
  modalToOpen,
  isModalOpen,
  closeModal,
}: {
  modalToOpen: 'DP' | 'ShadeFinder';
  isModalOpen: boolean;
  closeModal: () => void;
}): JSX.Element {
  const connected = useSelector((state: RootState) => state.digitalAssistant.connected);
  const [progressPercentage, setProgressPercentage] = useState<number>(5);

  useEffect(() => {
    const handler = () => {
      closeModal();
    };

    if (window.YMK && modalToOpen === 'ShadeFinder') {
      window.YMK.addEventListener('shadeFinderStarted', handler);
    }

    return () => {
      if (window.YMK && modalToOpen === 'ShadeFinder') {
        window.YMK.removeEventListener('shadeFinderStarted', handler);
      }
    };
  }, [closeModal, modalToOpen]);

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        if (progressPercentage < 90) {
          setProgressPercentage(progressPercentage + 10);
        }
      },
      modalToOpen === 'DP' ? 2000 : 300
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [progressPercentage, modalToOpen]);

  useEffect(() => {
    // make progress 100% (DP)
    if (connected && modalToOpen === 'DP') {
      setProgressPercentage(100);
    }
  }, [connected, modalToOpen]);

  return (
    <Modal
      onClose={() => {
        // do nothing
      }}
      isOpen={isModalOpen}
      size="full"
      isCentered
      scrollBehavior="inside"
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalContent
        justifyContent="center"
        p="0px"
        borderRadius="0px"
        backdropFilter={modalToOpen === 'DP' ? '' : 'blur(30px)'}
        backgroundColor={
          modalToOpen === 'DP' ? '#344054b2' : 'rgba(52,64,84,0.8)'
        }
      >
        <VStack>
          <Text
            fontFamily="Ogg"
            color="#fff"
            p="10px"
            fontSize={{ base: '30px', iPadPro: '22px' }}
          >
            {//modalToOpen === 'DP'
              //? TextConstants.MODALS.DP_LOADING_MODAL_TITLE
              //:
              TextConstants.MODALS.SHADEFINDER_LOADING_MODAL_TITLE}
          </Text>
          <Progress
            value={progressPercentage}
            width={{ base: '90%', iPadPro: '30%' }}
            border="1px solid #fff"
            variant="transparent"
            height={{ base: '20px', iPadPro: '0.75rem' }}
          />
        </VStack>
      </ModalContent>
    </Modal>
  );
}

export default LoadingModal;
