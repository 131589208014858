import { Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import StyleWrapper from './StyleWrapper';
import TextConstants from '../../../constants/TextConstants';
import { applySuggestionLook } from '../../../store/reducers/commerceReducer';
import { AppDispatch } from '../../../store/store';

const RandomAndCuratedLookOptions = ({
  data,
  isCardInTranscript,
}: {
  data: {
    params: 'curated' | 'random';
  };
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const handleClick = () => {
    if (data?.params === 'curated') {
      dispatch(applySuggestionLook('curated'));
    } else {
      dispatch(applySuggestionLook('random'));
    }
  };

  return (
    <StyleWrapper>
      <Button
        fontSize={{ base: 'small', sm: '1rem' }}
        variant="secondary"
        p="20px"
        w="100%"
        m="0 auto"
        borderRadius="0.5rem"
        _focus={{ boxShadow: 'none' }}
        onClick={handleClick}
        disabled={isCardInTranscript}
      >
        {data?.params === 'curated'
          ? TextConstants.COMMERCE.CURATED_LOOK
          : TextConstants.COMMERCE.RANDOM_LOOK}
      </Button>
    </StyleWrapper>
  );
};

export default RandomAndCuratedLookOptions;
