const TextConstants = {
  TOOLTIPS: {
    SHADE_BUTTON:
      'Use our Shade Matching tool to automatically pick the best shades for you',
    RESET_SHADE_BUTTON: 'Reset',
    CURATE_LOOK: 'Curate Look',
    PREVIOUS_LOOK: 'Previous Look',
    NEXT_LOOK: 'Next Look',
    RESET_LOOK: 'Reset Look',
  },
  DIALOG_TYPE: {
    WELLNESS: 'wellness',
    ECOMMERCE: 'e-commerce',
  },
  CONVERSATION_RESULT_SOURCE: {
    PERSONA: 'persona',
    USER: 'user',
  },
  DIGITAL_CHARACTER_ANIMATION_STAGE: {
    HELLO_POSITIVE_SPEAKING: 'hello-positive-speaking',
    REGULAR_NEUTRAL_IDLE: 'regular-neutral-idle',
    REGULAR_NEUTRAL_SPEAKING: 'regular-neutral-speaking',
    REGULAR_POSITIVE_SPEAKING: 'regular-positive-speaking',
  },
  ALERT_MESSAGE_TRASHHOLD: 3300,
  PATHNAMES: {
    MAIN_PAGE: '/',
    DP_PAGE: 'video',
    CITY_PAGE: 'foyer-welcome',
    DESERT_PAGE: 'desert',
    JOURNAL_SHARE: '/diary-records/share/',
  },
  HEADER: {
    FIRST_SECTION: {
      TITLE: 'Intro',
      FIRST_OPTION: 'City',
      SECOND_OPTION: 'Desert',
      THIRD_OPTION: 'Overview',
    },
  },
  JOURNAL: {
    JOURNAL_DATA_OFFSET: '0',
    JOURNAL_DATA_LIMIT: '10000',
    NEW_ENTRY_TITLE: ' New Entry',
    NEW_ENTRY_SUBTITLE: 'Record your feelings here.',
    NEW_ENTRY_TEXT_LENGTH: 'characters left',
    NEW_ENTRY_ALERT_CONTENT: 'Your entry has successfully been submitted',
    NEW_ENTRY_SUBMIT_BUTTON: 'SUBMIT NEW ENTRY',
    MODAL_988_BUTTON: 'CONTACT 988',
    ANALYSIS_TITLE: 'Journal Overview Sentiment Analysis',
    ANALYSIS_PLACEHOLDER: 'Select dates',
    ENTRY_TITLE: 'Entry Details',
    ENTRY_CONTENT: 'Entry Content',
    ENTRY_ANALYSIS: 'Sentiment Analysis',
    ENTRIE_TAB: 'Entries',
    SHARE_TAB: 'Share',
    ANALYSIS_TAB: 'Analysis',
    ENTRIES_TITLE: 'Journal Sentiment Analysis Overview',
    ENTRIES_SUBTITLE: 'Recent Journal Entries',
    ENTRIES_ADD_BUTTON: 'ADD JOURNAL ENTRY',
    SHARE_TITLE: 'Enter your Therapists Email',
    SHARE_BUTTON: 'SHARE',
    SHARE_PLACEHOLDER: 'Email address here...',
    JOURNAL_SHARE_GUEST_TEXT: 'Please log in to share your journal entries',
    JOURNAL_SHARE_SUCCESS_MESSAGE: 'You have shared your journal entry',
    ADDED_JOURNAL_ENTRY_TEXT_LENGTH: 100,
    JOURNAL_NULLABLE_DATA: 'You don’t have any journal entries, yet.',
    JOURNAL_DETAILS: 'DETAILS',
  },

  WELLNESS_THERAPY: {
    THERAPY_PRIMER_DETAILS_BUTTON: 'Proceed',
    THERAPY_RECOMMENDATION_RESULT_OPTIONAL_BUTTON: ' Do something else',
    THERAPY_RECOMMENDATION_RESULT_TITLE:
      'Based on your answers, here are a few therapeutic approaches that might be best suited for you.',
  },

  PROFILE_MANAGMENT: {
    PROFILE_INFORMATION_SECTION: {
      COMPONENT_TITLE: 'Profile Information',
      COMPONENT_SUBTITLE: 'Update your photo and personal details here',

      FIRST_NAME_TITLE: 'First name',
      LAST_NAME_TITLE: 'Last name',
      EMAIL_TITLE: 'Email',
      UPDATE_PROFILE_INFORMATION_SUCCESS: 'The changes have been saved',
      IMAGE_UPLOAD_TEXT: {
        FIRST_PART: 'Click to upload',
        SECOND_PART: 'or drag and drop',
        THIRD_PART: ' SVG, PNG, JPG or GIF (max. 800x400px)',
      },
    },
    PERSONAL_INFO_SECTION: {
      COMPONENT_TITLE: 'Personal info',
      COMPONENT_SUBTITLE: 'Update your personal data',

      GENDER_TITLE: 'Which most closely describes your gender?',
      BIRTHDAY_TITLE: 'Date of Birth',
      COUNTRY_TITLE: 'What Country do you live in?',
      PHONE_NUMBER: 'Phone number',
      UPDATE_PERSONAL_INFO_SUCCESS: 'The changes have been saved',
    },
    PASSWORD_SECTION: {
      COMPONENT_TITLE: 'Password',
      COMPONENT_SUBTITLE:
        'Please enter your current password to change your password',

      CURRENT_PASSWORD_TITLE: 'Current password',
      CURRENT_PASSWORD_PLACEHOLDER: 'Enter current password',
      NEW_PASSWORD_TITLE: 'New password',
      NEW_PASSWORD_PLACEHOLDER: 'Enter new password',
      CONFIRM_PASSWORD_TITLE: 'Confirm new password',
      CONFIRM_PASSWORD_PLACEHOLDER: 'Confirm new password',
      UPDATE_PASSWORD_SUCCESS: 'Your password has been changed!',
    },
    ADDRESS_SECTION: {
      COMPONENT_TITLE: 'Address',
      COMPONENT_SUBTITLE: 'Please fill in your address information',

      ADDRESS_1_TITLE: 'Address Line 1',
      ADDRESS_1_PLACEHOLDER: 'Address Line 1',

      ADDRESS_2_TITLE: 'Address Line 2',
      ADDRESS_2_PLACEHOLDER: 'Address Line 2',

      CITY_TITLE: 'City',
      CITY_PLACEHOLDER: 'City',

      STATE_TITLE: 'State',
      STATE_PLACEHOLDER: 'State',

      ZIPCODE_TITLE: 'Zip code',
      ZIPCODE_PLACEHOLDER: 'Zip code',

      UPDATE_ADDRESS_SUCCESS: 'The changes have been saved',
    },
  },
  DRAWER: {
    PROFILE_GOBACK_TITLE: 'Go back',
    MY_BAG_GOBACK_TITLE: 'Continue shopping',
  },
  ERROR_TEXT: {
    FIRST_NAME:
      'Please enter a valid first name - should not be more than 25 characters, no spaces and no numbers.',
    FIRST_NAME_MAX_CHARACTERS: 'First Name must be at most 50 characters.',
    LAST_NAME:
      'Please enter a valid last name - should not be more than 25 characters, no spaces and no numbers.',
    LAST_NAME_MAX_CHARACTERS: 'Last Name must be at most 50 characters.',

    EMAIL: 'Please enter a valid email address.',
    PASSWORD: {
      VALIDATION_MIN: 'Password should be at least 8 characters.',
      VALIDATION_MAX: 'Password shouldn’t be more than 64 characters.',

      MATCH: 'Passwords must match.',
    },
    INVALID_ADDRESS: 'Invalid address.',
    INVALID_ZIP: 'Should be at least 4 characters.',
    MOBILE:
      'Please enter a valid phone number - 1st and 4th characters cannot be 1, should be 10 digits.',
    TERMS:
      'Please confirm that you consent with our Terms of use and Privacy Policy.',
    REQUIRED: 'This field is required.',
    PHONE_NUMBER: 'Please enter a valid phone number.',
  },

  MODALS: {
    SIGN_UP_MODAL: {
      TITLE: 'Create Account',
      SUBTITLE: 'Enter an email address and password.',
      BUTTON_TEXT: 'CREATE ACCOUNT',
      FIRST_NAME_LABEL: 'First Name',
      FIRST_NAME_PLACEHOLDER: 'Enter First Name',
      FIRST_NAME_MAX_CHARACTERS: 'First Name must be at most 50 characters',
      LAST_NAME_LABEL: 'Last Name',
      LAST_NAME_PLACEHOLDER: 'Enter Last Name',
      LAST_NAME_MAX_CHARACTERS: 'Last Name must be at most 50 characters',
      EMAIL_LABEL: 'Email Address',
      EMAIL_PLACEHOLDER: 'Enter Email',
      ALREADY_HAVE_ACCOUNT: 'Already have an account?',
      Login: 'Log in',
      ALMAST_THERE: 'Almost There!',
      LAST_STEP: 'Last step! Check your email',
      ALMAST_THERE_DESCRIPTION:
        'We’re almost there now, just a few more details before we can get started.',
      CODE_VERIFICATION: 'We have sent the link to',
      CODE_VERIFICATION_FAIL: 'Didn’t get a code?',
      CLICK_TO_RESEND: 'Click to resend.',
      GENDER_SELECT_LABEL: 'What option best describes your gender?',
      BIRTHDAY_LABEL: 'What is your date of birth?',
      COUNTRY_LABEL: 'What Country do you live in?',
      MALE: 'Male',
      FEMALE: 'Female',
      NEXT: 'Next',
      SKIP: 'Skip',
      ENTER_SPA: 'ENTER SPA',
      ACCOUNT_CREATED: 'Account Created!',
      THANK_YOU:
        'Thank you for entering your information. You are now ready to enter the wellness spa.',
      NEW_OTP_ALET_MESSAGE: 'The code was sent to your email address',
      FIRST_NAME: 'First Name',
      LAST_NAME: 'Last Name',
      FAILD_MODAL_TITLE: 'Account creation failed!',
      FAILD_MODAL_SUBTITLE:
        'Sorry, the activation has failed. Try with another email or contact with support.',
    },
    AUTH_PROMPT_MODAL: {
      TITLE: 'Welcome!',
      SECTION:
        'Would you like to Sign in or Sign up? You can also continue as a guest.',
      BUTTON_1: 'Sign In',
      BUTTON_2: 'Sign Up',
      BUTTON_3: 'Continue as a Guest',
    },
    SIGN_IN_MODAL: {
      TITLE: 'Sign In',
      BUTTON_TEXT: 'Sign In',
      EMAIL_INPUT_LABEL: 'Email',
      EMAIL_INPUT_PLACEHOLDER: 'Enter email',
      PASSWORD_INPUT_LABEL: 'Password',
      PASSWORD_INPUT_PLACEHOLDER: 'Enter password',
      REMEMBER_ME_TEXT: 'Remember me',
      FORGOT_PASSWORD: 'Forgot password?',
      CONTINUE_WITHOUT_LOGIN: 'Continue without login',
    },
    SESSION_TIMEOT_MODAL: {
      TITLE: 'Session Timeout',
      DESCRIPTION: (
        timer: number
      ) => `Your session has been inactive for 10 minutes. You will be logged
        out in ${timer} seconds unless you click "Stay Logged In".`,
      BUTTON_LABEL: 'Stay Logged In',
    },
    FORGOT_PASSWORD_MODAL: {
      TITLE: 'Forgot Password?',
      BUTTON_TEXT: 'Send Email',
      EMAIL_INPUT_LABEL: 'Email',
      EMAIL_INPUT_PLACEHOLDER: 'Enter email',
      FORGOT_PASSWORD_SUCCESS:
        'Please check your email to change your password.',
    },

    RESET_PASSWORD_MODAL: {
      TITLE: 'Reset Password',
      BUTTON_TEXT: 'Confirm',
      RESET_PASSWORD_SUCCESS: 'Your password has successfully  been changed',
    },

    TUTORIAL: {
      SLIDE_ONE: {
        TITLE: 'Welcome to our Virtual World!',
        SUB_TITLE:
          'We’ve created a beautiful experience for you. Click continue to learn how to explore.',
      },
      SLIDE_TWO: {
        TITLE: 'Moving Through the Space',
        SUB_TITLE:
          'To move through this virtual world, tap the circular targets on the floor.',
      },
      SLIDE_THREE: {
        TITLE: 'Look at all the Beauty',
        SUB_TITLE:
          'Click and drag anywhere on your screen to look around the 360 view area.',
      },
      SLIDE_FOUR: {
        TITLE: 'Unlock Interactivity',
        SUB_TITLE:
          'Click the smaller hotspots around the room to interact with objects and explore additional features.',
      },
    },
    EXIT_TO_LOBBY_MODAL: {
      TITLE: 'Exit without Saving?',
      SUB_TITLE:
        'The information you shared during this wellness experience will be deleted unless you create an user account to store your data. Create an account now using the button below.',
    },
    EXIT_TO_LOBBY_MODAL_FOR_AUTH_USER: {
      TITLE: 'Return to Lobby',
      SUB_TITLE:
        'Are you sure you want to return to the lobby? This will end your conversation with Tutl.',
      SIGN_UP: 'Create an account',
      CANCEL: 'Cancel',
    },
    MODAL_988: {
      LINK_MAIN_PAGE: 'https://988lifeline.org',
      LINK_CHAT: 'https://988lifeline.org/chat/',
      FIRST_SECTION: {
        PARAGRAPHS: {
          FIRST_PART:
            ' We value all members of our community and see Lifeline 988 as a first, important step toward a transformed crisis care system and helping to destigmatize mental health. If you would like mental health-related crisis support, or are worried about someone else, please',
          SECOND_PART:
            'so that all our journey is shared and remember no one is ever forgotten or less worthy; all our burdens are lessened when shared.',
        },
        HYPERLINKS: { FIRST: 'click', SECOND: 'https://988lifeline.org/chat/' },
      },

      SECOND_SECTION: {
        TITLE: 'About Lifeline 988',
        PARAGRAPH:
          "The Lifeline’s network of over 200 crisis centers has been in operation since 2005, and has been proven to be effective. It’s the counselors at these local crisis centers who answer the contacts the Lifeline receives every day. Numerous studies have shown that callers feel less suicidal, less depressed, less overwhelmed and more hopeful after speaking with a Lifeline counselor. The 988 Suicide & Crisis Lifeline is a national network of local crisis centers that provides free and confidential emotional support to people in suicidal crisis or emotional distress 24 hours a day, 7 days a week in the United States. We're committed to improving crisis services and advancing suicide prevention by empowering individuals, advancing professional best practices, and building awareness.",
      },
    },
    DP_LOADING_MODAL_TITLE: 'Loading digital person...',
    SHADEFINDER_LOADING_MODAL_TITLE: 'Loading...',
    CLOSE_BUDDHIST_ROOM_BUTTON: 'Back to Wellness',
    WELCOME_PAGE_MODAL_BUTTON: 'ACCEPT & CONTINUE',
  },
  ACCORDION: {
    CUSTOMIZE_MAKEUP: {
      TOGGLE_BUTTON: 'Customize Makeup',
      ADD_ITEM: 'Add Item',
      ADD_ALL: 'Add All',
      ITEM_ADDED: 'Added',
      COLOR_SLIDER_LABEL: 'Confidence',
      PATTERN_SLIDER_LABEL: 'Classic',
      BACK_BUTTON: 'Back',
      LOOK_BUTTON: 'LOOK',
      RESET_LOOK_BUTTON: 'Reset Look',
    },
  },

  BUTTON_TEXT: {
    SAVE_PROFILE_DATA: 'Save Changes',
    PROCEED: 'Proceed',
    MOVE_ON: 'Move On',
    UPDATE_PASSWORD: 'Update Password',
    UPDATE_ADDRESS: 'Save Changes',
    CANCEL: 'Cancel',
    SELECT: 'Select',
    CONTINUE: 'CONTINUE',
    GET_STARTED: 'GET STARTED',
    SIGN_IN: 'Sign In',
    SIGN_UP: 'Sign Up',
    EXIT: 'EXIT',
    OK: 'OK',
  },

  SIZES: {
    PIXEL_5_WIDTH: 393,
    IPHONE_SE_WIDTH: 375,
    IPHONE_12_WIDTH: 390,
    IPHONE_XR_WIDTH: 414,
    IPHONE_SE_HEIGHT: 667,
    IPAD_WIDTH: 768,
    IPAD_PRO_WIDTH: 1024,
    BIG_SCREEN_DEVICE_SIZE: 889,
    MEDIUM_SCREEN_SIZE: 1200,
  },
  CAPTIONS: {
    USER_SPEACH_FEEDBACK: {
      TITLE: {
        USER_SPEAKING: 'Listening: ',
        USER_FINISHED_SPEAKING: 'I heard: ',
      },
      LOADING_PLACEHOLDER: 'Loading...',
    },
  },
  WELLNESS: {
    MENU_TITLE: 'MAIN TOPICS:',
  },

  COMMERCE: {
    BRONZER_PRODUCT: 'Bronzer',
    HIGHLIGHTER_PRODUCT: 'Highlighter',
    BROW_HARMONY_PENCIL_AND_GEL_PRODUCT: 'Brow Harmony Pencil And Gel',
    VIDEO_TUTORIAL: 'Video Tutorial',
    ALWAYS_AN_OPTIMIST_MIST_PRODUCT: 'Always an Optimist 4-in-1 Mist',
    OPTIMIST_PORE_DIFFUSING_PRIMER_PRODUCT: 'Optimist Pore Diffusing Primer',
    GO_TO_WELLNESS: 'Go to Mental Wellness Space',
    HASHTAG_REMINDER: '#rarereminder',
    RARE_REMINDERS: 'Rare Reminders',
    WRITE_NEW_REMINDER: 'Write your own',
    JOURNAL: 'Journal',
    RANDOM_LOOK: 'Random Look',
    CURATED_LOOK: 'Curated Look',
    SAVE_LOOK: 'Save Look',
    SAVED_LOOKS_QUANTITY: 5,
    PROCEED_TO_NEXT_NODE_TIMEOUT: 10000,
    TRY_IT_BUTTON: 'Try it On',

    PRODUCT_INFO: {
      SELENA_MESSAGE: "Selena's Message",
      INGREDIENTS: 'Ingredients',
    },
    DOLLAR_TEXT: ' - $',
    SHADEFINDER_RECOMMENDED_PRODUCTS_COUNT: 5,

    LINKS: {
      BROW_HARMONY_PENCIL_AND_GEL:
        'https://www.rarebeauty.com/products/brow-harmony-pencil-and-gel?variant=34493775609991',
      RARE_REMINDER_TWITTER:
        'https://twitter.com/search?q=%23rareminder&src=typed_query&f=live',
      SELENA_TWITTER: 'https://twitter.com/selenagomez',
    },
    // determined by the client
    MULTIPLYING_SCORE_OF_COMPARING_EMOTIONS_IN_TONE_ANALYZER: 1.33,
  },

  DP_OVERVIEW_VIDEO_CARD: {
    OVERVIEW_TITLE: 'Overview',
  },

  PANORAMIC_VIDEOS: {
    BIG_SCREEN_MAX_ZOOM: 150,
    MOBILE_MAX_ZOOM: 70,
  },
  ORDER_CONTACT_INFO: {
    SUBMIT_BUTTON_TEXT: 'Make an order',
    CANCEL_BUTTON_TEXT: 'Return to cart',
    EMAIL_INPUT_LABEL: 'Email',
    EMAIL_INPUT_PLACEHOLDER: 'Enter email',
    PHONE_INPUT_LABEL: 'Phone',
    PHONE_INPUT_PLACEHOLDER: 'Enter phone',
    COUNTRY_INPUT_LABEL: 'Country',
    COUNTRY_INPUT_PLACEHOLDER: 'Enter country',
    STATE_INPUT_LABEL: 'State/Province',
    STATE_INPUT_PLACEHOLDER: 'Enter state/province',
    CITY_INPUT_LABEL: 'City',
    CITY_INPUT_PLACEHOLDER: 'Enter city',
    ZIP_INPUT_LABEL: 'Zip code',
    ZIP_INPUT_PLACEHOLDER: 'Enter zip code',
    ADDRESS1_INPUT_LABEL: 'Address Line 1',
    ADDRESS2_INPUT_LABEL: 'Address Line 2',
    ADDRESS_INPUT_PLACEHOLDER: 'Enter address',
    ADDRESS_TITLE: 'Address',
    SAVED_ADDRESS: 'Saved address',
    NEW_ADDRESS: 'New address',
    PLACEHOLDER: 'Select country',
    US: 'United States',
    CA: 'Canada',
    MX: 'Mexico',
    PHONE_LENGTH: '+ and 10 to 12 digit characters',
    TEXT_LENGTH: 'Text length should be between 2 and 400 symbols',
  },
  THANK_FOR_ORDER_MODAL: {
    TITLE: 'Thank you for your order',
    MESSAGE:
      'Thank you for your order.\nWe will be in touch with you shortly to arrange shipping and payment.',
  },
  PROFILE_ADDRESSES: {
    DEFAULT_ADDRESS: 'Default address',
    ADD_ADDRESS: 'Add address',
  },
  WELCOME_PAGE: {
    OUR_STORY: 'OUR STORY',
    SELENA_STORY: `SELENA'S STORY`,
  },
};

export default TextConstants;
