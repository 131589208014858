import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, HStack, Input } from '@chakra-ui/react';
import ReactTooltip from 'react-tooltip';
import {
  addConversationResult,
  sendTextMessage,
  setShowTranscript,
  stopSpeaking,
} from '../store/reducers/digitalAssistantReducer';
// import MicComponent from './MicComponent';
import CustomMic from "../components/common/Mic/index"
import mic from '../img/vm-mic.svg';
import micFill from '../img/vm-mic-fill.svg';
import breakpoints from '../utils/breakpoints';
import { AppDispatch, RootState } from '../store/store';
import { SVG } from './icons/icons';
import { searchInProducts } from '../global-components/RequestFactory';
import { setSearchedProductsData } from '../store/reducers/commerceReducer';
import { getUserFavoriteProductInput } from '../store/reducers/vmReducer';
import { throttle } from 'lodash';
import { stopTalking } from '../utils/textToSpeech';
import TextConstants from '../constants/TextConstants';

const volumeMeterHeight = 24;
const volumeMeterMultiplier = 1.2;
const smallHeight = volumeMeterHeight;
const largeHeight = volumeMeterHeight * volumeMeterMultiplier;

const Container = styled.div`
  display: ${({ connected }) => (connected ? '' : 'none')};

  .form-control {
    opacity: 0.8;
    &:focus {
      opacity: 1;
    }
  }
  .mic-button {
    color: #7f2648;
    border-color: #7f2648;
    padding-top: 20px;
    padding-bottom: 20px;
    &:hover {
    }
  }

  .utterance-processing {
    opacity: 0.7;
    font-style: italic;
  }
  .speaking-status {
    width: 47px;

    @media (min-width: ${breakpoints.md}px) {
      min-width: 56px;
    }
  }

  .volume-display {
    position: relative;
    top: ${volumeMeterHeight * 0.5}px;
    display: flex;
    align-items: flex-end;
    justify-content: start;
    min-width: ${({ videoWidth }) =>
      videoWidth <= breakpoints.md ? 21 : 32}px;
    .meter-component {
      /* don't use media queries for this since we need to write the value
      in the body of the component */
      height: ${({ videoWidth }) =>
        videoWidth >= breakpoints.md ? largeHeight : smallHeight}px;
      background-size: ${({ videoWidth }) =>
        videoWidth >= breakpoints.md ? largeHeight : smallHeight}px;
      background-position: bottom;
      background-repeat: no-repeat;
      min-width: ${({ videoWidth }) =>
        videoWidth <= breakpoints.md ? 21 : 28}px;
      position: absolute;
    }
    .meter-component-1 {
      background-image: url(${mic});
      z-index: 10;
    }
    .meter-component-2 {
      background-image: url(${micFill});
      z-index: 20;
    }
  }

  .mic-component-container {
    position: relative;
    margin-left: -50px;
    width: unset;
  }

  .mic-component-container button {
    border-width: 0;
    z-index: 99999;
  }
`;

const VMChatControls = (): JSX.Element => {
  const isMuted = useSelector((state: RootState) => state.digitalAssistant.isMuted);
  const typingOnly = useSelector((state: RootState) => state.digitalAssistant.typingOnly);
  const userSpeaking = useSelector((state: RootState) => state.digitalAssistant.userSpeaking);
  const connected = useSelector((state: RootState) => state.digitalAssistant.connected);
  const videoWidth = useSelector((state: RootState) => state.digitalAssistant.videoWidth);
  const _shouldSendToneAnalyzerContext = useSelector((state: RootState) => state.digitalAssistant.shouldSendToneAnalyzerContext);
  const isUserFavoriteProductInputActive = useSelector(
    (state: RootState) => state.vm.isUserFavoriteProductInputActive
  );

  const dispatch = useDispatch<AppDispatch>();
  const [inputValue, setInputValue] = useState('');
  const [inputFocused, setInputFocused] = useState(false);
  const [showTextInput] = useState(isMuted || typingOnly);

  const handleInput = (e) => setInputValue(e.target.value);
  const handleFocus = () => {
    setInputFocused(true);
  };
  const handleBlur = () => setInputFocused(false);

  const throttledStopTalking = useCallback(
    throttle(() => {console.log("stop talking"); stopTalking()}, 1000),
    []
  );

  const handleMessageSubmit = async (e = null, outerMessage = null) => {    e && e.preventDefault();
    dispatch(stopSpeaking());
    throttledStopTalking();
    setInputValue('');
    if (isUserFavoriteProductInputActive) {
      checkIfProductNameExist();

      // disable searching user favorite product functionality
      dispatch(getUserFavoriteProductInput(false));
    } else {
      const messageText = (outerMessage !== null)
        ? outerMessage
        : inputValue;

      // except when we need to get user favorite product name, by default we use this function
      if(!_shouldSendToneAnalyzerContext)
        dispatch(sendTextMessage({ text: messageText }));
      else{
        dispatch(
          addConversationResult({
            source: TextConstants.CONVERSATION_RESULT_SOURCE.USER,
            text: messageText,
          })
        );
      }
    }
  };

  if (userSpeaking === true && inputValue !== '' && inputFocused === false) {
    // setInputValue('');
  }

  // when we switch to keyboard input, capture focus
  const textInput = useRef<HTMLInputElement>();

  useEffect(() => {
    if (showTextInput) textInput.current.focus();
  }, [showTextInput]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const checkIfProductNameExist = () => {
    searchInProducts(inputValue)
      .then((result) => {
        if (result.length === 0) {
          dispatch(sendTextMessage({ text: 'else' }));
          return;
        }
        dispatch(sendTextMessage({ text: inputValue }));
        dispatch(setSearchedProductsData(result));
      })
      .catch(() => {
        dispatch(sendTextMessage({ text: 'else' }));
        return;
      });
  };

  useEffect(() => {
    if (isUserFavoriteProductInputActive) {
      // auto-open transcript in commerce page
      dispatch(setShowTranscript(true));
    }
  }, [dispatch, isUserFavoriteProductInputActive]);

  const onRecognizeSpeech = (text: string) => {
    setInputValue(text);
    handleMessageSubmit(null, text);
  };

  // const [isSendButtonDisabledBySpeaking, setIsSendButtonDisabledBySpeaking] = useState(false);
  // const isDigitalAssistantSpeaking = useSelector((state: RootState) => state.digitalAssistant.isDigitalAssistantSpeaking);

  // useEffect(() => {
  //   setIsSendButtonDisabledBySpeaking(isDigitalAssistantSpeaking);
  // }, [isDigitalAssistantSpeaking]);

  return (
    <Container
      connected={connected}
      userSpeaking={userSpeaking}
      videoWidth={videoWidth}
    >
      <div className="row mb-3 display-flex justify-content-center">
        <form onSubmit={handleMessageSubmit} className="col-10">
          <HStack>
            <Input
              type="text"
              placeholder="Message"
              bgColor="#fff"
              value={inputValue}
              onChange={handleInput}
              onFocus={handleFocus}
              onBlur={handleBlur}
              aria-label="Keyboard input"
              ref={textInput}
              borderWidth="1.5px"
              width="calc(100% - 54px)"
              style={{ paddingRight: '45px' }}
            />
            <CustomMic handleMessageSubmit={handleMessageSubmit}/>
            {/* <MicComponent
              onRecognizeSpeech={onRecognizeSpeech}
            /> */}
            <Button
              type="submit"
              // disabled={isSendButtonDisabledBySpeaking || !inputValue}
              variant="circle-icon"
              size="md"
              ml="14px !important"
              onClick={handleMessageSubmit}
            >
              <SVG id="send" />
            </Button>
          </HStack>
        </form>
      </div>
    </Container>
  );
};

export default VMChatControls;
