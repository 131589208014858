import { DPChatUserDataType } from '../global-components/GlobalTypes';

const storageKey = 'DPChatUserData';

export function saveDPChatUserDataInLocalStorage(
  data: DPChatUserDataType
): void {
  localStorage.setItem(storageKey, JSON.stringify(data));
}

export function clearDPChatUserDataInLocalStorage(): void {
  localStorage.removeItem(storageKey);
}

export function getDPChatUserDataFromLocalStorage(): DPChatUserDataType {
  const localStorageDataParsed = JSON.parse(localStorage.getItem(storageKey));

  const initialDPChatUserData = { returning_user: 'false', stage: 'welcome' };

  if (
    localStorage.getItem(storageKey) === null ||
    // check for fields
    !localStorageDataParsed['returning_user'] ||
    !localStorageDataParsed.stage
  ) {
    localStorage.setItem(storageKey, JSON.stringify(initialDPChatUserData));
    console.log(initialDPChatUserData);
    return initialDPChatUserData;
  }
  console.log(localStorageDataParsed);
  return localStorageDataParsed;
}
