import { Box, Button, useMediaQuery } from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextConstants from '../../../constants/TextConstants';
import {
  sendTextMessage,
  stopSpeaking,
} from '../../../store/reducers/digitalAssistantReducer';
import { RootState } from '../../../store/store';

const CommerceOnscreenOtherOptions = ({
  data,
  isCardInTranscript,
}: {
  data: {
    options: {
      label: string;
      value: string;
    }[];
  };
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [isLargerThanIPadPro] = useMediaQuery(
    `(min-width: ${TextConstants.SIZES.IPAD_PRO_WIDTH}px)`
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );

  const handleClick = (param: string) => {
    dispatch(stopSpeaking());
    console.log("Speaking click");
    console.log(param);
    dispatch(sendTextMessage({ text: param }));
    setIsButtonDisabled(true);
  };

  const renderOptions = () => (
    <Box
      p="10px 10px 10px 0"
      d="flex"
      flexDirection="column"
      gap="20px"
      minWidth="250px"
      className="commerce-onscreen-other-options-container"
    >
      {data.options.map(({ label, value }) => (
        <Button
          fontSize={{ base: 'small', sm: '1rem' }}
          variant="secondary"
          p="20px"
          w="100%"
          m="0 auto"
          whiteSpace="break-spaces"
          key={JSON.stringify({ label, value })}
          borderRadius="0.5rem"
          onClick={() => {
            handleClick(value);
          }}
          disabled={isButtonDisabled || isCardInTranscript}
        >
          {label}
        </Button>
      ))}
    </Box>
  );

  return (
    <Box
      display="flex"
      pl="30px"
      flexDirection="column"
      marginBottom={isTranscriptOpen || isLargerThanIPadPro ? 'unset' : '5vh'}
      maxH="60vh"
      overflowY={isTranscriptOpen ? 'unset' : 'scroll'}
      data-sm-content="commerceOnscreenOtherOptions"
    >
      {data.options && data.options.length && renderOptions()}
    </Box>
  );
};

export default CommerceOnscreenOtherOptions;
