/* eslint-disable react/jsx-props-no-spreading */

import { Image } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import styled from 'styled-components';
import {
  CustomizeMakeupProductType,
  CustomizeMakeupSubCategoryType,
} from '../../../global-components/GlobalTypes';
import leftArrowIcon from '../../../img/arrow-left-icon.svg';
import rightArrowIcon from '../../../img/arrow-right-icon.svg';
import { setCustomizeMakeupSliders } from '../../../store/reducers/commerceReducer';
import { RootState } from '../../../store/store';

const SliderContainer = styled.div`
  padding: 0px 25px 0px 25px;
  .slick-dots {
    display: none !important;
  }
  .slick-slide {
    display: flex;
    justify-content: center;
  }
  button {
    display: table-cell;
  }
`;

export const SubcategoryCircle = styled.p`
  display: table-cell;
  vertical-align: middle;
  font-size: 13px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  color: #fff;
  background: linear-gradient(#9b2c2c, #9198e5);
`;

function LeftArrow(props) {
  const { onClick } = props;
  return (
    <Image
      onClick={onClick}
      src={leftArrowIcon}
      style={{
        left: '-25px',
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
      }}
    />
  );
}

function RightArrow(props) {
  const { onClick } = props;
  return (
    <Image
      onClick={onClick}
      src={rightArrowIcon}
      style={{
        right: '-25px',
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
      }}
    />
  );
}

const SubcategorySlider = ({
  subcategories,
  nextStep,
  setProductsLevelData,
  selectedCategory,
}: {
  subcategories: CustomizeMakeupSubCategoryType[];
  nextStep: () => void;
  setProductsLevelData: (data: CustomizeMakeupProductType[]) => void;
  selectedCategory: 'Face' | 'Lips' | 'Eyes' | 'Miscellaneous';
}): JSX.Element => {
  const dispatch = useDispatch();
  const activeSubCategorySlideIndex = useSelector(
    (state: RootState) =>
      state.commerce.customizeMakeupSliders[selectedCategory]
        .subCategorySliderIndex
  );
  const [swiped, setSwiped] = useState(false);
  const handleSwiped = useCallback(() => {
    setSwiped(true);
  }, [setSwiped]);

  const handleOnItemClick = useCallback(
    (event, el, index) => {
      if (swiped) {
        event.stopPropagation();
        event.preventDefault();
        setSwiped(false);
      } else {
        setProductsLevelData(el.children);
        nextStep();
        dispatch(
          setCustomizeMakeupSliders({
            [selectedCategory]: {
              subCategorySliderIndex: index,
            },
          })
        );
      }
    },
    [swiped]
  );
  const maxCount = subcategories && subcategories.length;
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: maxCount ? Math.min(maxCount, 3) : 0,
    slidesToScroll: maxCount ? Math.min(maxCount, 3) : 0,
    initialSlide: activeSubCategorySlideIndex,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    onSwipe: handleSwiped,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: maxCount ? Math.min(maxCount, 2) : 0,
          slidesToScroll: maxCount ? Math.min(maxCount, 2) : 0,
        },
      },
    ],
  };

  return (
    <SliderContainer>
      <Slider {...settings}>
        {subcategories?.length &&
          subcategories.map((el, index) => (
            <button
              type="button"
              onClickCapture={(event) => handleOnItemClick(event, el, index)}
              key={el?.id}
            >
              <SubcategoryCircle>{el?.name}</SubcategoryCircle>
            </button>
          ))}
      </Slider>
    </SliderContainer>
  );
};

export default SubcategorySlider;
