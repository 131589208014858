import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ChakraProvider, Box } from '@chakra-ui/react';
import { Dict } from '@chakra-ui/utils/src/types';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/App.scss';
import Header from './components/header/Header';
import { SVGSource } from './components/icons/icons';
import DigitalAssistantChat from './routes/DigitalAssistantChat';
import Landing from './routes/Landing';
import Feedback from './routes/Feedback';
import WelcomePage from './routes/AuthenticationFlow/welcome/WelcomePage';
import FoyerWelcome from './routes/FoyerWelcome/FoyerWelcome';
import Desert from './routes/desert/Desert';
import Verification from './routes/AuthenticationFlow/verification/Verification';
import ResetPasswordModal from './components/modals/ResetPasswordModal';
import ShareRecords from './components/drawer/journal/ShareRecords';
import checkShareRecordsUrl from './helpers/journal/checkShareRecordsUrl';

const App = ({ theme }: { theme: Dict }): JSX.Element => {
  const isSharePageOpened = checkShareRecordsUrl();
  return (
    <ChakraProvider theme={theme}>
      <Box className="wrapper">
        <Router>
          {!isSharePageOpened && <Header />}
          <Box className="pageContent">
            <Switch>
              <Route path="/diary-records/share/:token">
                <ShareRecords />
              </Route>
              <Route path="/reset-password/:token">
                <ResetPasswordModal />
              </Route>
              <Route path="/activate-account/:token">
                <Verification />
              </Route>
              <Route path="/video">
                <DigitalAssistantChat />
              </Route>
              <Route path="/feedback">
                <Feedback />
              </Route>
              <Route path="/terms">
                <Landing />
              </Route>
              <Route exact path="/foyer-welcome">
                <FoyerWelcome />
              </Route>
              <Route exact path="/desert">
                <Desert />
              </Route>
              <Route exact path="/">
                <WelcomePage />
              </Route>
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </Box>
        </Router>
      </Box>
      <SVGSource />
    </ChakraProvider>
  );
};

export default App;
