import { Box, Button, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendTextMessage,
  stopSpeaking,
} from '../../../store/reducers/digitalAssistantReducer';
import { RootState } from '../../../store/store';

const TherapyRecommendationQuestion = ({
  data,
  isCardInTranscript,
}: {
  data: {
    id: number;
    question: string;
    type: 'range' | 'text';
    answers: {
      id: number;
      answer: string;
    }[];
  };
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );
  const handleTextOptionClick = (param: string) => {
    dispatch(stopSpeaking());
    dispatch(sendTextMessage({ text: param }));
    setIsButtonDisabled(true);
  };

  const handleNumberOptionClick = (param: string) => {
    dispatch(stopSpeaking());
    dispatch(sendTextMessage({ text: param }));
    setIsButtonDisabled(true);
  };

  const renderTextOptions =
    data.answers &&
    data.answers.map(({ id, answer }) => (
      <Button
        width="100%"
        height="auto"
        cursor="pointer"
        my="10px"
        variant="ghost"
        whiteSpace="normal"
        minHeight="60px"
        bg="#fff"
        data-trigger-text={answer}
        key={JSON.stringify({ id, answer })}
        onClick={() => {
          handleTextOptionClick(answer);
        }}
        disabled={isButtonDisabled || isCardInTranscript}
      >
        {answer}
      </Button>
    ));

  const renderNumberOptions = (
    <Box d="flex">
      {data.answers &&
        data.answers.map(({ id, answer }) => (
          <Button
            paddingInlineEnd="5px"
            paddingInlineStart="5px"
            variant="secondary"
            display="flex"
            justifyContent="center"
            alignItems="center"
            my="5px"
            mx="1px"
            width={{ base: '29px', sm: '30px' }}
            height="30px"
            key={JSON.stringify({ id, answer })}
            borderRadius="50%"
            border="1px solid grey"
            onClick={() => {
              handleNumberOptionClick(answer);
            }}
            disabled={isButtonDisabled || isCardInTranscript}
          >
            {answer}
          </Button>
        ))}
    </Box>
  );

  return (
    <Box
      maxW="390px"
      maxH="65vh"
      overflowY="scroll"
      d="flex"
      flexDirection="column"
      alignItems="center"
      p="30px"
      data-sm-content="therapyQuiz"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      {!isTranscriptOpen && (
        <Text className="transcript-entry-content" mb="20px">
          {data.question}
        </Text>
      )}
      <Box w="100%">
        {data.type === 'text' ? renderTextOptions : renderNumberOptions}
      </Box>
    </Box>
  );
};

export default TherapyRecommendationQuestion;
