import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
  CommerceInitialState,
  CustomizeMakeupProductType,
  SearchedProductType,
} from '../../global-components/GlobalTypes';

const initialData = () => {
  const item = localStorage.getItem('commerceData');
  // Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : [];
};

const initialState: CommerceInitialState = {
  isShadeFinderCompleted: false,
  myBagDataItems: initialData(),
  selectedProductVariants: [],
  isHeaderModalsShown: {
    registerModal: false,
    myBagDrawer: false,
    journalDrawer: false,
    profileDrawer: false,
  },
  activeProductVariant: null,
  customizeMakeupSliders: {
    Face: {
      subCategorySliderIndex: 0,
    },
    Lips: {
      subCategorySliderIndex: 0,
    },
    Eye: {
      subCategorySliderIndex: 0,
    },
    Miscellaneous: {
      subCategorySliderIndex: 0,
    },
  },
  dialogType: null,
  makeupAccordionSliderByWatsonFeatureState: {
    category: null,
    subcategory: null,
    selectedProduct: null,
  },
  isAccordionOpen: false,
  categorizedProductsData: [],
  uncategorizedProductsData: [],
  suggestionLook: null,
  searchedProductsData: null,
  isShadeFinderOpened: false,
};

export const CommerceReducer = createSlice({
  name: 'commerce',
  initialState,
  reducers: {
    setAddProductsToBag: (state, { payload }) => {
      localStorage.setItem('commerceData', JSON.stringify(payload));
      return {
        ...state,
        myBagDataItems: payload,
      };
    },

    removeProductsFromCart: (state) => {
      localStorage.removeItem('commerceData');
      return {
        ...state,
        myBagDataItems: [],
      };
    },

    setIsHeaderModalsShown: (state, { payload }) => ({
      ...state,
      isHeaderModalsShown: { ...state.isHeaderModalsShown, ...payload },
    }),

    setCustomizeMakeupSliders: (state, { payload }) => ({
      ...state,
      customizeMakeupSliders: { ...state.customizeMakeupSliders, ...payload },
    }),

    setSelectedProductVariant: (state, { payload }) => {
      const checkDuplicateProduct = () => {
        let newSelectedProductVariants = JSON.parse(
          JSON.stringify(current(state).selectedProductVariants)
        );
        for (let i = 0; i < newSelectedProductVariants.length; i++) {
          const element = newSelectedProductVariants[i];
          // TODO temporary fix curate/random look break on button press
          if (element && element?.productId === payload?.productId) {
            element.sku = payload?.sku;
            return newSelectedProductVariants;
          }
        }
        return [...newSelectedProductVariants, payload];
      };

      return {
        ...state,
        selectedProductVariants: checkDuplicateProduct(),
      };
    },

    removeSelectedProductVariant: (state, { payload }) => {
      let newSelectedProductVariants = JSON.parse(
        JSON.stringify(current(state).selectedProductVariants)
      );
      const removingVariantsArray = newSelectedProductVariants.filter(
        (item) => item.productVariant.sku !== payload
      );
      return {
        ...state,
        selectedProductVariants: removingVariantsArray,
      };
    },

    removeAllSelectedProductVariants: (state) => ({
      ...state,
      selectedProductVariants: [],
    }),

    setActiveProductVariant: (state, { payload }) => ({
      ...state,
      activeProductVariant: payload,
    }),

    removeActiveProductVariant: (state) => ({
      ...state,
      activeProductVariant: null,
    }),

    setDialogType: (state, { payload }) => ({
      ...state,
      dialogType: payload,
    }),

    openMakeupAccordionSliderByWatsonFeature: (state, { payload }) => ({
      ...state,
      makeupAccordionSliderByWatsonFeatureState: {
        category: payload.category,
        subcategory: payload.subcategory,
        selectedProduct: payload.selectedProduct,
      },
    }),

    openAccordion: (state, { payload }) => ({
      ...state,
      isAccordionOpen: payload,
    }),

    applySuggestionLook: (
      state,
      { payload }: { payload: 'curated' | 'random' | null }
    ) => ({
      ...state,
      suggestionLook: payload,
    }),

    setIsShadeFinderCompleted: (state, { payload }) => ({
      ...state,
      isShadeFinderCompleted: payload,
    }),
    setUncategorizedProductsData: (
      state,
      { payload }: PayloadAction<CustomizeMakeupProductType[]>
    ) => ({
      ...state,
      uncategorizedProductsData: payload,
    }),
    setCategorizedProductsData: (state, { payload }) => ({
      ...state,
      categorizedProductsData: payload,
    }),

    setSearchedProductsData: (
      state,
      { payload }: { payload: SearchedProductType[] | null }
    ) => ({
      ...state,
      searchedProductsData: payload,
    }),
  },
});

export const {
  setAddProductsToBag,
  removeProductsFromCart,
  setIsHeaderModalsShown,
  setSelectedProductVariant,
  removeSelectedProductVariant,
  removeAllSelectedProductVariants,
  setCustomizeMakeupSliders,
  setIsShadeFinderCompleted,
  removeActiveProductVariant,
  setActiveProductVariant,
  setDialogType,
  openMakeupAccordionSliderByWatsonFeature,
  openAccordion,
  applySuggestionLook,
  setCategorizedProductsData,
  setUncategorizedProductsData,
  setSearchedProductsData,
} = CommerceReducer.actions;

export default CommerceReducer.reducer;
