import {
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Image,
  Button,
} from '@chakra-ui/react';
import styled from 'styled-components';
import TextConstants from '../../../constants/TextConstants';
import emailIcon from '../../../assets/icons/email-icon.png';

const Container = styled.div``;
const SignupConfirmationModal = ({
  isModalOpen,
  closeModals,
  email,
}: {
  isModalOpen: boolean;
  closeModals: () => void;
  email: string;
}): JSX.Element => (
  <Modal
    isOpen={isModalOpen}
    onClose={() => {
      closeModals();
    }}
    isCentered
  >
    <ModalOverlay
      backdropFilter="auto"
      backdropInvert="10%"
      backdropBlur="0px"
    />
    <ModalContent borderRadius="0" justifyContent="center">
      <Container>
        <Box display="flex" justifyContent="center">
          <Stack spacing="16px" mx="16px" mb="20px" justifyContent="center">
            <Image
              mt="20px"
              alignSelf="center"
              boxSize="48px"
              objectFit="cover"
              src={emailIcon}
            />
            <Box>
              <Text textStyle="title" textAlign="center">
                {TextConstants.MODALS.SIGN_UP_MODAL.LAST_STEP}
              </Text>
              <Box mt="8px" textAlign="center">
                <Text textStyle="subTitle">
                  {TextConstants.MODALS.SIGN_UP_MODAL.CODE_VERIFICATION}
                </Text>
                <Text
                  fontWeight="600"
                  color="var(--chakra-colors-gray-500)"
                  lineHeight="20px"
                  fontFamily="Inter"
                >
                  {email}
                </Text>
              </Box>
            </Box>
            <Button onClick={closeModals}>
              {TextConstants.BUTTON_TEXT.OK}
            </Button>
          </Stack>
        </Box>
      </Container>
    </ModalContent>
  </Modal>
);

export default SignupConfirmationModal;
