import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setIsHeaderModalsShown } from '../../store/reducers/commerceReducer';
import { RootState } from '../../store/store';
import CreateAccountModal from '../modals/signUp/CreateAccountModal';
import SignupStepsModal from '../modals/signUp/signupSteps/SignupStepsModal';
import SignupConfirmationModal from '../modals/signUp/SignupConfirmationModal';
import { registerRequest } from '../../global-components/RequestFactory';
import handleResponseErrorMessage from '../../helpers/handleResponseErrorMessage';
import { setSignInModal } from '../../store/reducers/signInModalReducer';
import { executeAuthPromptModalCallback } from '../../store/reducers/authPromptReducer';

const SignupContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const isSignUpModalsOpenGlobalState = useSelector(
    (state: RootState) => state.commerce.isHeaderModalsShown.registerModal
  );

  const [createAccountData, setCreateAccountData] = useState<{
    email: string;
    password: string;
  } | null>(null);
  const [signUpResponseError, setSignUpResponseError] = useState('');
  const [isRequesting, setIsRequesting] = useState(false);
  const [isCreateAccountModalOpen, setCreateAccountModalOpen] = useState(false);
  const [isSingupStepsModalOpen, setSingupStepsModalOpen] = useState(false);
  const [isSignupConfirmationModalOpen, setSignupConfirmationModalOpen] =
    useState(false);

  const closeSignUpContainer = (
    shouldExecuteAuthPromptModalCallback: boolean
  ) => {
    dispatch(setIsHeaderModalsShown({ registerModal: false }));
    dispatch(setSignInModal({ isOpen: false }));
    setCreateAccountModalOpen(false);
    setSingupStepsModalOpen(false);
    setSignupConfirmationModalOpen(false);
    if (shouldExecuteAuthPromptModalCallback) {
      setTimeout(() => {
        dispatch(executeAuthPromptModalCallback(true));
      }, 200);
    }
  };

  const openLogin = () => {
    dispatch(setIsHeaderModalsShown({ registerModal: false }));
    dispatch(setSignInModal({ isOpen: true }));
  };

  const registerUser = (data) => {
    setIsRequesting(true);
    const dateOfBirth = new Date(data.dateOfBirth);
    const offset = dateOfBirth.getTimezoneOffset() / 60;
    dateOfBirth.setHours(dateOfBirth.getHours() + Math.abs(offset));
    const registerData = {
      email: createAccountData.email.toLowerCase(),
      password: createAccountData.password,
      ...data,
      phoneNumber: data.phoneNumber && '+' + data.phoneNumber,
      dateOfBirth,
    };

    registerRequest(registerData)
      .then(() => {
        setCreateAccountModalOpen(false);
        setSingupStepsModalOpen(false);
        setSignupConfirmationModalOpen(true);
        setSignUpResponseError('');
        setIsRequesting(false);
      })
      .catch((error) => {
        setIsRequesting(false);
        if (error.violations && error.violations.length) {
          setSignUpResponseError(handleResponseErrorMessage(error.violations));
        } else {
          setSignUpResponseError(error.message);
        }
        setCreateAccountModalOpen(true);
        setSingupStepsModalOpen(false);
        setSignupConfirmationModalOpen(false);
      });
  };

  useEffect(() => {
    setCreateAccountModalOpen(isSignUpModalsOpenGlobalState);
    setSingupStepsModalOpen(false);
  }, [isSignUpModalsOpenGlobalState]);

  return (
    <>
      <CreateAccountModal
        isModalOpen={isCreateAccountModalOpen}
        closeModal={(param: boolean) => {
          closeSignUpContainer(param);
        }}
        openLogin={() => {
          openLogin();
        }}
        signUpResponseError={signUpResponseError}
        openSignupSteps={(data) => {
          setCreateAccountModalOpen(false);
          setSingupStepsModalOpen(true);
          setCreateAccountData(data);
        }}
      />
      <SignupStepsModal
        isModalOpen={isSingupStepsModalOpen}
        closeModals={() => {
          closeSignUpContainer(true);
        }}
        submit={(data) => {
          registerUser(data);
        }}
        isRequesting={isRequesting}
      />
      <SignupConfirmationModal
        isModalOpen={isSignupConfirmationModalOpen}
        closeModals={() => {
          closeSignUpContainer(true);
        }}
        email={createAccountData && createAccountData.email}
      />
    </>
  );
};

export default SignupContainer;
