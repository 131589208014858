import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from '../../global-components/GlobalTypes';
import { clearDPChatUserDataInLocalStorage } from '../../helpers/DPNewUserFlowStorageHelper';
import { addSecondsToDate } from '../../utils/DateUtils';
import {
  getUserDataCookie,
  REFRESH_TOKEN_TTL,
  removeUserDataCookie,
  setUserDataCookie,
} from '../../utils/CookieUtils';
import { stopTimer } from './sessionTimeoutReducer';
import { updateWellnessStatus } from '../../global-components/RequestFactory';

const initialState: AuthState = {
  userData: getUserDataCookie() || null,
  isUserLoggedInBeforeDPOpening: false,
};

export const AuthSlicer = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setUserData: (state, { payload }) => {
      setUserDataCookie(
        payload,
        payload.isRememberMe
          ? { expires: addSecondsToDate(REFRESH_TOKEN_TTL) }
          : undefined
      );

      return {
        ...state,
        userData: payload,
      };
    },

    removeUserData: (state) => {
      localStorage.removeItem('isUserSeeingTutorialFirstTime');
      removeUserDataCookie();
      clearDPChatUserDataInLocalStorage();

      return {
        ...state,
        userData: null,
      };
    },

    setUserLoggedInBeforeDPOpening: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      isUserLoggedInBeforeDPOpening: payload,
    }),

    updateWellnessStatusState: (
      state,
      { payload }
    ) => ({
      ...state,
      userData:{
        ...state.userData,
        wellness_status: payload.text,
      }      
    }),
  },
});

export const logoutUser = createAsyncThunk(
  'authentication/logout',
  async (_, { dispatch }) => {
    dispatch(removeUserData());
    dispatch(stopTimer());
  }
);

export const updateWellnessData = createAsyncThunk(
  'authentication/updateUser',
  async ({ text }: { text: string }, thunk) => {
    const { authenticationState: { userData } } = thunk.getState() as { authenticationState: AuthState; };

    const updatedRes = await updateWellnessStatus(userData?.token, {wellness_status: text})
    thunk.dispatch(updateWellnessStatusState({text}))    
  }
);

export const { setUserData, removeUserData, setUserLoggedInBeforeDPOpening, updateWellnessStatusState } =
  AuthSlicer.actions;

export default AuthSlicer.reducer;
