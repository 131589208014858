import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  VideoHOCWithLogoutTimerProps,
  VideoHOCWithLogoutTimerRef,
} from '../../global-components/GlobalTypes';
import { setCurrentlyPlayingVideoID } from '../../store/reducers/mediaMuteReducer';
import {
  startLogOutTimer,
  stopLogOutTimer,
} from '../../store/reducers/sessionTimeoutReducer';
import { RootState } from '../../store/store';

const VideoHOCWithLogoutTimer = forwardRef<
  VideoHOCWithLogoutTimerRef,
  VideoHOCWithLogoutTimerProps
>(
  (
    { className, src, onPlay, onPause, onEnded, autoPlay = true, ...restProps },
    ref
  ) => {
    const { current: videoID } = useRef(uuidv4());
    const videoRef = useRef(null);
    const dispatch = useDispatch();

    const currentlyPlayingVideoID = useSelector(
      (state: RootState) => state.mediaMuteState.currentlyPlayingVideoID
    );
    const isTranscriptOpen = useSelector(
      (state: RootState) => state.digitalAssistant.showTranscript
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          pause() {
            videoRef.current.pause();
          },
          play() {
            videoRef.current.play();
          },
        };
      },
      []
    );

    useEffect(() => {
      // set globally playing video ID
      dispatch(setCurrentlyPlayingVideoID(videoID));
      return () => {
        // start logout timer on component unmount
        dispatch(startLogOutTimer());
        // reset globally playing video ID
        dispatch(setCurrentlyPlayingVideoID(null));
      };
    }, []);

    useEffect(() => {
      if (currentlyPlayingVideoID !== videoID) {
        videoRef.current.pause();
      } else {
        // not auto-play video component if transcript is opened (is in transcript)
        if (!isTranscriptOpen && autoPlay) {
          videoRef.current.play();

          dispatch(stopLogOutTimer());
        }
      }
    }, [currentlyPlayingVideoID]);

    const handlePlay = () => {
      // stop timer when playing started
      dispatch(stopLogOutTimer());

      // set globally playing video ID
      dispatch(setCurrentlyPlayingVideoID(videoID));
      if (onPlay) {
        onPlay();
      }
    };

    const handlePaused = () => {
      // start timer when playing paused
      dispatch(startLogOutTimer());

      if (onPause) {
        onPause();
      }
    };

    const handleEnded = () => {
      // start timer when video ended
      dispatch(startLogOutTimer());

      if (onEnded) {
        onEnded();
      }
    };

    return (
      <video
        id={videoID}
        className={className}
        onPlay={handlePlay}
        onEnded={handleEnded}
        onPause={handlePaused}
        ref={videoRef}
        autoPlay={autoPlay}
        {...restProps}
      >
        <source src={src} type="video/mp4" />
      </video>
    );
  }
);

export default VideoHOCWithLogoutTimer;
