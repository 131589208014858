import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Stack,
} from '@chakra-ui/react';
import { FormikProps } from 'formik';
import { FormikSignUpValues } from './SignupStepsModal';
import TextConstants from '../../../../constants/TextConstants';

const SignupNamesStep = ({
  formik,
}: {
  formik: FormikProps<FormikSignUpValues>;
}): JSX.Element => (
  <Box>
    <Stack spacing="16px" mx="16px" justifyContent="center">
      <Text textStyle="title" textAlign="center">
        {TextConstants.MODALS.SIGN_UP_MODAL.ALMAST_THERE}
      </Text>
      <Text textStyle="subTitle" textAlign="center" mt="8px">
        {TextConstants.MODALS.SIGN_UP_MODAL.ALMAST_THERE_DESCRIPTION}
      </Text>
      <FormControl isInvalid={formik.errors.firstName !== undefined}>
        <FormLabel fontSize="sm" mb="6px" color="gray.700">
          {TextConstants.MODALS.SIGN_UP_MODAL.FIRST_NAME_LABEL}
        </FormLabel>
        <Input
          name="firstName"
          onChange={formik.handleChange}
          value={formik.values.firstName}
          placeholder={
            TextConstants.MODALS.SIGN_UP_MODAL.FIRST_NAME_PLACEHOLDER
          }
          borderRadius="none"
          textStyle="title"
          type="text"
          color="gray.900"
          fontSize="md"
        />
        <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={formik.errors.lastName !== undefined}>
        <FormLabel fontSize="sm" mb="6px" color="gray.700">
          {TextConstants.MODALS.SIGN_UP_MODAL.LAST_NAME_LABEL}
        </FormLabel>
        <Input
          name="lastName"
          type="lastName"
          onChange={formik.handleChange}
          value={formik.values.lastName}
          placeholder={TextConstants.MODALS.SIGN_UP_MODAL.LAST_NAME_PLACEHOLDER}
          borderRadius="none"
          textStyle="title"
          color="gray.900"
          fontSize="md"
        />
        <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
      </FormControl>
    </Stack>
  </Box>
);

export default SignupNamesStep;
