import TextConstants from '../../constants/TextConstants';

const checkShareRecordsUrl = (): boolean => {
  let isShareUrl = false;
  const pathname = window.location.pathname;
  if (
    pathname.slice(0, pathname.lastIndexOf('/') + 1) ===
    TextConstants.PATHNAMES.JOURNAL_SHARE
  ) {
    isShareUrl = true;
  }
  return isShareUrl;
};
export default checkShareRecordsUrl;
