import { useEffect, useRef, useState } from 'react';
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  useMediaQuery,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { PannellumVideo } from '@erosargsyan/pannellum-react';
import { RootState } from '../../store/store';
import { openWellnessPanoramicVideoModal } from '../../store/reducers/panoramicVideoReducer';
import {
  mute,
  sendTextMessage,
  stopSpeaking,
} from '../../store/reducers/digitalAssistantReducer';
import MutePanoramicVideoButton from '../MutePanoramicVideoButton';
import TextConstants from '../../constants/TextConstants';
import {
  checkIfIOSDevice,
  checkIfSafariBrowser,
} from '../../helpers/checkDevicePlatform';

const videoCloudURL = process.env.REACT_APP_VIDEO_CLOUD_URL;

const PanoramicVideoModal = (): JSX.Element => {
  const videoURL = `${videoCloudURL}/RB-st-martin-cupecoy-beach.mp4`;
  const [videoRef, setVideoRef] = useState(null);
  const panellumVideoRef = useRef<PannellumVideo>(null);

  const dispatch = useDispatch();
  const [isSmallerThanIPhoneXR] = useMediaQuery(
    `(max-width: ${TextConstants.SIZES.IPHONE_XR_WIDTH}px)`
  );
  const panoramicVideoModalToOpen = useSelector(
    (state: RootState) => state.panoramicVideoModalState
  );

  useEffect(() => {
    if (panoramicVideoModalToOpen) {
      dispatch(stopSpeaking());
      dispatch(mute(true));
    }
  }, [panoramicVideoModalToOpen, dispatch]);

  useEffect(() => {
    setVideoRef(document.getElementsByClassName('vjs-tech'));
  }, [panoramicVideoModalToOpen]);

  useEffect(() => {
    // add playsinline attribute to 360 video
    if (videoRef?.length) {
      videoRef[0].setAttribute('playsinline', '');
    }
  }, [videoRef]);

  return (
    <Modal
      onClose={() => {
        dispatch(openWellnessPanoramicVideoModal(null));
        dispatch(sendTextMessage({ text: 'proceed' }));
      }}
      id="intro-video-modal"
      isOpen
      size="full"
      scrollBehavior="inside"
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalContent>
        <ModalCloseButton _focus={{ boxShadow: 'none' }} zIndex="22" />
        <PannellumVideo
          id="beach-panellum-video"
          ref={panellumVideoRef}
          video={videoURL}
          loop
          autoplay
          width="100vw"
          height="100vh"
          pitch={0}
          yaw={0}
          hfov={140}
          mouseZoom={false}
          controls={false}
          // ititially muted for IOS and Safari browser only
          muted={checkIfIOSDevice() || checkIfSafariBrowser()}
          playsinline
          // maximum zoom in percent

          maxHfov={
            isSmallerThanIPhoneXR
              ? TextConstants.PANORAMIC_VIDEOS.MOBILE_MAX_ZOOM
              : TextConstants.PANORAMIC_VIDEOS.BIG_SCREEN_MAX_ZOOM
          }
        />
        <MutePanoramicVideoButton
          videoRef={panellumVideoRef}
          parentComponentType="modal"
        />
      </ModalContent>
    </Modal>
  );
};

export default PanoramicVideoModal;
