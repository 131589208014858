import { forwardRef, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import {
  Alert,
  AlertIcon,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
} from '@chakra-ui/react';
import ProfileSectionButtons from './ProfileSectionButtons';
import { SVG } from '../../icons/icons';
import TextConstants from '../../../constants/TextConstants';
import { setUserData } from '../../../store/reducers/authenticationReducer';
import { useGetUserInfo } from '../../../helpers/userInfo/UserInfo';
import { updateProfileInformation } from '../../../global-components/RequestFactory';
import { UserDataType } from '../../../global-components/GlobalTypes';
import { getUserDataCookie } from '../../../utils/CookieUtils';
import { personalInfoValidationSchema } from '../../../helpers/validation';

const Container = styled.div`
  .react-datepicker-popper {
    z-index: 3;
  }
  .gender-select {
    padding-left: 35px;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: rgba(127, 38, 73, 1);
  }
  .react-datepicker__day--in-range {
    background-color: rgba(127, 38, 73, 1);
  }
  .react-datepicker__day--selected {
    background-color: var(--chakra-colors-brand-500);
  }
`;

const PersonalInfo = (): JSX.Element => {
  const dispatch = useDispatch();
  const [isRequesting, setIsRequesting] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [requestError, setRequestError] = useState({
    isErrorShown: false,
    errorText: '',
  });
  const [isInitialValueChanged, setInitialValueChange] = useState(false);

  const userData = useGetUserInfo();

  const setUserDataInRedux = (result: UserDataType) => {
    dispatch(setUserData(result));
  };

  const hideResponseError = () => {
    setRequestError({ isErrorShown: false, errorText: '' });
  };

  const customDateInput = (props, ref: React.LegacyRef<HTMLInputElement>) => (
    <Input
      placeholder="MM/DD/YYYY"
      background="#FFFFFF"
      value={props.value}
      ref={ref}
      onClick={props.onClick}
      onChange={props.onChange}
      pl="40px"
      _placeholder={{ color: 'gray.900' }}
      borderWidth="1px"
      borderColor="gray.300"
      borderRadius="0px"
      boxShadow="unset"
      fontFamily="third"
    />
  );
  const CustomInput = forwardRef(customDateInput);

  const formik = useFormik({
    initialValues: {
      dateOfBirth: userData.dateOfBirth ? new Date(userData.dateOfBirth) : '',
      gender: userData.gender ? userData.gender : '',
    },
    onSubmit: () => {
      const valuesToSubmit = {
        dateOfBirth: formik.values.dateOfBirth
          ? new Date(formik.values.dateOfBirth)
          : null,
        gender: formik.values.gender || null,
      };

      setIsRequesting(true);
      updateProfileInformation(valuesToSubmit, userData, setUserDataInRedux)
        .then((patchedUserData) => {
          setIsRequestSuccess(true);
          setTimeout(
            () => setIsRequestSuccess(false),
            TextConstants.ALERT_MESSAGE_TRASHHOLD
          );
          hideResponseError();
          setIsRequesting(false);
          setRequestError({ isErrorShown: false, errorText: '' });
          // getUserDataCookie() is userData (userData not refreshing while running promise)
          setUserDataInRedux({ ...getUserDataCookie(), ...patchedUserData });
          formik.resetForm();
        })
        .catch((error) => {
          setIsRequesting(false);
          setIsRequestSuccess(false);
          setRequestError({
            isErrorShown: true,
            errorText: error.violations
              ? error.violations[0].message
              : error.message,
          });
          setTimeout(
            () => hideResponseError(),
            TextConstants.ALERT_MESSAGE_TRASHHOLD
          );
        });
    },
    validationSchema: personalInfoValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  useEffect(() => {
    // show buttons only after initial value change

    if (formik.values.gender !== formik.initialValues.gender) {
      setInitialValueChange(true);
    }

    if (
      new Date(formik.values.dateOfBirth).getTime() !==
      new Date(formik.initialValues.dateOfBirth).getTime()
    ) {
      setInitialValueChange(true);
    }

    if (
      Number.isNaN(new Date(formik.values.dateOfBirth).getTime()) &&
      Number.isNaN(new Date(formik.initialValues.dateOfBirth).getTime()) &&
      formik.values.gender === formik.initialValues.gender
    ) {
      setInitialValueChange(false);
    }
  }, [formik.values, formik.initialValues]);
  const { resetForm } = formik;

  const handleResetForm = () => {
    resetForm();
    setRequestError({ isErrorShown: false, errorText: '' });
  };

  const renderInputs = () => (
    <Box pb="20px">
      <FormControl
        isInvalid={formik.errors.dateOfBirth !== undefined}
        pt="20px"
      >
        <FormLabel
          htmlFor="dateOfBirth"
          fontFamily="Inter"
          fontSize="14px"
          lineHeight="20px"
          color="gray.700"
        >
          {TextConstants.PROFILE_MANAGMENT.PERSONAL_INFO_SECTION.BIRTHDAY_TITLE}
        </FormLabel>
        <InputGroup>
          <InputLeftElement color="gray.500">
            <SVG id="calendar" />
          </InputLeftElement>
          <DatePicker
            name="dateOfBirth"
            placeholderText="MM/DD/YYYY"
            selected={formik.values.dateOfBirth}
            clearButtonClassName="datepicker-clear-button"
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={100}
            onChange={(value) => {
              if (value === null) {
                formik.setFieldValue('dateOfBirth', '');
              } else {
                formik.setFieldValue('dateOfBirth', value);
              }
            }}
            showPopperArrow
            customInput={<CustomInput />}
          />
          <FormErrorMessage>{formik.errors.dateOfBirth}</FormErrorMessage>
        </InputGroup>
      </FormControl>

      <FormControl
        isInvalid={formik.errors.gender !== undefined}
        mt="25px"
        borderTop="1px solid var(--chakra-colors-gray-200)"
        pt="20px"
      >
        <FormLabel
          htmlFor="gender"
          fontFamily="Inter"
          fontSize="14px"
          lineHeight="20px"
          color="gray.700"
        >
          {TextConstants.PROFILE_MANAGMENT.PERSONAL_INFO_SECTION.GENDER_TITLE}
        </FormLabel>
        <InputGroup>
          <InputLeftElement color="gray.500">
            <SVG id="gender" />
          </InputLeftElement>

          <Select
            className="gender-select"
            name="gender"
            value={formik.values.gender}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            background="white"
            borderRadius="none"
            color="gray.900"
            borderWidth="1px"
            borderColor="gray.300"
            boxShadow="unset"
            fontFamily="third"
            iconSize="lg"
          >
            <option value="">Select your gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="rather_not_say">Rather not say</option>
            <option value="custom">Custom</option>
          </Select>
          <FormErrorMessage>{formik.errors.gender}</FormErrorMessage>
        </InputGroup>
      </FormControl>
    </Box>
  );

  return (
    <Container>
      <Stack
        mt="10px"
        mb="20px"
        justifyContent="center"
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        {renderInputs()}

        {isInitialValueChanged && (
          <ProfileSectionButtons
            cancelButtonTitle={TextConstants.BUTTON_TEXT.CANCEL}
            saveButtonTitle={TextConstants.BUTTON_TEXT.SAVE_PROFILE_DATA}
            isDisabled={isRequesting}
            resetForm={handleResetForm}
          />
        )}
      </Stack>

      {requestError.isErrorShown && (
        <Box pb="30px">
          <Alert status="error">
            <AlertIcon />
            {requestError.errorText}
          </Alert>
        </Box>
      )}

      {isRequestSuccess && (
        <Box pb="30px">
          <Alert
            status="success"
            backgroundColor="var(--chakra-colors-brand-500)"
            color="#fff"
          >
            <AlertIcon color="#fff" />
            {
              TextConstants.PROFILE_MANAGMENT.PERSONAL_INFO_SECTION
                .UPDATE_PERSONAL_INFO_SUCCESS
            }
          </Alert>
        </Box>
      )}
    </Container>
  );
};

export default PersonalInfo;
