import { useDispatch, useSelector } from 'react-redux';
import { setAddProductsToBag } from '../../store/reducers/commerceReducer';
import { AppDispatch, RootState } from '../../store/store';
import { CommerceInitialState } from '../../global-components/GlobalTypes';
import {addOrUpdateCart} from "../../global-components/RequestFactory"
import { useIsUserLoggedIn } from '../../helpers/userInfo/UserInfo';
import { parsedAddCartRequest } from '../../utils/cartUtils';

const useAddProductToBag = (
  selectedProductVariants: CommerceInitialState['activeProductVariant'][]
): (() => void) => {
  const dispatch = useDispatch<AppDispatch>();
  const myBagVariants = useSelector(
    (state: RootState) => state.commerce.myBagDataItems
  );

  const isLoggedIn = useIsUserLoggedIn();

  const handleDispatch = () => {
    if (selectedProductVariants.length) {
        let newMyBagData = []
      if (!myBagVariants.length) {
        newMyBagData = selectedProductVariants
        // dispatch(setAddProductsToBag(selectedProductVariants));
      } else {        
        newMyBagData = myBagVariants;
        for (let i = 0; i < selectedProductVariants.length; i++) {
          const selectedProductVariant = selectedProductVariants[i];
          const repeatingVariant =
            myBagVariants.filter(
              (variant) =>
                variant.productVariant &&
                variant.productVariant.sku ===
                  selectedProductVariant.productVariant.sku
            ) || [];
          if (!repeatingVariant.length) {
            newMyBagData = [...newMyBagData, selectedProductVariant];            
          }
        }        
      }
      if(isLoggedIn){
        const parsedCartReq = parsedAddCartRequest(newMyBagData)
        addOrUpdateCart(isLoggedIn, {"cart_item": parsedCartReq})
     }
      dispatch(setAddProductsToBag(newMyBagData));
    }
  };

  return handleDispatch;
};
export default useAddProductToBag;
