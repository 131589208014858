import { useDispatch } from 'react-redux';
import { useState } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import TextConstants from '../../../constants/TextConstants';
import { SVG } from '../../icons/icons';
import {
  useGetUserInfo,
  useIsUserLoggedIn,
} from '../../../helpers/userInfo/UserInfo';
import { shareDiaryRecords } from '../../../global-components/RequestFactory';
import { setUserData } from '../../../store/reducers/authenticationReducer';
import { AppDispatch } from '../../../store/store';
import handleResponseErrorMessage from '../../../helpers/handleResponseErrorMessage';
import { emailValidation } from '../../../helpers/validation';

const Share = (): JSX.Element => {
  const isUserLogin = useIsUserLoggedIn();
  const [isRequesting, setIsRequesting] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [requestResponse, setRequestResponse] = useState({
    isAlertShown: false,
    alertText: '',
  });
  const dispatch = useDispatch<AppDispatch>();
  const userData = useGetUserInfo();

  const setUserDataInRedux = (result) => {
    dispatch(setUserData(result));
  };

  const validationSchema = Yup.object().shape({
    email: emailValidation(),
  });

  const hideAlertResponse = () => {
    setRequestResponse({ isAlertShown: false, alertText: '' });
  };
  const showAlertResponse = (alertMessage) => {
    setRequestResponse({ isAlertShown: true, alertText: alertMessage });
  };

  const days = 365;
  const date = new Date();
  const dateEnd = new Date(date.getTime());
  const dateStart = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      setIsRequesting(true);
      shareDiaryRecords(
        {
          email: values.email,
          dateStart: dateStart.toISOString(),
          dateEnd: dateEnd.toISOString(),
        },
        userData,
        setUserDataInRedux
      )
        .then(() => {
          showAlertResponse(
            TextConstants.JOURNAL.JOURNAL_SHARE_SUCCESS_MESSAGE
          );
          setIsRequestSuccess(true);
          setTimeout(() => {
            hideAlertResponse();
            setIsRequesting(false);
          }, TextConstants.ALERT_MESSAGE_TRASHHOLD);
          formik.resetForm();
        })
        .catch((error) => {
          if (error.violations && error.violations.length) {
            showAlertResponse(handleResponseErrorMessage(error.violations));
          } else {
            showAlertResponse(error.message);
          }
          setIsRequestSuccess(false);
          setIsRequesting(false);
        });
    },
    validationSchema,
    validateOnBlur: false,
  });
  return isUserLogin ? (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Box w="100%">
          <Text
            fontFamily="inter"
            fontStyle="normal"
            fontWeight="500"
            fontSize="16x"
            lineHeight="24px"
            color="var(--chakra-colors-gray-700)"
            pt="10px"
            pb="10px"
          >
            {TextConstants.JOURNAL.SHARE_TITLE}
          </Text>
          <InputGroup>
            <InputLeftElement pt="4px" pointerEvents="none">
              <SVG id="email" />
            </InputLeftElement>
            <FormControl isInvalid={formik.errors.email !== undefined}>
              <Input
                type="text"
                placeholder={TextConstants.JOURNAL.SHARE_PLACEHOLDER}
                bg="#FFFFFF"
                color="var(--chakra-colors-gray-500)"
                fontFamily="inter"
                fontStyle="normal"
                fontWeight="400"
                fontSize="14x"
                lineHeight="20px"
                name="email"
                pl="40px"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
          </InputGroup>
        </Box>
        <Button
          mt="25px"
          variant="no-radius"
          width="100%"
          height="40px"
          gap="10px"
          background="var(--chakra-colors-brand-600)"
          border="1px solid var(--chakra-colors-brand-600)"
          color="#FFFFFF"
          _hover={{ background: 'var(--chakra-colors-brand-600)' }}
          type="submit"
          disabled={isRequesting}
        >
          <SVG id="journal-share-2" />
          <Text
            fontFamily="inter"
            fontStyle="normal"
            fontWeight="500"
            fontSize="14px"
            lineHeight="24px"
            letterSpacing="0.15em"
          >
            {TextConstants.JOURNAL.SHARE_BUTTON}
          </Text>
        </Button>
      </form>
      {requestResponse.isAlertShown && (
        <Alert marginTop="10px" status={isRequestSuccess ? 'success' : 'error'}>
          <AlertIcon />
          {requestResponse.alertText}
        </Alert>
      )}
    </>
  ) : (
    <Text
      fontFamily="inter"
      fontStyle="normal"
      fontWeight="400"
      fontSize="14x"
      lineHeight="20px"
      color="var(--chakra-colors-gray-500)"
      pt="10px"
      pb="10px"
    >
      {TextConstants.JOURNAL.JOURNAL_SHARE_GUEST_TEXT}
    </Text>
  );
};

export default Share;
