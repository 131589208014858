import { Box } from '@chakra-ui/react';
import s from './IconInCircles.module.scss';
import { SVG } from '../../icons/icons';

const IconInCircles = ({
  icon,
  theme,
}: {
  icon: string;
  theme: 'success' | 'warning' | 'noColor' | 'primary';
}): JSX.Element => {
  let themeClassName = s.themeBrand;

  if (theme === 'success') {
    themeClassName = s.themeSuccess;
  }

  if (theme === 'warning') {
    themeClassName = s.themeWarning;
  }

  if (theme === 'noColor') {
    themeClassName = s.themeNoColor;
  }

  if (theme === 'primary') {
    themeClassName = s.themePrimary;
  }

  return (
    <Box className={`${s.IconInCircles} ${themeClassName}`}>
      <SVG id={icon} />
    </Box>
  );
};

export default IconInCircles;
