import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileContainer from './ProfileContainer';
import backIcon from '../../../assets/icons/drawer-back-icon.png';
import { RootState } from '../../../store/store';
import { setIsHeaderModalsShown } from '../../../store/reducers/commerceReducer';
import TextConstants from '../../../constants/TextConstants';

function ProfileDrawer(): JSX.Element {
  const dispatch = useDispatch();
  const isProfileDrawerOpen = useSelector(
    (state: RootState) => state.commerce.isHeaderModalsShown.profileDrawer
  );
  const closeHandlerFunction = () => {
    dispatch(setIsHeaderModalsShown({ profileDrawer: false }));
  };

  const HeaderComponent = () => (
    <Box
      cursor="pointer"
      onClick={() => {
        closeHandlerFunction();
      }}
    >
      <HStack flexDirection="row">
        <Image src={backIcon} boxSize="12px" objectFit="contain" />
        <Text
          fontSize="14px"
          fontFamily="Inter"
          letterSpacing="0.1em"
          color="var(--chakra-colors-brand-700)"
          fontWeight="medium"
          textTransform="uppercase"
        >
          {TextConstants.DRAWER.PROFILE_GOBACK_TITLE}
        </Text>
      </HStack>
    </Box>
  );

  return (
    <Drawer
      isOpen={isProfileDrawerOpen}
      placement="right"
      onClose={closeHandlerFunction}
      size="sm"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader bg="#FDF6F0">
          <HeaderComponent />
        </DrawerHeader>

        <DrawerBody bg="var(--chakra-colors-gray-100)" p="0px">
          <ProfileContainer />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default ProfileDrawer;
