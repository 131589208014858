import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

const useIsGlobalMediaPlaying = () => {
  const currentlyPlayingVideoID = useSelector(
    (state: RootState) => state.mediaMuteState.currentlyPlayingVideoID
  );

  return Boolean(currentlyPlayingVideoID);
};

export default useIsGlobalMediaPlaying;
