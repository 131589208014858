import TextConstants from '../constants/TextConstants';

export default function calculateVMDimensions(): number[] {
  const headerheight = 88;

  let vmWidth = window.innerWidth;
  if (vmWidth > 600) {
    vmWidth = 600;
  } else if (vmWidth < 300) {
    vmWidth = 300;
  }

  let vmHeight = window.innerHeight;
  if (vmHeight > 1030) {
    vmHeight = 900;
  } else if (vmHeight < 300) {
    vmHeight = 300;
  } else if (
    window.innerWidth <= TextConstants.SIZES.IPAD_WIDTH &&
    vmHeight > 300
  ) {
    vmHeight -= headerheight;
  } else {
    // padding-top===35px; padding-bottom===18px;
    vmHeight -= headerheight + 53;
  }

  return [vmWidth, vmHeight];
}
