import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { RootState } from '../../store/store';

function PermissionDeniedModal(): JSX.Element {
  const error = useSelector((state: RootState) => state.digitalAssistant.error);
  const history = useHistory();
  return (
    <Modal
      isOpen={error.msg === 'permissionsDenied'}
      onClose={() => {
        history.go(0);
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <VStack spacing="none">
              <Text as="h3" textStyle="text-lg" paddingY="4">
                Permissions Denied
              </Text>
              <VStack
                spacing="4"
                color="gray.500"
                textAlign="center"
                paddingBottom="4"
              >
                <Text>
                  Looks like you’ve denied us access to your camera and
                  microphone. You can always change permissions in your browser
                  settings and try again.
                </Text>
              </VStack>
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default PermissionDeniedModal;
