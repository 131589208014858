/* eslint-disable */
export default (
  <div>
    <h1 id="user-terms-and-conditions">USER TERMS AND CONDITIONS</h1>
    <p>
      We, Soul Machines Limited (we, us, our), are a ground-breaking high-tech
      company of AI researchers, neuroscientists, psychologists, artists and
      innovative thinkers. We use digital neural networks that combine
      biologically inspired models of the human brain and key sensory networks
      to create a virtual central nervous system that we call our Digital Brain
      (Digital Person). For the purposes of these terms and conditions (the
      Terms), where you access a Digital Person through our website, this will
      be called the Service.
    </p>
    <ol>
      <li>
        <p>The Terms</p>
        <ol>
          <li>
            <p>
              These are the Terms on which we allow you to access the Service.
            </p>
          </li>
          <li>
            <p>
              Please read these terms carefully before you access the Service.
              These terms tell you who we are, how we will provide access to the
              Service, how you and we may change or end the contract, what to do
              if there is a problem and other information.
            </p>
          </li>
          <li>
            <p>
              These Terms are supplemental to any other agreement you may have
              with us or other third parties, including our commercial partners
              who are utilising a Digital Person.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>Information about us and how to contact us</p>
        <ol>
          <li>
            <p>
              We are Soul Machines Limited a company registered in New Zealand
              under company number 6014687, with our registered address as set
              out below.
            </p>
          </li>
          <li>
            <p>You can contact us as follows:</p>
            <blockquote>
              <p>
                FAO: Director of Licensing and Consumer Business Development
              </p>
              <p>
                Address: L1, 106 Customs Street West, Auckland, 1010 New Zealand
              </p>
              <p>Email: support@soulmachines.com</p>
            </blockquote>
          </li>
        </ol>
      </li>
      <li>
        <p>Changes to the Terms</p>
        <ol>
          <li>
            <p>
              We may revise the Terms from time to time, for example if there
              are changes to relevant laws and regulatory requirements or
              changes to our business practices. If we revise these Terms we
              will post the revised Terms on this webpage and update the “Last
              Updated” date below.
            </p>
          </li>
          <li>
            <p>
              Your access will be subject to the most recent version of the
              Terms available on this webpage. We recommend that you read
              through the Terms available on this webpage regularly so that you
              can be sure that you are aware of any changes that may apply to
              you.
            </p>
          </li>
          <li>
            <p>These Terms were last updated on 15th June 2018</p>
          </li>
        </ol>
      </li>
      <li>
        <p>Important information about your data</p>
        <ol>
          <li>
            <p>
              Our Digital People will provide you with more personalized and
              tailored customer support by responding to your facial and vocal
              expressions. In order to provide such a tailored experience, it is
              necessary for our Digital People to obtain information about you,
              including through your computing device and any embedded
              microphone or camera. If you wish to disable your microphone or
              camera, please consult your device settings for information about
              how to disable these features. Please note that if you do disable
              your microphone or camera we will not be able to provide you with
              full access to all the features of our Digital People.
            </p>
          </li>
          <li>
            <p>
              Any personal information that we collect from you as you access
              the Service will be used in accordance with our privacy notice. To
              find out more about the data we collect from you and how we use it
              please read our privacy notice available&nbsp;
              <a
                href="https://www.soulmachines.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                here.
              </a>
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>Access to the Digital Person</p>
        <ol>
          <li>
            <p>
              By accessing the Service directly provided by us, you confirm that
              you have read, understand and agree to be bound by the rules set
              out in these Terms.
            </p>
          </li>
          <li>
            <p>
              Our Digital People are not intended for children. If you are under
              the age of 18, please do not access our Digital People at any time
              or in any manner.
            </p>
          </li>
          <li>
            <p>
              Access to the Service is currently made available to you free of
              charge.
            </p>
          </li>
          <li>
            <p>
              Access to the Service is permitted on a temporary basis. We may
              suspend, withdraw, discontinue or change all or any part of the
              Digital People without notice to you.
            </p>
          </li>
          <li>
            <p>
              Subject to your continued compliance with these Terms, we hereby
              grant to you a non-exclusive, non-transferable, non-sublicensable,
              revocable, licence to access and use the Service for your own
              personal use. If you breach any provision of these Terms, this
              licence will immediately terminate and you must immediately cease
              use of the Service.
            </p>
          </li>
          <li>
            <p>
              You are responsible for making all arrangements necessary for you
              to have access and use the Service.
            </p>
          </li>
          <li>
            <p>
              You are responsible for ensuring that all persons who access or
              use the Service through your internet connection are aware of
              these Terms and other applicable terms and conditions, and that
              they comply with them.
            </p>
          </li>
          <li>
            <p>
              We guarantee to you that the Service will be provided using
              reasonable care and skill. However, we are not able to guarantee
              the availability of the Service or the accuracy, completeness,
              currency or reliability of any features, content, materials or
              information of the Service. We also cannot guarantee that the
              Service will be secure or free from bugs or viruses.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>Acceptable use</p>
        <ol>
          <li>
            <p>
              You agree not to access or use, or attempt to access or use, any
              Digital Person to take any action that could harm us or any third
              party, interfere with or harm any Digital Person or the operation
              thereof, or use any Digital Person (including by providing
              information to the Digital Person) in any manner that violates any
              laws or third-party rights. For example, and without limitation,
              you may:
            </p>
            <ol>
              <li>
                <p>
                  not copy the Digital Person (or any element thereof) except
                  where such copying is incidental to normal use or access of
                  the Digital Person, or where it is necessary for the purpose
                  of back-up or operational security;
                </p>
              </li>
              <li>
                <p>
                  not access or use the Digital Person in any way that causes or
                  may cause, damage to the Digital Person or impairment of the
                  availability or accessibility of the Digital People;
                </p>
              </li>
              <li>
                <p>
                  not to rent, lease, sub-license, loan, translate, merge,
                  adapt, vary or modify the Digital Person (or any element
                  thereof);
                </p>
              </li>
              <li>
                <p>
                  modify, translate, reverse engineer, disassemble, decompile or
                  create derivative works of the Digital Person, or allow a
                  third party, directly or indirectly, to do any of the
                  foregoing;
                </p>
              </li>
              <li>
                <p>
                  permit any third party to access or use the Digital Person
                  except as expressly permitted by this Agreement and, in the
                  case of Third Party Content, any applicable third-party
                  license agreement;
                </p>
              </li>
              <li>
                <p>
                  post, use, store or transmit information that is false,
                  incomplete, outdated, infringing, unlawful or otherwise
                  violate of third party rights, libellous, defamatory,
                  deceptive, obscene, fraudulent, predatory of minors,
                  harassing, threatening or hateful;
                </p>
              </li>
              <li>
                <p>
                  disrupt or attempt to disrupt the operation of the Digital
                  Person by any method, including through use of viruses, Trojan
                  horses, worms, time bombs, denial of service attacks, flooding
                  or spamming or use any robot, scraper or similar technology;
                </p>
              </li>
              <li>
                <p>
                  attempt to gain unauthorized access to any user accounts,
                  computer systems or networks; or
                </p>
              </li>
              <li>
                <p>
                  engage in behaviour towards the Digital Person, or encourage
                  the Digital Person to engage in behaviour, which we deem to be
                  abusive, unlawful, libellous, defamatory, deceptive, obscene,
                  fraudulent, predatory of minors, harassing, threatening,
                  hateful or otherwise harmful or offensive.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <p>Third party content</p>
        <p>
          By accessing the Service, third party (including data, information,
          applications, and other products, services, and/or materials) or links
          (including to third-party websites, applications or services) (Third
          Party Content) may be made available to you. We have no control over
          any Third-Party Content and we are not responsible or liable for them
          in anyway.
        </p>
      </li>
      <li>
        <p>Intellectual property</p>
        <ol>
          <li>
            <p>
              We are the owner or the licensee of all intellectual property
              rights in the Service, our Digital People or any element thereof.
              Any and all features, content, materials and information available
              via the Service (with the exception of Third Party Content) are
              owned or licensed by us and shall remain owned or licensed by us
              at all times.
            </p>
          </li>
          <li>
            <p>
              If you copy, use or access any part of the Service in breach of
              the Terms, your right to use and access the Service will cease
              immediately and you must destroy any copies of the materials you
              have made.
            </p>
          </li>
          <li>
            <p>
              All right, title and interest in and to any Third-Party Content
              shall remain with the respective rights holders.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>Feedback</p>
        <p>
          We are always looking to try and improve our technology and our
          Digital People so would welcome any comment, questions, suggestions or
          ideas (Feedback) you have may about us or our Digital People. By
          submitting Feedback to us you agree that we may use your Feedback in
          any way without obligation to you.
        </p>
      </li>
      <li>
        <p>Our responsibility for loss or damage suffered by you</p>
        <ol>
          <li>
            <p>
              If we fail to comply with these terms, we are responsible for loss
              or damage you suffer that is a foreseeable result of our breaking
              this contract or our failing to use reasonable care and skill, but
              we are not responsible for any loss or damage that is not
              foreseeable. Loss or damage is foreseeable if either it is obvious
              that it will happen or if, at the time the contract was made, both
              we and you knew it might happen, for example, if you discussed it
              with us before you first accessed the Service.
            </p>
          </li>
          <li>
            <p>
              This includes liability for death or personal injury caused by our
              negligence or the negligence of our employees, agents or
              subcontractors; for fraud or fraudulent misrepresentation; for
              breach of your legal rights in relation to your access to the
              Service.
            </p>
          </li>
          <li>
            <p>
              If by accessing the Service we damage your device or digital
              content belonging to you and this is caused by our failure to use
              reasonable care and skill we will either repair the damage or pay
              you compensation. However, we will not be liable for damage which
              you could have avoided by following our advice to apply an update
              offered to you free of charge or for damage which was caused by
              you failing to correctly follow installation instructions or to
              have in place the minimum system requirements advised by us.
            </p>
          </li>
          <li>
            <p>
              We only supply the products for domestic and private use. If you
              use the products for any commercial, business or re-sale purpose
              we will have no liability to you for any loss of profit, loss of
              business, business interruption, or loss of business opportunity.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>Other important terms</p>
        <ol>
          <li>
            <p>
              We may transfer our rights and obligations under these Terms to
              another organisation, but this will not affect your rights or our
              obligations under these Terms. You may only transfer your rights
              or obligations under these Terms to another person if we agree in
              writing.
            </p>
          </li>
          <li>
            <p>
              Nobody else has any rights under this contract. This contract is
              between you and us. No other person shall have any rights to
              enforce any of its terms.
            </p>
          </li>
          <li>
            <p>
              Each of the paragraphs of these terms operates separately. If any
              court or relevant authority decides that any of them are unlawful,
              the remaining paragraphs will remain in full force and effect.
            </p>
          </li>
          <li>
            <p>
              If we do not insist immediately that you do anything you are
              required to do under these terms, or if we delay in taking steps
              against you in respect of your breaking this contract, that will
              not mean that you do not have to do those things and it will not
              prevent us taking steps against you at a later date.
            </p>
          </li>
          <li>
            <p>
              These Terms and any non-contractual obligations arising out of
              them shall be governed by and construed in accordance with New
              Zealand law.
            </p>
          </li>
        </ol>
      </li>
    </ol>
  </div>
);
