import { PermissionType } from '../../global-components/GlobalTypes';

export const acceptPermissionAndSetInLocalStorage = (
  storage_key:
    | 'areMediaPermissionsAccepted'
    | 'isHappyHatsTOSAccepted'
    | 'isSMPermissionAccepted',
  param: PermissionType
): void => {
  localStorage.setItem(storage_key, param);
};

export const getPermissionAcceptance = (
  storage_key:
    | 'areMediaPermissionsAccepted'
    | 'isHappyHatsTOSAccepted'
    | 'isSMPermissionAccepted'
): PermissionType => {
  if (localStorage.getItem(storage_key) === null) {
    return 'disagree';
  }
  return localStorage.getItem(storage_key) as PermissionType;
};
