import {
  Box,
  Button,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { useRef } from 'react';
import styled from 'styled-components';
import { SVG } from '../../../components/icons/icons';
import TextConstants from '../../../constants/TextConstants';
import { CustomizeMakeupProductType } from '../../../global-components/GlobalTypes';

const Container = styled.div`
  .ingredients-headline {
    align-items: start !important;
    width: 100%;
    font-weight: bold;
    margin-top: 10px;
    font-size: 13px;
  }
`;

const ProductInfoPopover = ({
  gutter,
  iconScale,
  product,
}: {
  gutter: number;
  iconScale: number;
  product?: CustomizeMakeupProductType;
}): JSX.Element => {
  const popoverContentRef = useRef(null);

  return (
    <Container>
      <Popover
        gutter={gutter}
        placement="top"
        computePositionOnMount
        onClose={() => popoverContentRef.current.scrollTo(0, 0)}
      >
        <PopoverTrigger>
          <Button
            className="product-info-button"
            variant="no-style"
            transform={`scale(${iconScale})`}
          >
            <SVG id="info" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          _focus={{ boxShadow: 'none' }}
          overflowY="scroll"
          overflowX="hidden"
          maxHeight={{ base: '135px', iPad: '200px' }}
          ref={popoverContentRef}
        >
          <PopoverContent>
            <PopoverHeader
              justifyContent="space-between"
              display="flex"
              bg="var(--chakra-colors-primary-50)"
              fontWeight="bold"
            >
              <Box>{product?.name}</Box>

              {product?.awardImages?.length ? (
                <Box alignItems="end" display="flex">
                  <Image
                    src={product?.awardImages[0]?.staticStorageUrl}
                    alt={product?.awardImages[0]?.originalName}
                    width="50px"
                    height="auto"
                  />
                </Box>
              ) : (
                ''
              )}
            </PopoverHeader>
            <PopoverBody p="10px 25px 10px 10px">
              <Text>{product?.description}</Text>
            </PopoverBody>
          </PopoverContent>

          {
            // BE issue
            product?.selenaMessage !== 'NULL' ? (
              <PopoverContent>
                <PopoverHeader
                  color="var(--chakra-colors-primary-600)"
                  fontWeight="bold"
                >
                  {TextConstants.COMMERCE.PRODUCT_INFO.SELENA_MESSAGE}
                </PopoverHeader>
                <PopoverBody p="10px 25px 10px 10px">
                  <Text>{product.selenaMessage}</Text>
                </PopoverBody>
              </PopoverContent>
            ) : null
          }

          {
            // BE issue
            product?.ingredients !== 'None' && product?.ingredients !== null ? (
              <PopoverContent>
                <PopoverHeader
                  mb="-10px"
                  color="var(--chakra-colors-primary-600)"
                  fontWeight="bold"
                >
                  {TextConstants.COMMERCE.PRODUCT_INFO.INGREDIENTS}
                </PopoverHeader>
                <PopoverBody
                  p="10px 25px 10px 10px"
                  dangerouslySetInnerHTML={{ __html: product.ingredients }}
                />
              </PopoverContent>
            ) : null
          }
        </PopoverContent>
      </Popover>
    </Container>
  );
};

export default ProductInfoPopover;
