import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import xIcon from '../../../assets/icons/x-icon.png';
import plusIcon from '../../../assets/icons/plus-icon.png';
import minusIcon from '../../../assets/icons/minus-icon.png';
import { CommerceInitialState } from '../../../global-components/GlobalTypes';

function MyBagDrawerProduct({
  productVariant,
  productVariantUpdateQuantity,
  removeProduct,
}: {
  productVariant: CommerceInitialState['activeProductVariant'];
  productVariantUpdateQuantity: (
    variant: CommerceInitialState['activeProductVariant']
  ) => void;
  removeProduct: (
    variant: CommerceInitialState['activeProductVariant']
  ) => void;
}): JSX.Element {
  const product = productVariant.productVariant;
  const currentQuantity = Number(productVariant.quantity);

  return product ? (
    <HStack flexDirection="row" justifyContent="space-around" marginTop="25px">
      <Image
        src={xIcon}
        cursor="pointer"
        onClick={() => {
          removeProduct(productVariant);
        }}
      />
      <Image
        src={product?.image?.staticStorageUrl}
        alt={product?.image?.originalName}
        boxSize="100px"
        objectFit="cover"
      />

      <Text
        width="160px"
        fontSize="12px"
        fontFamily="Inter"
        letterSpacing="0.1em"
        color="var(--chakra-colors-brand-700)"
        fontWeight="medium"
      >
        {productVariant?.name}
      </Text>

      <VStack>
        <Text
          fontSize="15px"
          fontFamily="Inter"
          letterSpacing="0.1em"
          color="var(--chakra-colors-brand-700)"
          fontWeight="medium"
        >
          ${product?.price}
        </Text>
        <HStack w="100%" h="40px" border="2px" borderColor="gray.200">
          <button
            onClick={() => {
              productVariantUpdateQuantity({
                ...productVariant,
                quantity: currentQuantity - 1,
              });
            }}
            disabled={Number(productVariant?.quantity) === 1}
            type="button"
          >
            <Image src={minusIcon} cursor="pointer" />
          </button>

          <Text minWidth="20px" textAlign="center">
            {productVariant?.quantity}
          </Text>
          <button
            onClick={() => {
              productVariantUpdateQuantity({
                ...productVariant,
                quantity: currentQuantity + 1,
              });
            }}
            type="button"
          >
            <Image src={plusIcon} />
          </button>
        </HStack>
      </VStack>
    </HStack>
  ) : null;
}

export default MyBagDrawerProduct;
