import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  addDiaryRecord,
  createGuestUserJournalScore,
} from '../../../global-components/RequestFactory';
import TextConstants from '../../../constants/TextConstants';
import { SVG } from '../../icons/icons';
import { AppDispatch, RootState } from '../../../store/store';
import {
  sendTextMessage,
  stopSpeaking,
} from '../../../store/reducers/digitalAssistantReducer';
import {
  FormattedRecordDataType,
  RecordDataType,
} from '../../../global-components/GlobalTypes';
import {
  useGetUserInfo,
  useIsUserLoggedIn,
} from '../../../helpers/userInfo/UserInfo';
import { setUserData } from '../../../store/reducers/authenticationReducer';
import cookies from '../../../helpers/addCookies';
import { setEntryDataRedux } from '../../../store/reducers/journalReducer';

const AddNewEntry = ({
  handleUpdate,
  handleCloseNewEntry,
  handleEntryDetails,
}: {
  handleUpdate: () => void;
  handleCloseNewEntry: () => void;
  handleEntryDetails: (entry: RecordDataType) => void;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const lastPersonaUtterance = useSelector(
    (state: RootState) => state.digitalAssistant.lastPersonaUtterance
  );
  const cookieEntryData = useSelector(
    (state: RootState) => state.journalState.entryData
  );
  const entryData = [...cookieEntryData];
  const userData = useGetUserInfo();
  const setUserDataInRedux = (result) => {
    dispatch(setUserData(result));
  };
  const targetPersonaUtterance =
    "That's great! Let's start with making a journal entry.";

  const islogin = useIsUserLoggedIn();
  const [isRequesting, setIsRequesting] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const maxCharts = 2000;
  const [charsCount, setCharsCount] = useState(maxCharts);
  const timestamp = new Date().getTime();
  const minutes = 1000 * 60 * 60 * 24;
  const expire = timestamp + minutes;
  const expireDate = new Date(expire);

  const validationSchema = Yup.object().shape({
    text: Yup.string().min(1).required(TextConstants.ERROR_TEXT.REQUIRED),
  });

  const newEntryAuthUserRequest = (values) => {
    addDiaryRecord(
      {
        text: values.text,
        analyze: true,
      },
      userData,
      setUserDataInRedux
    )
      .then((data) => {
        if (targetPersonaUtterance === lastPersonaUtterance) {
          dispatch(stopSpeaking());
          dispatch(sendTextMessage({ text: 'proceed' }));
        }
        setIsRequesting(false);
        setIsShowAlert(true);
        handleUpdate();
        formik.resetForm();
        setTimeout(() => {
          setIsShowAlert(false);
          handleEntryDetails(data);
          handleCloseNewEntry();
        }, 1500);
      })
      .catch((error) => {
        setIsRequesting(false);
        // TODO if error DP speaks error
        if (targetPersonaUtterance === lastPersonaUtterance) {
          dispatch(stopSpeaking());
          dispatch(sendTextMessage({ text: 'proceed' }));
        }
        console.log('error', error);
      });
  };

  const newEntryGuestUserRequest = (values) => {
    createGuestUserJournalScore({
      text: values.text,
    })
      .then((result) => {
        const guestEntryData = cookies.get('guestEntryData') || [];
        const newDate = new Date();
        const createdAt = new Date(newDate.getTime()).toISOString();
        const data = {
          createdAt,
          text: values.text,
          ...result,
        };
        cookies.set('guestEntryData', [data, ...guestEntryData], {
          path: '/',
          expires: expireDate,
        });
        if (targetPersonaUtterance === lastPersonaUtterance) {
          dispatch(stopSpeaking());
          dispatch(sendTextMessage({ text: 'proceed' }));
        }
        setIsRequesting(false);
        setIsShowAlert(true);
        let newData = [];
        const scoresData = {
          ...data.emotionalScore,
          ...data.classificationScore,
        };
        if (!entryData.length) {
          newData = [
            {
              date: data.createdAt.split('T')[0],
              average: scoresData,
              records: [data],
            },
          ];
        } else if (
          entryData[entryData.length - 1].date === data.createdAt.split('T')[0]
        ) {
          const dayAllRecords = [
            ...entryData[entryData.length - 1].records,
            data,
          ];

          let joySum = 0;
          let fearSum = 0;
          let angerSum = 0;
          let frustratedSum = 0;
          let sadSum = 0;
          let excitedSum = 0;
          let satisfiedSum = 0;
          for (let i = 0; i < dayAllRecords.length; i += 1) {
            const element = dayAllRecords[i];
            joySum += element.emotionalScore.joy;
            fearSum += element.emotionalScore.fear;
            angerSum += element.emotionalScore.anger;
            frustratedSum += element.classificationScore.frustrated;
            sadSum += element.classificationScore.sad;
            excitedSum += element.classificationScore.excited;
            satisfiedSum += element.classificationScore.satisfied;
          }
          newData = [
            ...entryData.splice(0, entryData.length - 1),
            {
              date: entryData[entryData.length - 1].date,
              average: {
                joy: joySum / dayAllRecords.length,
                fear: fearSum / dayAllRecords.length,
                anger: angerSum / dayAllRecords.length,
                frustrated: frustratedSum / dayAllRecords.length,
                sad: sadSum / dayAllRecords.length,
                excited: excitedSum / dayAllRecords.length,
                satisfied: satisfiedSum / dayAllRecords.length,
              },
              records: dayAllRecords,
            },
          ];
        } else {
          newData = [
            ...cookieEntryData,
            {
              date: data.createdAt.split('T')[0],
              average: scoresData,
              records: [data],
            },
          ];
        }
        dispatch(setEntryDataRedux(newData));

        formik.resetForm();
        setTimeout(() => {
          setIsShowAlert(false);
          handleEntryDetails(data);
          handleCloseNewEntry();
        }, 1500);
      })
      .catch((error) => {
        setIsRequesting(false);
        // TODO if error DP speaks error
        if (targetPersonaUtterance === lastPersonaUtterance) {
          dispatch(stopSpeaking());
          dispatch(sendTextMessage({ text: 'proceed' }));
        }
        console.log('error', error);
      });
  };

  const formik = useFormik({
    initialValues: {
      text: '',
    },
    onSubmit: (values) => {
      setIsRequesting(true);
      if (islogin) {
        newEntryAuthUserRequest(values);
      } else {
        newEntryGuestUserRequest(values);
      }
    },
    validationSchema,
    validateOnBlur: false,
  });

  useEffect(() => {
    setCharsCount(maxCharts - formik.values.text.length);
  }, [formik.values]);

  return (
    <Box pr="30px" pl="30px" pb="20px" height="100%" position="relative">
      <form
        className="new-entry-form"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Box w="100%">
          <Text
            display="inline-block"
            fontFamily="inter"
            fontStyle="normal"
            fontWeight="500"
            fontSize="16x"
            lineHeight="24px"
            color="var(--chakra-colors-gray-700)"
          >
            {TextConstants.JOURNAL.NEW_ENTRY_TITLE}
          </Text>
          <Box
            cursor="pointer"
            display="inline-block"
            position="absolute"
            right="30px"
            top="35px"
            onClick={() => handleCloseNewEntry()}
          >
            <SVG id="close-x" />
          </Box>
          <Text
            fontFamily="inter"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14x"
            lineHeight="20px"
            color="var(--chakra-colors-gray-500)"
            pb="20px"
          >
            {TextConstants.JOURNAL.NEW_ENTRY_SUBTITLE}
          </Text>
          <FormControl isInvalid={formik.errors.text !== undefined}>
            {isShowAlert ? (
              <Alert
                bg="var(--chakra-colors-brand-600)"
                color="#FFFFFF"
                fontFamily="inter"
                fontStyle="normal"
                fontWeight="400"
                fontSize="14x"
                lineHeight="20px"
                maxHeight="400px"
                status="success"
                variant="subtle"
                mb="25px"
                borderRadius="5px"
              >
                <AlertIcon color="#FFFFFF" />
                {TextConstants.JOURNAL.NEW_ENTRY_ALERT_CONTENT}
              </Alert>
            ) : null}
            <Textarea
              maxLength={maxCharts}
              bg="#FFFFFF"
              color="var(--chakra-colors-gray-500)"
              fontFamily="inter"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14x"
              lineHeight="20px"
              maxHeight="400px"
              height="350px"
              name="text"
              onChange={formik.handleChange}
              value={formik.values.text}
            />
            <FormErrorMessage>{formik.errors.text}</FormErrorMessage>
          </FormControl>

          <Text
            fontFamily="inter"
            fontStyle="normal"
            fontWeight="400"
            fontSize="13x"
            lineHeight="20px"
            color="var(--chakra-colors-gray-500)"
            mt="5px"
          >
            {charsCount} {TextConstants.JOURNAL.NEW_ENTRY_TEXT_LENGTH}
          </Text>
        </Box>
        {!isShowAlert && (
          <Box width="100%" textAlign="center" position="absolute" bottom="4%">
            <Button
              variant="no-radius"
              height="40px"
              gap="10px"
              background="#fdf6f0"
              border="1px solid var(--chakra-colors-brand-600)"
              color="var(--chakra-colors-brand-600)"
              _hover={{ background: '#fdf6f0' }}
              type="submit"
              isDisabled={isRequesting}
            >
              <SVG id="journal-add-icon" />
              <Text
                fontFamily="inter"
                fontStyle="normal"
                fontWeight="500"
                fontSize="14px"
                letterSpacing="0.15em"
              >
                {TextConstants.JOURNAL.NEW_ENTRY_SUBMIT_BUTTON}
              </Text>
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
};
export default AddNewEntry;
