import {
  CommerceInitialState,
  CustomizeMakeupProductType,
} from '../../global-components/GlobalTypes';

const findProductVariantBySku = (
  sku: string,
  allProducts: CustomizeMakeupProductType[]
): CommerceInitialState['activeProductVariant'] => {
  if (sku && allProducts.length) {
    for (let i = 0; i < allProducts.length; i++) {
      const product = allProducts[i];
      for (let j = 0; j < product.productVariants.length; j++) {
        const productVariant = product.productVariants[j];
        if (productVariant.sku === sku) {
          return {
            productId: product.id,
            quantity: 1,
            name: product.name,
            productVariant,
          };
        }
      }
    }
  }
  return;
};

export default findProductVariantBySku;
