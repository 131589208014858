import { extendTheme } from '@chakra-ui/react';
import TextConstants from './constants/TextConstants';

const theme = extendTheme({
  breakpoints: {
    iPadPro: `${TextConstants.SIZES.IPAD_PRO_WIDTH}px`,
    iPad: `${TextConstants.SIZES.IPAD_WIDTH}px`,
    iPhone12: `${TextConstants.SIZES.IPHONE_12_WIDTH}px`,
    iPhoneXR: `${TextConstants.SIZES.IPHONE_XR_WIDTH}px`,
    iPhoneSE: `${TextConstants.SIZES.IPHONE_SE_WIDTH}px`,
    bigDevice: `${TextConstants.SIZES.BIG_SCREEN_DEVICE_SIZE}px`,
  },
  fonts: {
    headingMainPage: 'Ogg, serif',
    subHeadingMainPage: 'neue-haas-unica, sans-serif',
    third: 'Inter, sans-serif',
    heading: 'Inter',
    body: 'Inter',
    defaultFont: 'Inter',
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '6rem',
    '8xl': '7.5rem',
    '9xl': '9rem',
  },
  fontWeights: {
    normal: 350,
  },
  lineHeights: {
    normal: 'normal',
    none: 1,
    shorter: 1.25,
    short: 1.428,
    base: 1.5,
    tall: 1.555,
    taller: '2',
    3: '.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
  },
  colors: {
    brand: {
      25: '#F2E9ED',
      50: '#D9BEC8',
      100: '#BF93A4',
      200: '#B27D92',
      300: '#A56780',
      400: '#99516D',
      500: '#8C3C5B',
      600: '#7F2649',
      700: '#661E3A',
      800: '#4C172C',
      900: '#4C172C',
    },
    gray: {
      50: '#F9FAFB',
      100: '#F2F4F7',
      200: '#EAECF0',
      300: '#D0D5DD',
      400: '#98A2B3',
      500: '#667085',
      600: '#475467',
      700: '#344054',
      800: '#1D2939',
      900: '#101828',
    },
    success: {
      50: '#ECFDF3',
      100: '#D1FADF',
      200: '#A6F4C5',
      300: '#6CE9A6',
      400: '#32D583',
      500: '#12B76A',
      600: '#039855',
      700: '#027A48',
      800: '#05603A',
      900: '#054F31',
    },
    warning: {
      50: '#FFFAEB',
      100: '#FEF0C7',
      200: '#FEDF89',
      300: '#FEC84B',
      400: '#FDB022',
      500: '#F79009',
      600: '#DC6803',
      700: '#B54708',
      800: '#93370D',
      900: '#7A2E0E',
    },
    error: {
      25: '#FFFBFA',
      50: '#FEF3F2',
      100: '#FEE4E2',
      200: '#FECDCA',
      300: '#FDA29B',
      400: '#F97066',
      500: '#F04438',
      600: '#D92D20',
      700: '#B42318',
      800: '#912018',
      900: '#7A271A',
    },
    primary: {
      50: '#D9BEC8',
      100: '#BF93A4',
      600: '#7F2649',
    },
  },
  space: {
    15: '3.75rem',
    7: '1.5rem',
  },
  sizes: {
    11: '2.75rem',
    13: '3.25rem',
    15: '3.75rem',
    25: '25rem',
  },
  textStyles: {
    h1: {
      fontFamily: 'heading',
      fontSize: ['5xl', '5xl', '8xl'],
      fontWeight: 'regular',
      lineHeight: 'none',
    },
    h2: {
      fontFamily: 'heading',
      fontSize: ['2xl', '2xl', '6xl'],
      fontWeight: 'regular',
      lineHeight: 'base',
    },
    h3: {
      fontFamily: 'heading',
      fontSize: { base: '2xl', md: '4xl' },
      fontWeight: 'regular',
      lineHeight: 'tall',
    },
    'text-lg': {
      fontFamily: 'third',
      fontSize: ['xl'],
      fontWeight: 'medium',
      lineHeight: 'tall',
    },
    'text-sm': {
      fontFamily: 'third',
      fontSize: ['sm'],
      fontWeight: 'normal',
      lineHeight: 'base',
    },
    title: {
      fontFamily: 'defaultFont',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '28px',
    },

    subTitle: {
      fontFamily: 'defaultFont',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: 'gray.700',
    },
    inputLabel: {
      fontFamily: 'defaultFont',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '30px',
      lineHeight: '20px',
    },
    paragraph: {
      fontFamily: 'defaultFont',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
    },
    'tab-label': {
      fontFamily: 'inter',
      fontStyle: 'normal',
      fontWeight: 500,
    },
    'button-label': {
      fontFamily: 'inter',
      fontStyle: 'normal',
      fontWeight: 500,
    },
  },
  transition: {
    duration: {
      normal: '300ms',
    },
  },
  shadows: {
    outline: 'none',
    sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
  },
  components: {
    Container: {
      baseStyle: {
        px: {
          base: '1.25rem',
          md: '1rem',
        },
      },
    },
    Button: {
      baseStyle: {
        fontFamily: 'third',
        fontWeight: 'medium',
      },
      sizes: {
        sm: {
          fontSize: 'sm',
          px: 4,
          py: 2,
          h: '2.25rem',
        },
        lg: {
          fontSize: 'md',
          px: '1.125rem',
          py: 2,
          h: 11,
        },
        xl: {
          fontSize: 'md',
          px: 5,
          py: 3,
          h: 12,
        },
        '2xl': {
          fontSize: 'lg',
          px: 7,
          py: 4,
          h: 15,
        },
      },
      variants: {
        squared: {
          border: '1px solid #7F2649',
          borderRadius: { md: 'none' },
          textTransform: { md: 'uppercase' },
          lineHeight: 'base',
          letterSpacing: '0.1em',
          bg: 'brand.500',
          color: 'white',
          _hover: {
            bg: 'brand.600',
          },
        },
        'no-radius': {
          borderRadius: { md: 'none' },
          lineHeight: 'base',
          bg: 'brand.500',
          color: 'white',
          _hover: {
            bg: 'brand.600',
          },
        },
        secondary: {
          bg: '#FFFFFF',
          color: 'brand.700',
          _hover: {
            bg: 'brand.100',
            _disabled: {
              opacity: 1,
              color: 'brand.300',
              bg: 'brand.25',
            },
          },
          _focus: {
            bg: 'brand.50',
          },
          _disabled: {
            opacity: 1,
            color: 'brand.300',
            bg: 'brand.25',
          },
        },
        'secondary-gray': {
          borderColor: '#7F2649',
          borderRadius: 'none',
          border: '1px solid #7F2649',
          color: 'brand.700',
          letterSpacing: '0.1em',
          _hover: {
            bg: 'gray.50',
            _disabled: {
              opacity: 1,
              borderColor: 'gray.200',
              color: 'gray.300',
            },
          },
          _focus: {
            boxShadow: '0px 0px 0px 4px #EAECF0',
          },
          _disabled: {
            opacity: 1,
            borderColor: 'gray.200',
            color: 'gray.300',
          },
        },
        outline: {
          borderRadius: { md: 'none' },
          borderColor: 'gray.300',
          color: 'gray.700',
          _hover: {
            bg: 'gray.50',
            _disabled: {
              opacity: 1,
              borderColor: 'gray.200',
              color: 'gray.300',
            },
          },
          _focus: {
            boxShadow: '0px 0px 0px 4px #F2F4F7',
          },
          _disabled: {
            opacity: 1,
            borderColor: 'gray.200',
            color: 'gray.300',
          },
        },
        ghost: {
          color: 'brand.600',
          _hover: {
            bg: 'brand.50',
            _disabled: {
              color: 'gray.300',
              opacity: 1,
            },
          },
          _focus: {
            bg: 'transparent',
            boxShadow: 'none',
          },
          _disabled: {
            color: 'gray.300',
            opacity: 1,
          },
        },
        'ghost-gray': {
          color: 'gray.500',
          _hover: {
            bg: 'gray.50',
            color: 'gray.600',
            _disabled: {
              color: 'gray.300',
              opacity: 1,
            },
          },
          _focus: {
            bg: 'transparent',
          },
          _disabled: {
            color: 'gray.300',
            opacity: 1,
          },
        },
        link: {
          color: 'brand.700',
          _hover: {
            textDecoration: 'none',
            color: 'brand.800',
            _disabled: {
              color: 'gray.300',
              opacity: 1,
            },
          },
          _focus: {
            bg: 'transparent',
            boxShadow: 'none',
          },
          _disabled: {
            color: 'gray.300',
            opacity: 1,
          },
        },
        'link-gray': {
          color: 'gray.500',
          _hover: {
            textDecoration: 'none',
            color: 'gray.600',
            _disabled: {
              color: 'gray.300',
              opacity: 1,
            },
          },
          _focus: {
            bg: 'transparent',
          },
          _disabled: {
            color: 'gray.300',
            opacity: 1,
          },
        },
        error: {
          bg: 'error.600',
          color: 'white',
          _hover: {
            bg: 'error.700',
            _disabled: {
              opacity: 1,
              bg: 'error.200',
            },
          },
          _focus: {
            boxShadow:
              '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2',
          },
          _disabled: {
            opacity: 1,
            bg: 'error.200',
          },
        },
        'error-secondary': {
          bg: 'error.50',
          color: 'error.700',
          _hover: {
            bg: 'error.100',
            _disabled: {
              opacity: 1,
              bg: 'error.25',
              color: 'error.300',
            },
          },
          _focus: {
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          },
          _disabled: {
            opacity: 1,
            bg: 'error.25',
            color: 'error.300',
          },
        },
        'error-outline': {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'error.300',
          color: 'error.700',
          _hover: {
            bg: 'error.50',
            color: 'error.800',
            _disabled: {
              opacity: 1,
              borderColor: 'error.200',
              color: 'error.300',
            },
          },
          _focus: {
            boxShadow:
              '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2',
          },
          _disabled: {
            opacity: 1,
            borderColor: 'error.200',
            color: 'error.300',
          },
        },
        'ghost-error': {
          color: 'error.700',
          _hover: {
            bg: 'error.50',
            color: 'error.700',
            _disabled: {
              color: 'error.300',
              opacity: 1,
            },
          },
          _focus: {
            bg: 'transparent',
            boxShadow:
              '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2',
          },
          _disabled: {
            color: 'error.300',
            opacity: 1,
          },
        },
        'link-error': {
          color: 'error.700',
          _hover: {
            textDecoration: 'none',
            color: 'error.800',
            _disabled: {
              color: 'error.300',
              opacity: 1,
            },
          },
          _focus: {
            bg: 'transparent',
          },
          _disabled: {
            color: 'error.300',
            opacity: 1,
          },
        },
        'no-style': {
          height: 'unset',
          padding: '0',
          _focus: { boxShadow: 'unset' },
        },
        'circle-icon': ({ size, buttonType, themeColor }) => {
          const params = getCircleIconParams(size, buttonType, themeColor);

          return {
            borderRadius: '50%',
            minW: 'unset',
            minH: 'unset',
            p: 2,
            ...params,
            _hover: {
              ...params._hover,
              _disabled: {
                opacity: 1,
                ...params._disabled,
              },
            },
            _focus: {
              ...params._focus,
            },
            _disabled: {
              opacity: 1,
            },
          };
        },
      },
      defaultProps: {
        size: 'xl',
        colorScheme: 'brand',
        // variant: 'squared',
      },
    },
    Input: {
      parts: ['field', 'addon'],
      sizes: {
        md: {
          field: {
            h: 11,
          },
          addon: {
            h: 11,
          },
        },
      },
      variants: {
        outline: {
          field: {
            borderWidth: '1px',
            borderColor: 'gray.300',
            color: 'gray.900',
            boxShadow: 'unset',
            fontFamily: 'third',
            _focus: {
              boxShadow: 'none',
              borderColor: 'brand.300',
            },
            _placeholder: {
              color: 'gray.500',
              opacity: 1,
            },
            _disabled: {
              bg: 'gray.50',
              color: 'gray.500',
              opacity: 1,
              _placeholder: {
                color: 'gray.500',
                opacity: 1,
              },
            },
            _invalid: {
              boxShadow: 'unset',
              _focus: {
                boxShadow:
                  '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2',
                borderColor: 'error.300',
              },
            },
          },
          addon: {
            bg: 'white',
            px: '12px',
            color: 'gray.500',
            borderColor: 'gray.300',
            boxShadow: 'unset',
            fontFamily: 'third',
          },
        },
      },
      defaultProps: {
        colorScheme: 'brand',
        focusBorderColor: 'brand.300',
        errorBorderColor: 'error.300',
        _placeholder: {
          color: 'gray.500',
          opacity: 1,
        },
      },
    },
    Textarea: {
      baseStyle: {
        py: 2.5,
        lineHeight: 1.5,
      },
      variants: {
        outline: {
          borderWidth: '1px',
          borderColor: 'gray.300',
          color: 'gray.900',
          boxShadow: 'unset',
          fontFamily: 'third',
          _focus: {
            boxShadow: 'none',
            borderColor: 'brand.300',
          },
          _placeholder: {
            color: 'gray.500',
            opacity: 1,
          },
          _disabled: {
            bg: 'gray.50',
            color: 'gray.500',
            opacity: 1,
            _placeholder: {
              color: 'gray.500',
              opacity: 1,
            },
          },
          _invalid: {
            boxShadow: 'unset',
            _focus: {
              boxShadow:
                '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2',
              borderColor: 'error.300',
            },
          },
        },
      },
      sizes: {
        md: {
          minH: 32,
        },
      },
      defaultProps: {
        colorScheme: 'brand',
        focusBorderColor: 'brand.300',
        errorBorderColor: 'error.300',
        _placeholder: {
          color: 'gray.500',
          opacity: 1,
        },
      },
    },
    Select: {
      parts: ['field', 'icon'],
      baseStyle: {
        field: {
          cursor: 'pointer',
        },
        icon: {
          color: 'gray.500',
        },
      },
      sizes: {
        md: {
          field: {
            h: 11,
          },
        },
      },
      defaultProps: {
        colorScheme: 'brand',
      },
    },
    Progress: {
      sizes: {
        lg: {
          track: {
            h: 6,
            borderRadius: '2xl',
          },
        },
      },
      defaultProps: {
        colorScheme: 'brand',
      },
      variants: {
        transparent: {
          track: {
            border: '1px solid #fff',
            background: 'transparent',
          },
          filledTrack: {
            background: 'white',
          },
        },
      },
    },
    Modal: {
      parts: ['body', 'footer', 'overlay', 'dialog'],
      baseStyle: {
        dialogContainer: {
          pl: '0px',
          pr: '0px',
          '@supports(height: -webkit-fill-available)': {},
        },
        dialog: {
          borderRadius: '.5rem',
        },
        body: {
          pt: 6,
          pb: 6,
        },
        footer: {
          pt: 2,
          pb: 6,
        },
        overlay: {
          background: 'rgba(52, 64, 84, 0.7)',
          backdropFilter: 'blur(16px)',
        },
      },
    },
  },
});

function getCircleIconThemeParams(themeColor, type) {
  const THEME_PARAMS = {
    filled: {
      brand: {
        bg: 'brand.600',
        color: 'white',
        _hover: {
          bg: 'brand.700',
        },
        _focus: {
          boxShadow:
            '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7',
        },
        _disabled: {
          color: 'white',
          bg: 'brand.200',
        },
      },
      secondary: {
        bg: 'brand.50',
        color: 'brand.700',
        _hover: {
          bg: 'brand.100',
        },
        _focus: {
          boxShadow:
            '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7',
        },
        _disabled: {
          color: 'brand.300',
          bg: 'brand.25',
        },
      },
      error: {
        bg: 'error.600',
        color: 'white',
        _hover: {
          bg: 'error.700',
        },
        _focus: {
          boxShadow:
            '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2',
        },
        _disabled: {
          bg: 'error.200',
        },
      },
      'error-secondary': {
        bg: 'error.50',
        color: 'error.700',
        _hover: {
          bg: 'error.100',
        },
        _focus: {
          boxShadow:
            '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2',
        },
        _disabled: {
          bg: 'error.25',
          color: 'error.300',
        },
      },
    },
    outline: {
      brand: {
        bg: 'transparent',
        color: 'gray.700',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'gray.300',
        _hover: {
          bg: 'gray.50',
        },
        _focus: {
          boxShadow:
            '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7',
        },
        _disabled: {
          color: 'gray.300',
          borderColor: 'gray.200',
        },
      },
      secondary: {
        _hover: {},
        _focus: {},
        _disabled: {},
      },
      error: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'error.300',
        bg: 'transparent',
        color: 'error.700',
        _hover: {
          color: 'error.800',
          bg: 'error.50',
        },
        _focus: {
          boxShadow:
            '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2',
        },
        _disabled: {
          borderColor: 'error.200',
          color: 'error.300',
        },
      },
    },
    ghost: {
      brand: {
        bg: 'transparent',
        color: 'brand.700',
        _hover: {
          bg: 'brand.50',
        },
        _focus: {
          boxShadow:
            '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7',
        },
        _disabled: {
          color: 'gray.300',
        },
      },
      gray: {
        bg: 'transparent',
        color: 'gray.500',
        _hover: {
          bg: 'gray.50',
          color: 'gray.600',
        },
        _focus: {},
        _disabled: {
          color: 'gray.300',
        },
      },
    },
    link: {
      brand: {
        bg: 'transparent',
        color: 'brand.700',
        _hover: {
          color: 'brand.700',
          bg: 'brand.50',
        },
        _focus: {
          boxShadow: 'none',
        },
        _disabled: {
          color: 'brand.300',
        },
      },
      error: {
        bg: 'transparent',
        color: 'error.700',
        _hover: {
          color: 'error.700',
          bg: 'error.50',
        },
        _focus: {
          boxShadow:
            '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2',
        },
        _disabled: {
          color: 'error.300',
        },
      },
    },
  };

  return THEME_PARAMS[type][themeColor];
}

function getCircleIconParams(
  size = 'md',
  type = 'filled',
  themeColor = 'brand'
) {
  const SIZES = {
    sm: 9,
    md: 10,
    lg: 11,
    xl: 12,
    '2lx': 15,
  };

  const params = getCircleIconThemeParams(themeColor, type);

  return {
    h: SIZES[size],
    w: SIZES[size],
    ...params,
    _hover: params._hover,
    _focus: params._focus,
    _disabled: params._disabled,
  };
}

export default theme;
