import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReactEditor, withReact } from 'slate-react';
import { createEditor, Descendant, Editor, Transforms } from 'slate';
import { OnDrag, OnDragEnd, OnDragStart } from 'react-moveable';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { withHistory } from 'slate-history';
import HTTPMethod from 'http-method-enum';
import NoteBody from './NoteBody';
import {
  MovableFrame,
  NoteResponse,
  NoteTransformedResponse,
  NoteValue,
} from '../../../interfaces/Notes';
import {
  NOTE_CARD_CLASSNAME,
  RARE_REMINDER_LINK,
} from '../../../constants/Notes';
import {
  changeNotePosition,
  changeNoteValue,
  changeStackLevel,
  replaceNote,
} from '../../../store/reducers/noteManagerReducer';
import {
  getAppropriateStackLevel,
  withTextLimit,
} from '../../../services/Notes';
import MovableNote from './MovableNote';
import {
  getTransformedNote,
  transformMovableFrameToPoint,
} from '../../../utils/Notes';
import {
  STICKY_NOTE_API_ROUTE,
  STICKY_NOTES_URL,
} from '../../../constants/apiRoutes';
import { useOutsideHandler } from '../../../hooks/useOutsideHandler';
import { fetchResponse } from '../../../global-components/RequestFactory';
import { useIsUserLoggedIn } from '../../../helpers/userInfo/UserInfo';
import { RootState } from '../../../store/store';

type NoteProps = {
  onDelete: (id: number) => void;
  onDragStart: (event: OnDragStart) => void;
  onDragEnd: (event: OnDragEnd) => void;
  onMaxStackLevelIncreased: () => void;
  maxStackLevel: number;
  note: NoteTransformedResponse;
  isFocusedOnInit: boolean;
  mirrorWidth: number;
  mirrorHeight: number;
};

const Note = ({
  onDelete,
  onDragStart,
  onDragEnd,
  isFocusedOnInit,
  note,
  onMaxStackLevelIncreased,
  maxStackLevel,
  mirrorHeight,
  mirrorWidth,
}: NoteProps): JSX.Element => {
  const noteRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const isUserLogin = useIsUserLoggedIn();
  const [noteValue, setNoteValue] = useState<NoteValue[]>(note.value);
  const [currentStackLevel, setCurrentStackLevel] = useState<number>(
    note.stackLevel
  );

  const userData = useSelector(
    (state: RootState) => state.authenticationState.userData
  );

  const [frame, setFrame] = useState<MovableFrame>({
    translate: [0, 0],
    clipStyle: 'inset',
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const handleInsertTextCallback = () => {
    setIsEdit(true);
  };

  const editor = useMemo(
    () =>
      withHistory(
        withTextLimit()(
          withReact(createEditor() as ReactEditor),
          handleInsertTextCallback
        )
      ),
    []
  );

  // If some note after dragging overlaps other
  // we need to update max stack level
  // and next note will be used with increased stack level
  const changeStackLevelsIfNeeded = useCallback(() => {
    const point = transformMovableFrameToPoint(frame);
    const appropriateStackLevel = getAppropriateStackLevel(note.id, point);

    if (appropriateStackLevel === maxStackLevel + 1) {
      onMaxStackLevelIncreased();
    }

    dispatch(
      changeStackLevel({
        id: note.id,
        newStackLevel: appropriateStackLevel,
      })
    );
    setCurrentStackLevel(appropriateStackLevel);
  }, [onMaxStackLevelIncreased, frame, note]);

  const handleValueChange = (currentValue: Descendant[]) => {
    setNoteValue(currentValue as NoteValue[]);
  };

  const handleStopEditing = useCallback(async () => {
    const appropriateStackLevel = getAppropriateStackLevel(
      note.id,
      transformMovableFrameToPoint(frame)
    );
    const noteRequest = {
      value: noteValue,
      positionX: frame.translate[0],
      positionY: frame.translate[1],
      stackLevel: appropriateStackLevel,
    };

    dispatch(
      changeNoteValue({
        id: note.id,
        value: noteValue,
      })
    );
    changeStackLevelsIfNeeded();
    setIsEdit(false);

    if (isFocusedOnInit) {
      if (isUserLogin) {
        const createdNoteResponse = await fetchResponse<NoteResponse>(
          `${STICKY_NOTES_URL}/${STICKY_NOTE_API_ROUTE}`,
          HTTPMethod.POST,
          noteRequest,
          userData.token
        );

        dispatch(
          replaceNote({
            id: note.id,
            note: getTransformedNote(
              createdNoteResponse.data,
              mirrorWidth,
              mirrorHeight
            ),
          })
        );
      } else {
        dispatch(
          replaceNote({
            id: note.id,
            note: {
              ...note,
              stackLevel: appropriateStackLevel,
              position: transformMovableFrameToPoint(frame),
              isFocusedOnInit: false,
            },
          })
        );
      }

      return;
    }

    if (isUserLogin) {
      await fetchResponse(
        `${STICKY_NOTES_URL}/${STICKY_NOTE_API_ROUTE}/${note.id}`,
        HTTPMethod.PUT,
        noteRequest
      );
    }

    ReactEditor.blur(editor);
    ReactEditor.deselect(editor);
  }, [noteValue, editor, changeStackLevelsIfNeeded, note, frame]);

  const handleDragStart = useCallback(
    (event: OnDragStart) => {
      onDragStart(event);

      setCurrentStackLevel(maxStackLevel + 1);
      event.set(frame.translate);
    },
    [frame, maxStackLevel]
  );

  const handleDrag = useCallback(
    (e: OnDrag) => {
      setFrame((prevState) => ({
        ...prevState,
        translate: e.beforeTranslate,
      }));

      e.target.style.transform = `translate(${e.beforeTranslate[0]}px, ${e.beforeTranslate[1]}px)`;
    },
    [frame]
  );

  const handleDragEnd = useCallback(
    async (event: OnDragEnd) => {
      const eventPathArray: string[] = event.inputEvent
        .composedPath()
        .map((value: Element) => value.className);

      const isDeleteButtonClicked = eventPathArray.some((element) =>
        String(element).includes('note-delete-button')
      );

      const isLinkClicked = eventPathArray.some((element) =>
        String(element).includes('reminder-text')
      );
      const appropriateStackLevel = getAppropriateStackLevel(
        note.id,
        transformMovableFrameToPoint(frame)
      );
      const noteRequest = {
        value: noteValue,
        positionX: frame.translate[0],
        positionY: frame.translate[1],
        stackLevel: appropriateStackLevel,
      };

      onDragEnd(event);

      if (!event.isDrag && isDeleteButtonClicked) {
        onDelete(note.id);

        return;
      }

      if (!event.isDrag && isLinkClicked) {
        window.open(RARE_REMINDER_LINK, '_blank');

        return;
      }

      changeStackLevelsIfNeeded();

      if (!event.isDrag) {
        setIsEdit(true);

        return;
      }

      dispatch(
        changeNotePosition({
          id: note.id,
          newPosition: transformMovableFrameToPoint(frame),
        })
      );

      if (isUserLogin) {
        await fetchResponse<NoteResponse>(
          `${STICKY_NOTES_URL}/${STICKY_NOTE_API_ROUTE}/${note.id}`,
          HTTPMethod.PUT,
          noteRequest
        );
      }

      noteRef.current?.blur();
    },
    [changeStackLevelsIfNeeded, frame, noteRef, note]
  );

  useOutsideHandler(noteRef, isEdit, handleStopEditing);

  useEffect(() => {
    if (noteRef.current) {
      noteRef.current.style.transform = `translate(${note.position.x}px, ${note.position.y}px)`;

      if (isFocusedOnInit) {
        setCurrentStackLevel(maxStackLevel);
        setIsEdit(true);
      }

      setFrame((prevState) => ({
        ...prevState,
        translate: [note.position.x, note.position.y],
      }));
    }
  }, [noteRef, note.position]);

  useEffect(() => {
    if (isEdit) {
      ReactEditor.focus(editor);

      Transforms.select(editor, Editor.end(editor, []));
    }
  }, [isEdit]);

  return (
    <>
      <div
        ref={noteRef}
        style={{
          zIndex: currentStackLevel,
        }}
        className={classNames(
          NOTE_CARD_CLASSNAME,
          'd-flex flex-column justify-content-between'
        )}
      >
        <NoteBody
          isEdit={isEdit}
          value={noteValue}
          editor={editor}
          onChange={handleValueChange}
        />
      </div>
      {!isEdit && (
        <MovableNote
          targetElementRef={noteRef}
          mirrorWidth={mirrorWidth}
          mirrorHeight={mirrorHeight}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDrag={handleDrag}
        />
      )}
    </>
  );
};

export default memo(
  Note,
  (prevProps, nextProps) =>
    prevProps.note === nextProps.note &&
    prevProps.maxStackLevel === nextProps.maxStackLevel
);
