import { useCallback, useEffect, useState } from 'react';
import { Box, Image, Tooltip } from '@chakra-ui/react';
import Slider from 'react-slick';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import styled from 'styled-components';
import leftArrowIcon from '../../../img/arrow-left-icon.svg';
import rightArrowIcon from '../../../img/arrow-right-icon.svg';
import { SVG } from '../../../components/icons/icons';
import {
  CustomizeMakeupPatternType,
  CustomizeMakeupProductType,
} from '../../../global-components/GlobalTypes';
import removeActiveClassHelper from '../../../helpers/products/removeActiveClassHelper';
import checkSliderArrowDisabledOrNo from '../../../helpers/checkSliderArrowDisabledOrNo';
import { removeSelectedProductVariant } from '../../../store/reducers/commerceReducer';
import TextConstants from '../../../constants/TextConstants';

declare const window;

const SliderContainer = styled.div`
  padding: 0px 25px 0px 25px;
  display: flex;
  height: 22px;
  margin-top: 14px;
  margin-bottom: 14px;
  .slick-slide {
    div {
      display: flex;
      justify-content: center;
    }
  }
  .slick-list {
    padding-top: 2px;
  }
  .slick-dots {
    display: none !important;
  }

  .pattern-icon {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 33px !important;
    height: 33px !important;
    margin-top: -7px;
    background-color: #fff;
    border-radius: 50%;
    margin-left: ${({ patternsCount }) =>
      patternsCount > 2 ? 'unset' : '8px'};
    outline: none;
  }
  .isActive {
    border: 1px solid #fdf7f952;
    box-shadow: 0px 0px 3px 3px rgb(127 37 73);
  }
`;

function LeftArrow(props) {
  const { onClick, className } = props;
  return (
    <Image
      onClick={onClick}
      src={leftArrowIcon}
      className="ss"
      style={{
        display: checkSliderArrowDisabledOrNo(className),
        left: '-25px',
        fontSize: '0',
        lineHeight: 0,
        position: 'absolute',
        top: '50%',
        width: '20px',
        height: '20px',
        padding: '0',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
      }}
    />
  );
}

function RightArrow(props) {
  const { onClick, className } = props;
  return (
    <Image
      onClick={onClick}
      src={rightArrowIcon}
      style={{
        display: checkSliderArrowDisabledOrNo(className),
        right: '-25px',
        fontSize: '0',
        lineHeight: 0,
        position: 'absolute',
        top: '50%',
        width: '20px',
        height: '20px',
        padding: '0',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
      }}
    />
  );
}

const PatternSlider = ({
  selectedProductData,
  setSelectedPatternFunction,
}: {
  selectedProductData: CustomizeMakeupProductType;
  setSelectedPatternFunction: (pattern: CustomizeMakeupPatternType) => void;
}): JSX.Element => {
  const [swiped, setSwiped] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const handleSwiped = useCallback(() => {
    setSwiped(true);
  }, [setSwiped]);

  const handleOnItemClick = (event, pattern, index) => {
    if (swiped) {
      event.stopPropagation();
      event.preventDefault();
      setSwiped(false);
    } else {
      // add active color to patterns
      const removingElements = document.querySelectorAll(
        `[data-pattern-slider]`
      );
      removeActiveClassHelper(removingElements);
      const element = document.querySelector(
        `[data-pattern-slider=index${index}]`
      );
      element.classList.add('isActive');

      // save pattern data in state
      setSelectedPatternFunction(pattern);
    }
  };

  const maxCount = selectedProductData?.patternsData?.patterns?.length + 1;

  const patternsCount = maxCount - 1;

  useEffect(() => {
    // reset isActive border if new product is selected

    const allDataPatternSliders = document.querySelectorAll(
      `[data-pattern-slider]`
    );
    removeActiveClassHelper(allDataPatternSliders);

    allDataPatternSliders[0].classList.add('isActive');
  }, [selectedProductData]);

  useEffect(() => {
    const allDataPatternSliders = document.querySelectorAll(
      `[data-pattern-slider]`
    );
    allDataPatternSliders[0].classList.add('isActive');
    if (maxCount) {
      setSelectedPatternFunction(selectedProductData.patternsData.patterns[0]);
    }
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 2000,
    slidesToShow: maxCount ? Math.min(maxCount, 7) : 0,
    slidesToScroll: maxCount ? Math.min(maxCount, 7) : 0,
    initialSlide: 0,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    onSwipe: handleSwiped,
    variableWidth: patternsCount > 2 ? false : true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: maxCount ? Math.min(maxCount, 6) : 0,
          slidesToScroll: maxCount ? Math.min(maxCount, 6) : 0,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: maxCount ? Math.min(maxCount, 4) : 0,
          slidesToScroll: maxCount ? Math.min(maxCount, 4) : 0,
        },
      },
    ],
  };

  return (
    <>
      <SliderContainer>
        <Slider {...settings} patternsCount={patternsCount}>
          <Tooltip
            placement="top"
            hasArrow
            label={TextConstants.TOOLTIPS.RESET_SHADE_BUTTON}
            aria-label={TextConstants.TOOLTIPS.RESET_SHADE_BUTTON}
            bg="var(--chakra-colors-brand-600)"
            fontFamily="inter"
            fontStyle="normal"
            fontWeight="400"
            fontSize={{ base: '12px', md: '14px' }}
          >
            <Box
              ml="8px"
              mt="-5px"
              cursor="pointer"
              onClick={() => {
                // remove active colors from colors/patterns slider
                const removingElements = document.querySelectorAll(
                  `[data-pattern-slider]`
                );
                removeActiveClassHelper(removingElements);
                const removingAllColorsElements =
                  document.querySelectorAll(`[data-color-slider]`);
                removeActiveClassHelper(removingAllColorsElements);

                // unselect pattern
                setSelectedPatternFunction(null);

                if (selectedProductData) {
                  dispatch(
                    removeSelectedProductVariant(
                      selectedProductData.productVariants[0].sku
                    )
                  );
                }

                // reset YMK
                if (selectedProductData?.patternsData?.colors?.length) {
                  window.YMK.reset(
                    selectedProductData.patternsData.colors[0].skuId
                  );
                } else if (selectedProductData?.productVariants?.length) {
                  window.YMK.reset(selectedProductData.productVariants[0].sku);
                }
              }}
            >
              <SVG id="reset-color" />
            </Box>
          </Tooltip>
          {selectedProductData &&
            selectedProductData.patternsData.patterns.map((pattern, index) => (
              <button
                // changes button wrapping div's style
                style={{ width: patternsCount > 2 ? 'unset' : 62 }}
                key={pattern?.image?.id}
                className="pattern-icon"
                type="button"
                data-pattern-slider={'index' + index}
                onClickCapture={(event) =>
                  handleOnItemClick(event, pattern, index)
                }
              >
                <Image
                  maxHeight="100%"
                  borderRadius="50%"
                  src={pattern?.image?.staticStorageUrl}
                  alt={pattern?.name}
                />
              </button>
            ))}
        </Slider>
      </SliderContainer>
    </>
  );
};

export default PatternSlider;
