import * as Yup from 'yup';
import regexp from '../constants/Regexp';
import TextConstants from '../constants/TextConstants';

export const passwordValidation = () => {
  return Yup.string()
    .required(TextConstants.ERROR_TEXT.REQUIRED)
    .min(8, TextConstants.ERROR_TEXT.PASSWORD.VALIDATION_MIN)
    .max(64, TextConstants.ERROR_TEXT.PASSWORD.VALIDATION_MAX);
};

export const profilePasswordValidationSchema = Yup.object().shape({
  currentPassword: passwordValidation(),
  newPassword: passwordValidation(),
  newPasswordConfirm: passwordValidation().oneOf(
    [Yup.ref('newPassword'), null],
    TextConstants.ERROR_TEXT.PASSWORD.MATCH
  ),
});

export const emailValidation = () => {
  return Yup.string().email(TextConstants.ERROR_TEXT.EMAIL).required(TextConstants.ERROR_TEXT.EMAIL);
};

export const profileInformationValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, TextConstants.MODALS.SIGN_UP_MODAL.FIRST_NAME_MAX_CHARACTERS)
    .min(1),
  lastName: Yup.string()
    .max(50, TextConstants.MODALS.SIGN_UP_MODAL.LAST_NAME_MAX_CHARACTERS)
    .min(1),
  email: emailValidation(),
  phoneNumber: Yup.string(),
});

export const personalInfoValidationSchema = Yup.object().shape({
  dateOfBirth: Yup.date(),
  gender: Yup.string(),
});

const TEXT_FIELD_MIN_LENGTH = 2;
const TEXT_FIELD_MAX_LENGTH = 400;

export const TEXT_FIELD_VALIDATION_RULES = Yup
  .string()
  .required(TextConstants.ERROR_TEXT.REQUIRED)
  .min(TEXT_FIELD_MIN_LENGTH, TextConstants.ORDER_CONTACT_INFO.TEXT_LENGTH)
  .max(TEXT_FIELD_MAX_LENGTH, TextConstants.ORDER_CONTACT_INFO.TEXT_LENGTH);
