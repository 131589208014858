import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Text } from '@chakra-ui/react';
import { AppDispatch, RootState } from '../../../store/store';
import ProductsSettingsContainer from './ProductsSettingsContainer';
import ProductsSlider from './ProductsSlider';
import SubcategorySlider from './SubcategorySlider';
import {
  CustomizeMakeupProductType,
  CustomizeMakeupSubCategoryType,
} from '../../../global-components/GlobalTypes';
import SimpleProductsSlider from './SimpleProductSlider';
import {
  openAccordion,
  openMakeupAccordionSliderByWatsonFeature,
  setIsHeaderModalsShown,
} from '../../../store/reducers/commerceReducer';
import TextConstants from '../../../constants/TextConstants';
import HHIcon from '../../../components/common/HHIcon/HHIcon';
import useAddProductToBag from '../../../components/hooks/useAddProductToBag';
import {
  removeActiveProductVariant,
  removeSelectedProductVariant,
} from '../../../store/reducers/commerceReducer';
import { setIsShadeFinderCompleted } from '../../../store/reducers/commerceReducer';

declare const window;

const MultiStepContainer = ({
  subcategories,
  selectedCategory,
  handleTabChange,
}: {
  subcategories: CustomizeMakeupSubCategoryType[];
  handleTabChange: (text: 'Cheeks' | 'Lips' | 'Eyes' | 'Miscellaneous') => void;
  selectedCategory: 'Face' | 'Lips' | 'Eyes' | 'Miscellaneous';
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const makeupAccordionSliderByWatsonFeatureState = useSelector(
    (state: RootState) =>
      state.commerce.makeupAccordionSliderByWatsonFeatureState
  );
  const [currentStep, setCurrentStep] = useState(1);
  const [products, setProducts] = useState([]);
  const [productData, setProductData] = useState(null);
  const [shadeFinderRecommendationSKUs, setShadeFinderRecommendationSKUs] =
    useState(null);
  const [isAddItemButtonClicked, setIsAddItemButtonClicked] = useState(false);
  const activeProductVariant = useSelector(
    (state: RootState) => state.commerce.activeProductVariant
  );
  const categorizedProductsData = useSelector(
    (state: RootState) => state.commerce.categorizedProductsData
  );

  const myBagDataItems = useSelector(
    (state: RootState) => state.commerce.myBagDataItems
  );

  const addItemToBag = useAddProductToBag([activeProductVariant]);

  // Proceed to next step
  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const setProductsLevelData = (data) => {
    setProducts(data);
  };

  const setShadesLevelData = (data) => {
    setProductData(data);
  };
  // Go back to prev step
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    const product = JSON.parse(JSON.stringify(productData));
    const result = [];
    if (shadeFinderRecommendationSKUs && product?.productVariants) {
      shadeFinderRecommendationSKUs.forEach((SKU) =>
        product?.productVariants?.forEach((productVariantObj, index) => {
          // push to array only 5 objects
          if (
            productVariantObj?.sku === SKU &&
            result.length <
              TextConstants.COMMERCE.SHADEFINDER_RECOMMENDED_PRODUCTS_COUNT
          ) {
            // add found sku objects to array
            result.push(productVariantObj);
            // remove found sku objects from initial product data
            product.productVariants.splice(index, 1);
          }

          if (
            productVariantObj?.attributeValues?.forEach(
              (attributeValueObject) => {
                if (
                  attributeValueObject?.value === SKU &&
                  result.length <
                    TextConstants.COMMERCE
                      .SHADEFINDER_RECOMMENDED_PRODUCTS_COUNT
                ) {
                  // add found sku objects to array
                  result.push(productVariantObj);
                  // remove found sku objects from initial product data
                  product.productVariants.splice(index, 1);
                }
              }
            )
          ) {
            // add found sku objects to array
            result.push(productVariantObj);
            // remove found sku objects from initial product data
            product.productVariants.splice(index, 1);
          }
        })
      );
    }

    if (result && result.length) {
      // connect result and filtered product datas
      product.productVariants = [...result, ...product.productVariants];
      setProductData(product);
    }
  }, [shadeFinderRecommendationSKUs]);

  useEffect(() => {
    const handleCompletion = (e) => {
      const arrayOfSKUs = e.recommendations.map((obj) => obj.matched.skuId);
      dispatch(setIsShadeFinderCompleted(true));
      setShadeFinderRecommendationSKUs(arrayOfSKUs);
    };
    const handleCancellation = () => {
      console.log('Error occured during shade finder initialization');
    };

    if (window.YMK) {
      window.YMK.addEventListener('shadeFinderComplete', handleCompletion);
      window.YMK.addEventListener('shadeFinderCancelled', handleCancellation);
    }

    return () => {
      if (window.YMK) {
        window.YMK.removeEventListener('shadeFinderComplete', handleCompletion);
        window.YMK.removeEventListener(
          'shadeFinderCancelled',
          handleCancellation
        );
      }
    };
  }, []);

  const openSubcategoryByWatsonFeature = useCallback(
    (
      categoryFeature: string,
      subcategoryFeature: string
    ): CustomizeMakeupProductType[] => {
      let result;
      let categoryVariable = categoryFeature;

      // Whimsical and BE category names are different
      if (categoryVariable === 'Cheeks') {
        categoryVariable = 'Face';
      }
      if (categoryVariable === 'Eyes') {
        categoryVariable = 'Eye';
      }

      if (categorizedProductsData && categorizedProductsData.length) {
        categorizedProductsData.forEach((el) => {
          if (el.name === categoryVariable) {
            el.children.forEach((child) => {
              if (child && child.name === subcategoryFeature) {
                result = child.children;
                return;
              }
            });
          }
        });
      }

      return result;
    },
    [categorizedProductsData]
  );

  const openShadesByWatsonFeature = useCallback(
    (productsData: CustomizeMakeupProductType[], selectedProduct: string) => {
      let result;

      if (productsData && productsData.length) {
        productsData.forEach((el) => {
          if (el.name === selectedProduct) {
            result = el;
            return;
          }
        });
      }

      return result;
    },
    []
  );

  useEffect(() => {
    // change tab by Watson feature
    if (makeupAccordionSliderByWatsonFeatureState.category) {
      // change tab
      handleTabChange(makeupAccordionSliderByWatsonFeatureState.category);
      if (
        !makeupAccordionSliderByWatsonFeatureState.subcategory &&
        !makeupAccordionSliderByWatsonFeatureState.selectedProduct
      ) {
        // this state change means state setting completion (for reseting makeupAccordionSliderByWatsonFeatureState)
        setCurrentStep(1);
      }

      if (makeupAccordionSliderByWatsonFeatureState.subcategory) {
        const productsData = openSubcategoryByWatsonFeature(
          makeupAccordionSliderByWatsonFeatureState.category,
          makeupAccordionSliderByWatsonFeatureState.subcategory
        );

        // set products level data
        setProductsLevelData(productsData);

        if (!makeupAccordionSliderByWatsonFeatureState.selectedProduct) {
          // open products slider
          setCurrentStep(2);
        }

        if (makeupAccordionSliderByWatsonFeatureState.selectedProduct) {
          const shadesData = openShadesByWatsonFeature(
            productsData,
            makeupAccordionSliderByWatsonFeatureState.selectedProduct
          );

          // set shades level data
          setShadesLevelData(shadesData);

          // open shades slider
          setCurrentStep(3);
        }
      }
      // in the end auto-open customize makeup accordion
      dispatch(openAccordion(true));
    }
  }, [
    makeupAccordionSliderByWatsonFeatureState,
    openMakeupAccordionSliderByWatsonFeature,
    dispatch,
  ]);

  useEffect(() => {
    // reset state
    dispatch(
      openMakeupAccordionSliderByWatsonFeature({
        category: null,
        subcategory: null,
        selectedProduct: null,
      })
    );
  }, [currentStep, openMakeupAccordionSliderByWatsonFeature, dispatch]);

  useEffect(() => {
    if (isAddItemButtonClicked) {
      const timeout = setTimeout(() => {
        setIsAddItemButtonClicked(false);
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
    return null;
  }, [isAddItemButtonClicked]);

  const renderAddItemButton = () => (
    <Box textAlign="end">
      {!isAddItemButtonClicked ? (
        <Button
          width="100%"
          variant="no-radius"
          onClick={() => {
            if (activeProductVariant) {
              addItemToBag();
              setIsAddItemButtonClicked(true);              
              dispatch(removeActiveProductVariant());
              dispatch(
                removeSelectedProductVariant(
                  activeProductVariant?.productVariant?.sku
                )
              );
              if (myBagDataItems?.length === 0) {
                dispatch(setIsHeaderModalsShown({ myBagDrawer: true }));
              }
            }
          }}
          flex="auto"
          height="3.5vh"
          gap="10px"
          disabled={!activeProductVariant}
          bg="var(--chakra-colors-brand-600)"
          _hover={{
            _disabled: {
              bg: 'brand.500',
              color: 'white',
            },
          }}
        >
          <HHIcon icon="shopping-bag" />
          <Text
            textStyle="button-label"
            fontSize="14px"
            letterSpacing="0.15em"
            textTransform="uppercase"
          >
            {TextConstants.ACCORDION.CUSTOMIZE_MAKEUP.ADD_ITEM}
          </Text>
        </Button>
      ) : (
        <Button
          width="100%"
          variant="no-radius"
          bg="brand.700"
          flex="auto"
          height="3.5vh"
          gap="10px"
        >
          <HHIcon icon="shopping-bag" />
          <Text
            textStyle="button-label"
            fontSize="14px"
            letterSpacing="0.15em"
            textTransform="uppercase"
          >
            {TextConstants.ACCORDION.CUSTOMIZE_MAKEUP.ITEM_ADDED}
          </Text>
        </Button>
      )}
    </Box>
  );

  const productIsMulticolorPerSkuAndHavePatterns = (productData) => {
    if (
      productData?.isMulticolorPerSku ||
      (productData?.productVariants?.length === 1 &&
        productData?.patternsData?.patterns?.length)
    ) {
      return true;
    }
    return false;
  };

  switch (currentStep) {
    case 1:
      return (
        <SubcategorySlider
          setProductsLevelData={setProductsLevelData}
          subcategories={subcategories}
          nextStep={nextStep}
          selectedCategory={selectedCategory}
        />
      );
    case 2:
      dispatch(removeActiveProductVariant());
      return (
        <ProductsSlider
          products={products}
          nextStep={nextStep}
          prevStep={prevStep}
          setShadesLevelData={setShadesLevelData}
        />
      );
    case 3:
      return (
        <>
          {productIsMulticolorPerSkuAndHavePatterns(productData) ? (
            <ProductsSettingsContainer
              selectedProductData={productData}
              prevStep={prevStep}
            />
          ) : (
            productData?.productVariants?.length && (
              <SimpleProductsSlider
                selectedProductData={productData}
                prevStep={prevStep}
              />
            )
          )}
          {renderAddItemButton()}
        </>
      );

    default:
      return null;
  }
};

export default MultiStepContainer;
