import Speech from 'speak-tts';

const speech = new Speech();

(async () => {
    if (!speech.hasBrowserSupport()) {
        return;
    }

    const initData = await speech.init({
        lang: 'en-US',
        rate: 1,
        pitch: 1.15,
        splitSentences: true,
    });

    const voices = initData.voices;
    const preferredVoice = voices.find((voice) => (
        // for iOS - search for specific voices
        /Samantha|Karen/i.test(voice.name) ||
        // for other platforms - search for any english female voice
        (
            (voice.lang === 'en-US' || voice.lang === 'en-GB')
            && /female/i.test(voice.name)
            && !/whisper/i.test(voice.name)
        )
    ));

    if (preferredVoice) {
        speech.setVoice(preferredVoice.name);
    }
})();

export async function speakText(text: string, listeners?: any) {
    if (!speech.hasBrowserSupport()) {
        return null;
    }

    // on iOS, onend even handler might be skipped sometimes due to agressive garbage collection
    // so we need to fake this even and trigger it manually
    if (listeners && listeners.onend) {
        setTimeout(() => {
            const interval = setInterval(() => {
                if (!speech.speaking()) {
                    listeners.onend(null, true);

                    clearInterval(interval);
                }
            }, 100);
        }, 200);
    }

    return speech.speak({
        text,
        queue: true,
        listeners: listeners || {},
    });
}

export async function pauseTalking() {
    return speech.pause();
}

export async function resumeTalking() {
    return speech.resume();
}

export async function stopTalking() {
    return speech.cancel();
}

export async function requestSpeechAudioAccess() {
    // Say a short "phrase".
    // It's required on iOS for the audio to work and should be called by a user interaction (e.g. a button click).
    return speakText('m');
}
