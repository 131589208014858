import {
  ClassificationScoreType,
  EmotionalScoreType,
} from '../../global-components/GlobalTypes';

const scoresDataArr = (
  emotionalScore: EmotionalScoreType,
  classificationScore: ClassificationScoreType
): number[] => {
  if (emotionalScore && classificationScore) {
    return [
      emotionalScore.joy,
      emotionalScore.fear,
      emotionalScore.anger,
      classificationScore.frustrated,
      classificationScore.sad,
      classificationScore.excited,
      classificationScore.satisfied,
    ];
  }
  if (emotionalScore) {
    return [emotionalScore.joy, emotionalScore.fear, emotionalScore.anger];
  }
  if (classificationScore) {
    return [
      classificationScore.frustrated,
      classificationScore.sad,
      classificationScore.excited,
      classificationScore.satisfied,
    ];
  }
  return [];
};

export default scoresDataArr;
