import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import App from './App';
import store from './store/store';
import GlobalStyle from './globalStyle';
import theme from './theme-overrides';
import { SpeechRecognitionProvider } from './components/common/Mic/speechRecognitionContext';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <SpeechRecognitionProvider>
      <App theme={theme} />
      </SpeechRecognitionProvider>
    </Provider>
    <GlobalStyle />
    {/* globally enable react tooltips */}
    <ReactTooltip />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
