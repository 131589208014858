import Toolbar from './editor/Toolbar';
import { IconButtonTypeEnum } from '../../../constants/Notes';
import IconButton from './editor/IconButton';

type NoteHeaderProps = {
  isEdit: boolean;
};

const NoteHeader = ({ isEdit }: NoteHeaderProps): JSX.Element => (
  <div className="d-flex justify-content-between note-header-container">
    {isEdit ? (
      <Toolbar />
    ) : (
      <>
        <div>
          <p className="note-header">Rare Beauty</p>
        </div>
        <div>
          <IconButton
            className="note-delete-button"
            isDisableFocusOnClick={false}
            buttonIconType={IconButtonTypeEnum.DELETE}
            onClick={() => {
              // do nothing
            }}
          />
        </div>
      </>
    )}
  </div>
);

export default NoteHeader;
