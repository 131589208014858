const regexp = {
  name: /^[A-Za-z ]*$/,
  firstName: /^[A-Z][a-z-]+$/,
  lastName: /^[A-Z][a-z-]+$/,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  mobile: /^\(([0,2-9][0-9]{2})\)([ ])([0,2-9][0-9]{2})([-])([0-9]{4})$/,
  isNumber: /[^0-9]/g,
  productPatternName: /^(\d).*$/,
  address: /[A-Za-z0-9'\.\-\s\,]/,
};

export default regexp;
