export const parsedAddCartRequest = (bagItems): any => {
    const parsedCartReq = bagItems.map(_bagItem => {
        return {
            "qty_cart": _bagItem.quantity,
            "product_id": _bagItem.productId,
            "price": _bagItem.productVariant.price,
            "product_variant_id": _bagItem.productVariant.id
        }
      })
      return parsedCartReq;
  };


  export const parsedRemoveCartRequest = (bagItem): any => {
        return {
            "product_id": bagItem.productId,
            "product_variant_id": bagItem.productVariant.id
        }    
  };