import { createSlice } from '@reduxjs/toolkit';
import { FormattedRecordDataType } from '../../global-components/GlobalTypes';
import cookies from '../../helpers/addCookies';

const initialState: {
  entryData: FormattedRecordDataType[];
  isJournalOpenedWhileDPEnabled: boolean;
} = {
  entryData: cookies.get('entryData') || [],
  isJournalOpenedWhileDPEnabled: false,
};

export const JournalReducer = createSlice({
  name: 'journalReducer',
  initialState,
  reducers: {
    setEntryDataRedux: (state, { payload }) => {
      const timestamp = new Date().getTime();
      const minutes = 1000 * 60 * 60 * 24;
      const expire = timestamp + minutes;
      const expireDate = new Date(expire);
      cookies.set('entryData', payload, {
        path: '/',
        expires: expireDate,
      });
      return {
        ...state,
        entryData: payload,
      };
    },
    removeGuestEntryData: (state) => {
      cookies.remove('entryData', { path: '/' });
      return {
        ...state,
        entryData: [],
      };
    },
    setJournalOpenedWhileDPEnabled: (state, { payload }) => ({
      ...state,
      isJournalOpenedWhileDPEnabled: payload,
    }),
  },
});

export const {
  setEntryDataRedux,
  removeGuestEntryData,
  setJournalOpenedWhileDPEnabled,
} = JournalReducer.actions;

export default JournalReducer.reducer;
