import { useRef, useState } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  mute,
  sendTextMessage,
  stopSpeaking,
} from '../../../store/reducers/digitalAssistantReducer';
import { RootState } from '../../../store/store';
import TextConstants from '../../../constants/TextConstants';
import VideoHOCWithLogoutTimer from '../../HOC/VideoHOCWithLogoutTimer';

const TherapyRecommendationResult = ({
  data,
  isCardInTranscript,
}: {
  data: {
    result: {
      question: string;
      answer: string;
    }[];
    therapyApproaches: {
      id: number;
      name: string;
      video: {
        staticStorageUrl: string;
        contentUrl: string;
        originalName: string;
        id: number;
      };
    }[];
  };
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );
  const videoRef = useRef(null);
  const isMuted = useSelector((state: RootState) => state.digitalAssistant.isMuted);
  const isMutedBeforePlayingVideo = useRef(isMuted);

  const handleClick = (therapy: string) => {
    dispatch(stopSpeaking());
    dispatch(sendTextMessage({ text: therapy }));
    setIsButtonDisabled(true);
    dispatch(mute(isMutedBeforePlayingVideo.current));
    videoRef.current.pause();
  };

  const onPlay = () => {
    // mute persona
    dispatch(stopSpeaking());
    dispatch(mute(true));
  };

  const onPause = () => {
    dispatch(mute(isMutedBeforePlayingVideo.current));
  };

  const renderTherapyData = () =>
    data.therapyApproaches.map(({ id, name, video }) => (
      <Box key={JSON.stringify({ id, name, video })}>
        <Button
          variant="secondary"
          p="20px"
          m="5px"
          borderRadius="0.5rem"
          onClick={() => {
            handleClick(name);
          }}
          disabled={isButtonDisabled || isCardInTranscript}
        >
          {name}
        </Button>
        <Box my="20px">
          <VideoHOCWithLogoutTimer
            className="therapy-recommendation-result-video"
            src={video.staticStorageUrl}
            poster={`${process.env.PUBLIC_URL}/images/wellness-video-poster.jpg`}
            onPlay={onPlay}
            onPause={onPause}
            preload="auto"
            controls
            ref={videoRef}
          />
        </Box>
      </Box>
    ));

  const renderOptionalButton = () => (
    <Button
      variant="secondary"
      p="20px"
      m="5px"
      borderRadius="0.5rem"
      onClick={() => {
        handleClick('Do something else');
      }}
      disabled={isButtonDisabled || isCardInTranscript}
    >
      {
        TextConstants.WELLNESS_THERAPY
          .THERAPY_RECOMMENDATION_RESULT_OPTIONAL_BUTTON
      }
    </Button>
  );

  return (
    <Box
      d="flex"
      flexDirection="column"
      maxH="65vh"
      overflowY="scroll"
      data-sm-content="recommendationQuiz"
      alignItems={isTranscriptOpen ? 'start' : 'center'}
      p={isTranscriptOpen ? '10px 0 10px 35px' : '0'}
      paddingBottom={isTranscriptOpen ? 0 : '5vh'}
      marginRight={!isTranscriptOpen && { base: '0', sm: '40px' }}
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      <Box w="280px">
        <Text className="transcript-entry-content">
          {TextConstants.WELLNESS_THERAPY.THERAPY_RECOMMENDATION_RESULT_TITLE}
        </Text>
        <Box p="10px">
          {renderTherapyData()}
          {renderOptionalButton()}
        </Box>
      </Box>
    </Box>
  );
};

export default TherapyRecommendationResult;
