import { Box } from '@chakra-ui/react';
import s from './HHIcon.module.scss';
import { SVG } from '../../icons/icons';

const HHIcon = ({ icon }: { icon: string }): JSX.Element => (
  <Box className={s.HHIcon}>
    <SVG id={icon} />
  </Box>
);

export default HHIcon;
