import { createSlice } from '@reduxjs/toolkit';
import { PanoramicVideoModalState } from '../../global-components/GlobalTypes';

const initialState: PanoramicVideoModalState = null;

export const PanoramicVideoReducer = createSlice({
  name: 'panoramicVideoReducer',
  initialState,
  reducers: {
    openWellnessPanoramicVideoModal: (
      state,
      {
        payload,
      }: {
        payload: PanoramicVideoModalState;
      }
    ) => payload,
  },
});

export const { openWellnessPanoramicVideoModal } =
  PanoramicVideoReducer.actions;

export default PanoramicVideoReducer.reducer;
