import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Image, Text, Tooltip, useMediaQuery } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import styled from 'styled-components';
import leftArrowIcon from '../../../img/arrow-left-icon.svg';
import rightArrowIcon from '../../../img/arrow-right-icon.svg';
import { SVG } from '../../../components/icons/icons';
import { CustomizeMakeupVariantType } from '../../../global-components/GlobalTypes';
import logo from '../../../assets/icons/rare-beauty.png';
import removeActiveClassHelper from '../../../helpers/products/removeActiveClassHelper';
import faceFilterIcon from '../../../img/face-filter-icon.png';
import checkSliderArrowDisabledOrNo from '../../../helpers/checkSliderArrowDisabledOrNo';
import {
  removeActiveProductVariant,
  removeSelectedProductVariant,
} from '../../../store/reducers/commerceReducer';
import { AppDispatch } from '../../../store/store';
import TextConstants from '../../../constants/TextConstants';
import { RootState } from '../../../store/store';
import { setIsShadeFinderCompleted } from '../../../store/reducers/commerceReducer';

declare const window;

const SliderContainer = styled.div`
  padding: 0px 25px 0px 25px;
  display: flex;
  margin-top: 10px;
  .slick-slide {
    padding-left: 0px;
    padding-bottom: 0px;
    div {
      display: flex;
      justify-content: center;
    }
  }
  .slick-list {
    // fixes active border on the top
    padding-top: 4px;
  }
  .slick-dots {
    display: none !important;
  }

  .isActive {
    border: 1px solid #fdf7f952;
    box-shadow: 0px 0px 3px 4px rgb(127 37 73);
  }
`;

const SwatchButton = styled.button`
  position: relative;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 33px !important;
  height: 33px !important;
  margin-top: -7px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: ${({ isShadeFinderCompleted, shadeItemIndex }) =>
    isShadeFinderCompleted && shadeItemIndex < 5
      ? '0px 0px 2px 2px rgb(127 37 73)'
      : 'unset'};

  border: ${({ isShadeFinderCompleted, shadeItemIndex }) =>
    isShadeFinderCompleted && shadeItemIndex < 5
      ? '1px solid #fdf7f952'
      : 'unset'};
  outline: none;
`;

function LeftArrow(props) {
  const { onClick, className } = props;
  return (
    <Image
      onClick={onClick}
      src={leftArrowIcon}
      style={{
        display: checkSliderArrowDisabledOrNo(className),
        left: '-25px',
        position: 'absolute',
        top: '30%',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
      }}
    />
  );
}

function RightArrow(props) {
  const { onClick, className } = props;
  return (
    <Image
      onClick={onClick}
      src={rightArrowIcon}
      style={{
        display: checkSliderArrowDisabledOrNo(className),
        right: '-25px',
        position: 'absolute',
        top: '30%',
        transform: 'translate(0, -50%)',
        cursor: 'pointer',
      }}
    />
  );
}

const ProductShadeVariantsSlider = ({
  shadeProductVariants,
  handleClickShadeProductVariant,
  isShadeFinderAvailable,
  setShadeVariantIndexOnItemClick,
}: {
  isShadeFinderAvailable: boolean;
  shadeProductVariants: CustomizeMakeupVariantType[];
  handleClickShadeProductVariant: (data: CustomizeMakeupVariantType) => void;
  setShadeVariantIndexOnItemClick: (index: number) => void;
}): JSX.Element => {
  const maxCount = shadeProductVariants && shadeProductVariants.length + 1;
  const isAccordionOpen = useSelector(
    (state: RootState) => state.commerce.isAccordionOpen
  );

  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );
  const [selectedShadeVariantSku, setSelectedShadeVariantSku] = useState('');
  const [isShadeFinderTooltipOpened, setIsShadeFinderTooltipOpened] =
    useState(false);
  const [isSmallerThanIPadProSize] = useMediaQuery(
    `(max-width: ${TextConstants.SIZES.IPAD_PRO_WIDTH}px)`
  );
  const [swiped, setSwiped] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const isActiveRef = useRef(false);

  const { isShadeFinderCompleted, makeupAccordionSliderByWatsonFeatureState } =
    useSelector((state: RootState) => state.commerce);

  const handleSwiped = useCallback(() => {
    setSwiped(true);
  }, [setSwiped]);

  const clickResetButton = () => {
    if (shadeProductVariants?.length && selectedShadeVariantSku) {
      window.YMK.reset(shadeProductVariants[0].sku);

      dispatch(removeSelectedProductVariant(selectedShadeVariantSku));
    }

    // remove active product variant
    dispatch(removeActiveProductVariant());

    // remove active product variant border
    const removingElements = document.querySelectorAll(`[data-shade-slider]`);
    removeActiveClassHelper(removingElements);

    // reset selected shade sku/name
    setSelectedShadeVariantSku('');
  };

  const handleShadeFinderTooltip = () => {
    if (isShadeFinderTooltipOpened) {
      if (isSmallerThanIPadProSize && isTranscriptOpen) {
        // fixes tooltip opens when fullscreen transcript is open (small devices)
        return false;
      } else {
        return true;
      }
    }
  };

  const handleOnItemClick = (event, productVariant, index) => {
    if (swiped) {
      // prevent clicking function when sliding
      event.stopPropagation();
      event.preventDefault();
      setSwiped(false);
    } else {
      setShadeVariantIndexOnItemClick(index);

      // change active-border for newely selected product
      const removingElements = document.querySelectorAll(`[data-shade-slider]`);
      removeActiveClassHelper(removingElements);
      const element = document.querySelector(
        `[data-shade-slider=index${index}]`
      );
      element?.classList.add('isActive');

      // select shade
      handleClickShadeProductVariant(productVariant);

      // save newely selected shade's sku/name
      setSelectedShadeVariantSku(productVariant.sku);
    }
  };

  useEffect(
    () => {
      // everytime reset selected shade index, to reset isActive border when opening new product
      setShadeVariantIndexOnItemClick(0);
    },
    // run only when openProductinCustomizeMakeup function is executed
    [makeupAccordionSliderByWatsonFeatureState?.selectedProduct]
  );

  useEffect(() => {
    // these functions are run in first mount

    // add active product border for first shade
    const allDataShadeSliders =
      document.querySelectorAll(`[data-shade-slider]`);
    removeActiveClassHelper(allDataShadeSliders);
    allDataShadeSliders[0]?.classList.add('isActive');

    // select first shade by default
    handleClickShadeProductVariant(shadeProductVariants[0]);

    // select first shade-sku by default
    setSelectedShadeVariantSku(shadeProductVariants[0].sku);

    // add dependency to update selected shade after Shadefinder is completed
  }, [shadeProductVariants]);

  useEffect(() => {
    dispatch(setIsShadeFinderCompleted(false));

    // delay shadefinder tooltip opening, fixes when opened through openMakeupAccordionSliderByWatsonFeature()
    const delay = setTimeout(() => {
      setIsShadeFinderTooltipOpened(true);
    }, 500);

    return () => clearTimeout(delay);
  }, []);

  useEffect(() => {
    //hide shadefinder tooltip onclicking outside element

    // fixes initially clicked
    const timeout = setTimeout(() => {
      isActiveRef.current = true;
    });

    // fixes can't listen PerfectCorp module click
    const iframe = document.getElementById(
      'YMK-module-iframe'
    ) as HTMLIFrameElement;
    const iframeWindow = iframe?.contentWindow;

    const handleClick = () => {
      if (isActiveRef.current) {
        setIsShadeFinderTooltipOpened(false);
      }
    };

    document.addEventListener('click', handleClick);
    document.addEventListener('touchstart', handleClick);

    iframeWindow?.addEventListener('click', handleClick);
    iframeWindow?.addEventListener('touchstart', handleClick);

    return () => {
      clearTimeout(timeout);
      document.removeEventListener('touchstart', handleClick);
      document.removeEventListener('click', handleClick);
      iframeWindow?.removeEventListener('click', handleClick);
      iframeWindow?.removeEventListener('touchstart', handleClick);
    };
  }, []);

  // show product variant name after some delay
  const [renderProductVariantName, setRenderProductVariantName] =
    useState(false);

  useEffect(() => {
    let timeout;
    if (isAccordionOpen) {
      timeout = setTimeout(() => {
        setRenderProductVariantName(true);
      }, 400);
    } else {
      if (timeout) {
        clearTimeout(timeout);
      }
      setRenderProductVariantName(false);
    }
  }, [isAccordionOpen]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 2000,
    slidesToShow: maxCount ? Math.min(maxCount, 7) : 0,
    slidesToScroll: maxCount ? Math.min(maxCount, 7) : 0,
    initialSlide: 0,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    onSwipe: handleSwiped,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: maxCount ? Math.min(maxCount, 6) : 0,
          slidesToScroll: maxCount ? Math.min(maxCount, 6) : 0,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: maxCount ? Math.min(maxCount, 4) : 0,
          slidesToScroll: maxCount ? Math.min(maxCount, 4) : 0,
        },
      },
    ],
  };

  return (
    shadeProductVariants?.length && (
      <SliderContainer>
        <Slider {...settings}>
          <Tooltip
            placement="top"
            hasArrow
            label={TextConstants.TOOLTIPS.RESET_SHADE_BUTTON}
            aria-label={TextConstants.TOOLTIPS.RESET_SHADE_BUTTON}
            bg="var(--chakra-colors-brand-600)"
            fontFamily="inter"
            fontStyle="normal"
            fontWeight="400"
            fontSize={{ base: '12px', md: '14px' }}
          >
            <Box
              ml="10px"
              mt="-5px"
              cursor="pointer"
              onClick={clickResetButton}
            >
              <SVG id="reset-color" />
            </Box>
          </Tooltip>

          {isShadeFinderAvailable ? (
            <Box
              width="30px"
              mt={{ base: '-9px', md: '-9px' }}
              cursor="pointer"
              outline="none"
              //  open/close on hover
              onMouseEnter={() => setIsShadeFinderTooltipOpened(true)}
              onMouseLeave={() => setIsShadeFinderTooltipOpened(false)}
            >
              <Tooltip
                isOpen={handleShadeFinderTooltip()}
                placement="top"
                hasArrow
                label={TextConstants.TOOLTIPS.SHADE_BUTTON}
                aria-label={TextConstants.TOOLTIPS.SHADE_BUTTON}
                bg="var(--chakra-colors-brand-600)"
                fontFamily="inter"
                fontStyle="normal"
                fontWeight="400"
                fontSize={{ base: '12px', md: '14px' }}
              >
                <Image width="50px" src={faceFilterIcon} />
              </Tooltip>
            </Box>
          ) : null}
          {shadeProductVariants?.length &&
            shadeProductVariants.map((productVariant, shadeItemIndex) => (
              <Box key={JSON.stringify(productVariant)}>
                <Box>
                  <SwatchButton
                    key={productVariant?.id}
                    className="swatch-icon"
                    data-shade-slider={'index' + shadeItemIndex}
                    type="button"
                    isShadeFinderCompleted={isShadeFinderCompleted}
                    shadeItemIndex={shadeItemIndex}
                    onClickCapture={(event) =>
                      handleOnItemClick(event, productVariant, shadeItemIndex)
                    }
                  >
                    <Image
                      src={productVariant?.swatchImage?.staticStorageUrl || logo}
                      alt={productVariant?.swatchImage?.originalName}
                      maxHeight="100%"
                      borderRadius="50%"
                    />
                  </SwatchButton>
                </Box>

                <Text
                  w={
                    productVariant?.optionValues[0]?.value?.length > 7
                      ? 'fit-content'
                      : 'auto'
                  }
                  h="100%"
                  mt="5px"
                  mb="10px"
                  textAlign="center"
                  alignSelf="center"
                  fontFamily="inter"
                  fontStyle="normal"
                  fontWeight={
                    isShadeFinderCompleted && shadeItemIndex < 5 ? '650' : '400'
                  }
                  fontSize={
                    productVariant?.optionValues[0]?.value?.length > 6
                      ? '9px'
                      : '10px'
                  }
                  textTransform="uppercase"
                >
                  {renderProductVariantName &&
                    productVariant?.optionValues[0]?.value}
                </Text>
              </Box>
            ))}
        </Slider>
      </SliderContainer>
    )
  );
};

export default ProductShadeVariantsSlider;
