import { useEffect, useRef, useState } from 'react';
import TextConstants from '../constants/TextConstants';

function useSaveCuratedRandomLooks() {
  const [latestLook, setLook] = useState(null);
  const allLooks = useRef([]);

  useEffect(() => {
    // saved looks quantity
    if (allLooks.current.length < TextConstants.COMMERCE.SAVED_LOOKS_QUANTITY) {
      const result = allLooks.current;

      if (latestLook) {
        result.push(latestLook);
      }

      allLooks.current = result;
    } else {
      const result = allLooks.current;

      result.shift();
      result.push(latestLook);

      allLooks.current = result;
    }
  }, [latestLook]);

  return [latestLook, allLooks.current, setLook] as const;
}

export default useSaveCuratedRandomLooks;
