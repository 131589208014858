import { useState } from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import TextConstants from '../../constants/TextConstants';
import regexp from '../../constants/Regexp';
import { resetPasswordRequest } from '../../global-components/RequestFactory';
import handleResponseErrorMessage from '../../helpers/handleResponseErrorMessage';
import { passwordValidation } from '../../helpers/validation';

const Container = styled.div``;

const ResetPasswordModal = (): JSX.Element => {
  const history = useHistory();
  const { token } = useParams();

  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(true);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [requestResponse, setRequestResponse] = useState({
    isAlertShown: false,
    alertText: '',
  });

  const placeholderStyle = {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  };

  const validationSchema = Yup.object().shape({
    newPassword: passwordValidation(),
    newPasswordConfirm: Yup.string()
      .required(TextConstants.ERROR_TEXT.REQUIRED)
      .oneOf(
        [Yup.ref('newPassword'), null],
        TextConstants.ERROR_TEXT.PASSWORD.MATCH
      ),
  });

  const hideAlertResponse = () => {
    setRequestResponse({ isAlertShown: false, alertText: '' });
  };
  const showAlertResponse = (alertMessage) => {
    setRequestResponse({ isAlertShown: true, alertText: alertMessage });
  };
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      newPasswordConfirm: '',
    },
    onSubmit: (values) => {
      setIsRequesting(true);
      resetPasswordRequest({
        passwordResetToken: token,
        newPassword: values.newPassword,
        newPasswordConfirm: values.newPasswordConfirm,
      })
        .then(() => {
          showAlertResponse(
            TextConstants.MODALS.RESET_PASSWORD_MODAL.RESET_PASSWORD_SUCCESS
          );
          setIsRequestSuccess(true);
          setTimeout(() => {
            hideAlertResponse();
            closeResetPasswordModal();
            setIsRequesting(false);
            history.push(TextConstants.PATHNAMES.MAIN_PAGE);
          }, TextConstants.ALERT_MESSAGE_TRASHHOLD);
          formik.resetForm();
        })
        .catch((err) => {
          if (err.violations && err.violations.length) {
            showAlertResponse(handleResponseErrorMessage(err.violations));
          } else {
            showAlertResponse(err.message);
          }
          setIsRequestSuccess(false);
          setIsRequesting(false);
        });
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const closeResetPasswordModal = () => {
    setIsResetPasswordModalOpen(false);
  };

  return (
    <Container>
      <Modal
        onClose={() => {
          // do nothing
        }}
        isOpen={isResetPasswordModalOpen}
        isCentered
        blockScrollOnMount
        preserveScrollBarGap
      >
        <ModalOverlay
          backdropFilter="auto"
          backdropInvert="10%"
          backdropBlur="0px"
        />
        <ModalContent borderRadius="0">
          <ModalHeader mt="15px" textAlign="center">
            {TextConstants.MODALS.RESET_PASSWORD_MODAL.TITLE}
          </ModalHeader>
          <Stack
            spacing={3}
            mt="10px"
            mb="40px"
            mx="45px"
            as="form"
            justifyContent="center"
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <FormControl isInvalid={formik.errors.newPassword !== undefined}>
              <FormLabel>
                {
                  TextConstants.PROFILE_MANAGMENT.PASSWORD_SECTION
                    .NEW_PASSWORD_TITLE
                }
              </FormLabel>
              <Input
                name="newPassword"
                type="password"
                borderRadius="none"
                onChange={formik.handleChange}
                value={formik.values.newPassword}
                placeholder={
                  TextConstants.PROFILE_MANAGMENT.PASSWORD_SECTION
                    .NEW_PASSWORD_PLACEHOLDER
                }
                _placeholder={placeholderStyle}
              />
              <FormErrorMessage>{formik.errors.newPassword}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={formik.errors.newPasswordConfirm !== undefined}
            >
              <FormLabel>
                {
                  TextConstants.PROFILE_MANAGMENT.PASSWORD_SECTION
                    .CONFIRM_PASSWORD_TITLE
                }
              </FormLabel>
              <Input
                name="newPasswordConfirm"
                type="password"
                borderRadius="none"
                onChange={formik.handleChange}
                value={formik.values.newPasswordConfirm}
                placeholder={
                  TextConstants.PROFILE_MANAGMENT.PASSWORD_SECTION
                    .CONFIRM_PASSWORD_PLACEHOLDER
                }
                _placeholder={placeholderStyle}
              />
              <FormErrorMessage>
                {formik.errors.newPasswordConfirm}
              </FormErrorMessage>
            </FormControl>
            <Stack spacing={7}>
              <Button my={3} type="submit" disabled={isRequesting}>
                {TextConstants.MODALS.RESET_PASSWORD_MODAL.BUTTON_TEXT}
              </Button>
            </Stack>

            {requestResponse.isAlertShown && (
              <Alert status={isRequestSuccess ? 'success' : 'error'}>
                <AlertIcon />
                {requestResponse.alertText}
              </Alert>
            )}
          </Stack>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default ResetPasswordModal;
