import { useLocation } from 'react-router-dom';
import { HStack, Box, Button, Badge } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import s from './UserNav.module.scss';
import { SVG } from '../../icons/icons';
import { RootState } from '../../../store/store';
import { logoutUser } from '../../../store/reducers/authenticationReducer';
import { removeProductsFromCart, setIsHeaderModalsShown } from '../../../store/reducers/commerceReducer';
import TextConstants from '../../../constants/TextConstants';
import { useIsUserLoggedIn } from '../../../helpers/userInfo/UserInfo';
import IntroMenuButton from '../common/IntroVideoButton';
import { hasRoles } from '../../../utils/RoleUtils';
import { Role } from '../../../constants/Role';
import { setSignInModal } from '../../../store/reducers/signInModalReducer';
import { HeaderAuthPromptActionType } from '../Header';

const Container = styled.div`
  .userNavBagBadge {
    min-height: 1.5rem;
    border-radius: 0.75rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 9px;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--chakra-fonts-third);
    color: var(--chakra-colors-gray-700);
    margin-left: 0.5rem;
    background-color: var(--chakra-colors-gray-100);

    &:before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      background: var(--chakra-colors-gray-500);
      flex: none;
      margin-right: 7px;
      border-radius: 50%;
    }
  }
`;

const UserNav = ({
  isAuthPromptOpened,
  totalProductsAmount,
  openExitToLobbyFunction,
  setAuthPromptAction,
}: {
  isAuthPromptOpened: boolean;
  totalProductsAmount: number;
  openExitToLobbyFunction: () => void;
  setAuthPromptAction: (action: HeaderAuthPromptActionType) => void;
}): JSX.Element => {
  const loading = useSelector((state: RootState) => state.digitalAssistant.loading);
  const connected = useSelector((state: RootState) => state.digitalAssistant.connected);
  const isLoggedIn = useIsUserLoggedIn();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  return (
    <Container>
      <Box as="nav">
        <HStack spacing={{ base: 4, lg: 8 }} as="ul">
          <Box
            as="li"
            d="flex"
            className={`${
              connected && !loading && pathname === '/video' ? '' : 'd-none'
            }`}
          >
            <Button
              aria-label="my-bag"
              variant="link"
              className={s.textLink}
              onClick={() => {
                openExitToLobbyFunction();
              }}
            >
              <SVG id="arrow-left" />
              <span className="link-text">Exit to Lobby</span>
            </Button>
          </Box>

          {isLoggedIn && hasRoles(Role.SUPER_ADMIN) ? (
            <Box as="li" d="flex">
              <Button
                aria-label="to-admin-panel"
                variant="link"
                className={s.textLink}
                onClick={() => location.assign(process.env.REACT_APP_ADMIN_URL)}
              >
                <SVG id="arrow-left-circle" />
                <span className="link-text">To Admin Panel</span>
              </Button>
            </Box>
          ) : null}

          <Box as="li" d="flex">
            <IntroMenuButton />
          </Box>

          <Box as="li" d="flex">
            <Button
              aria-label="journal"
              variant="link"
              className={s.textLink}
              onClick={() => {
                if (isAuthPromptOpened) {
                  dispatch(setIsHeaderModalsShown({ journalDrawer: true }));
                } else {
                  setAuthPromptAction('Journal');
                }
              }}
            >
              <SVG id="journal-icon" />
              <span className="link-text">Journal</span>
            </Button>
          </Box>

          <Box as="li" d="flex">
            <Button
              aria-label="my-bag"
              variant="link"
              className={s.textLink}
              onClick={() => {
                if (isAuthPromptOpened) {
                  dispatch(setIsHeaderModalsShown({ myBagDrawer: true }));
                } else {
                  setAuthPromptAction('MyBag');
                }
              }}
            >
              <SVG id="shopping-bag" />
              <span className="link-text">My Bag</span>
              {totalProductsAmount > 0 && (
                <Badge minWidth="50px" className="userNavBagBadge">
                  {totalProductsAmount}
                </Badge>
              )}
            </Button>
          </Box>

          {isLoggedIn ? (
            <Box display="flex" flexDirection="row" as="li">
              <Button
                aria-label="user-avatar"
                variant="link"
                className={s.textLink}
                cursor="pointer"
                onClick={() =>
                  dispatch(setIsHeaderModalsShown({ profileDrawer: true }))
                }
              >
                <SVG id="user-avatar" />
              </Button>

              <Button
                aria-label="Log out"
                variant="ghost"
                _hover={{ background: 'transparent' }}
                _active={{ background: 'transparent' }}
                paddingX="1"
                onClick={() => {
                  dispatch(removeProductsFromCart())
                  dispatch(logoutUser());
                }}
              >
                <SVG id="log-out" />
              </Button>
            </Box>
          ) : (
            <>
              <Button
                colorScheme="gray"
                variant="ghost"
                borderRadius="0"
                size="xl"
                fontWeight="500"
                fontFamily="third"
                onClick={() => dispatch(setSignInModal({ isOpen: true }))}
              >
                {TextConstants.BUTTON_TEXT.SIGN_IN}
              </Button>
              <Button
                colorScheme="brand"
                size="xl"
                borderRadius="0"
                fontWeight="500"
                fontFamily="third"
                onClick={() =>
                  dispatch(setIsHeaderModalsShown({ registerModal: true }))
                }
                _focus={{ boxShadow: 'none' }}
              >
                {TextConstants.BUTTON_TEXT.SIGN_UP}
              </Button>
            </>
          )}
        </HStack>
      </Box>
    </Container>
  );
};

export default UserNav;
