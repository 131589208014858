import { Box } from '@chakra-ui/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  FormattedRecordDataType,
  LineChartOptions,
} from '../../../global-components/GlobalTypes';
import findScoreColor from '../../../helpers/journal/findScoreColor';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({
  dataEntry,
  options,
}: {
  options: LineChartOptions;
  dataEntry: FormattedRecordDataType[];
}): JSX.Element => {
  const [lineChartData, setLineChartData] = useState(null);

  const formatChartData = (score) => {
    let lineData = [];
    for (let i = dataEntry.length - 1; i >= 0; i -= 1) {
      lineData.push({
        x: dataEntry[i].date,
        y: dataEntry[i].average[score],
      });
    }
    const previousDate = new Date(lineData[0].x);
    previousDate.setDate(previousDate.getDate() - 1);
    if (lineData.length === 1) {
      lineData = [
        { x: previousDate.toISOString().split('T')[0], y: 0 },
        ...lineData,
      ];
    }
    return lineData;
  };

  useEffect(() => {
    if (dataEntry.length) {
      setLineChartData({
        datasets: [
          {
            label: 'Joy',
            data: formatChartData('joy'),
            borderColor: findScoreColor('joy', 1),
            backgroundColor: findScoreColor('joy', 1),
          },
          {
            label: 'Fear',
            data: formatChartData('fear'),
            borderColor: findScoreColor('fear', 1),
            backgroundColor: findScoreColor('fear', 1),
          },
          {
            label: 'Anger',
            data: formatChartData('anger'),
            borderColor: findScoreColor('anger', 1),
            backgroundColor: findScoreColor('anger', 1),
          },
          {
            label: 'Frustrated',
            data: formatChartData('frustrated'),
            borderColor: findScoreColor('frustrated', 1),
            backgroundColor: findScoreColor('frustrated', 1),
          },
          {
            label: 'Sad',
            data: formatChartData('sad'),
            borderColor: findScoreColor('sad', 1),
            backgroundColor: findScoreColor('sad', 1),
          },
          {
            label: 'Excited',
            data: formatChartData('excited'),
            borderColor: findScoreColor('excited', 1),
            backgroundColor: findScoreColor('excited', 1),
          },
          {
            label: 'Satisfied',
            data: formatChartData('satisfied'),
            borderColor: findScoreColor('satisfied', 1),
            backgroundColor: findScoreColor('satisfied', 1),
          },
        ],
      });
    }
  }, [dataEntry]);

  return (
    lineChartData && (
      <Box height="85%" width="100%" p="5px">
        <Line options={options} data={lineChartData} />
      </Box>
    )
  );
};

export default LineChart;
