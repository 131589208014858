import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import TextConstants from '../../../constants/TextConstants';
import { AddressFormFields } from '../../../global-components/GlobalTypes';

interface Props {
  fields: AddressFormFields;
  fieldNamePrefix?: string;
  disabled?: boolean;
}

function AddressForm({ fields, fieldNamePrefix, disabled}: Props) {
  return <>
    <FormControl isInvalid={!!fields.state.error} pt="20px">
      <FormLabel>
        {TextConstants.ORDER_CONTACT_INFO.STATE_INPUT_LABEL}
      </FormLabel>
      <Input
        name={fieldNamePrefix ? `${fieldNamePrefix}.state` : 'state'}
        borderRadius="none"
        onChange={fields.state.onChange}
        value={fields.state.value}
        placeholder={TextConstants.ORDER_CONTACT_INFO.STATE_INPUT_PLACEHOLDER}
        disabled={disabled}
      />
      <FormErrorMessage>{fields.state.error}</FormErrorMessage>
    </FormControl>
    <FormControl isInvalid={!!fields.city.error} pt="20px">
      <FormLabel>
        {TextConstants.ORDER_CONTACT_INFO.CITY_INPUT_LABEL}
      </FormLabel>
      <Input
        name={fieldNamePrefix ? `${fieldNamePrefix}.city` : 'city'}
        borderRadius="none"
        onChange={fields.city.onChange}
        value={fields.city.value}
        placeholder={TextConstants.ORDER_CONTACT_INFO.CITY_INPUT_PLACEHOLDER}
        disabled={disabled}
      />
      <FormErrorMessage>{fields.city.error}</FormErrorMessage>
    </FormControl>
    <FormControl isInvalid={!!fields.zipcode.error} pt="20px">
      <FormLabel>
        {TextConstants.ORDER_CONTACT_INFO.ZIP_INPUT_LABEL}
      </FormLabel>
      <Input
        name={fieldNamePrefix ? `${fieldNamePrefix}.zipcode` : 'zipcode'}
        borderRadius="none"
        onChange={fields.zipcode.onChange}
        value={fields.zipcode.value}
        placeholder={TextConstants.ORDER_CONTACT_INFO.ZIP_INPUT_PLACEHOLDER}
        disabled={disabled}
      />
      <FormErrorMessage>{fields.zipcode.error}</FormErrorMessage>
    </FormControl>
    <FormControl isInvalid={!!fields.addressLine1.error} pt="20px">
      <FormLabel>
        {TextConstants.ORDER_CONTACT_INFO.ADDRESS1_INPUT_LABEL}
      </FormLabel>
      <Input
        name={fieldNamePrefix ? `${fieldNamePrefix}.addressLine1` : 'addressLine1'}
        borderRadius="none"
        onChange={fields.addressLine1.onChange}
        value={fields.addressLine1.value}
        placeholder={TextConstants.ORDER_CONTACT_INFO.ADDRESS_INPUT_PLACEHOLDER}
        disabled={disabled}
      />
      <FormErrorMessage>{fields.addressLine1.error}</FormErrorMessage>
    </FormControl>
    <FormControl isInvalid={!!fields.addressLine2.error} pt="20px">
      <FormLabel>
        {TextConstants.ORDER_CONTACT_INFO.ADDRESS2_INPUT_LABEL}
      </FormLabel>
      <Input
        name={fieldNamePrefix ? `${fieldNamePrefix}.addressLine2` : 'addressLine2'}
        borderRadius="none"
        onChange={fields.addressLine2.onChange}
        value={fields.addressLine2.value}
        placeholder={TextConstants.ORDER_CONTACT_INFO.ADDRESS_INPUT_PLACEHOLDER}
        disabled={disabled}
      />
      <FormErrorMessage>{fields.addressLine2.error}</FormErrorMessage>
    </FormControl>
  </>;
}

export default AddressForm;
