const HardcodedHeaderLinksData = [
  {
    id: 1,
    url: 'https://www.rarebeauty.com/pages/about',
    title: 'About us',
  },
  {
    id: 2,
    url: 'https://www.rarebeauty.com/pages/chat',
    title: 'Support',
  },
  {
    id: 3,
    url: '',
    title: 'Press',
  },
  {
    id: 4,
    url: 'https://www.rarebeauty.com/pages/contact',
    title: 'Contact us',
  },
  {
    id: 5,
    url: '',
    title: 'Careers',
  },
  {
    id: 6,
    url: '',
    title: 'Sitemap',
  },
  {
    id: 7,
    url: 'https://www.rarebeauty.com/policies/privacy-policy',
    title: 'Legal',
  },
  {
    id: 8,
    url: '',
    title: 'Cookie settings',
  },
];

export default HardcodedHeaderLinksData;
