import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  Alert,
  AlertIcon,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';
import ProfileSectionButtons from './ProfileSectionButtons';
import TextConstants from '../../../constants/TextConstants';
import { updateProfilePassword } from '../../../global-components/RequestFactory';
import { useGetUserInfo } from '../../../helpers/userInfo/UserInfo';
import { setUserData } from '../../../store/reducers/authenticationReducer';
import { UserDataType } from '../../../global-components/GlobalTypes';
import { profilePasswordValidationSchema } from '../../../helpers/validation';

const placeholderStyle = {
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
};

const ProfilePasswordUpdate = (): JSX.Element => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [requestError, setRequestError] = useState({
    isErrorShown: false,
    errorText: '',
  });
  const [isInitialValueChanged, setInitialValueChange] = useState(false);

  const userData = useGetUserInfo();

  const dispatch = useDispatch();

  const setUserDataInRedux = (result: UserDataType) => {
    dispatch(setUserData(result));
  };

  const hideResponseError = () => {
    setRequestError({ isErrorShown: false, errorText: '' });
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    onSubmit: (values) => {
      setIsRequesting(true);
      updateProfilePassword(
        {
          currentPassword: values.currentPassword.trim(),
          newPassword: values.newPassword.trim(),
          newPasswordConfirm: values.newPasswordConfirm.trim(),
        },
        userData,
        setUserDataInRedux
      )
        .then(() => {
          setIsRequesting(false);
          setIsRequestSuccess(true);
          setTimeout(
            () => setIsRequestSuccess(false),
            TextConstants.ALERT_MESSAGE_TRASHHOLD
          );
          hideResponseError();
          formik.resetForm();
        })
        .catch((error) => {
          setIsRequesting(false);
          setIsRequestSuccess(false);
          setRequestError({
            isErrorShown: true,
            errorText: error.violations
              ? error.violations[0].message
              : error.message,
          });
          setTimeout(
            () => hideResponseError(),
            TextConstants.ALERT_MESSAGE_TRASHHOLD
          );
        });
    },
    validationSchema: profilePasswordValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const { resetForm, setTouched } = formik;

  const handleResetForm = () => {
    resetForm();
    setTouched({}, false);
    setIsRequestSuccess(false);
    hideResponseError();
  };

  useEffect(() => {
    if (
      !formik.values.newPasswordConfirm &&
      !formik.values.currentPassword &&
      !formik.values.newPassword
    ) {
      resetForm();
      setInitialValueChange(false);
      setTouched({}, false);
      hideResponseError();
    } else {
      setInitialValueChange(true);
    }
  }, [formik.values, setTouched, resetForm]);

  const getCurrentPasswordErrorText = () => {
    if (formik.errors.currentPassword) {
      return formik.errors.currentPassword;
    }
    if (requestError.isErrorShown) {
      return requestError.errorText;
    }
    return null;
  };

  const renderInputs = () => (
    <Box pb="20px">
      <FormControl
        isInvalid={
          formik.errors.currentPassword !== undefined ||
          requestError.isErrorShown
        }
        pt="20px"
      >
        <FormLabel
          fontFamily="Inter"
          fontSize="14px"
          lineHeight="20px"
          color="gray.700"
        >
          {
            TextConstants.PROFILE_MANAGMENT.PASSWORD_SECTION
              .CURRENT_PASSWORD_TITLE
          }
        </FormLabel>
        <Input
          name="currentPassword"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.currentPassword}
          borderRadius="none"
          placeholder={
            TextConstants.PROFILE_MANAGMENT.PASSWORD_SECTION
              .CURRENT_PASSWORD_PLACEHOLDER
          }
          _placeholder={placeholderStyle}
        />
        <FormErrorMessage>{getCurrentPasswordErrorText()}</FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={formik.errors.newPassword !== undefined}
        mt="25px"
        borderTop="1px solid var(--chakra-colors-gray-200)"
        pt="20px"
      >
        <FormLabel
          fontFamily="Inter"
          fontSize="14px"
          lineHeight="20px"
          color="gray.700"
        >
          {TextConstants.PROFILE_MANAGMENT.PASSWORD_SECTION.NEW_PASSWORD_TITLE}
        </FormLabel>
        <Input
          name="newPassword"
          type="password"
          borderRadius="none"
          onChange={formik.handleChange}
          value={formik.values.newPassword}
          placeholder={
            TextConstants.PROFILE_MANAGMENT.PASSWORD_SECTION
              .NEW_PASSWORD_PLACEHOLDER
          }
          _placeholder={placeholderStyle}
        />
        <FormErrorMessage>{formik.errors.newPassword}</FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={formik.errors.newPasswordConfirm !== undefined}
        mt="25px"
        borderTop="1px solid var(--chakra-colors-gray-200)"
        pt="20px"
      >
        <FormLabel
          fontFamily="Inter"
          fontSize="14px"
          lineHeight="20px"
          color="gray.700"
        >
          {
            TextConstants.PROFILE_MANAGMENT.PASSWORD_SECTION
              .CONFIRM_PASSWORD_TITLE
          }
        </FormLabel>
        <Input
          name="newPasswordConfirm"
          type="password"
          borderRadius="none"
          onChange={formik.handleChange}
          value={formik.values.newPasswordConfirm}
          placeholder={
            TextConstants.PROFILE_MANAGMENT.PASSWORD_SECTION
              .CONFIRM_PASSWORD_PLACEHOLDER
          }
          _placeholder={placeholderStyle}
        />
        <FormErrorMessage>{formik.errors.newPasswordConfirm}</FormErrorMessage>
      </FormControl>
    </Box>
  );

  return (
    <Stack
      spacing={2}
      mt="10px"
      mb="20px"
      justifyContent="center"
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      {renderInputs()}
      {isInitialValueChanged && (
        <ProfileSectionButtons
          cancelButtonTitle={TextConstants.BUTTON_TEXT.CANCEL}
          saveButtonTitle={TextConstants.BUTTON_TEXT.UPDATE_PASSWORD}
          isDisabled={isRequesting}
          resetForm={handleResetForm}
        />
      )}

      {isRequestSuccess && (
        <Box pb="30px">
          <Alert
            status="success"
            backgroundColor="var(--chakra-colors-brand-500)"
            color="#fff"
          >
            <AlertIcon color="#fff" />
            {
              TextConstants.PROFILE_MANAGMENT.PASSWORD_SECTION
                .UPDATE_PASSWORD_SUCCESS
            }
          </Alert>
        </Box>
      )}
    </Stack>
  );
};

export default ProfilePasswordUpdate;
