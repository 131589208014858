import { Box, Button, Text, useMediaQuery } from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextConstants from '../../../constants/TextConstants';
import checkIfObjectNotEmpty from '../../../helpers/checkIfObjectNotEmpty';
import {
  sendTextMessage,
  stopSpeaking,
} from '../../../store/reducers/digitalAssistantReducer';
import { RootState } from '../../../store/store';

const WellnessOnscreenOtherOptions = ({
  data,
  isCardInTranscript,
}: {
  data: {
    wellnessOnscreenOtherOptions: {
      recommendedTherapies?: {
        title: string;
        options: {
          label: string;
          value: string;
        }[];
      };
      globalOptions: {
        title: string;
        options: {
          label: string;
          value: string;
        }[];
      };
    };
  };
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();
  const [isLargerThanIPadPro] = useMediaQuery(
    `(min-width: ${TextConstants.SIZES.IPAD_PRO_WIDTH}px)`
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );

  const handleClick = (param: string) => {
    dispatch(stopSpeaking());
    console.log("Speaking");
    console.log(param);
    dispatch(sendTextMessage({ text: param }));
    setIsButtonDisabled(true);
  };

  const renderRecommendedTherapiesOptions = () =>
    data.wellnessOnscreenOtherOptions.recommendedTherapies?.options?.map(
      ({ label, value }) => (
        <Button
          variant="secondary"
          p="20px"
          w="100%"
          m="0 auto"
          key={JSON.stringify({ label, value })}
          borderRadius="0.5rem"
          onClick={() => {
            handleClick(value);
          }}
          disabled={isButtonDisabled || isCardInTranscript}
        >
          {label}
        </Button>
      )
    );

  const renderGlobalOptions = () =>
    data.wellnessOnscreenOtherOptions.globalOptions.options.map((el) => {
      if (el) {
        return (
          <Button
            fontSize={{ base: 'small', sm: '1rem' }}
            variant="secondary"
            p="20px"
            w="100%"
            m="0 auto"
            key={JSON.stringify(el)}
            borderRadius="0.5rem"
            onClick={() => {
              handleClick(el.value);
            }}
            disabled={isButtonDisabled || isCardInTranscript}
          >
            {el.label}
          </Button>
        );
      }
      return null;
    });

  const renderRecommendedTherapiesSection = () => (
    <Box
      className="recommended-therapies-container"
      d="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      {data.wellnessOnscreenOtherOptions.recommendedTherapies?.title && (
        <Text
          backgroundColor="var(--chakra-colors-brand-50) !important"
          className="transcript-entry-content"
        >
          {data.wellnessOnscreenOtherOptions.recommendedTherapies?.title}
        </Text>
      )}
      <Box
        p="10px 10px 10px 0"
        d="flex"
        flexDirection="column"
        gap="20px"
        minWidth="250px"
        className="recommended-therapies-buttons-container"
      >
        {renderRecommendedTherapiesOptions()}
      </Box>
    </Box>
  );

  const renderGlobalOptionsSection = () => (
    <Box
      className="global-options-container"
      ml="8px"
      d="flex"
      justifyContent="center"
      flexDirection="column"
      maxW={{ base: '300px', sm: 'unset' }}
      alignItems={isTranscriptOpen ? 'start' : 'center'}
      marginRight={!isTranscriptOpen && { base: '0', sm: '40px' }}
    >
      {data.wellnessOnscreenOtherOptions.globalOptions?.title && (
        <Text
          backgroundColor="var(--chakra-colors-brand-50) !important"
          className="transcript-entry-content"
          mt="20px"
        >
          {data.wellnessOnscreenOtherOptions.globalOptions?.title}
        </Text>
      )}
      <Box
        d="flex"
        flexDirection="column"
        gap="20px"
        minWidth="250px"
        mt="10px"
        className="global-options-buttons-container"
      >
        {renderGlobalOptions()}
      </Box>
    </Box>
  );
  return (
    <Box
      d="flex"
      px="30px"
      flexDirection="column"
      marginBottom={isTranscriptOpen || isLargerThanIPadPro ? 'unset' : '5vh'}
      maxH="60vh"
      overflowY={isTranscriptOpen ? 'unset' : 'scroll'}
      data-sm-content="wellnessOnscreenOtherOptions"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      {checkIfObjectNotEmpty(
        data.wellnessOnscreenOtherOptions.recommendedTherapies
      ) && renderRecommendedTherapiesSection()}

      {checkIfObjectNotEmpty(data.wellnessOnscreenOtherOptions.globalOptions) &&
        renderGlobalOptionsSection()}
    </Box>
  );
};

export default WellnessOnscreenOtherOptions;
