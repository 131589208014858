import { Button } from '@chakra-ui/react';
import StyleWrapper from './StyleWrapper';
import TextConstants from '../../../constants/TextConstants';
import { useDispatch } from 'react-redux';
import { sendTextMessage } from '../../../store/reducers/digitalAssistantReducer';

declare const window;

const SaveLookOption = ({
  isCardInTranscript,
}: {
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();

  const handleClick = () => {
    window.YMK?.snapshot('base64', (value) => {
      const a = document.createElement('a');
      a.href = value;
      a.download = 'Image.png';
      a.click();
    });
  };

  return (
    <StyleWrapper>
      <>
        <Button
          fontSize={{ base: 'small', sm: '1rem' }}
          variant="secondary"
          p="20px"
          w="100%"
          m="0 auto"
          borderRadius="0.5rem"
          _focus={{ boxShadow: 'none' }}
          onClick={handleClick}
          disabled={isCardInTranscript}
        >
          {TextConstants.COMMERCE.SAVE_LOOK}
        </Button>
        <Button
          fontSize={{ base: 'small', sm: '1rem' }}
          variant="secondary"
          p="20px"
          w="100%"
          m="0 auto"
          borderRadius="0.5rem"
          _focus={{ boxShadow: 'none' }}
          onClick={() => {
            dispatch(
              sendTextMessage({ text: TextConstants.BUTTON_TEXT.MOVE_ON })
            );
          }}
          disabled={isCardInTranscript}
        >
          {TextConstants.BUTTON_TEXT.MOVE_ON}
        </Button>
      </>
    </StyleWrapper>
  );
};

export default SaveLookOption;
