import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FormikProps } from 'formik';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';
import { FormikSignUpValues } from './SignupStepsModal';
import TextConstants from '../../../../constants/TextConstants';

const Container = styled.div`
  .form-control {
    width: 100%;
    height: 44px;

    &:focus {
      border-color: var(--chakra-colors-brand-300);
      box-shadow: 0px 1px 2px rgb(16 24 40 / 5%), 0px 0px 0px 4px #f4ebff;
    }
  }
  .country-list {
    max-height: 180px;
  }
  .is-invalid {
    border-color: var(--chakra-colors-error-300);
    &:focus {
      box-shadow: 0px 1px 2px rgb(16 24 40 / 5%), 0px 0px 0px 4px #fee4e2;
      border-color: var(--chakra-colors-error-300);
    }
  }
`;

const SignupPhoneNumberStep = ({
  formik,
  setCountryCode,
}: {
  formik: FormikProps<FormikSignUpValues>;
  setCountryCode: (value: string) => void;
}): JSX.Element => {
  const handleOnChange = (value, data) => {
    formik.setFieldValue('phoneNumber', value);
    setCountryCode(data.countryCode.toUpperCase());
  };

  return (
    <Container>
      <Stack spacing="16px" mx="16px" justifyContent="center">
        <Text textStyle="title" textAlign="center">
          {TextConstants.MODALS.SIGN_UP_MODAL.ALMAST_THERE}
        </Text>
        <Text textStyle="subTitle" textAlign="center" mt="8px">
          {TextConstants.MODALS.SIGN_UP_MODAL.ALMAST_THERE_DESCRIPTION}
        </Text>
        <Box>
          <FormControl
            isInvalid={formik.errors.phoneNumber !== undefined}
            pt="20px"
          >
            <FormLabel
              htmlFor="phoneNumber"
              fontFamily="Inter"
              fontSize="14px"
              lineHeight="20px"
              color="gray.700"
            >
              {
                TextConstants.PROFILE_MANAGMENT.PERSONAL_INFO_SECTION
                  .PHONE_NUMBER
              }
            </FormLabel>
            <ReactPhoneInput
              inputClass={formik.errors.phoneNumber ? 'is-invalid' : ''}
              defaultMask=".. (...) .. .. .."
              onChange={handleOnChange}
              country="us"
              value={`${formik.values.phoneNumber}`}
            />
            <FormErrorMessage>{formik.errors.phoneNumber}</FormErrorMessage>
          </FormControl>
        </Box>
      </Stack>
    </Container>
  );
};

export default SignupPhoneNumberStep;
