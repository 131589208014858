import { Role } from '../constants/Role';
import { getTokenData } from './CookieUtils';
import RoleHierarchy from "../constants/RoleHierarchy";

export const getRoles = (): Role[] => {
  return getTokenData()?.roles || [];
};

export const hasRolesInHierarchy = (roleHierarchy: Role, ...roles: Role[]): boolean => {
  return roles.some((role) => RoleHierarchy[roleHierarchy].has(role));
}

export const hasRoles = (...roles: Role[]): boolean => {
  return getRoles().some(function(roleCheck){
      console.log(roleCheck);
      return roleCheck === "ROLE_SUPER_ADMIN";
  });
};
