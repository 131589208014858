import axios, { AxiosError, AxiosPromise } from 'axios';
import HTTPMethod from 'http-method-enum';
import {
  HandleResponsePromiseType,
  InitialUserDataType,
  userDetailsDataType,
  RefreshTokenDataType,
  UserDataType,
  RecordDataType,
  CuratedLooksDataType,
  Order,
} from './GlobalTypes';
import logOutFunction from '../helpers/logOutFunction';
import {
  EmotionalData,
  PitchAnalyzerScore,
  ToneAnalyzerScore,
} from '../interfaces/Emotional';

const endPoint = process.env.REACT_APP_BACK_END_ENDPOINT;
const journalEndPoint = process.env.REACT_APP_BACK_END_DIARY_ENDPOINT;
const journalGuestEndPoint =
  process.env.REACT_APP_BACK_END_GUEST_DIARY_ENDPOINT;
const orchestrationEndPoint = process.env.REACT_APP_ORCHESTRATION_ENDPOINT;
const emotionalApiUrl = process.env.REACT_APP_EMOTIONAL_API_URL;
const pitchAnalyzerApiUrl = process.env.REACT_APP_PITCH_ANALYZER_API_URL;

let token = '';
const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const patchHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/merge-patch+json',
};

const authHeaders = (
  headers: { Accept: string; 'Content-Type': string },
  tokenParam: string
) => ({
  ...headers,
  Authorization: `Bearer ${tokenParam}`,
});

const watsonHeaders = (
  headers: { Accept: string; 'Content-Type': string },
  tokenParam: string,
  text: string,
  dialog: string,
  sessionId: string,
) => ({
  ...headers,
  Authorization: `Bearer ${tokenParam}`,
  dialog: dialog,
  text: text,
  sessionId: sessionId,
});

const getNewToken = (
  refreshToken: string,
  createdDate: number,
  userData: UserDataType,
  setUserDataInRedux: (result: UserDataType) => void
) =>
  new Promise(
    (
      resolve: (value?: RefreshTokenDataType) => void,
      reject: (reason?: Error) => void
    ) => {
      fetch(`${endPoint}/api/token/refresh`, {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify({ refresh_token: refreshToken }),
      })
        .then((response) => response.json())
        .then((data: RefreshTokenDataType) => {
          localStorage.setItem('createdDate', createdDate.toString());
          token = data.token;
          const userDataWithRefreshedToken = {
            ...userData,
            token: data.token,
            refresh_token: data.refresh_token,
          };

          setUserDataInRedux(userDataWithRefreshedToken);
          resolve(userDataWithRefreshedToken);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );

export function checkTokenExpiration(
  userData: UserDataType,
  setUserDataInRedux: (result: UserDataType) => void,
  // if no request function => we are checking token validation before DP initialization
  request: (token: string) => Promise<any> | null
): Promise<any> {
  if (userData) {
    token = userData.token;
    const initialDate = JSON.parse(localStorage.getItem('createdDate'));
    const createdDate = new Date().getTime();
    const refreshToken = userData.refresh_token;
    if (createdDate - initialDate > userData.expires_in * 1000) {
      return getNewToken(
        refreshToken,
        createdDate,
        userData,
        setUserDataInRedux
      ).then((userDataWithRefreshedToken) => {
        const refreshDate = new Date().getTime();
        localStorage.setItem('refreshDate', refreshDate.toString());
        return request
          ? request(userDataWithRefreshedToken.token)
          : Promise.resolve(userDataWithRefreshedToken.token);
      });
    }
    return request ? request(token) : Promise.resolve(token);
  }
}

export function refreshTokenAndCallOrchestration(
  refreshToken: string,
  createdDate: number,
  userData: UserDataType,
  setUserDataInRedux: (result: UserDataType) => void
): Promise<any> {
  return getNewToken(
    refreshToken,
    createdDate,
    userData,
    setUserDataInRedux
  ).then((userDataWithRefreshedToken) => {
    const refreshDate = new Date().getTime();
    localStorage.setItem('refreshDate', refreshDate.toString());
    return callOrchestration(userDataWithRefreshedToken.token);
  });
}

export function callOrchestrationAndSendUserText(
  userData: UserDataType,
  setUserDataInRedux: (result: UserDataType) => void
): Promise<any> {
  if (userData) {
    token = userData.token;

    const deltaTime = 300; // 5 minutes
    const expireTime = userData.expires_in - deltaTime;

    const initialDate = JSON.parse(localStorage.getItem('createdDate'));
    const createdDate = new Date().getTime();
    const refreshToken = userData.refresh_token;
    // if user token is expired => refresh and call orchestration
    if (createdDate - initialDate > expireTime * 1000) {
      return refreshTokenAndCallOrchestration(
        refreshToken,
        createdDate,
        userData,
        setUserDataInRedux
      );
    }

    // just send user text
    return Promise.resolve();
  }
}

export function callOrchestrationOnDPMount(
  userData: UserDataType,
  setUserDataInRedux: (result: UserDataType) => void
): Promise<any> {
  if (userData) {
    const createdDate = new Date().getTime();
    const refreshToken = userData.refresh_token;

    return refreshTokenAndCallOrchestration(
      refreshToken,
      createdDate,
      userData,
      setUserDataInRedux
    );
  }
}

function handleResponse(response) {
  return new Promise<HandleResponsePromiseType>((resolve, reject) => {
    response
      .json()
      .then((parsedResult) => {
        if (response.status >= 200 && response.status <= 299) {
          resolve(parsedResult);
        } else {
          reject(parsedResult);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function handleLogout(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      resolve(response);
    } else {
      logOutFunction();
      reject(response);
    }
  });
}

export function loginRequest(data: {
  email: string;
  password: string;
  rememberMe: boolean;
}): Promise<InitialUserDataType> {
  return fetch(`${endPoint}/api/token/get`, {
    method: HTTPMethod.POST,
    headers: defaultHeaders,
    body: JSON.stringify(data),
  }).then(handleResponse);
}

export function getUserDataRequest(
  id: number,
  token: string
): Promise<userDetailsDataType> {
  return fetch(`${endPoint}/api/users/${id}`, {
    method: 'GET',
    headers: authHeaders(defaultHeaders, token),
  }).then(handleResponse);
}

function callOrchestration(tokenParam: string): Promise<any> {
  return fetch(`${orchestrationEndPoint}/update-jwt`, {
    method: 'POST',
    headers: authHeaders(defaultHeaders, tokenParam),
  }).then(handleLogout);
}

export function registerRequest(data: {
  dateOfBirth: string;
  country: string;
  email: string;
  firstName: string;
  gender: string;
  lastName: string;
  password: string;
}): Promise<userDetailsDataType> {
  Object.keys(data).forEach((key) => {
    if (data[key] === '') data[key] = null;
  });
  return fetch(`${endPoint}/api/users`, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify(data),
  }).then(handleResponse);
}

export function forgotPasswordRequest(data: { email: string }): Promise<any> {
  return fetch(`${endPoint}/api/reset_password_request`, {
    method: 'POST',
    headers: defaultHeaders,
    body: JSON.stringify(data),
  }).then(handleResponse);
}

export function resetPasswordRequest(data: {
  passwordResetToken: string;
  newPassword: string;
  newPasswordConfirm: string;
}): Promise<any> {
  return fetch(`${endPoint}/api/reset_password_confirm`, {
    method: HTTPMethod.POST,
    headers: defaultHeaders,
    body: JSON.stringify(data),
  }).then(handleResponse);
}

export function getEntryDetailsData(
  data: {
    dateStart: string;
    dateEnd: string;
    limit: string;
    offset: string;
  },
  userData: UserDataType,
  setUserDataInRedux: (result: UserDataType) => void
): Promise<any> {
  const searchParams = new URLSearchParams(data);
  return checkTokenExpiration(userData, setUserDataInRedux, (token) =>
    fetch(`${journalEndPoint}/diary-records?${searchParams}`, {
      method: HTTPMethod.GET,
      headers: authHeaders(defaultHeaders, token),
    })
  ).then(handleResponse);
}

export function addDiaryRecord(
  data: {
    text: string;
    analyze: boolean;
  },
  userData: UserDataType,
  setUserDataInRedux: (result: UserDataType) => void
): Promise<any> {
  return checkTokenExpiration(userData, setUserDataInRedux, (token) =>
    fetch(`${journalEndPoint}/diary-record`, {
      method: 'POST',
      headers: authHeaders(defaultHeaders, token),
      body: JSON.stringify(data),
    })
  ).then(handleResponse);
}

export function shareDiaryRecords(
  data: {
    dateStart: string;
    dateEnd: string;
    email: string;
  },
  userData: UserDataType,
  setUserDataInRedux: (result: UserDataType) => void
): Promise<any> {
  return checkTokenExpiration(userData, setUserDataInRedux, (token) =>
    fetch(`${journalEndPoint}/diary-records/share`, {
      method: 'POST',
      headers: authHeaders(defaultHeaders, token),
      body: JSON.stringify(data),
    })
  ).then(handleResponse);
}

export function getShareData(token: string): Promise<any> {
  return fetch(`${journalEndPoint}/diary-records/share/${token}`, {
    method: 'GET',
    headers: defaultHeaders,
  }).then(handleResponse);
}

export function getProductsCategories(): Promise<any> {
  return fetch(`${endPoint}/api/categories/tree`, {
    method: 'GET',
    headers: defaultHeaders,
  }).then(handleResponse);
}

export function getProductAllData(): Promise<any> {
  return fetch(`${endPoint}/api/products/all`, {
    method: 'GET',
    headers: defaultHeaders,
  }).then(handleResponse);
}

export function searchInProducts(text: string): Promise<any> {
  return fetch(
    `${endPoint}/api/product_variants/search?keywords=${encodeURIComponent(
      text
    )}`,
    {
      method: 'GET',
      headers: defaultHeaders,
    }
  ).then(handleResponse);
}

export function createGuestUserJournalScore(data: {
  text: string;
}): Promise<any> {
  return fetch(`${journalGuestEndPoint}/analyze`, {
    method: HTTPMethod.POST,
    headers: defaultHeaders,
    body: JSON.stringify(data),
  }).then(handleResponse);
}

export function postGuestEntryData(
  diaryRecords: RecordDataType[],
  token: string
): Promise<any> {
  return fetch(`${journalEndPoint}/diary-record/bulk`, {
    method: 'POST',
    headers: authHeaders(defaultHeaders, token),
    body: JSON.stringify({ diaryRecords }),
  }).then(handleResponse);
}

export function updateProfileInformation(
  data: {
    firstName?: string;
    lastName?: string;
    dateOfBirth?: Date;
    gender?: string;
    country?: string;
    email?: string;
    addresses?: {
      city?: string;
      state?: string;
      addressLine1?: string;
      addressLine2?: string;
      zipcode?: string;
    }[];
  },
  userData: UserDataType,
  setUserDataInRedux: (result: UserDataType) => void
): Promise<InitialUserDataType> {
  return checkTokenExpiration(userData, setUserDataInRedux, (token) =>
    fetch(`${endPoint}/api/users/${userData.id}`, {
      method: HTTPMethod.PATCH,
      headers: authHeaders(patchHeaders, token),
      body: JSON.stringify(data),
    })
  )
    .then(handleLogout)
    .then(handleResponse);
}

export function updateProfilePassword(
  data: {
    currentPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
  },
  userData: UserDataType,
  setUserDataInRedux: (result: UserDataType) => void
): Promise<InitialUserDataType> {
  return checkTokenExpiration(userData, setUserDataInRedux, (token) =>
    fetch(`${endPoint}/api/change_password`, {
      method: HTTPMethod.POST,
      headers: authHeaders(defaultHeaders, token),
      body: JSON.stringify(data),
    })
  )
    .then(handleLogout)
    .then(handleResponse);
}

export function verifyUser(token: string): Promise<InitialUserDataType> {
  return fetch(`${endPoint}/api/activate_account/${token}`, {
    method: HTTPMethod.GET,
    headers: defaultHeaders,
  }).then(handleResponse);
}

export function getCuratedLooks(): Promise<CuratedLooksDataType> {
  return fetch(`${endPoint}/api/curated_looks/random`, {
    method: 'GET',
    headers: defaultHeaders,
  }).then(handleResponse);
}

export function getRandomLooks(name: string): Promise<any> {
  return fetch(`${endPoint}/api/generated_looks/random?name=${name}`, {
    method: 'GET',
    headers: defaultHeaders,
  }).then(handleResponse);
}

export function createOrder(data: Order): Promise<any> {
  return fetch(`${endPoint}/api/orders`, {
    method: HTTPMethod.POST,
    headers: defaultHeaders,
    body: JSON.stringify(data),
  }).then(handleResponse);
}

const axiosHttp = axios.create();

axiosHttp.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => Promise.reject(error)
);

export async function fetchResponse<TResponse>(
  url: string,
  method: HTTPMethod,
  body?: Record<string, unknown> | FormData,
  tokenParam?:string
): Promise<AxiosPromise<TResponse>> {
  tokenParam && (token = tokenParam);
  const isBodyInstanceOfFormData: boolean = body instanceof FormData;

  const data = isBodyInstanceOfFormData ? body : JSON.stringify(body);

  try {
    return await axiosHttp({
      url,
      method,
      headers: authHeaders(defaultHeaders, token),
      data,
    });
  } catch (error) {
    return Promise.reject(error);
  }
}
export function analyzeText(data: {
  text: string;
}): Promise<ToneAnalyzerScore> {
  return fetch(`${emotionalApiUrl}/analyze`, {
    method: HTTPMethod.POST,
    headers: defaultHeaders,
    body: JSON.stringify(data),
  })
    .then((response) => (response.ok ? response : Promise.reject(response)))
    .then((response) => response.json());
}

export function analyzeAudio(file: Blob): Promise<PitchAnalyzerScore> {
  const formData = new FormData();
  formData.append('file', file);
  return fetch(`${pitchAnalyzerApiUrl}/recognize`, {
    method: HTTPMethod.POST,
    body: formData,
  })
    .then((response) => (response.ok ? response : Promise.reject(response)))
    .then((response) => response.json());
}

export function sendEmotionalData(data: EmotionalData): Promise<any> {
  return fetch(`${emotionalApiUrl}/emotionalData`, {
    method: HTTPMethod.POST,
    headers: defaultHeaders,
    body: JSON.stringify(data),
  })
    .then((response) => (response.ok ? response : Promise.reject(response)))
    .then((response) => response.json());
}

export function callWatson(tokenParam: string, text: string, dialog: string, sessionId: string, contextVariables: object): Promise<any> {
  return fetch(`${orchestrationEndPoint}/watson`, {
    method: HTTPMethod.POST,
    headers: watsonHeaders(defaultHeaders, tokenParam, text, dialog, sessionId),
    body : JSON.stringify({contextVariables: contextVariables})
  }).then(handleResponse);
}

export function updateWatsonContextVariables(dialog: string, sessionId: string, contextVariables: object): Promise<any> {
  return fetch(`${orchestrationEndPoint}/watson/update-watson-context`, {
    method: HTTPMethod.POST,
    headers: watsonHeaders(defaultHeaders, '', '', dialog, sessionId),
    body : JSON.stringify({contextVariables})
  }).then(handleResponse);
}

export async function addOrUpdateCart(tokenParam: string, cartData): Promise<any> {
  const response = await fetch(`${endPoint}/api/cart`, {
    method: HTTPMethod.POST,
    headers: authHeaders(defaultHeaders, tokenParam),
    body: JSON.stringify(cartData),
  });
  return handleResponse(response);
}

export function removeProductFromCart(tokenParam: string, product): Promise<any> {
  return fetch(`${endPoint}/api/cart/remove`, {
    method: HTTPMethod.POST,
    headers: authHeaders(defaultHeaders, tokenParam),
    body: JSON.stringify(product),
  }).then(handleResponse);
}

export function fetchCartItems(tokenParam: string): Promise<any> {
  return fetch(`${endPoint}/api/get-cart`, {
    method: HTTPMethod.GET,
    headers: authHeaders(defaultHeaders, tokenParam)    
  }).then(handleResponse);
}

export async function updateWellnessStatus(tokenParam: string, requestBody): Promise<any> {
  return fetch(`${endPoint}/api/users/wellness-status`, {
    method: HTTPMethod.POST,
    headers: authHeaders(defaultHeaders, tokenParam),
    body: JSON.stringify(requestBody),
  }).then(handleResponse);
}
