import { useRef, useState } from 'react';
import { Box, Button, Heading } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import TextConstants from '../../../constants/TextConstants';
import {
  mute,
  sendTextMessage,
  stopSpeaking,
} from '../../../store/reducers/digitalAssistantReducer';
import { AppDispatch, RootState } from '../../../store/store';
import VideoHOCWithLogoutTimer from '../../HOC/VideoHOCWithLogoutTimer';

const TherapyApproach = ({
  data,
  isCardInTranscript,
}: {
  data: {
    id: number;
    name: string;
    video: {
      contentUrl: string;
      staticStorageUrl: string;
      originalName: string;
      id: number;
    };
  };
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const isMuted = useSelector((state: RootState) => state.digitalAssistant.isMuted);
  const isMutedBeforePlayingVideo = useRef(isMuted);
  const videoRef = useRef(null);
  const isTranscriptOpen = useSelector(
    (state: RootState) => state.digitalAssistant.showTranscript
  );
  const handleClick = () => {
    dispatch(stopSpeaking());
    dispatch(sendTextMessage({ text: 'proceed' }));
    dispatch(mute(isMutedBeforePlayingVideo.current));
    videoRef.current.pause();
    setIsButtonDisabled(true);
  };

  const onPlay = () => {
    // mute persona
    dispatch(stopSpeaking());
    dispatch(mute(true));
  };

  const onPause = () => {
    dispatch(mute(isMutedBeforePlayingVideo.current));
  };

  return (
    <Box
      display="block"
      flexDirection="column"
      justifyContent="center"
      alignItems={isTranscriptOpen ? 'start' : 'center'}
      data-sm-content="therapyPrimerDetails"
      overflowY={isTranscriptOpen ? 'unset' : 'scroll'}
      maxH={isTranscriptOpen ? 'auto' : '65vh'}
      maxW={!isTranscriptOpen && { base: '80%', sm: '35vw' }}
      p={isTranscriptOpen ? '10px 0 10px 35px' : '0'}
      paddingBottom={isTranscriptOpen ? 0 : '5vh'}
      marginRight={!isTranscriptOpen && { base: '0', sm: '40px' }}
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      <Box className="transcript-entry-content" mb="20px" py="10px">
        <Heading
          as="h2"
          fontSize="xl"
          className="therapy-primer-title"
          wordBreak="break-all"
        >
          {data.name}
        </Heading>
      </Box>

      <VideoHOCWithLogoutTimer
        className="therapy-primer-video"
        src={data.video.staticStorageUrl}
        onPlay={onPlay}
        onPause={onPause}
        poster={`${process.env.PUBLIC_URL}/images/wellness-video-poster.jpg`}
        preload="auto"
        controls
        ref={videoRef}
      />

      <Button
        zIndex="1"
        mt="20px"
        onClick={() => {
          handleClick();
        }}
        disabled={isButtonDisabled || isCardInTranscript}
      >
        {TextConstants.WELLNESS_THERAPY.THERAPY_PRIMER_DETAILS_BUTTON}
      </Button>
    </Box>
  );
};

export default TherapyApproach;
