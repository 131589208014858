import {
  FormattedRecordDataType,
  RecordDataType,
} from '../../global-components/GlobalTypes';

const changeJournalDataFormat = (
  records: RecordDataType[] | []
): FormattedRecordDataType[] => {
  let formattedData = [];
  if (records.length) {
    for (let i = 0; i < records.length; i++) {
      const scoresData = {
        ...records[i].emotionalScore,
        ...records[i].classificationScore,
      };
      if (!formattedData.length) {
        formattedData = [
          {
            date: records[i].createdAt.split('T')[0],
            average: scoresData,
            records: [records[i]],
          },
        ];
      } else if (
        formattedData[formattedData.length - 1].date ===
        records[i].createdAt.split('T')[0]
      ) {
        const dayAllRecords = [
          ...formattedData[formattedData.length - 1].records,
          records[i],
        ];

        let joySum = 0;
        let fearSum = 0;
        let angerSum = 0;
        let frustratedSum = 0;
        let sadSum = 0;
        let excitedSum = 0;
        let satisfiedSum = 0;
        for (let j = 0; j < dayAllRecords.length; j += 1) {
          const element = dayAllRecords[j];
          joySum += element.emotionalScore.joy;
          fearSum += element.emotionalScore.fear;
          angerSum += element.emotionalScore.anger;
          frustratedSum += element.classificationScore.frustrated;
          sadSum += element.classificationScore.sad;
          excitedSum += element.classificationScore.excited;
          satisfiedSum += element.classificationScore.satisfied;
        }
        formattedData = [
          ...formattedData.splice(0, formattedData.length - 1),
          {
            date: formattedData[formattedData.length - 1].date,
            average: {
              joy: joySum / dayAllRecords.length,
              fear: fearSum / dayAllRecords.length,
              anger: angerSum / dayAllRecords.length,
              frustrated: frustratedSum / dayAllRecords.length,
              sad: sadSum / dayAllRecords.length,
              excited: excitedSum / dayAllRecords.length,
              satisfied: satisfiedSum / dayAllRecords.length,
            },
            records: dayAllRecords,
          },
        ];
      } else {
        formattedData = [
          ...formattedData,
          {
            date: records[i].createdAt.split('T')[0],
            average: scoresData,
            records: [records[i]],
          },
        ];
      }
    }
  }
  return formattedData;
};

export default changeJournalDataFormat;
