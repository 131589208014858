import { useDispatch } from 'react-redux';
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import TextConstants from '../../constants/TextConstants';
import IconInCircles from '../common/IconInCircles/IconInCircles';
import { closeAssistantChat } from '../../store/reducers/digitalAssistantReducer';
import { setIsHeaderModalsShown } from '../../store/reducers/commerceReducer';
import { useIsUserLoggedIn } from '../../helpers/userInfo/UserInfo';

const ExitToLobbyModal = ({
  isExitToLobbyModalOpen,
  closeExitToLobbyFunction,
}: {
  closeExitToLobbyFunction: () => void;
  isExitToLobbyModalOpen: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();
  const isUserLogin = useIsUserLoggedIn();
  return (
    <Modal
      size="sm"
      isOpen={isExitToLobbyModalOpen}
      isCentered
      onClose={() => {
        closeExitToLobbyFunction();
      }}
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalBody>
          <VStack spacing="none">
            <IconInCircles icon="warning" theme="warning" />
            <Text as="h3" textStyle="text-lg" marginTop="4">
              {isUserLogin
                ? TextConstants.MODALS.EXIT_TO_LOBBY_MODAL_FOR_AUTH_USER.TITLE
                : TextConstants.MODALS.EXIT_TO_LOBBY_MODAL.TITLE}
            </Text>
            <VStack
              spacing="4"
              color="gray.500"
              textAlign="center"
              marginTop="2"
            >
              <Text textStyle="text-sm">
                {isUserLogin
                  ? TextConstants.MODALS.EXIT_TO_LOBBY_MODAL_FOR_AUTH_USER
                      .SUB_TITLE
                  : TextConstants.MODALS.EXIT_TO_LOBBY_MODAL.SUB_TITLE}
              </Text>
            </VStack>
          </VStack>
        </ModalBody>

        <ModalFooter justifyContent="center">
          <HStack
            spacing="3"
            width="100%"
            justify="flex-end"
            display={{ base: 'block', sm: 'flex' }}
          >
            <Button
              borderRadius="0"
              w="auto"
              colorScheme="gray"
              variant="outline"
              onClick={() => {
                closeExitToLobbyFunction();
                dispatch(closeAssistantChat());
              }}
              type="button"
            >
              {TextConstants.BUTTON_TEXT.EXIT}
            </Button>
            <Button
              w="auto"
              mt={{ base: '12px !important', sm: '0 !important' }}
              borderRadius="0"
              textTransform="uppercase"
              marginInlineStart={{
                base: '0 !important',
                sm: '12px !important',
              }}
              onClick={() => {
                closeExitToLobbyFunction();
                if (!isUserLogin) {
                  dispatch(setIsHeaderModalsShown({ registerModal: true }));
                }
              }}
              type="button"
            >
              {isUserLogin
                ? TextConstants.MODALS.EXIT_TO_LOBBY_MODAL_FOR_AUTH_USER.CANCEL
                : TextConstants.MODALS.EXIT_TO_LOBBY_MODAL_FOR_AUTH_USER
                    .SIGN_UP}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExitToLobbyModal;
