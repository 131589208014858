import './components/Note.scss';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HTTPMethod from 'http-method-enum';
import Note from './components/Note';
import {
  getNotesMaxStackLevel,
  getTransformedNote,
  recalculateNotesPositions,
} from '../../utils/Notes';
import {
  deleteNote,
  setMirrorDimensions,
  setNotes,
} from '../../store/reducers/noteManagerReducer';
import {
  NOTE_CONTAINER_ACTIVE_CLASSNAME,
  NOTE_CONTAINER_DEFAULT_CLASSNAME,
} from '../../constants/Notes';
import { NoteResponse } from '../../interfaces/Notes';
import { RootState } from '../../store/store';
import {
  STICKY_NOTE_API_ROUTE,
  STICKY_NOTES_API_ROUTE,
  STICKY_NOTES_URL,
} from '../../constants/apiRoutes';
import { fetchResponse } from '../../global-components/RequestFactory';
import { useIsUserLoggedIn } from '../../helpers/userInfo/UserInfo';

type StickyNotesContainerProps = {
  mirrorWidth: number;
  mirrorHeight: number;
};

const StickyNotesContainer = ({
  mirrorWidth,
  mirrorHeight,
}: StickyNotesContainerProps): JSX.Element => {
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const { notes } = useSelector((state: RootState) => state.noteManager);

  const userData = useSelector(
    (state: RootState) => state.authenticationState.userData
  );

  const isUserLogin = useIsUserLoggedIn();
  const [maxStackLevel, setMaxStackLevel] = useState<number>(
    getNotesMaxStackLevel(notes)
  );

  const handleDragStart = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.className = NOTE_CONTAINER_ACTIVE_CLASSNAME;
    }
  }, [containerRef]);

  const handleDragEnd = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.className = NOTE_CONTAINER_DEFAULT_CLASSNAME;
    }
  }, [containerRef]);

  const handleMaxStackLevelIncreased = () => {
    setMaxStackLevel(maxStackLevel + 1);
  };

  const handleMouseOutBlur = () => {
    window.focus();
  };

  const handleNoteDelete = async (id: number) => {
    if (isUserLogin) {
      await fetchResponse(
        `${STICKY_NOTES_URL}/${STICKY_NOTE_API_ROUTE}/${id}`,
        HTTPMethod.DELETE
      );
    }

    dispatch(deleteNote(id));
  };

  useEffect(() => {
    window.focus();

    window.addEventListener('blur', handleMouseOutBlur);

    return () => {
      window.removeEventListener('blur', handleMouseOutBlur);
    };
  }, []);

  useEffect(() => {
    if (isUserLogin) {
      const fetchData = async () => {
        const { data } = await fetchResponse<NoteResponse[]>(
          `${STICKY_NOTES_URL}/${STICKY_NOTES_API_ROUTE}`,
          HTTPMethod.GET,
          {},
          userData.token
        );
        const transformedNotes = data.map((note) =>
          getTransformedNote(note, mirrorWidth, mirrorHeight)
        );
        const stackLevel = getNotesMaxStackLevel(transformedNotes);

        setMaxStackLevel(stackLevel);
        dispatch(setNotes(transformedNotes));
      };

      fetchData();
    } else {
      recalculateNotesPositions(mirrorWidth, mirrorHeight);
    }

    dispatch(
      setMirrorDimensions({
        width: mirrorWidth,
        height: mirrorHeight,
      })
    );
  }, []);

  return (
    <div
      ref={containerRef}
      onBlur={handleMouseOutBlur}
      onMouseOut={handleMouseOutBlur}
      style={{
        width: mirrorWidth,
        height: mirrorHeight - 85,
        marginTop: 50,
      }}
      className="sticky-notes-container"
    >
      {notes.map((note) => (
        <Note
          onDelete={handleNoteDelete}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          maxStackLevel={maxStackLevel}
          onMaxStackLevelIncreased={handleMaxStackLevelIncreased}
          key={note.id}
          note={note}
          mirrorHeight={mirrorHeight - 85}
          mirrorWidth={mirrorWidth}
          isFocusedOnInit={note.isFocusedOnInit}
        />
      ))}
    </div>
  );
};

export default StickyNotesContainer;
