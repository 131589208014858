import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  VStack,
  Link,
  Box,
  Badge,
  HStack,
  Text,
  Button,
} from '@chakra-ui/react';
import s from './MobileNav.module.scss';
import { SVG } from '../../icons/icons';
import { logoutUser } from '../../../store/reducers/authenticationReducer';
import {removeProductsFromCart } from '../../../store/reducers/commerceReducer';
import { RootState } from '../../../store/store';
import { setIsHeaderModalsShown } from '../../../store/reducers/commerceReducer';
import TextConstants from '../../../constants/TextConstants';
import {
  useGetUserInfo,
  useIsUserLoggedIn,
} from '../../../helpers/userInfo/UserInfo';
import IntroVideoButton from '../common/IntroVideoButton';
import { hasRoles } from '../../../utils/RoleUtils';
import { Role } from '../../../constants/Role';
import HardcodedHeaderLinksData from '../../../constants/HeaderLinksData';
import { setSignInModal } from '../../../store/reducers/signInModalReducer';
import { HeaderAuthPromptActionType } from '../Header';

const MobileNav = ({
  totalProductsAmount,
  isAuthPromptOpened,
  openExitToLobbyFunction,
  setAuthPromptAction,
}: {
  totalProductsAmount: number;
  isAuthPromptOpened: boolean;
  openExitToLobbyFunction: () => void;
  setAuthPromptAction: (action: HeaderAuthPromptActionType) => void;
}): JSX.Element => {
  const dispatch = useDispatch();
  const isLoggedIn = useIsUserLoggedIn();
  const loading = useSelector((state: RootState) => state.digitalAssistant.loading);
  const connected = useSelector((state: RootState) => state.digitalAssistant.connected);
  const { pathname } = useLocation();
  const userData = useGetUserInfo();

  return (
    <Box className={s.mobileNavWrapper}>
      <Box as="nav" className={s.mobileNav}>
        <VStack spacing={2} as="ul" align="start">
          <Box
            as="li"
            d="flex"
            className={`${s.navItem} ${
              connected && !loading && pathname === '/video' ? '' : 'd-none'
            }`}
          >
            <Button
              aria-label="my-bag"
              variant="link"
              className={s.textLink}
              onClick={() => {
                openExitToLobbyFunction();
              }}
            >
              <SVG id="arrow-left" />
              <Text ml="-9px" className="link-text">
                Exit to Lobby
              </Text>
            </Button>
          </Box>

          {hasRoles(Role.SUPER_ADMIN) ? (
            <Box as="li" className={s.navItem} d="flex">
              <Button
                aria-label="to-admin-panel"
                variant="link"
                className={s.textLink}
                onClick={() => location.assign(process.env.REACT_APP_ADMIN_URL)}
              >
                <SVG mr="5px" id="arrow-left-circle" />
                <Text ml="-8px" className="link-text">
                  To Admin Panel
                </Text>
              </Button>
            </Box>
          ) : null}

          <Box as="li" mt="0" className={s.navItem} d="flex">
            <IntroVideoButton />
          </Box>

          <Box as="li" mt="0" className={s.navItem}>
            <Button
              aria-label="journal"
              variant="link"
              className={s.textLink}
              onClick={() => {
                if (isAuthPromptOpened) {
                  dispatch(setIsHeaderModalsShown({ journalDrawer: true }));
                } else {
                  setAuthPromptAction('Journal');
                }
              }}
            >
              <SVG id="journal-icon" />
              <span className="link-text">Journal</span>
            </Button>
          </Box>

          <Box as="li" className={s.navItem} d="flex">
            <Button
              aria-label="my-bag"
              variant="link"
              className={s.textLink}
              onClick={() => {
                if (isAuthPromptOpened) {
                  dispatch(setIsHeaderModalsShown({ myBagDrawer: true }));
                } else {
                  setAuthPromptAction('MyBag');
                }
              }}
            >
              <SVG mr="5px" id="shopping-bag" />
              <Text ml="-8px" className="link-text">
                My Bag
              </Text>
              {totalProductsAmount > 0 && (
                <Badge className={s.countBadge}>{totalProductsAmount}</Badge>
              )}
            </Button>
          </Box>

          {isLoggedIn && (
            <Box as="li" className={s.navItem}>
              <Link
                as={NavLink}
                to="/#"
                className={s.textLink}
                onClick={() =>
                  dispatch(setIsHeaderModalsShown({ profileDrawer: true }))
                }
              >
                <SVG id="user-avatar" />
                <Text ml="-8px" color="var(--chakra-colors-brand-700)">
                  Profile
                </Text>
              </Link>
            </Box>
          )}
        </VStack>
      </Box>

      <Box className={s.mobileNavFooterLinks}>
        {HardcodedHeaderLinksData.map((item) => (
          <Link key={item.id} isExternal href={item.url} className={s.textLink}>
            {item.title}
          </Link>
        ))}
      </Box>

      {isLoggedIn ? (
        <HStack justifyContent="space-between" mt="8" spacing="3" px="4">
          <Box maxW="200px">
            <Text textStyle="text-md" fontWeight="medium" color="gray.900">
              {userData.firstName} {userData.lastName}
            </Text>
            <Text textStyle="text-md" color="#661E3A">
              {userData.email}
            </Text>
          </Box>

          <Button
            aria-label="Log out"
            variant="ghost"
            className={s.logOutBtn}
            _hover={{ background: 'transparent' }}
            _active={{ background: 'transparent' }}
            onClick={() => {              
              dispatch(logoutUser());
              dispatch(removeProductsFromCart())
            }}
          >
            <SVG id="log-out" />
          </Button>
        </HStack>
      ) : (
        <Box className={s.navItem}>
          <Button
            w="100%"
            colorScheme="brand"
            size="xl"
            borderRadius="0"
            fontWeight="500"
            fontFamily="third"
            onClick={() =>
              dispatch(setIsHeaderModalsShown({ registerModal: true }))
            }
          >
            {TextConstants.BUTTON_TEXT.SIGN_UP}
          </Button>
          <Button
            bg="#D9BEC8"
            color="var(--chakra-colors-brand-700)"
            mt="15px"
            borderRadius="0"
            display="flex"
            w="100%"
            colorScheme="#661e3ab8"
            size="xl"
            fontWeight="500"
            fontFamily="third"
            onClick={() => dispatch(setSignInModal({ isOpen: true }))}
          >
            {TextConstants.BUTTON_TEXT.SIGN_IN}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default MobileNav;
