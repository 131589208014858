import { useRef, useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import Slider from 'react-slick';
import tutorialImageOne from '../../img/tutorial-image-one.png';
import tutorialImageTwo from '../../img/tutorial-image-two.png';
import tutorialImageThree from '../../img/tutorial-image-three.png';
import tutorialImageFour from '../../img/tutorial-image-four.png';
import TextConstants from '../../constants/TextConstants';

function TutorialStepsModals({
  isTutorialModalOpen,
  closeTurorialModal,
}: {
  isTutorialModalOpen: boolean;
  closeTurorialModal: () => void;
}): JSX.Element {
  const sliderRef = useRef(null);

  const [currentSliderPosition, setCurrentSliderPosition] = useState(0);

  function renderModalButtons() {
    let view;
    if (currentSliderPosition === 3) {
      view = (
        <Button borderRadius="0" w="100%" onClick={closeTurorialModal}>
          {TextConstants.BUTTON_TEXT.GET_STARTED}
        </Button>
      );
    } else {
      view = (
        <Button
          w="100%"
          borderRadius="0"
          onClick={() => sliderRef.current && sliderRef.current.slickNext()}
        >
          {TextConstants.BUTTON_TEXT.CONTINUE}
        </Button>
      );
    }
    return view;
  }

  return (
    <Modal
      isOpen={isTutorialModalOpen}
      onClose={() => {
        // reset slider current position
        setCurrentSliderPosition(0);
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalBody>
          <Slider
            slidesToShow={1}
            slidesToScroll={1}
            dots
            arrows={false}
            infinite={false}
            speed={500}
            ref={sliderRef}
            className="slick-dotted"
            beforeChange={(current, next) => {
              setCurrentSliderPosition(next);
            }}
          >
            <Box>
              <VStack spacing="none">
                <Image
                  src={tutorialImageOne}
                  alt="Tutorial image"
                  objectFit="cover"
                  width="100%"
                />

                <Text as="h3" textStyle="text-lg" marginTop="4">
                  {TextConstants.MODALS.TUTORIAL.SLIDE_ONE.TITLE}
                </Text>
                <VStack
                  spacing="4"
                  color="gray.500"
                  textAlign="center"
                  marginTop="2"
                >
                  <Text textStyle="text-sm">
                    {TextConstants.MODALS.TUTORIAL.SLIDE_ONE.SUB_TITLE}
                  </Text>
                </VStack>
              </VStack>
            </Box>

            <Box>
              <VStack spacing="none">
                <Image
                  src={tutorialImageTwo}
                  alt="Tutorial image"
                  objectFit="cover"
                  width="100%"
                />

                <Text as="h3" textStyle="text-lg" marginTop="4">
                  {TextConstants.MODALS.TUTORIAL.SLIDE_TWO.TITLE}
                </Text>
                <VStack
                  spacing="4"
                  color="gray.500"
                  textAlign="center"
                  marginTop="2"
                >
                  <Text textStyle="text-sm">
                    {TextConstants.MODALS.TUTORIAL.SLIDE_TWO.SUB_TITLE}
                  </Text>
                </VStack>
              </VStack>
            </Box>

            <Box>
              <VStack spacing="none">
                <Image
                  src={tutorialImageThree}
                  alt="Tutorial image"
                  objectFit="cover"
                  width="100%"
                />

                <Text as="h3" textStyle="text-lg" marginTop="4">
                  {TextConstants.MODALS.TUTORIAL.SLIDE_THREE.TITLE}
                </Text>
                <VStack
                  spacing="4"
                  color="gray.500"
                  textAlign="center"
                  marginTop="2"
                >
                  <Text textStyle="text-sm">
                    {TextConstants.MODALS.TUTORIAL.SLIDE_THREE.SUB_TITLE}
                  </Text>
                </VStack>
              </VStack>
            </Box>

            <Box>
              <VStack spacing="none">
                <Image
                  src={tutorialImageFour}
                  alt="Tutorial image"
                  objectFit="cover"
                  width="100%"
                />

                <Text as="h3" textStyle="text-lg" marginTop="4">
                  {TextConstants.MODALS.TUTORIAL.SLIDE_FOUR.TITLE}
                </Text>
                <VStack
                  spacing="4"
                  color="gray.500"
                  textAlign="center"
                  marginTop="2"
                >
                  <Text textStyle="text-sm">
                    {TextConstants.MODALS.TUTORIAL.SLIDE_FOUR.SUB_TITLE}
                  </Text>
                </VStack>
              </VStack>
            </Box>
          </Slider>
        </ModalBody>

        <ModalFooter>
          <HStack w="100%" spacing="1" justify="space-between" align="center">
            {renderModalButtons()}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default TutorialStepsModals;
