import { SignInModalState } from "../../global-components/GlobalTypes";
import { createSlice } from "@reduxjs/toolkit";

const initialState: SignInModalState = {
  isOpen: false,
  isSkipLoginButtonDisplayed: false,
};

const SignInModalReducer = createSlice({
  name: 'signInModal',
  initialState,
  reducers: {
    setSignInModal: (state, { payload }) => ({ ...initialState, ...payload }),
  },
});

export const { setSignInModal } = SignInModalReducer.actions;

export default SignInModalReducer.reducer;
