import {
  CustomizeMakeupCategoryType,
  CustomizeMakeupProductType,
} from '../../global-components/GlobalTypes';
import generateRandomNumberBetween from '../generateRandomNumberBetween';

export default function getRandomProducts(
  type: 'single' | 'multiple',
  productsData: CustomizeMakeupCategoryType[],
  advancedSelectedCategory?: 'Face' | 'Lips' | null
): CustomizeMakeupProductType[] {
  const plainProductsData: CustomizeMakeupProductType[] = [];

  productsData.forEach((category) => {
    const isSelectedCategory =
      !advancedSelectedCategory || category.name === advancedSelectedCategory;
    const isMiscellaneousCategory = category.name === 'Miscellaneous';

    if (isSelectedCategory && !isMiscellaneousCategory) {
      category.children.forEach((subcategory) => {
        subcategory.children.forEach((product) => {
          plainProductsData.push(product);
        });
      });
    }
  });

  const generatedNumbers = generateRandomNumberBetween(
    0,
    plainProductsData.length
  );

  if (type === 'single') {
    return [plainProductsData[generatedNumbers[0]]];
  }

  return [
    plainProductsData[generatedNumbers[0]],
    plainProductsData[generatedNumbers[1]],
    plainProductsData[generatedNumbers[2]],
  ];
}
