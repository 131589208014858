import { Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import StyleWrapper from './StyleWrapper';
import TextConstants from '../../../constants/TextConstants';
import { AppDispatch } from '../../../store/store';
import { setSceneToOpen } from '../../../store/reducers/pannellumReducer';
import { closeAssistantChat } from '../../../store/reducers/digitalAssistantReducer';

const GoToWellnessOption = ({
  isCardInTranscript,
}: {
  isCardInTranscript: boolean;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const handleClick = () => {
    dispatch(setSceneToOpen('spa'));
    dispatch(closeAssistantChat());
  };

  return (
    <StyleWrapper>
      <Button
        fontSize={{ base: 'small', sm: '1rem' }}
        variant="secondary"
        p="20px"
        w="100%"
        m="0 auto"
        borderRadius="0.5rem"
        _focus={{ boxShadow: 'none' }}
        onClick={handleClick}
        disabled={isCardInTranscript}
      >
        {TextConstants.COMMERCE.GO_TO_WELLNESS}
      </Button>
    </StyleWrapper>
  );
};

export default GoToWellnessOption;
