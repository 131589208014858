import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
  ModalCloseButton
} from '@chakra-ui/react';
import Slider from 'react-slick';
import LoadingModal from './LoadingModal';
import { RootState } from '../../store/store';
import { acceptMediaPermissions } from '../../store/reducers/digitalAssistantReducer';
import IconInCircles from '../../components/common/IconInCircles/IconInCircles';

const ConfirmationModal = ({
  isOpen,
  isCloseButtonDisabled,
  onClose,
  handleConfirm,
}: {
  isOpen: boolean;
  isCloseButtonDisabled: boolean;
  onClose: () => void;
  handleConfirm: () => void;
}): JSX.Element => {
  const loading = useSelector((state: RootState) => state.digitalAssistant.loading);
  const connected = useSelector((state: RootState) => state.digitalAssistant.connected);
  const areMediaPermissionsAccepted = useSelector(
    (state: RootState) => state.digitalAssistant.areMediaPermissionsAccepted
  );
  const dispatch = useDispatch();
  const sliderRef = useRef(null);

  const [isDPLoadingModalOpen, setIsDPLoadingModalOpen] = useState(false);

  const [currentSliderPosition, setCurrentSliderPosition] = useState(0);

  const renderModalButtons = () => {
    let view;
    if (currentSliderPosition === 3 && !loading && !connected) {
      view = (
        <Button
          borderRadius="0"
          onClick={handleConfirm}
          disabled={connected || areMediaPermissionsAccepted === 'disagree'}
        >
          Confirm
        </Button>
      );
    } else if (connected) {
      view = <Button disabled={connected}>Confirm</Button>;
    } else {
      view = (
        <Button
          borderRadius="0"
          onClick={() => sliderRef.current && sliderRef.current.slickNext()}
        >
          Next
        </Button>
      );
    }
    return view;
  };

  const renderConfirmationModal = () => (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (!isCloseButtonDisabled) {
          onClose();
          // reset slider current position
          setCurrentSliderPosition(0);
          // reset user consent state
          dispatch(acceptMediaPermissions('disagree'));
        }
      }}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent borderRadius="0">
      <ModalCloseButton
          _focus={{ boxShadow: 'none' }}
          zIndex="22"
          size='lg'        
        />
        <ModalBody>
          <Slider
            slidesToShow={1}
            slidesToScroll={1}
            dots
            arrows={false}
            infinite={false}
            speed={500}
            ref={sliderRef}
            className="slick-dotted"
            beforeChange={(current, next) => {
              setCurrentSliderPosition(next);
            }}
          >
            <Box>
              <VStack spacing="none">
                <IconInCircles icon="video" theme="success" />
                <Text
                  as="h3"
                  textStyle="text-lg"
                  marginTop="4"
                  textAlign="center"
                >
                  Allow Camera &amp; Mic Access
                </Text>
                <VStack
                  spacing="4"
                  color="gray.500"
                  textAlign="center"
                  marginTop="2"
                >
                  <Text textStyle="text-sm">
                    For me to work best, I need to be able to see you and hear
                    your voice. This will be just like a video call where we can
                    talk face to face.
                  </Text>
                  <Text textStyle="text-sm">
                    <strong>
                      If that sounds okay, please turn on access to your
                      microphone and camera when we request it.
                    </strong>
                  </Text>
                </VStack>
              </VStack>
            </Box>

            <Box>
              <VStack spacing="none">
                <IconInCircles icon="globe" theme="success" />
                <Text
                  as="h3"
                  textStyle="text-lg"
                  marginTop="4"
                  textAlign="center"
                >
                  Speed of Internet connection
                </Text>
                <VStack
                  spacing="4"
                  color="gray.500"
                  textAlign="center"
                  marginTop="2"
                >
                  <Text textStyle="text-sm">
                    <strong>
                      Also, the speed of your internet connection can have a big
                      impact on picture and audio quality during the call.
                    </strong>
                  </Text>
                  <Text textStyle="text-sm">
                    If you experience connectivity issues, the picture quality
                    may temporarily deteriorate or disappear entirely If that
                    happens, try finding a location with a better connection and
                    try again.
                  </Text>
                </VStack>
              </VStack>
            </Box>

            <Box>
              <VStack spacing="none">
                <IconInCircles icon="home" theme="success" />
                <Text
                  as="h3"
                  textStyle="text-lg"
                  marginTop="4"
                  textAlign="center"
                >
                  Please find a quiet environment.
                </Text>
                <VStack
                  spacing="4"
                  color="gray.500"
                  textAlign="center"
                  marginTop="2"
                >
                  <Text textStyle="text-sm">
                    I can find it hard to hear you when you&apos;re in a noisy
                    room or there is a lot of noise in the background. Find a
                    quiet place and let&apos;s keep this one-on-one for now.
                  </Text>
                </VStack>
              </VStack>
            </Box>

            <Box
              overflowY="auto"
              h={currentSliderPosition === 3 ? 'auto' : '0'}
            >
              <VStack spacing="none">
                <IconInCircles icon="home" theme="success" />

                <Text
                  as="h3"
                  textStyle="text-lg"
                  marginTop="4"
                  textAlign="center"
                >
                  Finally, the consent of our virtual try on service
                </Text>

                <VStack
                  spacing="4"
                  color="gray.500"
                  textAlign="center"
                  marginTop="2"
                >
                  <Text textStyle="text-sm">
                    Our virtual try on service provider may collect, capture,
                    store, use, receive or otherwise obtain a scan of your face
                    or any data or information based on the scan of your face
                    which may include your face geometry (“biometric data”) for
                    the purpose of providing you with a personalized virtual try
                    on experience. Your biometric data will not be disclosed to
                    or stored on any server, system or location outside of the
                    device you use to access the virtual try on experience and
                    will be permanently deleted after your use of the virtual
                    try on experience has completed.{' '}
                  </Text>
                  <HStack>
                    <Checkbox
                      id="accept-user-consent"
                      name="accept-user-consent"
                      disabled={currentSliderPosition !== 3}
                      onChange={(e) => {
                        if (e.target.checked) {
                          dispatch(acceptMediaPermissions('agree'));
                        } else {
                          dispatch(acceptMediaPermissions('disagree'));
                        }
                      }}
                      borderColor="blackAlpha.700"
                      pt="3px"
                      alignSelf="flex-start"
                      left="10px"
                    />

                    <Text textStyle="text-sm" variant="unstyled">
                      In order to provide the virtual try on service, you
                      acknowledge that your action of checking this box,
                      constitute an express consent to authorize Perfect Corp.
                      to process your biometric data according to the Privacy
                      Policy.
                    </Text>
                  </HStack>
                </VStack>
              </VStack>
            </Box>
          </Slider>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="1" justify="space-between" align="center">
            <Button
              colorScheme="gray"
              variant="outline"
              onClick={() => {
                onClose();
                // reset slider current position
                setCurrentSliderPosition(0);
                // reset user consent state
                dispatch(acceptMediaPermissions('disagree'));
              }}
              disabled={isCloseButtonDisabled}
            >
              Cancel
            </Button>
            {renderModalButtons()}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
  return (
    <>
      {renderConfirmationModal()}
      {isDPLoadingModalOpen && (
        <LoadingModal
          modalToOpen="DP"
          isModalOpen={isDPLoadingModalOpen}
          closeModal={() => {
            // do nothing
          }}
        />
      )}
    </>
  );
};

export default ConfirmationModal;
