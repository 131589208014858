const pieColors = [
  'rgba(161, 104, 247, 1)',
  'rgba(232, 195, 32, 1)',
  'rgba(186, 8, 2, 1)',
  'rgba(232, 119, 32, 1)',
  'rgba(46, 32, 232, 1)',
  'rgba(104, 247, 243, 1)',
  'rgba(64, 186, 43, 1)',
];

const pieLabels = [
  'Joy',
  'Fear',
  'Anger',
  'Frustrated',
  'Sad',
  'Excited',
  'Satisfied',
];
export { pieColors, pieLabels };
