import {
  AuthState,
  SessionTimeoutState,
} from '../../global-components/GlobalTypes';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: SessionTimeoutState = {
  isOpen: false,
  timer: null,
  isTimerResetActive: true,
};

const SessionTimeoutSlice = createSlice({
  name: 'sessionTimeout',
  initialState,
  reducers: {
    toggleSessionTimeoutModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({
      ...state,
      isOpen: payload,
    }),

    setTimer: (state, { payload }: PayloadAction<NodeJS.Timeout>) => ({
      ...state,
      timer: payload,
    }),

    stopTimer: (state) => {
      clearTimeout(state.timer);

      return {
        ...state,
        timer: null,
      };
    },

    toggleTimerReset: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isTimerResetActive: payload,
    }),
  },
});

export const setLogOutTimer = createAsyncThunk(
  'sessionTimeout/setLogOutTimer',
  async (_, { dispatch }) => {
    const logoutDelay = 600000; // 10 minutes
    const timerID = setTimeout(() => {
      // open SessionTimeoutModal
      dispatch(toggleSessionTimeoutModal(true));
    }, logoutDelay);

    dispatch(SessionTimeoutSlice.actions.setTimer(timerID));
  }
);

export const startLogOutTimer = createAsyncThunk(
  'sessionTimeout/startLogOutTimer',
  async (_, thunk) => {
    const {
      sessionTimeoutState: { timer },
    } = thunk.getState() as {
      sessionTimeoutState: SessionTimeoutState;
    };

    const {
      authenticationState: { userData },
    } = thunk.getState() as {
      authenticationState: AuthState;
    };

    await thunk.dispatch(toggleTimerReset(true));

    // if is logged in
    if (userData.token) {
      clearTimeout(timer);
      thunk.dispatch(setLogOutTimer());
    }
  }
);

export const resetLogOutTimer = createAsyncThunk(
  'sessionTimeout/resetLogOutTimer',
  async (_, thunk) => {
    const {
      sessionTimeoutState: { timer, isTimerResetActive },
    } = thunk.getState() as {
      sessionTimeoutState: SessionTimeoutState;
    };

    const {
      authenticationState: { userData },
    } = thunk.getState() as {
      authenticationState: AuthState;
    };
    // if is logged in
    if (userData.token && isTimerResetActive) {
      clearTimeout(timer);
      thunk.dispatch(setLogOutTimer());
    }
  }
);

export const stopLogOutTimer = createAsyncThunk(
  'sessionTimeout/resetLogOutTimer',
  async (_, thunk) => {
    const {
      authenticationState: { userData },
    } = thunk.getState() as {
      authenticationState: AuthState;
    };

    await thunk.dispatch(toggleTimerReset(false));

    // if is logged in
    if (userData.token) {
      thunk.dispatch(SessionTimeoutSlice.actions.stopTimer());
    }
  }
);

export const { toggleSessionTimeoutModal, stopTimer, toggleTimerReset } =
  SessionTimeoutSlice.actions;

export default SessionTimeoutSlice.reducer;
