import {
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import TextConstants from '../../../constants/TextConstants';
import IconInCircles from '../../common/IconInCircles/IconInCircles';

const VerificationFaildModal = ({
  isModalOpen,
}: {
  isModalOpen: boolean;
}): JSX.Element => (
  <Modal
    isOpen={isModalOpen}
    onClose={() => {
      // do nothing
    }}
    isCentered
  >
    <ModalOverlay
      backdropFilter="auto"
      backdropInvert="10%"
      backdropBlur="0px"
    />
    <ModalContent borderRadius="0" justifyContent="center">
      <Box>
        <Stack spacing="16px" mx="16px" mb="20px" justifyContent="center">
          <Box mt="20px" alignSelf="center" boxSize="48px">
            <IconInCircles icon="warning" theme="warning" />
          </Box>
          <Box>
            <Text textStyle="title" textAlign="center">
              {TextConstants.MODALS.SIGN_UP_MODAL.FAILD_MODAL_TITLE}
            </Text>
            <Text textStyle="subTitle" textAlign="center" mt="8px" mb="20px">
              {TextConstants.MODALS.SIGN_UP_MODAL.FAILD_MODAL_SUBTITLE}
            </Text>
          </Box>
        </Stack>
      </Box>
    </ModalContent>
  </Modal>
);

export default VerificationFaildModal;
