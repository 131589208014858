import {
  CustomizeMakeupCategoryType,
  CustomizeMakeupProductType,
} from '../../global-components/GlobalTypes';

const changeProductsDataFormat = (
  categoriesData: CustomizeMakeupCategoryType[],
  allData: CustomizeMakeupProductType[]
): CustomizeMakeupCategoryType[] => {
  let formatedCategoriesData = categoriesData;
  if (categoriesData.length && allData.length) {
    for (let i = 0; i < formatedCategoriesData.length; i++) {
      const category = formatedCategoriesData[i];
      for (let j = 0; j < category.children.length; j++) {
        const categoryChildren = category.children[j];
        for (let k = 0; k < allData.length; k++) {
          const subCategory = allData[k];
          if (categoryChildren.name === subCategory.category.name) {
            categoryChildren.children = [
              ...categoryChildren.children,
              subCategory,
            ];
          }
        }
      }
    }
    return formatedCategoriesData;
  }
};

export default changeProductsDataFormat;
