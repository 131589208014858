import './IconButton.scss';

import {
  CSSProperties,
  MouseEvent,
  useCallback,
} from 'react';
import {
  ArrowDownShort,
  ArrowUpShort,
  PlusCircle,
  Trash,
  TypeBold,
  TypeItalic,
  TypeUnderline,
} from 'react-bootstrap-icons';
import classNames from 'classnames';
import {IconButtonTypeEnum} from "../../../../constants/Notes";

type IconButtonProps = {
  isDisableFocusOnClick: boolean;
  buttonIconType: IconButtonTypeEnum,
  onClick: (e: MouseEvent) => void;
  className?: string;
  style?: CSSProperties;
};

const IconButton = ({
  className,
  isDisableFocusOnClick,
  buttonIconType,
  onClick,
  style,
} : IconButtonProps) : JSX.Element => {
  const getIcon = useCallback(() => {
    switch (buttonIconType) {
      case IconButtonTypeEnum.BOLD:
        return <TypeBold />;
      case IconButtonTypeEnum.ITALIC:
        return <TypeItalic />;
      case IconButtonTypeEnum.UNDERLINE:
        return <TypeUnderline />;
      case IconButtonTypeEnum.INCREASED:
        return <ArrowUpShort />;
      case IconButtonTypeEnum.DELETE:
        return <Trash />;
      case IconButtonTypeEnum.ADD:
        return <PlusCircle />;
      default:
        return <ArrowDownShort />;
    }
  }, [buttonIconType]);

  const handleMouseDown = (event: MouseEvent) => {
    if (isDisableFocusOnClick) {
      event.preventDefault();
    }
  };

  return (
    <button
      style={style}
      className={classNames('icon-button', className)}
      type="button"
      onClick={onClick}
      onMouseDown={handleMouseDown}
    >
      {getIcon()}
    </button>
  );
};

export default IconButton;
