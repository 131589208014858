import { InfoModalState } from "../../global-components/GlobalTypes";
import { createSlice } from "@reduxjs/toolkit";

const initialState: InfoModalState = {
  isOpen: false,
  title: '',
  message: '',
};

const InfoModalReducer = createSlice({
  name: 'infoModal',
  initialState,
  reducers: {
    setInfoModal: (state, { payload }) => ({ ...initialState, ...payload }),
  },
});

export const { setInfoModal } = InfoModalReducer.actions;

export default InfoModalReducer.reducer;
