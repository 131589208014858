import {
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import TextConstants from '../../constants/TextConstants';
import IconInCircles from '../common/IconInCircles/IconInCircles';

const Modal988 = ({
  isModal988Open,
  closeModal988,
}: {
  isModal988Open: boolean;
  closeModal988: () => void;
}): JSX.Element => {
  const [isSmallerThanIPhoneXR] = useMediaQuery(
    `(max-width: ${TextConstants.SIZES.IPHONE_XR_WIDTH}px)`
  );

  return (
    <Modal
      size={isSmallerThanIPhoneXR ? 'full' : 'md'}
      isOpen={isModal988Open}
      isCentered
      onClose={() => {
        closeModal988();
      }}
      scrollBehavior="inside"
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent borderRadius="0" maxW="3xl">
        <ModalCloseButton _focus={{ boxShadow: 'none' }} />

        <ModalBody
          //fix not scrollable for mobile devices
          paddingBottom={{ base: '150px', iPhoneXR: 'var(--chakra-space-6)' }}
        >
          <VStack spacing="none">
            <IconInCircles icon="info" theme="warning" />
            <VStack
              spacing="4"
              color="black.500"
              textAlign="center"
              marginTop="2"
            >
              <Text textStyle="text-sm" textAlign="justify" color="black">
                {
                  TextConstants.MODALS.MODAL_988.FIRST_SECTION.PARAGRAPHS
                    .FIRST_PART
                }{' '}
                <Link
                  color="blue"
                  fontWeight="500"
                  href={TextConstants.MODALS.MODAL_988.LINK_CHAT}
                  _focus={{ boxShadow: 'unset' }}
                  isExternal
                >
                  {
                    TextConstants.MODALS.MODAL_988.FIRST_SECTION.HYPERLINKS
                      .FIRST
                  }
                </Link>{' '}
                {
                  TextConstants.MODALS.MODAL_988.FIRST_SECTION.PARAGRAPHS
                    .SECOND_PART
                }{' '}
                <Link
                  color="blue"
                  fontWeight="500"
                  _focus={{ boxShadow: 'unset' }}
                  href={TextConstants.MODALS.MODAL_988.LINK_CHAT}
                  isExternal
                >
                  {
                    TextConstants.MODALS.MODAL_988.FIRST_SECTION.HYPERLINKS
                      .SECOND
                  }
                </Link>{' '}
              </Text>
            </VStack>
            <Text
              as="h3"
              textStyle="text-lg"
              marginTop="8"
              color="black"
              fontSize="lg"
            >
              {TextConstants.MODALS.MODAL_988.SECOND_SECTION.TITLE}
            </Text>
            <VStack
              spacing="4"
              color="black.500"
              textAlign="center"
              marginTop="2"
            >
              <Text textStyle="text-sm" textAlign="justify" color="black">
                {TextConstants.MODALS.MODAL_988.SECOND_SECTION.PARAGRAPH}
              </Text>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Modal988;
